import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.button`
  width: ${(props) => props.width ? props.width : '15vw'};
  height: ${(props) => props.height ? props.height : '7vh'};
  background: ${({ theme }) => theme.white};
  border: 1px solid ${(props) => props.color ? props.color : props.theme.primaryGreen};
  transition: 0.3s;
  color: ${(props) => props.color ? props.color : props.theme.primaryGreen};
  font-weight: 600;
  font-size: 2rem;
  border-radius: ${({ borderRadius }) => borderRadius || '8px'};
  display: flex;
  align-items: center;
  justify-content: center;
  
  :hover {
    border: 1px solid ${(props) => props.colorHover ? props.colorHover : props.theme.secondaryLightGreen};
    color: ${(props) => props.colorHover ? props.colorHover : props.theme.secondaryLightGreen};
  }

  :disabled {
    border: 1px solid ${({ theme }) => theme.grayInactivy};
    color: ${({ theme }) => theme.grayInactivy};
    cursor: default;
  }
  
  @media ${device.mobileL} {
    width: 140px;
  }
`

export const Text = styled.span`
  font-size: ${(props) => props.smallFont ? '1.4rem' : '1.6rem'};
  margin-left: 0.3vw;
  margin-top: 0.3vh;

  @media ${device.mobileL} {
    font-size: 16px;
    margin-left: 0.9vw;
  }
`
