import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;

  @media ${device.mobileL} {
    width: 100vw;
    padding: 0 20px;
    margin: 1.8% 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  border-radius: 16px 16px 48px 16px;
  background-color: ${({ theme }) => theme.grayBackgrounds};

  @media ${device.mobileL} {
    height: 278px;
    margin-top: 1.2%;
    border-radius: 0px
  }
`

export const ListLoadingContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProductsAlert = styled.span`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme }) => theme.graySecondaryText};

  align-self: center;
`
