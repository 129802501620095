import styled from 'styled-components'

export const Container = styled.div`
  width: 50vw;
  height: 65vh;
  border-radius: 40px 16px;
  background: ${props => props.theme.white};
  display: flex;
  z-index: 50;
  flex-direction: column;
`

export const Header = styled.div`
  height: 10vh;
  width: 100%;
  padding: 0 2vw;
  background: ${props => props.theme.grayBackgrounds};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 40px;
  border-top-right-radius: 16px;
`

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: ${props => props.theme.black};
`

export const CloseButton = styled.div`
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  background: ${props => props.theme.grayInactivy};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Content = styled.div`
  width: 50vw;
  height: 55vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`

export const ChildContainer = styled.div`
  flex: 1;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const DoubleInputArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const ButtonsArea = styled.div`
  height: 20%;
  width: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 40px;
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  place-self: flex-end;
`

export const ButtonsContainer = styled.div`
  width: 70%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`