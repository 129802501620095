import React from 'react'
import styled, { css } from 'styled-components'
import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { theme } from '../../theme'
import { device } from '../../styles/sizes'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;

  @media ${device.mobileL} {
    width: 95%;
  }
  `

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 40%;
  justify-content: space-between;

  @media ${device.mobileL} {
    width: 100%;
  }
`

export const Title = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayMainText};
  `

export const TitleContainer = styled.div`
  width: 100%;
  height: 30%;
  padding: 2% 5%;

  @media screen {
   padding: 2% 0;
  }
`

export const SubTitle = styled(Title)`
  font-size: 1.4rem;
  align-self: flex-start;
`

export const LabelArea = styled.div`
  display: flex;
  width: 89%;
  height: 16%;
  justify-content: flex-start;
  align-items: center;
  
  .left {
    width: 45%;
  }
  
  .right {
    width: 55%;
  }

  @media ${device.mobileL} {
    .left {
      width: 85%;
    }
  }
`

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ValueRangeSlider = styled(Slider)`

  .MuiSlider-root {
    color: ${({ theme }) => theme.primaryGreen};
    }
    
    .MuiSlider-thumb {
    height: 24px;
    width: 24px;
    background-color: ${({ theme }) => theme.white};
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.primaryGreen};
    margin-top: -8px;
    &:focus, :hover, :active {
      box-shadow: inherit;
      color: ${({ theme }) => theme.primaryGreen};
    }
  }

  .MuiSlider-track {
    height: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.primaryGreen};
  }
  .MuiSlider-rail {
    height: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.graySecondaryText};
  }

  @media ${device.mobileL} {
    width: 95%;
  }
`

function CustomTextField(props) {
  const { classes } = props

  return (
    <CurrencyTextField
      {...props}
      variant="outlined"
      currencySymbol="R$"
      decimalCharacter=","
      digitGroupSeparator="."
      minimumValue={0}
      maximumValue={10000}
      style={{
        borderColor: 'red',
      }}
      InputProps={{
      }}
    />
  )
}

export const RangeInput = styled(CustomTextField)`
  width: 40%;

  .MuiOutlinedInput-root {
    fieldset {
      border-color: ${({ theme }) => theme.graySecondaryText};
      border-radius: 6px;
    }
  }

  :hover fieldset {
    border-color: ${({ theme }) => theme.graySecondaryText};
  }
    
  .Mui-focused fieldset {
      border-color: ${({ theme }) => theme.primaryGreen} !important;
  }

  .MuiTypography-root {
    color: ${({ theme }) => theme.primaryGreen} !important;
    font-weight: 600 !important;
    font-size: 1.4rem;
  }
  
  .MuiInputBase-input{
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primaryGreen};
    font-weight: 600;
    text-align: start !important;
  }

  .MuiOutlinedInput-input {

  }

  transition: 0.3s;
`

export const ClearButton = styled.button`
  color: ${({ theme }) => theme.grayMainText};
  font-size: 1.2rem;
  font-weight: 700;
  `

export const ClearButtonArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;