import React from 'react'
import { FiPlus } from 'react-icons/fi'

import { Container, Text } from './styles'

export default function ButtonPrimaryIconLeft({ width, height, text, background, backgroundHover, color, smallFont, icon, ...rest }) {
  return (
    <Container 
      width={width}
      height={height} 
      background={background} 
      backgroundHover={backgroundHover} 
      color={color}
      {...rest}
    >
      {icon || <FiPlus />}
      <Text smallFont={smallFont}>
        {text} 
      </Text>
    </Container>
  )
}
