import api, { getProfileImageFormData } from './api'

async function updateStore(image, userId) {

  const imageData = new FormData()
  imageData.append('file', image)

  const { data } = await api.post(`user/upload-image/${userId}`, imageData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${imageData._boundary}`
    }
  })

  return data
}

export async function ValidateAccount(id) {
  return await api.post('active', { id: id })
}

async function changeStoreStatus(id) {
  const response = await api.put(`store/open/${id}`)

  return response
}

async function getFAQ(params) {
  const response = await api.get('store/support/faq', {
    params: {
      ...params
    }
  })

  return response
}

async function verifyEmail(email) {
  const response = await api.get(`user/email/${email}/store`)
  return response
}

async function fetchWalletBalance(storeId) {
  const response = await api.get(`store/${storeId}/wallet-balance`)
  return response
}

async function withdrawBalance(amount, reciever_id, user_token) {
  const response = await api.post(`store/transfer`, {
    amount: amount,
    reciever_id: reciever_id,
    user_token: user_token,
  })
  return response
}

async function addBankAccount(userId, bank, agency, account, accountType) {
  const response = await api.post(`store/${userId}/bank`, {
    bankDetails: {
      bank,
      agency,
      account,
      accountType
    }
  })

  return response
}

async function updatedBankAccount() {
  
}

async function sendMessageToSupport(storeId, body) {
  const response = await api.post(`store/message/${storeId}`, body)
  return response
}

async function uploadImageMessage(file) {
  const imageData = getProfileImageFormData(file)
  const response = await api.post('store/upload-message-image', imageData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${imageData._boundary}`
    }
  })
  return response
}

async function sendForgotPasswordEmail(email) {
  const response = await api.post('reset', { email, type: 'store' })
  return response
}

async function getInbox(storeId) {
  const response = await api.get(`store/${storeId}/inbox`)
  return response
}

async function readInboxMessage(storeId, messageId) {
  const response = await api.patch(`store/${storeId}/inbox/${messageId}`)
  return response
}

async function getStoreData(id, token) {
  const response = await api.get(`user/${id}/type/store`, {
    headers: { token }
  })
  return response
}

export {
  sendForgotPasswordEmail,
  updateStore,
  changeStoreStatus,
  getFAQ,
  verifyEmail,
  addBankAccount,
  fetchWalletBalance,
  sendMessageToSupport,
  uploadImageMessage,
  getInbox,
  readInboxMessage,
  getStoreData
}
