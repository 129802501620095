import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
`

export const SectionTitle = styled.h4`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${(props) => props.theme.grayMainText};
  margin-top: 3%;
`

export const Section = styled.section`
  margin-left: 1%;
  padding-top: 2%;
  display: flex;
  flex-direction: column;
  width: 80%;
`

export const MiniSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4%;
`

export const TypeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2%;
`

export const NotificationTypeText = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${props => props.theme.graySecondaryText};
  margin-right: 0.5vw;

`

export const TextSection = styled.div`
  width: 30%;
  display: flex;
  margin-left: 2%;
`

export const Subtitle = styled.span`
  color: ${props => props.theme.graySecondaryText};
  font-size: 1.4rem;
  font-weight: 700;
`

export const SwitchArea = styled.div`
  width: 20%;
  display: flex;
  justify-content: space-between;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.mBottom || 0};
`

export const Text = styled.span`
  font-size: 1.6rem;
  color: ${(props) => props.theme.grayMainText};
  font-weight: 700;
`
