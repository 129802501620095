import React from 'react'
import {
  SeeMoreContainer,
  SeeMoreContent,
  AccordionDescription,
} from './styles'

import { Modal } from '../../../../components/modal'

export default function AccordionModal({ item }) {
  return (
    <Modal
      title={item.title}
      width='40vw'
      height='auto'
    >
      <SeeMoreContainer>
        <SeeMoreContent>
          <AccordionDescription>{item.content}</AccordionDescription>
        </SeeMoreContent>
      </SeeMoreContainer>
    </Modal>
  )
}
