import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const FirstContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 20%;
`

export const IconContainer = styled.div`
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  background: ${({ theme }) => theme.secondaryBlue};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
`

export const BalanceText = styled.h3`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({theme}) => theme.grayMainText};
  `

export const BalanceValue = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.black};
`

export const SecondContent = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`
export const ChartContainer = styled.div`
  width: 8vw;
  height: 8vw;
`

export const LegendDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 20vh;
  justify-content: space-evenly;
`

export const CircleLegend = styled.div`
  width: 2vh;
  height: 2vh;
  border-radius: 50%;
  background: ${({ color }) => color ? color : ''};
`

export const ChartLegendContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ChartLegend = styled.span`
  font-weight: 600;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.grayMainText};
`

export const ChartValue = styled.span`
  font-weight: 700;
  font-size: 1rem;
  color: ${({ theme }) => theme.black};
`

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
`
