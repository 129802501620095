import React, { useEffect } from 'react'

import { sendValidationCode, confirmValidationCode } from '../../../../../api/api'
import {
  Container,
  ContentSide,
  Divider,
  Content,
  ContentInfo,
  BackgroundIcon,
  ContentBankInfo
} from './styles'

import {
  SubTitle,
  Description,
  ValueTitle,
  AccountType,
  AgencyTitle,
  BankTitle,
  Footer,
} from '../../modalWithdrawBalance/styles'

import { ReactComponent as BankIcon } from '../../../../../assets/bankIcon.svg'

import { formatAndMaskPhoneNumber, formatMoneyBRL } from '../../../../../Utils'
import { useAuth } from '../../../../../context/AuthContext'
import { useTheme } from '../../../../../context/ThemeContext'
import { useModal } from '../../../../../context/ModalContext'

import { Modal } from '../../../../../components/modal'
import MainInput from '../../../../../components/inputs/mainInput'
import ButtonLink from '../../../../../components/buttons/buttonLink'
import ButtonPrimary from '../../../../../components/buttons/buttonPrimary'
import ButtonSecondary from '../../../../../components/buttons/buttonSecondary'

export default function ThirdStep({ backStep, formProps }) {

  const { user } = useAuth()
  const { currentTheme } = useTheme()
  const { setModal } = useModal()

  useEffect(() => {
    handleSendCodeConfirmationSms()
  }, [])

  async function handleSendCodeConfirmationSms() {
    await sendValidationCode(user?.storeData.email, user?.storeData.phoneNumber, false)
  }

  async function handleValidateCode() {
    const response = await confirmValidationCode(user?.storeData.email, formProps.values.code)
    if (response.data.validation) {
      //chamar função para realizar o pagamento / transferência
      formProps.handleSubmit()
    } else {
      setModal(
        <Modal
          title='Atenção'
          width='35vw'
          height='30vh'
        >
          <Content>
            <ValueTitle>Código inválido</ValueTitle>
          </Content>
        </Modal>
      )
    }
  }

  return (
    <Container>
      <Content>
        <ContentSide width='40%'>
          <Description>Valor</Description>
          <ValueTitle>R$ {formatMoneyBRL(formProps.values.withdrawalQuantity)}</ValueTitle>

          <ContentBankInfo>
            <BackgroundIcon>
              <BankIcon height='2rem' width='2rem' fill={currentTheme.white} />
            </BackgroundIcon>
            <div className='defaultSpace' />
            <BankTitle>{formProps.values.accountForWithdrawal.name}</BankTitle>
            <AccountType>{formProps.values.accountForWithdrawal.accountType}</AccountType>
            <div className='defaultSpace' />
            <AgencyTitle>{`Ag. ${formProps.values.accountForWithdrawal.agency}`}</AgencyTitle>
            <AgencyTitle>{`Conta ${formProps.values.accountForWithdrawal.account}`}</AgencyTitle>
          </ContentBankInfo>

        </ContentSide>
        <Divider />
        <ContentSide width='60%'>
          <SubTitle>Etapa de segurança:</SubTitle>
          <ContentInfo>
            <AccountType>Para sua segurança um código foi enviado para o número de telefone cadastrado na sua conta Gowdock</AccountType>
            <ValueTitle>{formatAndMaskPhoneNumber(user.storeData.phoneNumber)}</ValueTitle>

            <MainInput
              width='100%'
              maxLength={6}
              placeholder='Inserir código'
              value={formProps.values.code}
              onChange={(event) => formProps.setFieldValue('code', event.target.value)}
            />

            <div>
              <AccountType>Não tem acesso ao seu celular?</AccountType>
              <ButtonLink
                withIcon={false}
                text='Enviar para email cadastrado.'
                onClick={handleSendCodeConfirmationSms}
              />
            </div>
          </ContentInfo>
        </ContentSide>
      </Content>

      <Footer>
        <ButtonSecondary
          text='Voltar'
          width='30%'
          onClick={backStep}
        />
        <div />
        <ButtonPrimary
          type='submit'
          text='Confirmar Retirada'
          width='30%'
          background={currentTheme.primaryPink}
          backgroundHover={currentTheme.secondaryPink}
          onClick={handleValidateCode}
          disabled={formProps.values.code.length < 6}
        />
      </Footer>
    </Container>
  )
}
