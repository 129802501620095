import formModel from './formModel'

const {
  formField: {
    userName,
    number,
    email,
    description,
    selectedDocs,
  }
} = formModel

const initialValues = {
  [userName.name]: '',
  [number.name]: '',
  [email.name]: '',
  [description.name]: '',
  [selectedDocs.name]: '',
}

export default initialValues
