import React from 'react'
import { ErrorMessage, useField } from 'formik'
import MainInput from '../../../../../components/inputs/mainInput'

import { Container } from './styles'
import { ErrorMsg } from '../../../account/styles'

export default function Textfield({width, ...props}) {
  const [field, meta] = useField(props)

  return (
    <Container width={width}>
      <MainInput
        {...field}
        {...props}
      />
      <ErrorMessage 
        name={field.name}
        render={msg => <ErrorMsg>{msg}</ErrorMsg>}
      />
    </Container>
  )
}
