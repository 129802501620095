const colors = {
  // primary
  primaryGreen: '#2C992B',
  mobileGreen: '#E1EBD0',
  primaryPink: '#951159',

  // secondary
  secondaryLightGreen: '#96c444',
  secondaryNeutralGreen: '#63bb45',
  secondaryPink: '#5a0a27',
  secondaryLightPink: '#F20D8A',
  secondaryDarkPurple: '#6b4b55',
  secondaryPurple: '#7b5da3',
  secondaryYellow: '#efb957',
  secondaryBlue: '#7dc4d3',

  // neutral 
  black: '#000',
  white: '#fff',
  grayMainText: '#605e70',
  graySecondaryText: '#b8b6c3',
  grayInactivy: '#c1ccc1',
  grayBackgrounds: '#f6f9f9',

  // status
  statusBlue: '#2f80ed',
  statusGreen: '#01c782',
  statusYellow: '#ffaa15',
  statusRed: '#ff4041',

  
  title_purple: '#5C072D',
  span_green: '#018F14',
  secondary_green: '#076b07',
  light_green: '#96c444',
  span_purple: '#961359',
  pink: '#951159',
  yellow: '#ebca06',
  orange: '#f35c02',
  light_orange: '#f49d00',
  gray_background: '#F5F6F4',
  gray_text: '#666',
  background_grey: '#F5F6F4',
  icons_gray: '#C6C7C5',
  light_blue: '#7DC4D3',
  border_purple: '#947782',
  gray_line: '#F5F6F1',
  light_gray: '#A6A6A6',
  text_gray: '#CACACA',
  default_bg_gray: '#c4c4c4',
  gray_title: '#bbb',
  border_gray: '#9DA99D',
  light_gray_text: '#9B9B9B',
  gray_navbar: '#f6f9f9',
  neutro: '#605E70'
}

export default colors