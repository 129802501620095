import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: ${({ width }) => width || 'auto'};
  text-decoration: ${({ underline }) => underline && 'underline' || 'none'};
  color: ${({ theme, color }) => color || theme.primaryGreen};
  
  :hover {
    color: ${({ theme }) => theme.secondaryLightGreen};
  }
  
  :disabled {
    color: ${({ theme }) => theme.grayInactivy};
  }

  transition: 0.3s;
  `

export const Text = styled.span`
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize || '1.6rem'};
  ${({ sideIcon }) => sideIcon === 'left' ? 'padding-left: 8px' : sideIcon === 'right' && 'padding-right: 8px'};

  @media ${device.mobileL} {
    font-size: 1.2rem;
  }
`
