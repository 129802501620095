import React, { useEffect, useState } from 'react'

import {
  Container,
  Content,
  OrderNumber,
  BuyerName,
  AmmountItems,
  DateTime,
  IconBackground,
  OrderLabel,
  FocusBackground,
  ShortContainer
} from './styles'
import { FiSearch } from 'react-icons/fi'
import { ReactComponent as GowdockIcon } from '../../assets/white_logo.svg'

import { translatePurchaseStatus } from '../../Utils'
import { useTheme } from '../../context/ThemeContext'

import {
  OPEN,
  ACCEPTED,
  SEPARATED,
  DELIVERING,
  DELIVERED,
  CANCELLED,
  CLOSED
} from '../../Utils'

export default function Order({
  width,
  height,
  marginVertical,
  orderNumber,
  buyerName,
  ammountItems,
  dateTime,
  orderStatus,
  onClick,
  short
}) {

  const { currentTheme } = useTheme()

  function handleSetOrderColor(status) {
    switch (status) {
      case OPEN:
        return currentTheme.statusGreen

      case ACCEPTED:
        return currentTheme.secondaryNeutralGreen

      case SEPARATED:
        return currentTheme.secondaryPurple

      case DELIVERING:
        return currentTheme.statusYellow

      case DELIVERED:
        return currentTheme.secondaryBlue

      case CANCELLED:
        return currentTheme.statusRed

      case CLOSED:
        return currentTheme.secondaryBlue
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 650);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    window.addEventListener('resize', handleResize);

    // Limpe o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container
      width={width}
      height={height}
      marginVertical={marginVertical}
    >
      <Content
        height={height}
      >
        <IconBackground bgColor={handleSetOrderColor(orderStatus)}>
          <GowdockIcon />
        </IconBackground>
        {!short ?
          <>
            <OrderNumber>{orderNumber}</OrderNumber>
            {
              isMobile
                ?
                null
                :
                <>
                  <BuyerName>{buyerName}</BuyerName>
                  <DateTime>{dateTime}</DateTime>
                </>
            }
          </>
          :
          <ShortContainer>
            <OrderNumber>{orderNumber}</OrderNumber>
            <DateTime>{dateTime}</DateTime>
          </ShortContainer>
        }
        {
          isMobile
            ?
            null
            :
            <AmmountItems>{ammountItems} itens</AmmountItems>
        }
        {!short &&
          <OrderLabel bgColor={handleSetOrderColor(orderStatus)}>{translatePurchaseStatus(orderStatus)}</OrderLabel>
        }
        <FocusBackground onClick={onClick} bgColor={handleSetOrderColor(orderStatus)}>{translatePurchaseStatus}
          <FiSearch size='2rem' color={handleSetOrderColor} />
        </FocusBackground>
      </Content>
    </Container>
  )
}
