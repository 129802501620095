import React, { useState, useRef, useEffect } from 'react'

import {
  Container,
  Content,
  IconDiv,
  ListItem,
  Option,
  OptionList,
  SelectContainer,
  Text,
} from './styles'

import SelectIcon from '../../../assets/select.svg'
import { FiChevronDown } from 'react-icons/fi'

export default function Select({
  height,
  width,
  options,
  mTop,
  mBottom,
  currentValue,
  title,
  setFieldValue,
  error,
  onValueChange,
  
  ...props
}) {
  const [value, setValue] = useState(currentValue || title)
  const [open, setOpen] = useState(false)

  const selectRef = useRef(null)

  useEffect(() => {
    window.addEventListener('click', addBackDrop)
    return () => window.removeEventListener('click', addBackDrop)
  }, [open])

  function addBackDrop(e) {
    if (open && !e.path.includes(selectRef.current)) {
      setOpen(false)
    }
  }

  function handleClick(item) {
    setValue(item)
    setFieldValue && setFieldValue(props.name, item)
    onValueChange && onValueChange(item)
    setOpen(false)
  }

  function handleOpen() {
    setOpen(!open)
  }

  return (
    <Container width={width} height={height} mTop={mTop} mBottom={mBottom}>
      <Content>
        <SelectContainer
          {...props}
          error={error}
          onClick={handleOpen}
          ref={selectRef}
        >
          <Text>
            {value}       
          </Text>
          <IconDiv isOpen={open}>
            <FiChevronDown />
          </IconDiv>
        </SelectContainer>        
        {open && (         
          <Option>          
            <OptionList > 
              {options.map((item, index) => (
                <ListItem                              
                  onClick={() => handleClick(item)}
                  key={index}
                >
                  {item}               
                </ListItem>
              ))}  
            </OptionList> 
          </Option>
        )}      
      </Content>
    </Container>
  )
}
