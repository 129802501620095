const data = {
  home: {
    name: 'Início',
    link: '/'
  },

  orders: {
    name: 'Pedidos',
    link: '/management',

    management: {
      name: 'Gerenciamento',
      link: '/management',
    },
    history: {
      name: 'Histórico',
      link: '/history',
    },

  },

  store: {
    name: 'Vitrini',
    link: '/products',

    products: {
      name: 'Seus produtos',
      link: '/products',
    },
    addProduct: {
      name: 'Adicionar produto',
      link: '/addproduct',
    },

  },

  financial: {
    name: 'Financeiro',
    balance: {
      name: 'Balanço',
      link: '/financial',
    },
    access: {
      link: '/accessfinancial'
    }

  },

  suport: {
    name: 'Suporte',
    link: '/doubt',

    doubts: {
      name: 'Dúvidas',
      link: '/doubt',
    },
    sac: {
      name: 'Fale conosco',
      link: '/contact-us',
    },

  },

  settings: {
    name: 'Configurações',
    link: '/config'
  },
}

export default data
