import React, { useState, useEffect } from 'react'
import { FiUser } from 'react-icons/fi'
import { CircularProgress } from '@material-ui/core'

import EmptyIcon from '../../../assets/empty.svg'
import {
  Container,
  Headers,
  HeaderContainer,
  HeaderName,
  HeaderAmount,
  Lists,
  ListContainer,
  ScrollContainer,
  ItemContainer,
  Description,
  OrderId,
  Date,
  Amount,
  Texts,
  Timer,
  ClientContainer,
  IconContainer,
  ClientName,
  PlaceholdImage,
  Outside,
  Tooltip,
  LoadingArea,
  CenterDiv,
  NoProductsText,
  OrderIdDetail,
  SectionGroup
} from './StepsStyles'

import { ACCEPTED, SEPARATED, DELIVERING, DELIVERED, OPEN, formatOnlyDate } from '../../../Utils'
import { formatShortDate } from '../../../Utils'

import { useAuth } from '../../../context/AuthContext'
import { useTheme } from '../../../context/ThemeContext'
import { getPurchases } from '../../../api/purchases'

import WhiteLogo from '../../../assets/white_logo.svg'
import Logo from '../../../assets/logo/Marca.svg'
import { ReactComponent as TruckIcon } from '../../../assets/truck.svg'

export function Item({ color, backgroundColor, click, active, percentage, item, hideName, truckIcon, isMobile }) {
  const uncheckedItems = item?.storeOrder[0]?.products?.filter(item => item.status === SEPARATED).length
  const {
    currentTheme: { grayMainText, primaryPink },
  } = useTheme()
  
  function tooltipMessage() {
    switch (item.status) {
      case OPEN:
        return 'Hora da realização do pedido'
      case ACCEPTED:
        return 'Itens separados'
      case SEPARATED:
        return 'Tempo para chegada do entregador'
      case DELIVERING:
        return 'Tempo para concluir entrega'
      case DELIVERED:
        return 'Hora da conclusão de entrega'
    }
  }

  function timerContent(date) {
    switch (item.status) {
      case OPEN:
        return date
      case ACCEPTED:
        return 'Em separação'
      case SEPARATED:
        return 'Acionar jadlog'
      case DELIVERING:
        return 'Transportadora'
      case DELIVERED:
        return date
    }
  }

  return (
    <Outside>
      <>
        {
          isMobile
            ?
            <ItemContainer onClick={click} active={active} color={color} backgroundColor={backgroundColor}>
              <Date>{formatShortDate(item.createdAt)}</Date>
              <Description>
                <PlaceholdImage color={color}>
                  {color === primaryPink ? <img src={Logo} width={20} height={20} /> : <img src={WhiteLogo} />}
                </PlaceholdImage>
                <Texts>
                  <OrderId active={active}>{item.code}
                    <OrderIdDetail>{item.code}</OrderIdDetail>
                  </OrderId>
                  <Amount active={active}>
                    {item.storeOrder[0]?.products.length} {item.storeOrder[0]?.products.length > 1 ? 'itens' : 'item'}
                  </Amount>
                </Texts>
              </Description>
            </ItemContainer>
            :
            <ItemContainer onClick={click} active={active} color={color} backgroundColor={backgroundColor}>
              <Date>{formatShortDate(item.createdAt)}</Date>
              <Description>
                <PlaceholdImage color={color}>
                  {color === primaryPink ? <img src={Logo} width={20} height={20} /> : <img src={WhiteLogo} />}
                </PlaceholdImage>
                <Texts>
                  <OrderId active={active}>{item.code}
                    <OrderIdDetail>{item.code}</OrderIdDetail>
                  </OrderId>
                  <Amount active={active}>
                    {item.storeOrder[0]?.products.length} {item.storeOrder[0]?.products.length > 1 ? 'itens' : 'item'}
                  </Amount>
                </Texts>
              </Description>
              <Timer color={color}>
                {timerContent(formatOnlyDate(item.createdAt))}
                <Tooltip>{tooltipMessage()}</Tooltip>
              </Timer>
              {!hideName && (
                <ClientContainer>
                  <IconContainer>
                    {truckIcon ? <TruckIcon height={16} /> : <FiUser size={13} color={grayMainText} />}
                  </IconContainer>
                  <ClientName>
                    {item.status === DELIVERED ? item?.client?.name : item?.storeOrder[0]?.collector?.name}
                  </ClientName>
                </ClientContainer>
              )}
            </ItemContainer>
        }
      </>
    </Outside>
  )
}

export default function Steps({ area, setFocusItem, focusItem }) {
  const [activePurchases, setActivePurchases] = useState(null)
  const [separatedPurchases, setSeparatedPurchases] = useState(null)
  const [deliveringPurchases, setDeliveringPurchases] = useState(null)
  const [deliveredPurchases, setDeliveredPurchases] = useState(null)
  const {
    user: { storeData },
  } = useAuth()

  const { currentTheme } = useTheme()
  const {
    secondaryNeutralGreen,
    secondaryPurple,
    secondaryYellow,
    secondaryBlue,
    grayBackgrounds,
  } = currentTheme

  async function fetchAllPurchases() {
    const array = [
      await getPurchases(storeData.id, { status: ACCEPTED }),
      await getPurchases(storeData.id, { status: SEPARATED }),
      await getPurchases(storeData.id, { status: DELIVERING }),
      await getPurchases(storeData.id, { status: DELIVERED }),
    ]

    Promise.all(array).then((data) => {
      setActivePurchases(data[0].data)
      setSeparatedPurchases(data[1].data)
      setDeliveringPurchases(data[2].data)
      setDeliveredPurchases(data[3].data)
    })
  }

  useEffect(() => {
    fetchAllPurchases()
  }, [])

  function Header({ name, amount, color }) {
    return (
      <HeaderContainer color={color}>
        <HeaderName>{name}</HeaderName>
        <HeaderAmount>{amount}</HeaderAmount>
      </HeaderContainer>
    )
  }

  function renderList(purchasesList, color, truckIcon = true, hideName = false, percentage = false) {
    return (
      <ListContainer>
        <ScrollContainer>
          {purchasesList ? purchasesList.length === 0 ? (
            <CenterDiv>
              <img src={EmptyIcon} alt='Empty Icon' />
              <NoProductsText>Nenhum produto nesta etapa</NoProductsText>
            </CenterDiv>
          ) : (
            purchasesList.map((item, index) => {
              return (
                <Item
                  key={index}
                  item={item}
                  color={color}
                  hideName={hideName}
                  percentage={percentage}
                  truckIcon={truckIcon}
                  backgroundColor={grayBackgrounds}
                  active={focusItem.index === index && focusItem.data.status === item.status}
                  click={() => setFocusItem({ data: item, index })}
                  isMobile={isMobile}
                />
              )
            })
          ) : (
            <LoadingArea>
              <CircularProgress style={{ color: currentTheme.secondaryLightGreen }} />
            </LoadingArea>
          )}
        </ScrollContainer>
      </ListContainer>
    )
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 650);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    window.addEventListener('resize', handleResize);

    // Limpe o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {
        isMobile
          ?
          <Container area={area}>
            <SectionGroup>
              <Header name='ativos' amount={activePurchases?.length || 0} color={secondaryNeutralGreen} />
              <Lists>
                {renderList(activePurchases, secondaryNeutralGreen, false, true, true, false)}
              </Lists>
            </SectionGroup>
            <SectionGroup>
              <Header name='separados' amount={separatedPurchases?.length || 0} color={secondaryPurple} />
              <Lists>
                {renderList(separatedPurchases, secondaryPurple)}
              </Lists>
            </SectionGroup>
            <SectionGroup>
              <Header name='transportadora' amount={deliveringPurchases?.length || 0} color={secondaryYellow} />
              <Lists>
                {renderList(deliveringPurchases, secondaryYellow)}
              </Lists>
            </SectionGroup>
            <SectionGroup>
              <Header name='concluídos' amount={deliveredPurchases?.length || 0} color={secondaryBlue} />
              <Lists>
                {renderList(deliveredPurchases, secondaryBlue, false)}
              </Lists>
            </SectionGroup>
          </Container>
          :
          <Container area={area}>
            <Headers>
              <Header name='ativos' amount={activePurchases?.length || 0} color={secondaryNeutralGreen} />
              <Header name='separados' amount={separatedPurchases?.length || 0} color={secondaryPurple} />
              <Header name='transportadora' amount={deliveringPurchases?.length || 0} color={secondaryYellow} />
              <Header name='concluídos' amount={deliveredPurchases?.length || 0} color={secondaryBlue} />
            </Headers>
            <Lists>
              {renderList(activePurchases, secondaryNeutralGreen, false, true, true, false)}
              {renderList(separatedPurchases, secondaryPurple)}
              {renderList(deliveringPurchases, secondaryYellow)}
              {renderList(deliveredPurchases, secondaryBlue, false)}
            </Lists>
          </Container>
      }
    </>
  )
}
