import styled from 'styled-components'

export const TextArea = styled.textarea`
  width: 100%;
  height: ${props => props.height || '24vh'};
  border: 1px solid ${({ theme, error }) => error ? theme.statusRed : theme.graySecondaryText};
  border-radius: 6px;
  padding: 10px;
  resize: none;

  font-size: 1.3rem;
  font-weight: 400;

  background-color: ${({ theme }) => theme.white};

  :focus {
    border: 1px solid ${props => props.theme.primaryGreen};
    box-shadow: 0px 8px 24px rgba(94, 135, 138, 0.1);
  }
`

export const Label = styled.label`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${props => props.theme.grayMainText};
`
