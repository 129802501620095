const formModel = {
  formId: 'checkoutForm',
  requiredErrorMsg: 'Este campo é obrigatório.',
  requiredOptionErrorMsg: 'Por favor, selecione uma opção.',
  maskInputMsg: 'Campo incompleto',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'Nome',
    },
    lastName: {
      name: 'lastName',
      label: 'Sobrenome',
    },
    cpf: {
      name: 'cpf',
      label: 'CPF',
      notValidError: 'Por favor, digite um CPF válido',
    },
    email: {
      name: 'email',
      label: 'Email',
      notValidError: 'Por favor, digite um email válido',
    },
    password: {
      name: 'password',
      label: 'Senha',
      minError: 'Sua senha deve ter no mínimo 8 caracteres',
    },
    confirmPassword: {
      name: 'confirmPassword',
      label: 'Confirmação de senha',
      notEqualError: 'As senhas devem ser iguais',
    },
    numberPhone: {
      name: 'numberPhone',
      label: 'Número do celular',
    },
    code: {
      name: 'code',
      label: 'Código',
      minError: 'O código tem 6 caracteres',
    },
    cep: {
      name: 'cep',
      label: 'CEP',
    },
    address: {
      name: 'address',
      label: 'Endereço',
    },
    neighborhood: {
      name: 'neighborhood',
      label: 'Bairro',
    },
    city: {
      name: 'city',
      label: 'Cidade',
    },
    state: {
      name: 'state',
      label: 'Estado (nome)',
    },
    number: {
      name: 'number',
      label: 'Número',
    },
    complement: {
      name: 'complement',
      label: 'Complemento (opcional)',
    },
    logo: {
      name: 'logo',
    },
    cnpj: {
      name: 'CNPJ',
      label: 'CNPJ',
    },
    cnpjPhoto: {
      name: 'CNPJphoto',
    },
    fantasyName: {
      name: 'fantasyName',
      label: 'Nome empresarial',
    },
    socialReason: {
      name: 'socialReason',
      label: 'Razão social',
    },
    isTermsChecked: {
      name: 'isTermsChecked'
    },
    InputFileLabel: 'Carregar arquivo',
    InputFileLabelLoaded: 'Arquivo carregado',
  },
}

export default formModel
