import styled from 'styled-components'
import { device } from '../../styles/sizes'

export const Container = styled.div`
  display: grid;
  grid-template-areas: 
  'balance requests requests'
  'stock manageRequests manageRequests';
  width: 100%;
  height: 87%;
  justify-content: center;
  align-items: center;
  grid-template-columns: 38.0298387% 22.2580645% 30.3225806%;
  grid-template-rows: 39% 61%;
  column-gap: 1.93%;

  @media ${device.mobileL} {
    display: flex;
    flex-direction: column;
  }
`

export const ContainerComponentArea = styled.div`
  grid-area: ${({ gArea }) => gArea || ''};
  width: 100%;
  height: 100%;
`
