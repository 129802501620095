import styled from 'styled-components'

export const Container = styled.button`
  width: ${(props) => props.width ? props.width : '15vw'};
  height: ${(props) => props.height ? props.height : '7vh'};
  background: ${({ theme }) => theme.white};
  border: 1px solid ${(props) => props.color ? props.color : props.theme.primaryGreen};
  transition: 0.3s;
  color: ${(props) => props.color ? props.color : props.theme.primaryGreen};
  font-weight: 600;
  font-size: 2.2rem;
  border-radius: ${({ borderRadius }) => borderRadius || '8px'};
  display: flex;
  align-items: center;
  justify-content: center;
  
  :hover {
    border: 1px solid ${(props) => props.colorHover ? props.colorHover : props.theme.secondaryLightGreen};
    color: ${(props) => props.colorHover ? props.colorHover : props.theme.secondaryLightGreen};
  }

  :disabled {
    border: 1px solid ${({ theme }) => theme.grayInactivy};
    color: ${({ theme }) => theme.grayInactivy};
    cursor: default;
  } 
`

export const Text = styled.span`
  font-size: ${(props) => props.smallFont ? '1.4rem' : '1.6rem'};

  margin-right: 0.3vw;
`
