import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ImageContainer = styled.div`
  background: ${props => props.theme.grayInactivy};
  width: 10vh;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 3% ;
`

export const Text = styled.span`
  color: ${props => props.theme.grayMainText};
  font-size: 2.2rem;
  font-weight: 700;
`
