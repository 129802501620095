import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
`

export const BalanceArea = styled.div`
  flex: 1;
  padding: 0 4vw;
  padding-bottom: 4vh;
  display: flex;
  flex-direction: column;
`

export const SalesArea = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height ? height : 'auto'};
  width: ${({ width }) => width ? width : 'auto'};
`

export const RevenueArea = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const Title = styled.h3`
  padding: 0;
  margin: 0;
  font-size: 1.625rem;
  font-weight: 600;
  color: ${({ theme }) => theme.black};
  margin-bottom: 0.5vh;
`

export const PositionCircle = styled.div`
  width: 4vh;
  height: 4vh;
  border-radius: 50%;
  background: ${({ theme }) => theme.secondaryLightGreen};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Position = styled.span`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.white};
`

export const ItenTextContainer = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
`

export const Itentext = styled.span`
  font-size: 1.1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.black};
`

export const ItemSmallText = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  color: ${({ theme }) => theme.grayMainText};
`

export const ItenValue = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryGreen};
`