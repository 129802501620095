import React from 'react'
import { FiX } from 'react-icons/fi'

import {
  Container,
  Exit,
  Header,
  TableAndText,
  TableContainer,
  ProductsTable,
  TableHead,
  TH,
  TBody,
  Inside,
  BottomButtonsContainer,
} from './ModalItemsStyles'

import { ACCEPTED } from '../../../Utils'

import ButtonPrimary from '../../../components/buttons/buttonPrimary'

import { useTheme } from '../../../context/ThemeContext'
import { Item } from './Item'

export default function ModalItems({ exit, data, acceptFunction }) {
  const { code, status, storeOrder } = data
  const { products } = storeOrder[0]
  const isOrderAcepted = status === ACCEPTED
  const {
    currentTheme: { grayMainText, secondaryPurple, secondaryPink },
  } = useTheme()

  function handleExit() {
    exit()
  }

  return (
    <Container>
      <Header>
        <h4>Itens</h4>
        <span>Pedido {code}</span>
        <Exit onClick={handleExit}>
          <FiX color={grayMainText} size={35} strokeWidth={1} />
        </Exit>
      </Header>

      <Inside>
        <TableAndText>
          <TableContainer>
            <ProductsTable>
              <TableHead>
                <TH width='10%' border_tl></TH>
                <TH left={true} width='19%'>
                  Produto
                </TH>
                <TH width='10%' left={true}>
                  Quantidade
                </TH>
                <TH width='15%'>Tipo</TH>
                <TH width='15%'>Valor</TH>
              </TableHead>
              <TBody>
                {products.map((item, index) => (
                  <Item key={index} item={item}  />
                ))}
              </TBody>
            </ProductsTable>
          </TableContainer>
        </TableAndText>
        {isOrderAcepted && (
          <BottomButtonsContainer>
            <ButtonPrimary
              text='Finalizar preparo'
              width='35%'
              background={secondaryPurple}
              backgroundHover={secondaryPink}
              onClick={() => acceptFunction()}
            />
          </BottomButtonsContainer>
        )}
      </Inside>
    </Container>
  )
}
