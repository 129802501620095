import React from 'react'

import { Container, ImageContainer, Text } from './styles'

import { ReactComponent as BankSvg } from '../../../../../assets/bankIcon.svg'
import { useTheme } from '../../../../../context/ThemeContext'

export default function Step4() {
  const { currentTheme } = useTheme()

  return (
    <Container>
      <ImageContainer>
        <BankSvg fill={currentTheme.white} width='6vh' height='auto' />
      </ImageContainer>
      <Text>Conta bancária adicionada com sucesso! 🎉</Text>
    </Container>
  )
}
