import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Content = styled.div`
  width: 100%;
  height: 60vh;
  overflow-y: auto;
  padding-bottom: 5%;
`

export const Overflow = styled.div`
  width: 100%;
  height: 56vh;
  overflow: hidden;
  border-bottom-right-radius: 50px;
`

export const HeaderInfo = styled.div`
  display: flex;
  padding: 0 2%;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 4vh;

  font-weight: bold;
  font-size: 1.4rem;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryPink};
`

export const MessageContainer = styled.div`
  width: 100%;
  height: 16vh;
  margin: 1vh 0;
  `

export const MessageContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%;
  `

export const MessageImgBg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7vw;
  height: 7vw;
  border-radius: 6px;
  background-color: ${({ bgColor, theme }) => bgColor || theme.grayBackgrounds};
`

export const MessageImg = styled.img`
  width: 7vw;
  height: 7vw;
  border-radius: 6px;
`

export const MessageTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  /* background-color: orange; */
  `

export const Title = styled.h1`
  font-weight: 700;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.black};
  `

export const Description = styled.h2`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  width: 80%;
  overflow: hidden;
  font-weight: 400;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.grayMainText};
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: flex-end;
  align-items: center;
`

export const ReadContainer = styled.div`
  display: flex;
  height: 100%;
  width: 5%;
  justify-content: center;
  align-items: center;
  /* background-color: palegoldenrod; */
`
