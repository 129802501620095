import React from 'react'

import { Container } from './styles'

export default function ButtonSecondary({ width, height, text, color, colorHover, smallFont, backgroundColor,  ...rest }) {
  return (
    <Container
      width={width}
      height={height}
      colorHover={colorHover}
      color={color}
      smallFont={smallFont}
      backgroundColor={backgroundColor}
      {...rest}
    >
      {text}
    </Container>
  )
}
