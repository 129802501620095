import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FiSend } from "react-icons/fi";


const Window = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  bottom: 20%;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
`;

const Header = styled.div`
  background-color: #2C992B;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 80%;
  overflow-y: scroll;
  padding-bottom: 40px;
  background-color: white;
`;

const MessageInput = styled.input`
  width: 100%;
  margin-top: 10px;
  padding: 5px;
  color: black;
  border-width: 1px;

`;

const ContainerInput = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 20px;
`;

const ButtonClose = styled.button`
  background: none;
  border: none;
  font-size: 22px;
  color: white;
`;

const ButtonInput = styled.button`
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'pointer')};
  outline: inherit;
  margin-left: 10px;
  margin-top: 10px;
  padding: 5px;
  align-content: center;
  justify-content: center;
  position: relative;

  &:after {
    content: ${(props) => (props.isLoading ? '""' : 'none')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border: 2px solid #2C992B;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

const MessageBubble = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
`;

const PromptBubble = styled.div`
  word-wrap: break-word;
  padding: 8px;
  max-width: 70%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => (props.isUser ? 'transparent' : '#2C992B')};
  color: ${(props) => (props.isUser ? 'black' : 'white')};
`;

const UserInputBubble = styled.div`
  word-wrap: break-word;
  padding: 8px;
  max-width: 70%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #951159;
  color: white;
`;

const TitleDesc = styled.h1`
  font-size: 16px;
  font-weight: bold;
`

const ChatWindow = ({ isOpen, onClose }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendMessage = async () => {
    if (newMessage.trim() !== '' && !isLoading) {
      try {
        setIsLoading(true);

        const response = await sendMessageToOpenAI(newMessage, threadId);

        setChatMessages((prevMessages) => [
          ...prevMessages,
          { prompt: newMessage, content: '', threadId: '', isUser: true },
        ]);

        setChatMessages((prevMessages) => [
          ...prevMessages,
          { prompt: response.prompt, content: response.content, threadId: response.threadId, isUser: false },
        ]);

        setThreadId(response.threadId);
        setNewMessage('');
      } catch (error) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const sendMessageToOpenAI = async (message, threadId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_OPENAISERVICE}/message`, {
        assistantId: 'asst_7PfTdxpGlTU48TZVecj59T4X',
        prompt: message,
        threadId: threadId,
      });

      return response.data;
    } catch (error) {
      throw new Error('Erro ao enviar mensagem para a OpenAI');
    }
  };

  return (
    <Window isOpen={isOpen} onClose={onClose}>
      <Header>
        <div>
          <TitleDesc>Gowchat</TitleDesc>
          <span>Assistente Virtual</span>
        </div>

        <ButtonClose onClick={onClose}>x</ButtonClose>
      </Header>
      <Content>
        {chatMessages.map((message, index) => (
          <MessageBubble key={index} isUser={message.isUser}>
            {message.isUser ? (
              <UserInputBubble>{message.prompt}</UserInputBubble>
            ) : (
              <PromptBubble>{message.content}</PromptBubble>
            )}
          </MessageBubble>
        ))}
      </Content>
      <ContainerInput>
        <MessageInput
          type="text"
          placeholder="Digite sua mensagem..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleEnterPress}
        />
        <ButtonInput isLoading={isLoading} onClick={handleSendMessage}>
          <FiSend color='#2C992B' size={25} />
        </ButtonInput>
      </ContainerInput>
    </Window>
  );
};

export default ChatWindow;
