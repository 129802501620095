import styled from 'styled-components'
import { device } from '../../../../styles/sizes'


// export const Content = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 80%;
//   width: 70%;
//   padding: 3%;
//   border-radius: 48px 8px;
//   background-color: ${({ theme }) => theme.grayBackgrounds};
//   `

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 70%;
  padding: 3%;
  border-radius: 48px 8px;
  background-color: ${({ theme }) => theme.grayBackgrounds};

  @media ${device.mobileL} {
    width: 100%;
    flex-direction: column;
    border-radius: 0;
    padding: 3% 5%;
  }
`;

export const SubTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: 700;
  transition: 0.3s;
  color: ${({ theme }) => theme.grayMainText};
`

export const Description = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  transition: 0.3s;
  color: ${({ theme }) => theme.grayMainText};
`

export const ValueTitle = styled.h1`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme }) => theme.primaryPink};
`

export const BankTitle = styled.h3`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.grayMainText};
`

export const AccountType = styled.h3`
  font-weight: 400;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.grayMainText};
`

export const AgencyTitle = styled.h3`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.secondaryLightGreen};
`

export const AccountTitle = styled.h3`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.secondaryLightGreen};
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 5%;
    height: 100%;
  }
`

export const TextFieldInline = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 2%;
  
  div {
    width: 2%;
  }
`
