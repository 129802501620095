import styled from 'styled-components'

export const Container = styled.section`
  grid-area: ${props => props.area};
  width: ${({ width }) => width || '28vw'};
  height: ${({ height }) => height || '63vh'};
  padding: 3% 6%;
  border-radius: 50px 10px 50px 10px;
  background-color: ${({ background, theme }) => (background ? theme.grayBackgrounds : theme.white)};
  border: 2px solid ${({ background, theme }) => (background ? theme.grayBackgrounds : theme.grayInactivy)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-self: center;
  overflow-y: auto;
  overflow-x: hidden;
  input:focus,
  button:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
`
export const List = styled.div`
  display: flex;
  font-weight: normal;
  justify-content: center;
  flex-direction: column;
  height: 58%;
`
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${props => props.height || '100%'};
  margin-bottom: ${props => props.bottom || '1%'};
  width: ${props => props.width || '100%'};
  flex-direction: ${props => props.direction || 'row'};
`

export const FlexContainer = styled.div`
  display: flex;
`
export const Image = styled.div`
  width: 25%;
  padding-bottom: 25%;
  background-color: ${({ theme }) => theme.grayButtonBackground};
  border-radius: 50%;
  align-self: center;
`
export const Description = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  margin-left: 20px;
  line-height: 22px;
`
export const Order = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.grayMainText};
`

export const Name = styled.h2`
  font-size: 1.25em;
  color: ${({ theme }) => theme.grayMainText};
`
export const Amount = styled.h2`
  font-weight: bold;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.primaryPink};
`
export const Time = styled.span`
  position: absolute;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.grayMainText};
  top: 28px;
  right: 16px;
 
`
export const Button = styled.button`
  width: ${props => props.width};
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.fontSize};
  font-weight: bold;
  font-family: 'Nunito';
  border: 1px solid ${props => props.borderColor};
  background-color: ${props => props.background};
  border-radius: 20px 6px 20px 6px;
  color: ${props => props.color};
  img {
    margin-right: 10px;
  }
`
export const ArrowIcon = styled.img`
  position: absolute;
  right: 65px;
`

export const Label = styled.h2`
  font-weight: 700;
  font-size: 1.25em;
  margin: 1% 0;
  color: ${({ theme }) => theme.grayMainText};
`
export const ListContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  display: inline-flex;
  gap: 15px;
  align-items: center;
  overflow: hidden;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    height: 8px;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
 margin-top: 3px;
`
export const ItemImageContainer = styled.div`
  width: 12vh;
  position: relative;
 
`
export const ItemImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.grayButtonBackground};
 
`
export const ItemName = styled.span`
  text-align: left;
  width: 100%;
  height: 2.0rem;
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 3px;
  line-height: 1;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  
`
export const ItemAmount = styled.span`
  font-size: 1.2em;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.primaryPink};

`
export const Empty = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    font-size: 16px;
    color: ${({ theme }) => theme.grayInactivy};
    margin: 0;
  }
`

export const EmptyImage = styled.img`
  width: 57px;
  height: 57px;
  margin: 1.5rem;
`

export const TypeContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  span {
    margin: 0;
    font-weight: 600;
    font-size: 10px;
    color: ${({ theme }) => theme.secondaryYellow};
  }
`
export const TypeImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 10px 6px;
  background-color: ${({ theme }) => theme.secondaryYellow};
  margin-bottom: 8px;
`
export const StatusAndValueContiner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  padding: 1px 12px;
  margin: 5px 0 0 0;
  border-radius: 25px;
  color: ${({ theme }) => theme.primaryGreen};
  border: 1px solid ${({ theme }) => theme.primaryGreen};
`
export const Value = styled.div`
  font-weight: bold;
  font-size: 1.5em;
  color: ${({ theme }) => theme.primaryGreen};
`

export const StatusImage = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 8px;
`
export const DescriptionText = styled.p`
  margin-bottom: 1%;
  font-size: 1.25em;
  color: ${({ theme }) => theme.grayMainText};
`
export const ProgressText = styled.p`
  color: ${({ theme }) => theme.secondaryYellow};
  font-weight: bold;
  font-size: 14px;
  align-self: flex-end;
  margin: 2% 2%;
`
export const ProgressBar = styled.div`
  width: 100%;
  height: 80%;
  background-color: ${({ theme }) => theme.grayMainText};
  display: flex;
`
export const Bar = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.secondaryYellow};
  width: ${props => props.width};
`
export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Count = styled.p`
  color: ${({ theme }) => theme.primaryPink};
  flex: 1;
  font-weight: bold;
  font-size: 1.25em;
  display: flex;
  align-items: center;
  ::before {
    content: '•';
    font-size: 1.3em;
    color: ${({ theme }) => theme.grayMainText};
    margin: 0 6px;
    padding-bottom: 3px;
  }
`
export const More = styled.button`
  color: ${({ theme }) => theme.primaryGreen};
  font-weight: bold;
  font-size: 1.25em;
  display: inline-block;
  
  :hover {
    color: ${({ theme }) => theme.secondaryLightGreen};
  }
  
  :disabled {
    color: ${({ theme }) => theme.grayInactivy};
  }

  transition: 0.3s;
`
export const Check = styled.button`
  width: 2.5em;
  height: 2.5em;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ active, theme }) => (active ? theme.primaryGreen : theme.white)};
  border: 2px solid ${({ active, theme }) => (active ? theme.primaryGreen : theme.grayInactivy)};
  position: absolute;
  right: -6px;
  top: -2px;
`
export const FooterColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Timer = styled.div`
  background-color: ${({ theme }) => theme.secondaryPurple};
  overflow: visible;
  padding: 0.4rem 1rem;
  border-radius: 3rem;
  text-align: center;
  z-index: 100;
  font-weight: bold;
  font-size: 1.2em;
  color: ${({ theme }) => theme.white};
  background-color: ${props => props.color};
  position: relative;

`
export const ClientName = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  text-align: center;
  color: ${({ theme }) => theme.grayMainText};
  
`
export const IconContainer = styled.div`
  display: flex;
  flex: 0.1;
  margin-right: 2%;
`

export const OrderLogo = styled.div`
  width: 7em;
  height: 7rem;
  display: flex;
  overflow: hidden;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${({ color, theme }) => (color === theme.primaryPink ? theme.white : color)};
  border: 1px solid ${({ color, theme }) => (color === theme.primaryPink ? theme.grayMainText : color)};
`

export const CloseButton = styled.div`
  position: absolute;
  right: -1%;
  top: -1%;

  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  background: ${props => props.theme.grayButtonBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`

export const CollectorInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`
