import React from 'react'

import { useTheme } from '../../../../context/ThemeContext'
import { ChartCircle } from './styles'

export default function CircleChart({ entry, tax, available }) {

  const { currentTheme } = useTheme()

  return (
    <ChartCircle
      data={{
        labels: ['Entrada R$', 'Taxa R$', 'Saldo R$'],
        datasets: [
          {
            data: [entry, tax, available],
            backgroundColor: [
              currentTheme.secondaryYellow,
              currentTheme.primaryPink,
              currentTheme.secondaryLightGreen
            ],
            borderWidth: 0,
          },
        ],
      }}
      options={{
        cutout: '70%',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }}
    />
  )
}
