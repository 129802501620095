import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { CircularProgress } from '@material-ui/core'


import { theme } from '../../theme'
import {
  Container,
  Content,
  Header,
  Sidebar,
  Step
} from '../../styles/layout/Layout'
import {
  BackButton,
  SuccessText,
  SendButton,
  ButtonsContainer,
  DisabledInput
} from './Styles'
import {
  ContentContainer,
  Title,
  Subtitle,
  FormContainer
} from '../login/Styles'

import LoginHeader from '../../components/loginHeader/LoginHeader'
import LoginRegisterSidebar from '../../components/loginRegisterSidebar/Index'
import { getErrorStyle } from '../../Utils'
import { Textfield } from '../login/Textfield'
import { useModal } from '../../context/ModalContext'
import { sendForgotPasswordEmail } from '../../api/store'

function ForgotPassword() {
  const [isSubmited, setIsSubmited] = useState(false)

  const { setModal } = useModal()
  const history = useHistory()

  async function handleSubmit(values) {
    setModal(<CircularProgress style={{ color: theme.palette.primary.main }} />)
    try {
      await sendForgotPasswordEmail(values.email)
      setIsSubmited(true)
      setModal()
    } catch {
      setIsSubmited(true)
      setModal()
      
    }
  }

  function backToLogin() {
    history.push('/login')
  }

  return (
    <Container>
      <Sidebar>
        <LoginRegisterSidebar />
      </Sidebar>

      <Header>
        <LoginHeader />
      </Header>

      <Content>
        <ContentContainer>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={
              Yup.object({
                email: Yup.string().email('Por favor, digite um email válido').required('O campo está vazio')
              })
            }
            onSubmit={handleSubmit}
          >
            {formikProps => (

              <Form>

                <FormContainer>
                  <Title>
                    Esqueci minha senha
                  </Title>
                  <Subtitle>
                    Coloque seu email cadastrado para enviarmos a solicitação:
                  </Subtitle>
                  {isSubmited ? (
                    <>
                      <DisabledInput
                        disabled
                        defaultValue='Solicitação enviada!'
                      />
                      <SuccessText>
                        O link para a alteração será enviado para o seu email.
                      </SuccessText>
                      <BackButton
                        onClick={backToLogin}
                      >
                        Voltar
                      </BackButton>
                    </>
                  ) : (
                    <>
                      <Textfield
                        borderColor={!!getErrorStyle('email', formikProps.errors, formikProps.touched)}
                        // borderColor={!!getErrorStyle('login', formikProps.errors, formikProps.touched) || !!errMessage}
                        // mock da mensagem do backend
                        placeholder='Email'
                        name='email'
                      />
                      <ButtonsContainer>
                        <BackButton type='button' onClick={backToLogin}>
                          Voltar
                        </BackButton>
                        <SendButton >
                          Enviar
                        </SendButton>
                      </ButtonsContainer>
                    </>
                  )}

                </FormContainer>
              </Form>
            )}

          </Formik>
        </ContentContainer>
      </Content>

      <Step></Step>
    </Container>
  )
}

export default ForgotPassword