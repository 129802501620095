import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.div`
  margin-top: ${props => props.mTop || 0};
  margin-bottom: ${props => props.mBottom || 0};
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width ? width : '100%'};  
  
  height: ${props => props.height || '6vh'};

  @media ${device.mobileL} {
    width: 152px;
    align-items: center;
    justify-content: center;
  }
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-height: 300%;  
  border-radius: 6px;

  height: 100%;

  @media ${device.mobileL} {
   position: relative; /* se manter em relative o menu aparece por cima do modal hamburguer */
  }
`

export const SelectContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${props => props.error ? props.theme.statusRed : props.theme.primaryGreen};
  border-radius: 6px;
  font-size: 1.4rem;
  padding: 0 0.5vw;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobileL} {
    justify-content: center;
  }
`

export const Text = styled.p`
  padding-right: 0.5vw;
  color: ${props => props.theme.primaryGreen};
  font-size: 1.4rem;
  white-space: nowrap;  
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${device.mobileL} {
    font-size: 16px;
  }
`

export const IconDiv = styled.div`
  transform: ${props => props.isOpen && 'rotate(-180deg)'};
  transition: 0.3s;
  color: ${props => props.theme.primaryGreen};
  font-size: 2.5rem;
`

export const Image = styled.img`
  margin-right: 0.5vw;
`

export const Option = styled.div`
  position: absolute;
  background-color: #fff;
  width:  100%;
  max-height: 300%;
  border: 1px solid ${props => props.theme.primaryGreen};
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  top: 120%;
  z-index: 50;
  overflow-y: auto;  
`

export const OptionList = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color:${props => props.placeHolder ? props.theme.grayInactivy : props.theme.grayMainText}; 
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5vh;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${props => props.theme.black};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 12%;
  border-bottom: 1px solid rgba(202, 202, 202, 0.3);

  :hover{
    background-color: ${({ theme }) => theme.secondaryLightGreen};
    
  }  
`

