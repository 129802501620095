import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { device } from '../../styles/sizes'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${device.mobileL} {
    display: none;
    background-color: red;
  }
`

export const Logo = styled.img`
  width: 10vw;
  height: auto;
  margin-top: 3vh;
  margin-left: 3vh;
  cursor: pointer;

  @media ${device.mobileL} {
    width: 50vw;
  }
`

export const TextContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

export const Title = styled.span`
  font-size: 2.5rem;
  color: ${props => props.color};
  font-weight: 700;
  padding: 0;
  margin: 0;
  line-height: 2rem;
`

export const Text = styled.span`
  font-size: 1.2rem;
  color: #000;
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 1.25rem;
  margin-top: 2vh;
`

export const BackButton = styled(Link)``

export const Image = styled.img`
  height: 70vh;
  width: auto;
  margin-bottom: -5vh;
`