import React, { useEffect, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import {
  Container,
  Title,
  ItemContent,
  Header,
  CircularProgressContainer,
  StockAlert
} from './styles'

import { StockItem } from '../stockItem'
import { useAuth } from '../../../context/AuthContext'
import { useTheme } from '../../../context/ThemeContext'
import { getProducts } from '../../../api/product'
import ButtonLink from '../../../components/buttons/buttonLink'


export function StockArea() {

  const { push } = useHistory()
  const { user } = useAuth()
  const { currentTheme } = useTheme()

  const [lowStockProducts, setLowStockProducts] = useState(null)
  const [loadingProducts, setLoadingProducts] = useState(false)

  const handleGetProductsWithLowStock = useCallback(async () => {
    try {
      setLoadingProducts(true)
      const response = await getProducts(user.storeData.id, {
        quantityRange: [1, 50]
      })

      if (response.data.length > 0) {
        setLowStockProducts(response.data.slice(0, 8))
        setLoadingProducts(false)
      }
    } finally {
      setLoadingProducts(false)
    }
  }, [])

  useEffect(() => {
    handleGetProductsWithLowStock()
  }, [])

  return (
    <Container>
      <Header>
        <Title>Estoque acabando</Title>
        <ButtonLink
          text='Ver mais'
          underline={false}
          onClick={() => push('/products')}
        />
      </Header>
      {lowStockProducts ?
        <ItemContent>
          {lowStockProducts?.sort((a, b) => a.quantity > b.quantity ? 1 : -1).map((item, key) => (
            <>
              <StockItem
                key={item.id}
                productImg={item.image}
                productName={item.name}
                productQuantity={item.quantity}
              />
            </>
          ))}
        </ItemContent>
        :
        <CircularProgressContainer>
          {loadingProducts ?
            <CircularProgress style={{ color: currentTheme.primaryGreen }} />
            :
            <StockAlert>Sem produtos com estoque baixo</StockAlert>
          }
        </CircularProgressContainer>

      }
    </Container>
  )
}
