import React from 'react'
import { Modal } from '../modal'

import { 
  ModalContainer, 
  ModalContent, 
  ModalFooter, 
  ModalImg, 
  ModalImgContainer, 
  ModalText, 
  ModaltextsContainer, 
  ModalTitle,
  ModalSpan
} from './styles'

import LockImg from '../../assets/safety.svg'
import ButtonSecondary from '../buttons/buttonSecondary'
import ButtonPrimary from '../buttons/buttonPrimary'
import { useTheme } from '../../context/ThemeContext'

export default function ModalCloseMarket({ closeModal, closeStore }) {
  const { currentTheme } = useTheme()
  
  function handleConfirm() {
    closeStore()
  }

  return (
    <Modal title='Fechar loja'>
      <ModalContainer>
        <ModalContent>

          <ModalImgContainer>
            <ModalImg src={LockImg} />
          </ModalImgContainer>

          <ModaltextsContainer>
            <ModalTitle>
              Tem certeza que gostaria de <ModalSpan color={currentTheme.primaryPink}>fechar</ModalSpan> sua loja?
            </ModalTitle>
            <ModalText>
              Ao fechar sua loja seus produtos não vão ficar
              disponíveis para os clientes e você não receberá pedidos.
            </ModalText>

          </ModaltextsContainer>

        </ModalContent>

        <ModalFooter>
          <ButtonSecondary
            width='13vw'
            height='7vh'
            smallFont
            text='Cancelar'
            onClick={closeModal}
          />

          <ButtonPrimary
            width='13vw'
            height='7vh'
            smallFont
            text='Fechar loja'
            onClick={handleConfirm}
            background={currentTheme.primaryPink}
            backgroundHover={currentTheme.secondaryLightPink}

          />
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}
