import React, { useState, useEffect, useRef } from 'react'

import {
  Container,
  CardContainer,
  ValueRangeSlider,
  InputsContainer,
  RangeInput,
  Title,
  TitleContainer,
  SubTitle,
  LabelArea,
  ClearButtonArea
} from './ValuePickerStyles'

import { ClearButton } from '../dataPicker/DataPickerStyles'

const INDEX_MIN = 0
const INDEX_MAX = 1

export default function ValuePicker({ onChange, defaultValue, isMobile }) {
  const [value, setValue] = useState(defaultValue ? defaultValue : [0, 1000])
  const rangeSliderRef = useRef(null)


  const handleMinInputChange = (event, value) => {
    setValue((oldValue) => [value, oldValue[INDEX_MAX]])
  }
  const handleMaxInputChange = (event, value) => {
    setValue((oldValue) => [oldValue[INDEX_MIN], value])
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function handleResetClick() {
    setValue([0, 1000])
  }

  useEffect(() => {
    onChange(value)
  }, [value])

  return (
    <Container>
      <TitleContainer>
        <Title>Valor</Title>
      </TitleContainer>
      <LabelArea>
        <div className='left'>
          <SubTitle>Mínimo</SubTitle>
        </div>
        <div className='right'>
          <SubTitle>Máximo</SubTitle>
        </div>
      </LabelArea>
      {
        isMobile
          ?
          <>
            <CardContainer ref={rangeSliderRef}>
              <InputsContainer>
                <RangeInput
                  value={value[INDEX_MIN]}
                  onChange={handleMinInputChange}
                />
                <RangeInput
                  value={value[INDEX_MAX]}
                  onChange={handleMaxInputChange}
                />
              </InputsContainer>
              <ValueRangeSlider
                value={value}
                min={0}
                max={1000}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
              />
            </CardContainer>
            <ClearButtonArea>
              <ClearButton onClick={handleResetClick}>Limpar</ClearButton>
            </ClearButtonArea>
          </>
          :
          <CardContainer ref={rangeSliderRef}>
            <InputsContainer>
              <RangeInput
                value={value[INDEX_MIN]}
                onChange={handleMinInputChange}
              />
              <RangeInput
                value={value[INDEX_MAX]}
                onChange={handleMaxInputChange}
              />
              <ClearButton onClick={handleResetClick}>Limpar</ClearButton>
            </InputsContainer>
            <ValueRangeSlider
              value={value}
              min={0}
              max={1000}
              onChange={handleChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
            />
          </CardContainer>
      }
    </Container>
  )
}
