import api from './api'
import apiJadlog from './apiJadlog'

export async function fetchPurchaseBalance(storeId) {
  const day = await api.get(`purchase/store/${storeId}/order-balance`, { params: { type: 'daily' } })
  const week = await api.get(`purchase/store/${storeId}/order-balance`, { params: { type: 'weekly' } })
  const year = await api.get(`purchase/store/${storeId}/order-balance`, { params: { type: 'annually' } })

  return [day.data, week.data, year.data]
}

export async function fetchSalesBalance(storeId, params) {
  const response = await api.get(`purchase/store/${storeId}/sales-balance`, { params: { ...params } })

  return response
}

export async function getPurchases(storeId, params) {
  const response = await api.get(`purchase/store/${storeId}`, { params: { ...params } })
  return response
}

export async function updatePurchaseStatus(id, status) {
  const response = await api.patch(`purchase/${id}`, {
    status
  })

  return response
}

export async function cancelPurchaseAndRefound(invoiceId, purchase_id, refund) {
  const response = await api.post(`purchase/invoice/refund/${invoiceId}`, {
    purchase_id,
    refund,
  })

  return response
}

export async function findOrderByCode(purchaseCode: string) {
  const response = await apiJadlog.get(`pegarOrderPeloPurchaseCode/${purchaseCode}`)
  return response
}

export async function updatedOrderJadlog(body: { _id: String, dfe: Object }) {
  const response = await apiJadlog.put('atualizarDfe', body)
  return response
}

export async function includeOrderJadlog(id: string) {
  const response = await apiJadlog.post('incluirPedido', {_id: id})
  return response
}

export async function updateShipmentId(body: {_id: String, shipmentId: String, codigo: String, status: string}) {
  const response = await apiJadlog.put('atualizarShipId', body)
  return response
}

export async function cancelOrderJadlog(shipmentId: string) {
  const response = await apiJadlog.post('cancelarPedido', {shipmentId: shipmentId})
  return response
}