import { useEffect, useState, useRef } from 'react'

const DEFAULT_DOC_TITLE = document.title

function tick(message) {
  document.title = document.title === message ? DEFAULT_DOC_TITLE : message
}

export function useTabNotification(interval = 3000, authenticated) {

  const [notification, setNotification] = useState(null)
  const intervalRef = useRef(null)

  function clearNotification() {
    document.title = DEFAULT_DOC_TITLE
    setNotification(null)
  }

  function resetInterval() {
    clearInterval(intervalRef.current)
    intervalRef.current = null
  }

  useEffect(() => {
    if (!authenticated) {
      clearNotification()
    }

    if (!intervalRef.current && notification) {
      intervalRef.current = setInterval(tick, interval, notification)
    } else {
      resetInterval()
    }

    return () => {
      resetInterval()
    }
  }, [notification, authenticated])

  return {
    setNotification,
    clearNotification
  }
}