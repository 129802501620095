import styled from 'styled-components'

export const Text = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  color: ${props => props.grayText ? props.theme.grayMainText : props.theme.secondaryLightGreen};
  `

export const SmallText = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${props => props.theme.graySecondaryText};
  text-align: center;
  margin-bottom: 5%;
`

export const ImageContainer = styled.div`
  background: ${props => props.theme.grayInactivy};
  width: 10vh;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 5%;
`
