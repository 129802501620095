import React, { useState } from 'react'
import ButtonSecondary from '../../components/buttons/buttonSecondary'
import ButtonPrimary from '../../components/buttons/buttonPrimary'
import { Modal } from '../../components/modal'
import { useTheme } from '../../context/ThemeContext'
import { useModal } from '../../context/ModalContext'

import {
  ButtonsContainer,
  Container,
  Text
} from './styles'

export default function SaveModal({ text, confirmText, cancelText, confirmFunction, cancelFunction, onlyButton }) {
  const { primaryPink, secondaryLightPink } = useTheme().currentTheme
  const { setModal } = useModal()

  function handleClick(btnAction) {
    btnAction()
    setModal(false)
  }

  return (
    <Modal width='28vw' height='28vh' noHeader>
      <Container>
        <Text>
          {text || 'Deseja salvar todas as alterações?'}
        </Text>
        <ButtonsContainer>
          {!onlyButton &&
            <ButtonSecondary
              onClick={() => handleClick(cancelFunction)}
              text={cancelText || 'Não'}
              width='8vw'
              height='6vh'
              color={primaryPink}
              colorHover={secondaryLightPink}
              smallFont
            />
          }
          <ButtonPrimary
            onClick={() => handleClick(confirmFunction)}
            text={confirmText || 'Sim'}
            width='8vw'
            height='6vh'
            smallFont
          />
        </ButtonsContainer>
      </Container>
    </Modal>
  )
}
