import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  margin-top: 3%;
  display: flex;
`

export const UserDetails = styled.div`
  width: 35%;
  height: 100%;
  margin-right: 10%;
`

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const DateText = styled.div`
  font-weight: 700;
  font-size: 1rem;
  color: ${props => props.theme.graySecondaryText};
  margin-top: 12%;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${props => props.mTop || '0'};
`

export const StaticDetails = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  `

export const Column = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: ${props => props.width || ''};
`

export const StaticText = styled.span`
  font-weight: 400;
  font-size: 1rem;
  color: ${props => props.theme.grayMainText};
  `

export const UserInfo = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${props => props.theme.grayMainText};
  margin-bottom: 3%;
`

export const SuportAdvice = styled.span`
  font-weight: 400;
  font-size: 1rem;
  color: ${props => props.theme.grayMainText};
  text-align: center;
  margin-top: 3%;
  padding: 0 8%;
`

export const ErrorMsg = styled.span`
  font-size: 1rem;
  color: ${props => props.theme.statusRed};
`