import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Container,
  Content,
  SearchContent,
  SearchDiv,
  SearchInput,
  HeaderContainer,
  ItemList,
  ProductsAlert,
  DropdownGroup,
  ButtonGroup
} from './styles'
import { FiSearch, FiSliders, FiDownload } from 'react-icons/fi'

import { getProducts } from '../../../api/product'
import { useAuth } from '../../../context/AuthContext'
import { useTheme } from '../../../context/ThemeContext'
import { useModal } from '../../../context/ModalContext'
import ButtonPrimaryIconLeft from '../../../components/buttons/buttonPrimaryIconLeft'
import ButtonSecondaryIconLeft from '../../../components/buttons/buttonSecondaryIconLeft'
import ButtonDropdown from '../../../components/selects/select'
import FilterModal from '../FilterModal'
import Item from '../item/Item'
import LoadingIndicator from '../../../components/LoadingIndicator'
import { Label } from '../../management/pending/PendingStyles'
import { csvTable } from '../../../api/productCsv'
import { theme } from '../../../theme'
import DetailsModal from '../../../components/modals/detailsModal'
import ButtonLink from '../../../components/buttons/buttonLink'

export default function List({ setItem, selectedItem, isMobile }) {
  const { user: { storeData: { id } } } = useAuth()

  const [products, setProducts] = useState(null)
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [amountPerPage, setAmountPerPage] = useState(20)
  const [stopLoadMore, setStopLoadMore] = useState(false)
  const [lastElement, setLastElement] = useState(null)

  const { push } = useHistory()
  const { currentTheme } = useTheme()
  const { setModal } = useModal()

  const [order, setOrder] = useState('sortDateDESC')
  const [search, setSearch] = useState('')
  const [valueRange, setValueRange] = useState([0, 10000])
  const [category, setCategory] = useState(null)

  const donwloadCsv = async () => {
    try {
      const csvData = await csvTable(id);
      const url = window.URL.createObjectURL(new Blob([csvData.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  }

  function openModal() {
    setModal(<FilterModal setValueRange={setValueRange} currentPriceRange={valueRange} currentCategories={category} setCategory={setCategory} isMobile={isMobile} />)
  }

  function openDetails(item) {
    setModal(<DetailsModal item={item} />)
  }

  const handleGetAllProducts = useCallback(async (text, sort, priceRange, category, pageNumber) => {
    let filter = {}
    filter['limit'] = amountPerPage

    text ? (
      text.startsWith('#') ? filter['code'] = text : filter['name'] = text
    ) : filter['pageNumber'] = pageNumber

    sort ? filter['sort'] = sort : null

    priceRange ? filter['priceRange'] = [priceRange[0], priceRange[1]] : null

    category ? filter['category'] = [category[0] && category[0].name, category[1] && category[1].name, category[2] && category[2].name] : null

    try {
      const response = await getProducts(id, filter)
      if (response.data.length > 0) {
        if (pageNumber > 1 && !text) {
          setProducts(oldProducts => [...oldProducts, ...response.data])
        } else {
          setProducts(response.data)
          setCurrentPage(1)
        }
      }

      if (response.data.length < amountPerPage) {
        setStopLoadMore(true)
      }
    }
    finally {
      setLoading(false)
    }
  }, [])


  function Order(item) {
    switch (item) {
      case 'Mais recentes':
        setOrder('sortDateDESC')
        break

      case 'Mais antigos':
        setOrder('sortDateASC')
        break

      case 'Menor estoque':
        setOrder('sortQuantityASC')
        break

      case 'Ordem A - Z':
        setOrder('sortNameASC')
        break

      case 'Ordem Z - A':
        setOrder('sortNameDESC')
        break

      case 'Maior estoque':
        setOrder('sortQuantityDESC')
        break

      case 'Preço crescente':
        setOrder('sortPriceASC')
        break

      case 'Preço decrescente':
        setOrder('sortPriceDESC')
        break
    }
    setCurrentPage(1)
    setStopLoadMore(false)
  }

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0]
        if (first.isIntersecting) {
          setCurrentPage(oldPage => oldPage + 1)
        }
      })
  )

  useEffect(() => {
    const currentElement = lastElement
    const currentObserver = observer.current

    if (currentElement && !stopLoadMore) {
      currentObserver.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement)
      }
    }
  }, [lastElement])

  useEffect(() => {
    handleGetAllProducts(search, order, valueRange, category, currentPage)

    return () => {
      setStopLoadMore(false)
    }
  }, [search, order, valueRange, category, currentPage])

  return (
    <Container>
      <HeaderContainer>
        <ButtonGroup>
          <ButtonPrimaryIconLeft
            onClick={() => push('/addproduct')}
            text='Adicionar produto'
            height='80%'
          />
          {/* <ButtonPrimaryIconLeft
            onClick={() => donwloadCsv()}
            text='Baixar Lista'
            height='80%'
            background={'#FFFFFF'}
            color={currentTheme.primaryGreen}
            icon={<FiDownload/>}
            backgroundHover={'#FFFFFF'}
            /> */}
          <ButtonLink
            onClick={() => donwloadCsv()}
            text='Baixar Lista'
            sideIcon={'left'}
            icon={<FiDownload size={22}/>}
          />
        </ButtonGroup>
        <SearchDiv >
          <SearchContent >
            <SearchInput
              placeholder='Buscar'
              onChange={(event) => setSearch(event.target.value)}
            />
            <FiSearch size='2rem' color={currentTheme.grayMainText} />
          </SearchContent>
          {
            isMobile
              ?
              <DropdownGroup>
                <ButtonDropdown
                  title='Mais recentes'
                  width='26%'
                  height='80%'
                  options={['Ordem A - Z', 'Ordem Z - A', 'Mais recentes', 'Menos recentes', 'Preço crescente', 'Preço decrescente', 'Maior estoque', 'Menor estoque']}
                  onValueChange={(item) => Order(item)}
                />
                <ButtonSecondaryIconLeft
                  icon={<FiSliders size='2rem' />}
                  text='Filtros'
                  width='20%'
                  height='80%'
                  smallFont
                  onClick={openModal}
                />
              </DropdownGroup>
              :
              <>
                <ButtonDropdown
                  title='Mais recentes'
                  width='26%'
                  height='80%'
                  options={['Ordem A - Z', 'Ordem Z - A', 'Mais recentes', 'Menos recentes', 'Preço crescente', 'Preço decrescente', 'Maior estoque', 'Menor estoque']}
                  onValueChange={(item) => Order(item)}
                />
                <ButtonSecondaryIconLeft
                  icon={<FiSliders size='2rem' />}
                  text='Filtros'
                  width='20%'
                  height='80%'
                  smallFont
                  onClick={openModal}
                />
              </>
          }
        </SearchDiv>
      </HeaderContainer>
      <Content>
        {loading ?
          <LoadingIndicator />
          :
          products ?
            <ItemList >
              {products?.map((item) => (
                <Item
                  key={item.id}
                  name={item.name}
                  image={item.image}
                  variations={item.variations}
                  price={item.priceRange}
                  quantity={item.quantity}
                  evaluation={item.evaluation}
                  ammountReview={item.ammountReview}
                  active={item.id === selectedItem?.id}
                  onClick={() => openDetails(item)}
                  isMobile={isMobile}
                />
              ))}
              <p ref={setLastElement}>{stopLoadMore ? `Total de ${products.length} produtos encontrados.` : 'Buscando mais produtos...'}</p>
            </ItemList>
            :
            <ProductsAlert>Nenhum produto encontrado</ProductsAlert>
        }
      </Content>
    </Container >
  )
}
