import React from 'react'

import {
  Container,
  ContainerAccounts,
  ContainerUserAccount,
  Content,
  ContentRightHeader,
  ContentSide,
  ContainerUserAccountIconArea,
  ContainerUserAccountInfoArea,
  BackgroundIcon,
  ContentInfo,
} from './styles'

import {
  SubTitle,
  Description,
  ValueTitle,
  AccountTitle,
  AccountType,
  AgencyTitle,
  BankTitle,
  TextFieldInline,
  Footer
} from '../../modalWithdrawBalance/styles'

import { formatMoneyBRL } from '../../../../../Utils'
import { useTheme } from '../../../../../context/ThemeContext'
import { useAuth } from '../../../../../context/AuthContext'

import { FiPlus } from 'react-icons/fi'
import { CircularProgress } from '@material-ui/core'
import { ReactComponent as BankIcon } from '../../../../../assets/bankIcon.svg'

import ButtonLink from '../../../../../components/buttons/buttonLink'
import ButtonSecondary from '../../../../../components/buttons/buttonSecondary'
import ButtonPrimary from '../../../../../components/buttons/buttonPrimary'

export default function SecondStep({ backStep, formProps, openNewAccountModal }) {

  const { user } = useAuth()
  const { currentTheme } = useTheme()

  return (
    <Container>
      <Content>
        <ContentSide width='55%'>
          <ContentRightHeader>
            <SubTitle>Contas cadastradas</SubTitle>
            <ButtonLink
              width='50%'
              withIcon={true}
              text='Adicionar Conta'
              sideIcon='left'
              icon={<FiPlus size='2rem' />}
              onClick={openNewAccountModal}
            />
          </ContentRightHeader>
          <ContainerAccounts>
            {!user.storeData.bankDetails ?
              <CircularProgress style={{ color: currentTheme.secondaryLightGreen }} />
              :
              user.storeData.bankDetails.map((item, key) => (
                <ContainerUserAccount key={key} onClick={() => formProps.setFieldValue('accountForWithdrawal', item)} active={formProps.values.accountForWithdrawal?.account === item.account}>
                  <ContainerUserAccountIconArea>
                    <BackgroundIcon><BankIcon height='1.6rem' width='1.6rem' fill={currentTheme.white} /></BackgroundIcon>
                  </ContainerUserAccountIconArea>

                  <ContainerUserAccountInfoArea>
                    <div>
                      <BankTitle>{item.bank}</BankTitle>
                      <div className='dotDivider'>
                        <BankTitle>•</BankTitle>
                      </div>
                      <AccountType>{item.accountType}</AccountType>
                    </div>

                    <div>
                      <AgencyTitle>{`Ag. ${item.agency}`}</AgencyTitle>
                      <div className='dotDivider'>
                        <BankTitle></BankTitle>
                      </div>
                      <AccountTitle>{`Conta ${item.account}`}</AccountTitle>
                    </div>
                  </ContainerUserAccountInfoArea>
                </ContainerUserAccount>
              ))}
          </ContainerAccounts>
        </ContentSide>
        <ContentSide width='45%'>
          <Description>Valor</Description>
          <ValueTitle>R$ {formatMoneyBRL(formProps.values.withdrawalQuantity)}</ValueTitle>
          {formProps.values.accountForWithdrawal &&
            <ContentInfo>
              <TextFieldInline>
                <BankTitle>Nome do titular:</BankTitle>
                <div />
                <AccountType>{formProps.values.accountForWithdrawal.name}</AccountType>
              </TextFieldInline>

              <TextFieldInline>
                <BankTitle>CPF / CNPJ: </BankTitle>
                <div />
                <AccountType>{formProps.values.accountForWithdrawal.cpf}</AccountType>
              </TextFieldInline>

              <TextFieldInline>
                <BankTitle>Banco: </BankTitle>
                <div />
                <AccountType>{formProps.values.accountForWithdrawal.bank}</AccountType>
              </TextFieldInline>

              <TextFieldInline>
                <BankTitle>Conta: </BankTitle>
                <div />
                <AccountType>{formProps.values.accountForWithdrawal.account}</AccountType>
              </TextFieldInline>

              <TextFieldInline>
                <BankTitle>Agência: </BankTitle>
                <div />
                <AccountType>{formProps.values.accountForWithdrawal.agency}</AccountType>
              </TextFieldInline>
            </ContentInfo>
          }
        </ContentSide>
      </Content>
      <Footer>
        <ButtonSecondary
          text='Voltar'
          width='30%'
          onClick={backStep}
        />
        <div />
        <ButtonPrimary
          text='Avançar'
          width='30%'
          onClick={formProps.handleSubmit}
          disabled={!formProps.values.accountForWithdrawal}
        />
      </Footer>
    </Container>
  )
}
