import React, { useEffect, useState } from 'react'
import Banks from '../../../../../Utils/BanksDatasheet.json'
import { useAuth } from '../../../../../context/AuthContext'
import Textfield from '../Textfield'
import { getErrorStyle } from '../../../../../Utils'
import { Container, DoubleInputArea, SideBySide } from './styles'
import Selectfield from '../Selectfield'
import { MaskedMainInput } from '../../../../../components/inputs/maskedMainInput'
import { handleFormatWithMask } from '../../../../../Utils/FormatWithMask'

export default function Step1({ formField, values, setFieldValue, formikProps }) {
  const { name, document, bank, accountType, agency, account, accountDigit } = formField
  const { errors, touched } = formikProps

  const { user: { storeData } } = useAuth()

  useEffect(() => {

    console.log(storeData)

    /* values.accountType = 'Pessoa Jurídica' */

    if (values.accountType) {
      switch (values.accountType) {
      case 'Pessoa Física':
        setFieldValue('name', "Pessoa Física")
        setFieldValue('document', handleFormatWithMask(storeData?.userCPF, 'CPF_CNPJ').masked)
        break

      case 'Pessoa Jurídica':
        setFieldValue('name', "Pessoa Jurídica")
        setFieldValue('document', handleFormatWithMask(storeData?.document, 'CPF_CNPJ').masked)
        break
      }
    }
  }, [values.accountType])

  return (
    <Container>
      <Selectfield
        width='100%'
        mBottom='2vh'
        error={getErrorStyle(accountType.name, errors, touched)}
        /* options={['Pessoa Jurídica']} */
        options={['Pessoa Física', 'Pessoa Jurídica']}
        title='Tipo de conta'
        name={accountType.name}
        setFieldValue={setFieldValue}
        currentValue={values.accountType}
        /* currentValue={'Pessoa Jurídica'} */
      />

      {/* <Textfield
        error={getErrorStyle(name.name, errors, touched)}
        placeholder={name.label}
        name={name.name}
        disabled
      /> */}
      {/* <MaskedMainInput
        mTop='2vh'
        value={values.document}
        name={document.name}
        error={getErrorStyle(document.name, errors, touched)}
        marginTop='2vh'
        onValueChange={({ masked, unmasked }) => setFieldValue(document.name, unmasked)}
        mask='CPF_CNPJ'
        placeholder='CPF / CNPJ'
      /> */}
      <Textfield
        name={document.name}
        error={getErrorStyle(document.name, errors, touched)}
        /* marginTop='2vh' */
        mask='CPF_CNPJ'
        placeholder='CPF / CNPJ'
        disabled
      />

      <DoubleInputArea>
        <Selectfield
          width='100%'
          mTop='2vh'
          error={getErrorStyle(bank.name, errors, touched)}
          options={Banks.map(bank => bank.name)}
          title='Banco'
          name={bank.name}
          setFieldValue={setFieldValue}
          currentValue={values.bank}
        />
      </DoubleInputArea>

      <DoubleInputArea>
        <Textfield
          error={getErrorStyle(agency.name, errors, touched)}
          marginTop='2vh'
          width='47%'
          placeholder={agency.label}
          name={agency.name}
        />

        <SideBySide>
          <Textfield
            error={getErrorStyle(account.name, errors, touched)}
            marginTop='2vh'
            width='80%'
            placeholder={account.label}
            name={account.name}
          />
          <span className='separator'>_</span>
          <Textfield
            error={getErrorStyle(accountDigit.name, errors, touched)}
            marginTop='2vh'
            width='20%'
            name={accountDigit.name}
          />
        </SideBySide>
      </DoubleInputArea>

    </Container>
  )
}
