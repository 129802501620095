import React, { useState } from 'react'
import {
  Container,
  ValueContainer,
  FooterContainer
} from './styles'

import { useModal } from '../../../context/ModalContext'

import { Modal } from '../../../components/modal'
import ButtonLink from '../../../components/buttons/buttonLink'
import DataPicker from '../../../components/dataPicker/DataPicker'
import ValuePicker from '../../../components/valuePicker/ValuePicker'
import ButtonPrimary from '../../../components/buttons/buttonPrimary'

export default function FilterModal({ setDateRange, setPriceRange, currentPriceRange, currentDate }) {
  const { setModal } = useModal()
  const [tempDate, setTempDate] = useState({})
  const [tempValue, setTempValue] = useState({})

  function handleApplyFilters() {
    setDateRange(tempDate)
    setPriceRange(tempValue)
    setModal(null)
  }

  function handleClearFilters() {
    setDateRange(null)
    setPriceRange(null)
    setModal(null)
  }

  return (
    <Modal
      title='Filtros'
      width='35vw'
      height='85vh'
    >
      <Container>
        <DataPicker
          onChange={(value) => setTempDate(value)}
          defaultValue={currentDate}
        />
        <ValueContainer>
          <ValuePicker
            onChange={(value) => setTempValue(value)}
            defaultValue={currentPriceRange}
          />
        </ValueContainer>
        <FooterContainer>
          <ButtonLink
            text='Limpar'
            onClick={handleClearFilters}
          />
          <ButtonPrimary
            text='Aplicar filtros'
            height='8vh'
            width='45%'
            smallFont
            onClick={handleApplyFilters}
          />
        </FooterContainer>
      </Container>
    </Modal>
  )
}
