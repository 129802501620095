import React, { useState, useContext, createContext, useMemo } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { LightTheme, DarkTheme } from '../styles/themes'

const ThemeContext = createContext({})

export function ThemeProvider({ children }) {

  const [isDark, setIsDark] = useState(false)

  function handleChangeTheme() {
    setIsDark(!isDark)
  }
  
  const currentTheme = useMemo(() => {
    return isDark ? DarkTheme : LightTheme
  }, [isDark])
  
  return (
    <ThemeContext.Provider value={{ isDark, setIsDark, handleChangeTheme, currentTheme }}>
      <StyledThemeProvider theme={currentTheme}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  )
}

export function useTheme() {
  return useContext(ThemeContext)
}
