import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  width:100vw;
  height:100vh;
  z-index:1000;
  `

export const Modal = styled.div`
  @keyframes grow-up {
    from {
      transform: scale(0);
    }
    
    to {
      transform: scale(1);
    }
  }
  
  animation: grow-up 0.3s;
  z-index:1100;
`

export const BackGround = styled.div`
  position: absolute;
  background-color:rgba(0,0,0,0.5);
  backdrop-filter:blur(1px);
  display:flex;
  width:100vw;
  height:100vh;
`
