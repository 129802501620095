import React from 'react'
import { FiPlus } from 'react-icons/fi'

import { useModal } from '../../../context/ModalContext'
import { useAuth } from '../../../context/AuthContext'
import { useHistory } from 'react-router-dom'

import BankAccountItem from './bankAccountItem'

import {
  AccountsContent,
  Container,
  FormContent,
  ForgotLink,
  TextContainer,
  Title,
  AddContainer,
  AddText,
  NoAccountContainer,
  NoAccountText,
  AccountsContainer,
} from './styles'
import ModalNewAccount from './modalNewAccount'
import api from '../../../api/api'

export default function BankAccounts({ accounts, setAccounts }) {
  const { push } = useHistory()
  const { user, handleUpdateUserField } = useAuth()
  const { setModal } = useModal()

  async function deleteAccount(id) {
    const response = await api.delete(`store/${user.storeData.id}/bank/${id}`)
    if (response.status === 200) {
      let updateBanks = user
      updateBanks.storeData.bankDetails.splice(id, 1)
      handleUpdateUserField({ bankDetails: updateBanks.storeData.bankDetails })
      push('/config/revenues')
    }
  }

  function openModal() {
    setModal(<ModalNewAccount />)
  }

  return (
    <Container>
      <AccountsContent>
        <TextContainer>
          <Title>
            Contas cadastradas
          </Title>
          <AddContainer onClick={openModal}>
            <FiPlus size='2.5rem' />
            <AddText>Adicionar conta</AddText>
          </AddContainer>
        </TextContainer>
        {user.storeData?.bankDetails?.length > 0 ? (
          <AccountsContainer>
            {user.storeData.bankDetails.map((item, key) => (
              <BankAccountItem
                key={key}
                account={item.account}
                bank={item.bank}
                agency={item.agency}
                type={item.accountType}
                click={() => deleteAccount(key)}
              />
            ))}
          </AccountsContainer>
        ) : (
          <NoAccountContainer>
            <NoAccountText>
              Nenhum conta cadastrada
            </NoAccountText>
          </NoAccountContainer>
        )}
      </AccountsContent>
    </Container>
  )
}
