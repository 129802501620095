import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 48px 16px;
  background-color: ${({ theme }) => theme.primaryPink};
  `

export const Content = styled.div`
  width: 16vw;
  height: 45%;
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  font-weight: 900;
  font-size: 2rem;
  color: ${({ theme }) => theme.white};
`

export const Description = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  text-align: justify;
  align-self: flex-start;
  padding: 0 20px;
  color: ${({ theme }) => theme.white};
`
