import { getIn } from 'formik'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export function getErrorStyle(fieldName, errors, touched) {
  return getIn(errors, fieldName) && getIn(touched, fieldName)
}

export function clearMask(str) {
  const clearString = str.replace(/\D/g, '')
  return clearString
}

export function translatePurchaseStatus(status) {
  let translate = status
  switch (status) {
  case 'OPEN':
    translate = 'Aberto'
    break

  case 'Aberto':
    translate = 'OPEN'
    break

  case 'ACCEPTED':
    translate = 'Aceito'
    break

  case 'Aceito':
    translate = 'ACCEPTED'
    break

  case 'SEPARATED':
    translate = 'Separado'
    break

  case 'Separado':
    translate = 'SEPARATED'
    break

  case 'DELIVERING':
    translate = 'Entregando'
    break

  case 'Entregando':
    translate = 'DELIVERING'
    break

  case 'DELIVERED':
    translate = 'Entregue'
    break

  case 'Entregue':
    translate = 'DELIVERED'
    break

  case 'CANCELLED':
    translate = 'Cancelado'
    break

  case 'Cancelado':
    translate = 'CANCELLED'
    break

  case 'CLOSED':
    translate = 'Fechado'
    break

  case 'Fechado':
    translate = 'CLOSED'
    break
  }
  return translate
}

export function formatShortDate(date) {
  const FormatedShortDate = format(new Date(date), 'dd/MM \'às\' HH:mm\'h\'', {
    locale: ptBR,
  })

  return FormatedShortDate
}

export function formatCompleteDate(date) {
  const FormatedCompleteDate = format(new Date(date), 'dd \'de\' MMMM \'às\' HH:mm\'h\'', {
    locale: ptBR,
  })

  return FormatedCompleteDate
}

export function formatOnlyDate(date) {
  const FormatedCompleteDate = format(new Date(date), 'dd/MM/yyyy', {
    locale: ptBR,
  })

  return FormatedCompleteDate
}

export function formatMoneyBRL(value) {
  const adaptValue = parseFloat(value)
  return adaptValue.toLocaleString('pt-br', { minimumFractionDigits: 2 })
}

export function formatAndMaskPhoneNumber(value) {
  let ddd = ''
  let finalNumber = ''
  if (value.length >= 1) {
    ddd = `(${value.slice(3, 5)})`
  }
  finalNumber = value.slice(value.length - 4, value.length)
  return `${ddd} '9 **** - ' ${finalNumber}`
}
export function FormatMaskCep (value ) {
  let cep = ''
  let finalCep = ''
  if (value.length >= 1) {

    cep = value.slice(0,5 )
  }
  finalCep = value.slice(value.length -3 ,value.length)

  return `${cep}-${finalCep}`
}
export function FormatMaskCnpj(value){
  let cnpj1 = ''
  let cnpj2 = ''
  let cnpj3 = ''
  let cnpj4 = ''
  let finalCnpj = ''


  if(value.length >= 1){
    cnpj1 = value.slice(0,2)
    cnpj2 = value.slice(2,5)
    cnpj3 = value.slice(5,8)
    cnpj4 = value.slice(8,12)

  }
  finalCnpj = value.slice(value.length -2, value.length)
  return  `${cnpj1}.${cnpj2}.${cnpj3}/${cnpj4}-${finalCnpj}`
}
export function FormatMaskNumber(value) {
  let nationality = ''
  let ddd = ''
  let number = ''
  let final = ''
  if (value.length >=1) {
    nationality = value.slice(0,3)
    ddd =`(${value.slice(3, 5)})`
    number = value.slice(5,10)
  }
  final =  value.slice(value.length -4, value.length)
  return `${nationality}${ddd}${number}-${final}`
}



export function passingDataToArray(obj, setState, setQuantity) {
  let data = []
  let labels = []
  obj.forEach(item => {
    data.push(item.count)
    labels.push(item.period)
  })
  const object = {
    labels: labels,
    data: data,
  }

  const quantity = object.data.reduce((a, b) => a + b, 0)

  setState(object)
  setQuantity && setQuantity(quantity)
}

export function handleCreateNumberMask() {

  const currencyMaskOptions = {
    prefix: 'R$ ',
    suffix: '',
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    integerLimit: 7,
    decimalLimit: 2,
    requireDecimal: true
  }

  return createNumberMask(currencyMaskOptions)
}

export function convertCurrencyToBRL(value) {
  const valueFormated = new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value)

  return `${valueFormated}`
}

export function returnStateName(state) {
  const states = {
    'AC': 'Acre',
    'AL': 'Alagoas',
    'AP': 'Amapá',
    'AM': 'Amazonas',
    'BA': 'Bahia',
    'CE': 'Ceará',
    'DF': 'Distrito',
    'ES': 'Espírito',
    'GO': 'Goiás',
    'MA': 'Maranhão',
    'MT': 'Mato Grosso',
    'MS': 'Mato Grosso do Sul',
    'MG': 'Minas Gerais',
    'PA': 'Pará',
    'PB': 'Paraíba',
    'PR': 'Paraná',
    'PE': 'Pernambuco',
    'PI': 'Piauí',
    'RJ': 'Rio de Janeiro',
    'RN': 'Rio Grande do Norte',
    'RS': 'Rio Grande do Sul',
    'RO': 'Rondônia',
    'RR': 'Roraima',
    'SC': 'Santa Catarina',
    'SP': 'São Paulo',
    'SE': 'Sergipe',
    'TO': 'Tocantins'
  }

  return states[state]
}

// PURCHASE STATUS
export const OPEN = 'OPEN'
export const ACCEPTED = 'ACCEPTED'
export const SEPARATED = 'SEPARATED'
export const DELIVERING = 'DELIVERING'
export const DELIVERED = 'DELIVERED'
export const CANCELLED = 'CANCELLED'
export const CLOSED = 'CLOSED'

// PRODUCT STATUS
export const DENIED = 'DENIED'
export const STANDBY = 'STANDBY'

export const TermsOfUse = `
Gowdock – Termos e Condições Gerais de Uso
          
Ao Usuário da Gowdock,
Estes termos e condições gerais de uso de nossa plataforma contém informações que o ajudarão a operar em nossas plataformas É muito importante que você conheça as nossas políticas e, por esta razão, é necessário que leia e compreenda os Termos e condições gerais de uso, bem como a leitura das cláusulas que viabilizam a contratação e as limitativas de direitos, aqui destacadas:

  1. A Gowdock não é fornecedora de quaisquer produtos ou serviços anunciados ou ofertados em suas plataformas. A Gowdock presta um serviço consistente na oferta de plataformas que fornecem espaços para que Usuários anunciantes/potenciais negociantes anunciem, oferecendo os seus próprios produtos e serviços para que eventuais interessados, os Usuários/potenciais compradores, possam negociar direta e exclusivamente entre si;
  2. Os Usuários anunciantes/potenciais negociantes somente poderão anunciar produtos ou serviços que possam ofertar, estabelecendo diretamente os termos do anúncio e todas as suas características (como título, descrição e imagem dos bens, preço, categoria, quantidade, condições de venda, forma e prazo de entrega, além da forma de pagamento);
  3. Para utilizar os serviços da Gowdock o Usuário deve aceitar a Política de Privacidade de suas plataformas, que contém informações claras e completas sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos Usuários e visitantes. O Usuário deverá efetuar um cadastro único, pessoal e intransferível. A Gowdock não se responsabiliza pelo uso inadequado e divulgação destes dados para terceiros. A Gowdock, nem quaisquer de seus empregados ou prepostos solicitará, por qualquer meio, físico ou eletrônico, que seja informada sua senha;
  4. A Gowdock, em razão de violação à legislação em vigor, a Política de Privacidade ou aos Termos e condições gerais de uso das plataformas poderá recusar qualquer solicitação de cadastro, advertir, suspender, temporária ou definitivamente, a conta de um Usuário, seus anúncios, suas compras ou aplicar uma sanção que impacte negativamente sua reputação;
  5. Não é permitido anunciar produtos expressamente proibidos pela legislação vigente ou por estes Termos e condições gerais de uso, que não possuam a devida autorização específica de órgãos reguladores competentes, ou que violem direitos de terceiros;
  6. A Gowdock disponibiliza um sistema para que o Usuário comprador opine sobre o Usuário negociante com relação à negociação realizada. A opinião será disponibilizada no perfil do Usuário negociante.
  7. O Usuário/potencial comprador, antes de decidir pela compra, deverá atentar-se às informações sobre a reputação do Usuário negociante, ao preço, às formas de pagamento, à disponibilidade, à forma e ao prazo de entrega dos produtos e serviços.
  8. O Usuário comprador poderá exercer o direito de arrependimento dos produtos adquiridos nas plataformas da Gowdock, dentro do prazo de 7 dias corridos a contar do recebimento. 
  9. É vedado aos Usuários anunciar à venda ou comprar produtos que sejam proibidos ou violem a legislação vigente, bem como sejam considerados produtos proibidos pela plataforma.
  10. O Usuário comprador se compromete em apenas agendar serviços de entrega quando houver disponibilidade para recebê-la em um período de 24 horas corridas. A Gowdock não se responsabiliza por mercadorias devolvidas em razão do destinatário estar ausente do destino solicitado na entrega. Cabe ao Usuário Entregador, em diálogo com o Usuário comprador definirem se haverá uma nova taxa a ser cobrada pela nova tentativa de entrega. 
  11. A Gowdock promove e incentiva a solução amigável de controvérsias e evitar a judicialização de questões que podem ser resolvidas administrativamente, razão pela qual, os Usuários aceitam e se comprometem a utilizar as ferramentas da plataforma (reclamação/ mediação) como primeiro meio para a solução de controvérsias decorrentes das compras e vendas realizadas nas plataformas.

Termos e condições gerais de uso das Plataformas:

1- Gowdock
A Gowdock é uma empresa de tecnologia que oferece serviços de intermediação de serviços e vendas. Os serviços oferecidos pela Gowdock foram projetados para formar um ecossistema que permita que as pessoas vendam, comprem, paguem, entreguem produtos e realizem outras atividades comerciais com tecnologia aplicada.

2- Termos e Condições de Uso
Estes termos e condições e seus anexos regulam a relação entre a Gowdock e as pessoas que usam seus serviços (doravante designadas “Usuários”). 
Os Usuários aceitam estes Termos e Condições a partir do momento em que se registram ou navegam pelos sites e aplicativos (doravante designadas “Plataformas”) da Gowdock.
Quando forem necessárias mudanças importantes em nossos serviços, publicaremos as modificações com 10 dias corridos de antecedência para que os Usuários possam revisá-las e continuar usando as Plataformas. Estas mudanças em nenhum caso afetarão as operações que já tenham finalizado.
Os Usuários que não tenham obrigações pendentes com a Gowdock ou com outros Usuários poderão finalizar a relação com a Gowdock cancelando sua conta.

3- Capacidade
As pessoas maiores de idade com capacidade legal para contratar poderão utilizar nossos serviços. Os menores de idade, a partir dos 13 anos, só poderão utilizar sua conta com autorização do representante legal, que responderá por todas as ações e obrigações decorrentes da utilização dessa conta e que deverá zelar pelo uso responsável e adequado dela em atenção à maturidade do menor que autorize.
Aqueles que utilizarem as Plataformas da Gowdock em representação de uma empresa deverão ter capacidade para contratar em nome dela. Além disso, para poder usar a conta, o Usuário deve estar ativo. 

4- Registro e conta
Para utilizar os serviços da Gowdock, é necessário o preenchimento de um formulário de registro com os dados que lhe forem requeridos. Ao completá-lo, o Usuário concorda em fazê-lo de forma exata, precisa e verdadeira e manter seus dados sempre atualizados. O Usuário será o único responsável pela certeza dos dados de registro. Sem prejuízo das informações fornecidas no formulário, poderemos solicitar e/ou consultar informações adicionais para verificar a identidade do Usuário
A conta do Usuário é pessoal, única e intransferível, ou seja, sob nenhuma circunstância pode ser vendida ou cedida a outra pessoa. Ela é acessada com a senha pessoal de segurança escolhida, devendo ser mantida sob estrita confidencialidade. Por isso, o Usuário será o único responsável pelas operações que se realizem em sua conta. Em caso de detecção de um uso não autorizado de sua conta, deverá notificar imediatamente e de forma fiável para a Gowdock. 
Poderemos rejeitar um pedido de registro ou cancelar um registro já aceite, sem que isso gere direito a uma indenização. Não poderão se registrar novamente no site os Usuários que tenham sido banidos previamente.
Além disso, no caso de detectar o uso de mais de uma conta, poderemos aplicar retenções, débitos e/ou qualquer outra medida se considerarmos que essa ação pode prejudicar o resto das pessoas que usam as Plataformas da Gowdock, além das sanções que possam corresponder. 
Ao se cadastrar na Gowdock, o Usuário poderá optar, a seu exclusivo critério, pela utilização de todos os serviços disponibilizados pelas empresas do grupo. Para fins de clareza, ao se cadastrar na Gowdock, o Usuário não será automaticamente cadastrado nos demais serviços disponibilizados pelas empresas do grupo.

5- Privacidade de dados
A Gowdock faz uso responsável das informações pessoais, protegendo a privacidade dos Usuários e toma as medidas necessárias para garantir a segurança em suas Plataformas.

6- Sanções
Caso o Usuário viole uma lei vigente ou os Termos e Condições, a Gowdock poderá avisar, suspender, restringir ou desativar temporariamente ou definitivamente a conta, sem prejuízo de outras sanções que se estabeleçam nas regras de uso particulares dos serviços da Gowdock. 

7- Responsabilidade
A Gowdock se responsabiliza por qualquer defeito na prestação de seu próprio serviço, na medida em que lhe seja imputável e com o alcance previsto nas leis vigentes. 

8- Tarifas
A Gowdock poderá cobrar tarifas por seus serviços, e o Usuário se compromete a pagá-las a tempo. 
Estas tarifas poderão ser modificadas ou eliminadas a qualquer momento com o devido pré-aviso estabelecido na cláusula 2 destes Termos e Condições. Da mesma forma, poderemos modificar as tarifas temporariamente por promoções em favor dos Usuários, estas sem aviso prévio.
o Usuário autoriza a Gowdock a reter e/ou debitar os fundos existentes e/ou futuros de sua conta e/ou das contas bancárias que tenha registado nas Plataformas, para saldar as tarifas não pagas ou qualquer outra dívida que possa ter. 
Em todos os casos, a fatura será emitida de acordo com os dados fiscais que as pessoas tenham carregados em sua conta. 

9- Propriedade intelectual
A Gowdock  e/ou suas sociedades relacionadas são proprietárias de todos os direitos de propriedade intelectual sobre suas Plataformas, todo seu conteúdo, serviços, produtos, marcas, nomes comerciais, logotipos, desenhos, imagens, frases publicitárias, direitos autorais, domínios, programas de computação, códigos, desenvolvimentos, software, bancos de dados, informações, tecnologia, patentes e modelos de utilidade, desenhos e modelos industriais, segredos comerciais, entre outros (doravante designada a “Propriedade Intelectual”), que estão protegidos por leis nacionais e internacionais.
Embora a Gowdock conceda permissão para usar seus produtos e serviços conforme previsto nos Termos e Condições, isso não implica uma autorização para usar sua Propriedade Intelectual, exceto com consentimento prévio e expresso da Gowdock e/ou de suas sociedades relacionadas. Em qualquer caso, os Usuários negociantes que utilizam os produtos e serviços não poderão utilizar a Propriedade Intelectual da Gowdock de uma forma que cause confusão no público, e deverão realizar sua atividade comercial sob uma marca ou nome comercial próprio e distintivo, que não seja confundível com a marca Gowdock e sua família de marcas.
É proibido usar nossos produtos ou serviços para fins ilegais, realizar qualquer tipo de engenharia reversa ou obras derivadas, usar ferramentas de busca ou extração de dados e conteúdo de nossa plataforma para reutilização e/ou criar bancos de dados próprios que incluam, no todo ou em parte, nosso conteúdo sem nossa expressa autorização. É também proibido o uso indevido, sem autorização e/ou contrário às normas vigentes e/ou que gere confusão ou implique uso difamatório e/ou que cause prejuízo, danos ou perdas a Gowdock e/ou suas sociedades relacionadas. A utilização dos produtos e serviços da Gowdock também não implica a autorização para usar propriedade intelectual de terceiros que possa estar envolvida, cujo uso ficará sob exclusiva responsabilidade do Usuário. Caso um Usuário ou qualquer anúncio infrinja a Propriedade Intelectual da Gowdock ou de terceiros, a Gowdock poderá remover esse anúncio totalmente ou parcialmente, sancionar o usuário conforme previsto nestes Termos e Condições e exercer as ações extrajudiciais e/ou judiciais correspondentes.

10- Indenidade
O Usuário manterá indene a Gowdock e suas sociedades relacionadas, assim como aqueles que a dirigem, sucedem, administram, representam e/ou trabalham nelas, perante qualquer reclamação administrativa ou judicial iniciada por outras Pessoas Usuárias, terceiros ou por qualquer entidade, em relação a suas atividades nas Plataformas.
Em virtude dessa responsabilidade, eles poderão realizar compensações, retenções ou outras medidas necessárias para a reparação de perdas, danos e prejuízos, qualquer que seja sua natureza.

11- Jurisdição e lei aplicável
Estes Termos e Condições são regidos pela lei local. Qualquer controvérsia decorrente de sua aplicação, interpretação, execução ou validade será resolvida pelos tribunais nacionais ordinários competentes, localizados na capital, salvo disposição específica de normas de ordem pública, como legislação relativa ao consumidor.          
`
