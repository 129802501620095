import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  
  div.buttons {
    display: flex;
    width: 100%;
    height: 35%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
`

export const StatusTitle = styled.h1`
  font-size: 2.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayMainText};
`

export const BackgroundIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  width: 8vh;
  border-radius: 4vh;
  background-color: ${({ theme }) => theme.grayInactivy};
`

