import React, { useEffect } from 'react';
import { useField, ErrorMessage } from 'formik';
import styled from 'styled-components';

const FormInput = styled.input`
  width: 100%;
  min-height: 40px; /* Adicione essa linha para definir uma altura mínima */
  border-radius: 6px;
  border: 1px solid ${({ borderColor }) => (borderColor ? 'red' : '#cacaca')};
  outline: 0;
  padding: 0.5rem;
  font-size: 1rem; 
  font-weight: 500;
  background: #f5f6f4;
  transition: 0.3s;

  &:focus {
    border: 1px solid #00ff00; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const ErrorMsg = styled.span`
  margin: 0;
  margin-top: ${({ mtop }) => mtop};
  margin-bottom: ${({ mbottom }) => mbottom};
  font-size: 0.9rem;
  color: red;
  font-weight: 600;
`;

export function Textfield({ borderColor, defaultValue, ...props }) {
  const [field, , helpers] = useField(props);
  const { setValue } = helpers;

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <InputContainer>
      <FormInput type="text" borderColor={borderColor} {...props} {...field} />
      <ErrorMessage name={field.name} render={(msg) => <ErrorMsg>{msg}</ErrorMsg>} />
    </InputContainer>
  );
}
