import React from 'react'
import {
  Container,
  Text,
} from './styles'

export default function ButtonLink({ icon, sideIcon, text, underline, fontSize, ...rest }) {
  return (
    <Container
      underline={underline}
      {...rest}
    >
      {icon ?
        sideIcon === 'right' ?
          <>
            <Text sideIcon={sideIcon} fontSize={fontSize} >{text}</Text>
            {icon}
          </>
          :
          sideIcon === 'left' &&
          <>
            {icon}
            <Text sideIcon={sideIcon} fontSize={fontSize}>{text}</Text>
          </>
        :
        <Text fontSize={fontSize}>{text}</Text>
      }

    </Container>
  )
}
