import React, { useState } from 'react'
import clsx from 'clsx'
import { Form, Formik } from 'formik'
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  ThemeProvider,
  Typography,
  CircularProgress,
} from '@material-ui/core'

import {
  Container,
  Content,
  Header,
  Sidebar,
} from '../../styles/layout/Layout'
import RegisterHeader from '../../components/RegisterHeader/RegisterHeader'
import LoginRegisterSidebar from '../../components/loginRegisterSidebar/Index'

import { theme } from '../../theme'
import { useModal } from '../../context/ModalContext'

import { Step1, Step2, RegisterFinished } from './RegisterBoth'
import { Step3LegalPerson, Step4LegalPerson } from './RegisterLegalPerson'
import {
  FormContainer,
  ContentContainer,
  StepperContainer,
  Button,
  Title,
  StepperConnector,
  ChildContainer,
  ButtonsContainer,
  BackButton,
  stepperStyles,
  StepContainer
} from './Styles'

import validationSchema from './formModel/validationSchema'
import RegisterformModel from './formModel/RegisterFormModel'
import formInitialValues from './formModel/formInitialValues'

import api, { register, uploadDoc, uploadImages } from '../../api/api'
import { verifyEmail } from '../../api/store'
import { clearMask } from '../../Utils'
import ErrorModal from '../../components/modals/errorModal'

const { formField } = RegisterformModel
const steps = ['conta', 'validação', 'sua loja', 'termos']

function StepIcon(props) {
  const classes = stepperStyles()

  const { active, completed } = props
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.label]: active,
        [classes.completed]: completed,
      })}
    />
  )
}

export default function Register() {
  const { setModal } = useModal()
  const [isCodeSent, setIsCodeSent] = useState(false)
  const [isLegalPerson, setIsLegalPerson] = useState(false)
  const [showCodeErrorMsg, setShowCodeErrorMsg] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [showNumberPhoneError, setShowNumberPhoneError] = useState(false)

  const classes = stepperStyles()

  const [step, setStep] = useState(0)

  const currentValidationSchema = validationSchema[step]

  const [timer, setTimer] = useState(0)

  async function submitForm(values, actions) {
    register(values)
      .then(async (response) => {
        uploadImages(values.logo, response.auth.accessToken, response.auth.user._id)
        // uploadDoc(values.CNPJphoto, response.auth.accessToken, response.auth.user._id)
        actions.setSubmitting(false)
        setModal(false)
        setStep(step + 1)
      })
      .catch((error) => {
        actions.setSubmitting(false)
        setModal(
          <ErrorModal errorMessage={error.response.data.message} />
        )
        //console.log(error.response.data.message)
      })
  }

  const isLastStep = step === steps.length - 1

  function handleSubmit(values, actions) {
    if (step === 3) {
      setModal(
        <CircularProgress style={{ color: theme.palette.primary.main }} />
      )
      submitForm(values, actions)
    } else {
      setStep(step + 1)
      actions.setTouched({})
      actions.setSubmitting(false)
    }
  }

  async function handleEmailValidation(props) {
    props.setTouched({
      firstName: true,
      lastName: true,
      email: true,
      password: true,
      confirmPassword: true,
    })

    if (
      !props.errors.firstName &&
      !props.errors.lastName &&
      !props.errors.email &&
      !props.errors.password &&
      !props.errors.confirmPassword
    ) {
      setModal(
        <CircularProgress style={{ color: theme.palette.primary.main }} />
      )

      try {
        const response = await verifyEmail(props.values.email)
        if (response.status === 200) {
          setStep(step + 1)
        }
      } catch {
        setEmailError('Este e-mail já está em uso')
      } finally {
        setModal()
      }
    }
  }

  async function handlePhoneCodeValidation(props) {
    if (!isCodeSent) {
      props.setTouched({ numberPhone: true, personType: true })
      if (!props.errors.numberPhone && !props.errors.personType) {
        setModal(
          <CircularProgress style={{ color: theme.palette.primary.main }} />
        )

        const object = {
          email: props.values.email,
          phoneNumber: '+55' + clearMask(props.values.numberPhone),
          checkUnitKey: true
        }
        await api.post('validation', object).then((res) => {
          console.log(res)
          setIsCodeSent(true)
          setTimer(60 * 2)
          props.setTouched({})
        }).catch((err) => {
          console.log(err)
          setShowNumberPhoneError(true)
        }).finally(() => {
          setModal()
        })
      }
    } else {
      props.setTouched({ code: true })
      if (!props.errors.code) {
        setModal(
          <CircularProgress style={{ color: theme.palette.primary.main }} />
        )

        const obj = {
          email: props.values.email,
          code: props.values.code,
        }

        await api
          .post('validation/code', obj)
          .then(() => {
            setStep(step + 1)
            props.setTouched({})
          })
          .catch(() => {
            setShowCodeErrorMsg(true)
          })
        setModal()
      }
    }
  }

  function handleBack() {
    if (step === 1) {
      if (!isCodeSent) setStep(step - 1)
      setIsCodeSent(false)
    } else setStep(step - 1)
  }

  function _renderStepContent(step, props) {
    switch (step) {
      case 0:
        return (
          <Step1
            formField={formField}
            setTouched={props.setTouched}
            setEmailError={setEmailError}
            emailError={emailError}
            {...props}
          />
        )
      case 1:
        return (
          <Step2
            showNumberPhoneError={showNumberPhoneError}
            setShowNumberPhoneError={setShowNumberPhoneError}
            setTouched={props.setTouched}
            isCodeSent={isCodeSent}
            formField={formField}
            setIsLegalPerson={setIsLegalPerson}
            isLegalPerson={isLegalPerson}
            setShowCodeErrorMsg={setShowCodeErrorMsg}
            showCodeErrorMsg={showCodeErrorMsg}
            handlePhoneCodeValidation={sendCodeAgain}
            timer={timer}
            setTimer={setTimer}
            formikProps={props}
            {...props}
          />
        )
      case 2:

        return <Step3LegalPerson
          formField={formField}
          setTouched={props.setTouched}
          {...props}
        />
      case 3:
        return <Step4LegalPerson formField={formField} {...props} />
      case 4:
        return <RegisterFinished />
      default:
        return <div>Not Found</div>
    }
  }

  function renderBottomButtons(props) {
    if (step === 4) return null
    const buttons = [<></>]
    if (step > 0) {
      buttons.push(
        <BackButton type='button' onClick={handleBack}>
          Voltar
        </BackButton>
      )
    } else buttons.push(<Button visibility='hidden' />)

    if (step === 1) {
      buttons.push(
        <Button type='button' onClick={() => handlePhoneCodeValidation(props)}>
          {isCodeSent ? 'Seguir' : 'Enviar código'}
        </Button>
      )
    } else if (step === 0) {
      buttons.push(
        <Button type='button' onClick={() => handleEmailValidation(props)}>
          Seguir
        </Button>
      )
    } else {
      buttons.push(
        <Button type='button' onClick={() => props.handleSubmit()} disabled={props.isSubmitting}>
          {isLastStep ? 'Finalizar ' : 'Seguir'}
        </Button>
      )
    }
    return buttons
  }

  async function sendCodeAgain(email, phoneNumber) {
    const object = {
      email,
      phoneNumber: '+55' + clearMask(phoneNumber),
      checkUnitKey: true
    }

    try {
      const response = await api.post('validation', object)
      if (response.status === 200) {
        setTimer(60 * 2)
      }
    } catch (error) {
      setShowNumberPhoneError(true)
    } finally {
      setModal()
    }
  }

  return (
    <Container>
      <Sidebar>
        <LoginRegisterSidebar />
      </Sidebar>

      <Header>
        <RegisterHeader />
      </Header>

      <Content>
        <ContentContainer>
          <FormContainer>
            <Title>Crie sua conta</Title>
            <Formik
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              onSubmit={handleSubmit}
            >
              {props => (
                <Form onSubmit={(event) => event.preventDefault()}>
                  <ChildContainer>
                    {_renderStepContent(step, props)}
                    <ButtonsContainer>
                      {renderBottomButtons(props)}
                    </ButtonsContainer>
                  </ChildContainer>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </ContentContainer>
      </Content>

      <StepContainer>
        <Step>
          <ThemeProvider theme={theme}>
            <StepperContainer>
              <Stepper
                activeStep={step}
                orientation='vertical'
                connector={<StepperConnector />}
              >
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={StepIcon}
                        classes={{ active: classes.label }}
                      >
                        <Typography color='primary'>{label} </Typography>
                      </StepLabel>
                      <StepContent />
                    </Step>
                  )
                })}
              </Stepper>
            </StepperContainer>
          </ThemeProvider>
        </Step>
      </StepContainer>
    </Container>
  )
}
