import * as Yup from 'yup'
import formModel from './formModel'

const {
  requiredErrorMsg,
  formField: {
    userName,
    number,
    email,
    description,
    selectedDocs,
  }
} = formModel

const validationSchema = 
  Yup.object().shape({
    [userName.name]: Yup.string().required(requiredErrorMsg),
    [number.name]: Yup.string().required(requiredErrorMsg).min(8, number.minError),
    [email.name]: Yup.string().email(email.notValidError).required(requiredErrorMsg),
    [description.name]: Yup.string().required(requiredErrorMsg).min(10, description.minError),
    [selectedDocs.name]: Yup.string().optional()
  })

export default validationSchema
