import styled from 'styled-components'
import { device } from '../../styles/sizes'

export const Container = styled.div`
  width: ${props => props.width || '50vw'};
  max-width: 800px;
  height: ${props => props.height || '65vh'};
  display: flex;
  border-radius: 8px 8px;
  background: ${props => props.theme.white};
  display: flex;
  z-index: 50;
  flex-direction: column;
  z-index: 100;

  @media ${device.mobileL} {
    border-radius: 8px 8px;
    width: 90%;
  }
`

export const Header = styled.div`
  height: 10vh;
  width: 100%;
  padding: 0 2vw;
  background: ${props => props.theme.grayBackgrounds};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media ${device.mobileL} {
    padding: 0 5%;
  }
`

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: ${props => props.theme.grayMainText};
`

export const CloseButotn = styled.div`
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  background: ${props => props.theme.grayButtonBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
