import styled from 'styled-components'

export const Container = styled.div`
  width: 60vw;
  height: 80vh;
  flex-shrink: 1;
  background-color: ${({ theme }) => theme.white};
  align-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 6em 1em;
`

export const Inside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 6em;
  border-bottom-left-radius: 1em;
  padding: 3em;
  padding-top: 1.5em;
  background-color: ${({ theme }) => theme.white};
  overflow: hidden;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 12%;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 0 4em;
  border-top-left-radius: 6em;
  border-top-right-radius: 1em;
  background-color: ${({ theme }) => theme.grayBackgrounds};
  h4 {
    color: ${({ theme }) => theme.grayMainText};
    font-weight: 700;
    font-size: 2.4em;
  }
  span {
    font-weight: 700;
    font-size: 2.4em;
    color: ${({ theme }) => theme.graySecondaryText};
    ::before {
      content: '•';
      color: ${({ theme }) => theme.grayMainText};
      margin: 0 6px;
    }
  }
`
export const Exit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4em;
  height: 4em;
  background-color: ${({ theme }) => theme.grayButtonBackground};
  border-radius: 50%;
  margin-left: auto;
`

export const PinkText = styled.span`
  font-weight: bold;
  font-size: 1.6em;
  color: ${({ theme }) => theme.primaryPink};
  margin-left: auto;
  margin-top: 1%;
`
export const Check = styled.button`
  width: 2.5em;
  height: 2.5em;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ active, theme }) => (active ? theme.primaryGreen : theme.white)};
  border: 2px solid ${({ active, theme }) => (active ? theme.primaryGreen : theme.grayInactivy)};
`

export const Deny = styled.button`
  width: 2.5em;
  height: 2.5em;
  border-radius: 100%;
  margin-left: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ active, theme }) => (active ? theme.statusRed : theme.white)};
  border: 2px solid ${({ active, theme }) => (active ? theme.statusRed : theme.grayInactivy)};
`

export const Div = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const TableAndText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%;
`
export const TableContainer = styled.div`
  overflow: auto;
  width: 100%;
`
export const ProductsTable = styled.table`
  width: 100%;
`
export const TableHead = styled.thead`
  background-color: ${({ theme }) => theme.graySecondaryText};
  color: ${({ theme }) => theme.white};
  font-size: 1.6em;
  width: 100%;
  position: sticky;
  top: 0;
`
export const TH = styled.th`
  font-weight: 100;
  border-top-left-radius: ${({ border_tl }) => (border_tl ? ' 0.5em' : 0)};
  border-top-right-radius: ${({ border_tr }) => (border_tr ? ' 0.5em' : 0)};
  padding: 0.5em 0;
  background-color: ${({ theme }) => theme.graySecondaryText};
  color: ${({ transparent }) => (transparent ? 'transparent' : '')};
  text-align: ${({ left }) => (left ? 'left' : '')};
  width: ${({ width }) => width};
`
export const TBody = styled.tbody`
  background-color: ${({ theme }) => theme.grayBackgrounds};
  overflow-y: scroll;
  max-height: 10vh;
  width: 100%;
`

export const TD = styled.td`
  padding: 0.8em 0;
  padding-left: ${({ center }) => (center ? '2.8%' : '')};
  text-align: ${({ left }) => (left ? 'left' : 'center')};
  width: ${({ width }) => width};
  font-size: 1.3em;
  color: ${({ theme }) => theme.black};
  margin-right: ${({ mRight }) => mRight || '0'};
`
export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

export const MiniTitle = styled.span`
  font-size: 0.9em;
  color: ${({ theme }) => theme.grayMainText};
`
export const ProductName = styled.h2`
  font-size: 1.1em;
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Link = styled.span`
  font-size: 0.9em;
  font-weight: bold;
  text-align: left;
  color: ${({ theme, accepted }) => accepted ? theme.primaryGreen : theme.primaryPink};
`
export const ProductImage = styled.img`
  height: auto;
  max-height: 10%;
  width: auto;
  max-width: 60%;
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CheckAllItems = styled.button`
  display: flex;
  width: 10em;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: ${({ theme }) => theme.primaryGreen};
  font-weight: 700;
  font-size: 1.3em;
`

export const BottomButtonsContainer = styled.div`
  display: flex;
  width: 60%;
  padding-right: 2.9%;
  margin-top: 2%;
  align-self: flex-end;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
