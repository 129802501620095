import React from 'react'
import { LineChart } from './Styles'
import { useTheme } from '../../context/ThemeContext'

export default function Chart({ data: info }) {

  const { currentTheme } = useTheme()

  const data = (canvas) => {
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 300)
    gradient.addColorStop(0, 'rgba(44,153,43,0.3)')
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)')

    return {
      labels: info?.labels || '',
      datasets: [{
        label: 'pedidos',
        data: info?.data || '',
        fill: true,
        backgroundColor: gradient,
        pointBackgroundColor: currentTheme.white,
        pointBorderColor: currentTheme.secondaryLightGreen,
        pointBorderWidth: 2,
        pointRadius: 8,
        borderColor: currentTheme.secondaryLightGreen,
        borderWidth: 3,
        tension: 0.4,
      }]
    }
  }

  return (
    <LineChart
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        },

        scales: {
          xAxes: {
            grid: {
              display: false
            },
            ticks: {
              color: currentTheme.graySecondaryText,
            }
          },

          yAxes: {
            grid: {
              color: currentTheme.grayBackgrounds,
              lineWidth: 2,
            },
            ticks: {
              color: currentTheme.graySecondaryText,
            },
            beginAtZero: true
          },
        }
      }}
    />
  )
}
