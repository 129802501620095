import React from 'react'

import {
  Container,
  Logo,
  Image,
  BackButton,
} from './Styles'

import LogoHorizontal from '../../assets/logo/Logo_Horizontal.svg'
import LoginImg from '../../assets/loginImage.svg'

export default function LoginRegisterSidebar() {
  return (
    <Container>
      <BackButton to='/login'>
        <Logo src={LogoHorizontal} />
      </BackButton>
      <Image src={LoginImg} />
    </Container>
  )
}
