import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const ContentSide = styled.div`
  width: ${({ width }) => width || 'auto'};
  height: 90%;
  padding-top:  3%;
  padding-left: 6%;
  `

export const ContentInfo = styled.div`
  width: 60%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const ContentBankInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 80%;
  justify-content: center;
  align-items: center;

  div.defaultSpace {
    height: 5%;
  }
`

export const Divider = styled.div`
  height: 80%;
  width: 2px;
  align-self: center;
  background-color: ${({ theme }) => theme.grayButtonBackground};
`

export const BackgroundIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  width: 8vh;
  border-radius: 4vh;
  background-color: ${({ theme }) => theme.grayInactivy};
`
