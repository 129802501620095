import styled, { css } from 'styled-components'
import { ReactComponent as Bank } from '../../../../../assets/bankIcon.svg'

import {
  SubTitle,
  Description,
} from '../../modalWithdrawBalance/styles'
import { device } from '../../../../../styles/sizes'

// export const ChartContainer = styled.div`
//   width: 70%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `
// export const SideContent = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   width: 50%;
//   justify-content: center;
//   align-items: center;
// `
// export const InfoContainer = styled.div`
//   width: 90%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   `

// export const InfoContent = styled.div`
//   width: 100%;
//   height: 50%;

//   button.boxed {
//     width: 100%;
//     height: 90%;
//   }
// `

// export const Divider = styled.div`
//   height: 90%;
//   width: 2px;
//   background-color: ${({ theme }) => theme.grayButtonBackground};
// `

// export const InputContainer = styled.div`
//   height: 25%;
//   width: 100%;
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;

//   span {
//     font-size: 1.2rem;
//     color: ${({ theme }) => theme.grayMainText};
//   }

//   margin-bottom: 3%;
//   `

// export const AddCashContainer = styled.div`
//   width: 100%;
//   height: 20%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   margin-bottom: 2%;
// `

// export const BankIcon = styled(Bank)`
//   fill: ${({ theme }) => theme.graySecondaryText};
//   width: 3rem;
//   height: 3rem;
//   transition: 0.3s;
// `

// export const SelectWithdrawalContainer = styled.button`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 75%;
//   width: 100%;
//   border: 1px solid ${({ theme }) => theme.graySecondaryText};
//   border-radius: 10px;
//   transition: 0.3s;

//   ${({ active }) => active && css`
//     border-color: ${({ theme }) => theme.secondaryLightGreen};
//     ${Description} {
//       color: ${({ theme }) => theme.secondaryLightGreen};
//     }
//     ${SubTitle} {
//       color: ${({ theme }) => theme.secondaryLightGreen};
//     }
//     ${BankIcon} {
//       fill: ${({ theme }) => theme.secondaryLightGreen};
//   }
//   `}

//   :hover {
//     border-color: ${({ theme }) => theme.secondaryLightGreen};
//   }
//   :hover ${Description} {
//     color: ${({ theme }) => theme.secondaryLightGreen};
//   }
//   :hover ${SubTitle} {
//     color: ${({ theme }) => theme.secondaryLightGreen};
//   }
//   :hover ${BankIcon} {
//     fill: ${({ theme }) => theme.secondaryLightGreen};
//   }
// `

// export const ErrorMsg = styled.span`
//   font-size: 1rem;
//   color: ${({ theme }) => theme.statusRed};
//   margin-bottom: ${(props) => props.margin ? '0' : '1vh'};
// `

export const ChartContainer = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    width: 100%;
  }
`

export const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 4px;

  @media ${device.mobileL} {
    width: 100%;
  }
`

export const InfoContainer = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const InfoContent = styled.div`
  width: 100%;
  height: 50%;

  button.boxed {
    width: 100%;
    height: 90%;
  }

  @media ${device.mobileL} {

  }
`

export const Divider = styled.div`
  height: 90%;
  width: 2px;
  background-color: ${({ theme }) => theme.grayButtonBackground};
`

export const InputContainer = styled.div`
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  span {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.grayMainText};
  }

  margin-bottom: 3%;
`

export const AddCashContainer = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2%;
`

export const BankIcon = styled(Bank)`
  fill: ${({ theme }) => theme.graySecondaryText};
  width: 3rem;
  height: 3rem;
  transition: 0.3s;
`

export const SelectWithdrawalContainer = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75%;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.graySecondaryText};
  border-radius: 10px;
  transition: 0.3s;

  ${({ active }) => active && css`
    border-color: ${({ theme }) => theme.secondaryLightGreen};
    ${Description} {
      color: ${({ theme }) => theme.secondaryLightGreen};
    }
    ${SubTitle} {
      color: ${({ theme }) => theme.secondaryLightGreen};
    }
    ${BankIcon} {
      fill: ${({ theme }) => theme.secondaryLightGreen};
  }
  `}

  :hover {
    border-color: ${({ theme }) => theme.secondaryLightGreen};
  }
  :hover ${Description} {
    color: ${({ theme }) => theme.secondaryLightGreen};
  }
  :hover ${SubTitle} {
    color: ${({ theme }) => theme.secondaryLightGreen};
  }
  :hover ${BankIcon} {
    fill: ${({ theme }) => theme.secondaryLightGreen};
  }

  @media ${device.mobileL} {
    width: 90%;
  }
`

export const ErrorMsg = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.statusRed};
  margin-bottom: ${(props) => props.margin ? '0' : '1vh'};
`
