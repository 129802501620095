import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import List from './list/List'
import Detail from './detail/Detail'
import { device } from '../../styles/sizes'
import { Modal } from '../../components/modal'
import { useModal } from '../../context/ModalContext'

export default function Products() {
  const { setModal } = useModal()
  const [item, setItem] = useState()

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 650);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    window.addEventListener('resize', handleResize);

    // Limpe o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container>
      <>
        <List setItem={setItem} selectedItem={item} isMobile={isMobile}/>
      </>
    </Container>
  )
}

const Container = styled.div`
  height: 83vh;
  display: flex;
  justify-content: center;

  @media ${device.mobileL} {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
`;