import { BRL_CNPJ, BRL_CPF } from './Masks'

function format(value, mask) {
  if (!mask) { return { masked: '', unmasked: value } }

  const text = value.replace(/\W+/g, '')
  let masked = ''
  const unmasked = text

  let index = 0
  let maskIndex = 0

  while (index < text.length) {
    if (masked.length === mask.length) {
      break
    }

    if (mask[index + maskIndex] instanceof RegExp) {
      if (mask[index + maskIndex].test(text[index])) {
        masked += text[index]
      }
    } else {
      if (mask[index + maskIndex] === text[index]) {
        masked += text[index]
      } else {
        masked += mask[index + maskIndex] + text[index]
        maskIndex += 1
      }
    }
    index += 1
  }

  return {
    masked,
    unmasked
  }
}

const BRL_CPF_CNPJ = (text) => {
  const rawValue = text?.replace(/\D+/g, '') || ''
  return rawValue.length <= 11 ? BRL_CPF : BRL_CNPJ
}

export function handleFormatWithMask(value, mask) {
  const masks = {
    '': format(value, ''),
    'CPF_CNPJ': format(value, BRL_CPF_CNPJ(value)),
    'CPF': format(value, BRL_CPF),
    'CNPJ': format(value, BRL_CNPJ),
  }

  return masks[mask]
}
