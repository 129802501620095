import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

	cursor: pointer;
	padding: 2%;

	@media ${device.mobileL} {
		width: 163px;
		height: 294px;		
	}
`

export const ImageContainer = styled.div`
	height: 28vh;
	width: 16vw;
	border-radius: 8px;
	transition: 0.3s ease;

	${({ active, theme }) => active && `
		border-width: 3.5px;
		border-color: ${theme.primaryGreen};
	`}

	:hover {
		border: 3.5px solid ${({ theme }) => theme.primaryGreen};
		box-sizing: border-box;
	}

	@media ${device.mobileL} {
		display: flex;
		width: 100%;
		height: 176px;
		align-items: center;
		justify-content: center;
	}
`

export const Image = styled.img`
	object-fit: cover;
	height: 100%;
  	width:100%;
	border-radius: inherit;

	@media ${device.mobileL} {
		object-fit: contain;
	}
`

export const ItemName = styled.h1`
	text-align: left;
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 5%;
	color: ${({ theme }) => theme.grayMainText};

	@media ${device.mobileL} {
		font-size: 14px;
	}
`

export const ItemInfoContainer = styled.div` 
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;

	div.divider{
		width: 100%;
	}
`

export const ItemsSold = styled.h3`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.grayMainText};
	`

export const ItemLogo = styled.div`
	display:flex;
	justify-content: center;
	align-items: center;
`
export const ItemRating = styled.div`
	grid-column-start:3;
	font-size: 1rem;
	color: ${({ theme }) => theme.grayMainText};
	text-align: end;
`

export const ItemStock = styled.h2`
	font-weight: 400;
	font-size: 1.2rem;
	color: ${({ theme }) => theme.graySecondaryText};
`

export const ItemPrice = styled.h1`
	grid-column-start: 3;
	font-size: 1.3rem;
	font-weight: bold;
	color:${({ theme }) => theme.grayMainText};
	text-align: end;

	@media ${device.mobileL} {
		font-size: 16px;
	}
`
