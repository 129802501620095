import React from 'react'
import { CircularProgress } from '@material-ui/core'

import logo from '../../assets/logo/Logo_Horizontal.svg'
import { theme } from '../../theme'

export default function LoadingPage() {
  return (
    <div className='h-screen w-screen flex flex-col justify-center items-center bg-config_gray'>
      <img src={logo} alt='Gowdock logo' className='h-auto w-64 mb-9' />
      <CircularProgress style={{ color: theme.palette.primary.main }} />
    </div>
  )
}
