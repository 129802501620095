import styled, { css } from 'styled-components'
import { FiChevronRight } from 'react-icons/fi'

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
`

export const HeaderTitle = styled.h1`
  grid-area: title;
  font-weight: 800;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.primaryGreen};
  white-space: nowrap;
  margin-bottom: 2%;
  align-self: flex-end;
  justify-self: center;
`

export const HeaderContent = styled.div`
  display: grid;
  grid-template-areas: 
    'img title btn'
    'img title .'
    'img search .'
    'img search .';
  grid-template-columns: 25% 50% 25%;
  grid-auto-rows: 1fr;
  width: 100%;
  background: ${({ theme }) => `linear-gradient(180deg, ${theme.white} 0%, ${theme.grayBackgrounds} 100%)`};
`

export const HeaderImage = styled.img`
  grid-area: img;
  height: 90%;
  align-self: flex-end;
  justify-self: center;
`

export const HeaderButtonArea = styled.div`
  grid-area: btn;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 5%;
`

export const ContainerSearch = styled.div`
  grid-area: search;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
`

export const SearchContent = styled.div`
  display: flex;
  width: 92%;
  height: 45%;
  border: 1px solid ${({ theme }) => theme.graySecondaryText};
  border-radius: 8px;
  padding-left: 2%;
  background-color: ${({ theme }) => theme.white};;
  `

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  background-color: transparent;
`

export const SearchButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  height: 100%;
  padding: 0 6%;
  border-radius: 0px 8px 8px 0px;
  background-color: ${({ theme }) => theme.primaryGreen};

  font-weight: 600;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.white};;
`

export const Footer = styled.div`
  display: flex;
  height: 60vh;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  `

export const FooterHeader = styled.div`
  display: flex;
  height: 13vh;
  width: 100%;
  padding: 0 13%;
  justify-content: space-between;
  align-items: center;
`

export const FooterContent = styled.div`
  width: 100%;
  height:60vh;
  padding: 0 13%;
  overflow-y: auto;
`

export const BorderButton = styled.button`
  width: 15%;
  height: 50%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.primaryPink};
  background-color: ${({ active, theme }) => (active ? theme.primaryPink : theme.white)};

  font-size: 1.5rem;
  color: ${({ active, theme }) => (active ? theme.white : theme.primaryPink)};
  font-weight: ${({ active }) => (active ? 'bold' : 600)};
`

export const AccordionContainer = styled.button`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 25%;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-bottom-width: 0;
  border-color: ${({ theme }) => theme.graySecondaryText};
  ${({ first }) => first && css`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-width: 0px;
    `
}
  ${({ last }) => last && css`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-width: 1px;
  border-top-width: 1px;
  margin-bottom: 50px;
  `
}
  padding: 10px 20px;
  cursor: pointer;
  transition: ease 0.4s;
  &:hover {
    background-color: whitesmoke;
  } 
  `

export const AccordionContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  `

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const AccordionPanel = styled.div`
  display: flex;
  flex-direction: row;
  height: ${({ active }) => active ? '15vh' : 0};
  justify-content: center;
  align-items: center;
  overflow: hidden;  
  text-align: start;
  transition: ease 0.2s;
`

export const AccordionIcon = styled(FiChevronRight)`
  transform: ${({ active }) => active ? 'rotate(90deg)' : 'rotate(0deg)'};
  transition: ease 0.4s;
  color: ${({ theme }) => theme.primaryPink};
`

export const AccordionTitle = styled.h2`
  font-weight: 600;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.primaryPink};
`

export const AccordionDescription = styled.p`
  font-weight: 600;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.grayMainText};

  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`

export const AccordionSeeMore = styled.button`
  display: ${({ active }) => active ? 'block' : 'none'};
  font-weight: 600;
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
  align-self: flex-end;
  justify-self: center;
  color: ${({ theme }) => theme.secondaryLightGreen};
  `

export const MissingAlert = styled.span`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme }) => theme.graySecondaryText};

  align-self: center;
`
