import React, { useEffect } from 'react'
import { ErrorMessage } from 'formik'

import {
  Container,
  Content,
  DefaultLabel,
  FieldContent,
  InputLine,
  DefaultButton,
  SpacedeInputLine
} from './styles'
import { FiPlus, FiMinus } from 'react-icons/fi'

import { useTheme } from '../../../context/ThemeContext'

import { getErrorStyle } from '../../../Utils'

import Tooltip from '../../../components/tooltip'
import MainTextArea from '../../../components/inputs/mainTextArea'
import MainInput from '../../../components/inputs/mainInput'

export default function SecondStep({ setStep, formFields, formikProps, state }) {
  const {
    productDescription,
    productWeight,
    productUnits,
    productDimensions,
    productLength,
    productWidth,
    productHeight,
  } = formFields
  const { errors, touched, values, getFieldProps, setFieldValue, handleBlur } = formikProps

  const { currentTheme: { primaryGreen } } = useTheme()

  function updateProductUnits(value) {
    const newValue = values[productUnits.name] + value
    setFieldValue(productUnits.name, newValue)
  }

  useEffect(() => {
    if (state && values.productDescription === '') {
      setFieldValue('productDescription', state.description)
      setFieldValue('productDimensions', { width: state.measurement.width, height: state.measurement.height, length: state.measurement.length, weight: state.measurement.weight })
      setFieldValue('productDimensions', { width: state.measurement.width, height: state.measurement.height, length: state.measurement.length, weight: state.measurement.weight })
      setFieldValue('productUnits', state.quantity)
    }
  }, [])

  return (
    <Container>
      <Content>
        <FieldContent>
          <MainTextArea
            label={productDescription.label}
            height='10vh'
            placeholder={productDescription.placeholder}
            error={!!getErrorStyle(productDescription.name, errors, touched)}
            {...getFieldProps(productDescription.name)}
          />
          <ErrorMessage name={productDescription.name} render={msg => <div className='error-message'>{msg}</div>} />
        </FieldContent>
        <FieldContent>
          <MainInput
            label={productWeight.label}
            width='100%'
            inputType='custom'
            suffix='kg'
            onBlur={handleBlur('productDimensions.weight')}
            value={values.productDimensions.weight != 0 && values.productDimensions.weight}
            onValueChange={({ value }) => {
              setFieldValue(productDimensions.name, { ...values.productDimensions, weight: !value ? 0 : parseFloat(value) })
            }}
            error={!!getErrorStyle('productDimensions.weight', errors, touched)}
            placeholder={productWeight.placeholder}
          />
          <ErrorMessage name='productDimensions.weight' render={msg => <div className='error-message'>{msg}</div>} />
        </FieldContent>

        <FieldContent>
          <DefaultLabel>
            {productDimensions.label}
            <Tooltip text={productDimensions.tooltip} />
          </DefaultLabel>
          <InputLine>
            <MainInput
              inputType='custom'
              suffix='cm'
              onBlur={handleBlur('productDimensions.width')}
              value={values.productDimensions.width != 0 && values.productDimensions.width}
              onValueChange={({ value }) => {
                setFieldValue(productDimensions.name, { ...values.productDimensions, width: !value ? 0 : parseFloat(value) })
              }}
              error={!!getErrorStyle('productDimensions.width', errors, touched)}
              placeholder={productWidth.placeholder}
            />
            <MainInput
              inputType='custom'
              suffix='cm'
              onBlur={handleBlur('productDimensions.length')}
              value={values.productDimensions.length != 0 && values.productDimensions.length}
              onValueChange={({ value }) => {
                setFieldValue(productDimensions.name, { ...values.productDimensions, length: !value ? 0 : parseFloat(value) })
              }}
              error={!!getErrorStyle('productDimensions.length', errors, touched)}
              placeholder={productLength.placeholder}
            />
            <MainInput
              inputType='custom'
              suffix='cm'
              onBlur={handleBlur('productDimensions.height')}
              value={values.productDimensions.height != 0 && values.productDimensions.height}
              onValueChange={({ value }) => {
                setFieldValue(productDimensions.name, { ...values.productDimensions, height: !value ? 0 : parseFloat(value) })
              }}
              error={!!getErrorStyle('productDimensions.height', errors, touched)}
              placeholder={productHeight.placeholder}
            />
          </InputLine>
          <ErrorMessage name='productDimensions.height' render={msg => <div className='error-message'>{msg}</div>} />
        </FieldContent>

        <FieldContent>
          <SpacedeInputLine>
            <DefaultButton type='button' onClick={() => updateProductUnits(-1)}>
              <FiMinus color={primaryGreen} size={24} />
            </DefaultButton>

            <MainInput
              inputType='custom'
              width='100%'
              label='Unidades do produto'
              suffix=' unidade(s)'
              allowNegative={false}
              onBlur={handleBlur('productUnits')}
              value={values[productUnits.name]}
              onValueChange={({ value }) => {
                setFieldValue(productUnits.name, !value ? 0 : parseInt(value))
              }}
              error={!!getErrorStyle(productUnits.name, errors, touched)}
              placeholder={productUnits.placeholder}
            />

            <DefaultButton type='button' onClick={() => updateProductUnits(+1)}>
              <FiPlus color={primaryGreen} size={24} />
            </DefaultButton>
          </SpacedeInputLine>
          <ErrorMessage name={productUnits.name} render={msg => <div className='error-message'>{msg}</div>} />
        </FieldContent>
      </Content>
    </Container>
  )
}
