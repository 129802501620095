import React from 'react'
import LoadingIndicator from '../../../../components/LoadingIndicator'

import {
  Image,
  ImageProfileButton,
  ImageProfileContainer,
  ImageProfileText,
  HiddenInput,
  Button,
  ImageContainer
} from './styles'

export default function Logofield({ disabled, Logo, setLogo, setFieldValue, name, loading }) {
  function handleImageChange(e) {
    if (e.target.files[0]) {
      setFieldValue('image', e.currentTarget.files[0])
  
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = () => {
        if (reader.readyState === 2) {
          setLogo(reader.result)
        }
      }
    }

  }

  return (
    <ImageProfileContainer>
      <ImageContainer>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Image src={Logo} />
        )}
      </ImageContainer>

      <ImageProfileButton>
        <ImageProfileText>Foto de perfil</ImageProfileText>

        <Button htmlFor='image' disabled={disabled}>
          Mudar foto
        </Button>
        <HiddenInput
          accept="image/*"
          id="image"
          type="file"
          onChange={handleImageChange}
          name={name}
        />
      </ImageProfileButton>
    </ImageProfileContainer>
  )
}
