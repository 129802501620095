import React from 'react'
import { useHistory } from 'react-router-dom'

import image from '../../assets/not_found.svg'

export default function NotFound() {
  const { push } = useHistory()

  function navigateToHome() {
    push('/')
  }

  return (

    <>
      <div className='flex flex-col h-5/6	 w-5/6 justify-between mx-auto'>

        <div className='flex flex-col mt-10 '>
          <span className='flex justify-center font-bold mb-4 text-pink text-5xl md:text-4xl text-center px-5'>
            Ops! Não tem nada aqui.
          </span>
          <p className='flex justify-center text-center font-semibold text-gray-700 text-lg mb-4 px-5'>
            Parece que você está perdido. Não conseguimos encontrar a página que você <br />
          está procurando ou ela não existe
          </p>
          <button onClick={navigateToHome} className='bg-light_green text-white font-bold mx-auto px-14 py-4 rounded-tl-3xl rounded-tr-lg rounded-bl-lg rounded-br-3xl focus:outline-none'>
            Voltar para o início
          </button>
        </div>

        <img src={image} alt='404 not found' className='h-96 mx-auto' />
      </div>
    </>
  )
}

