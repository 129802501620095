import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const FormContent = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
`

export const ForgotLink = styled(Link)`
  font-size: 1.4rem;
  color: ${props => props.theme.primaryPink};
  font-weight: 700;
  align-self: flex-end;
  margin-top: 2%;
  margin-bottom: 10%;
`

export const AccountsContent = styled.div`
  width: 45%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5%;
`

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 2%;
`

export const Title = styled.h3`
  font-size: 1.8rem;
  color: ${props => props.theme.grayMainText};
  font-weight: 700;
  `

export const AddContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  color: ${props => props.theme.primaryGreen};
  transition: 0.3s;
  
  :hover {
    color: ${props => props.theme.secondaryLightGreen};
  }
`

export const AddText = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  margin-left: 0.5vw;
`

export const NoAccountContainer = styled.div`
  width: 90%;
  height: 25%;
  background: ${props => props.theme.white};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NoAccountText = styled.span`
  font-size: 1.8rem;
  color: ${props => props.theme.graySecondaryText};
  font-weight: 700;
`

export const AccountsContainer = styled.div`
  display: flex;
  height: 68vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  -ms-overflow-style: none;  
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  } 
  
`
