import styled from 'styled-components'
import { device } from '../../styles/sizes'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '50vh'};
  margin:${({ marginVertical }) => marginVertical || '5%'}  0;
  background-color: ${({ theme }) => theme.grayBackgrounds};
  border-bottom: 3px solid ${({ theme }) => theme.white};

  @media ${device.mobileL} {
    height: 19vw;
    
  }
  `

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 5%;
  height: ${({ height }) => height || '50vh'};
  justify-content: space-between;
  align-items: center;

  @media ${device.mobileL} {
    margin-top: 1.2%;
  }
`

export const IconBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 60px;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  
`

export const FocusBackground = styled(IconBackground)`
  height: 5vh;
  width: 2.5vw;
  cursor: pointer;
  background-color: ${({ theme }) => theme.white}; 
  :hover {
    color: ${({ theme }) => theme.white};
    background-color: ${({ bgColor }) => bgColor || 'transparent'};
  }  
  transition: 0.3s;

  @media ${device.mobileL} {
    width: 5vw;
    height: 5vw;
    border-radius: 100px;
    background-color: ${({theme}) => theme.white};
  }
`

export const OrderNumber = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.black};

  @media ${device.mobileL} {
    font-size: 1.3rem;
  }
  `

export const BuyerName = styled.span`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.grayMainText};

  width: 18%; 
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  text-align : center;
`

export const AmmountItems = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.primaryPink};
`

export const DateTime = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.grayMainText};
`

export const OrderLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 45%;
  border-radius: 30px;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};

  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.white};

  @media ${device.mobileL} {
    height: 32px;
    font-size: 1rem;
  }
`

export const ShortContainer = styled.div`
  display: flex;
  flex-direction: column;
`
