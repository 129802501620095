import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0 3%;

  @media ${device.mobileL} {
    padding: 0 5%;
  }
`

export const HeaderContainer = styled(Container)`
  padding: initial;
  height: 10%;
  justify-content: center;
  align-items: flex-start;
  `

export const Content = styled.div`
  display: flex;
  width: inherit;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 51%;
`

export const ScrollableArea = styled.div`
  width: 31%;
  height: 35vh;
  border: 1px solid ${({ theme }) => theme.graySecondaryText};
  box-sizing: border-box;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.grayBackgrounds};
  overflow-y: auto;

  @media ${device.mobileL} {
    width: 100%;
  }
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20%;
  width: 100%;

  @media ${device.mobileL} {
    height: auto;
    margin-top: 5px;
  }
  `

export const FooterContent = styled.div`
  display: flex;
  height: 35%;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: ${({ theme }) => theme.grayMainText};

  @media ${device.mobileL} {
    flex-direction: column;
    justify-content: flex-start;
    height: 17.3vh;
    gap: 15px;
  }
`

export const ConfirmArea = styled(FooterContainer)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  justify-content: center;
  align-items: center;
  
  @media ${device.mobileL} {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 45%;
  }
`

export const LinkButtonContent = styled.div`
  display: flex;
`

export const DisplaySelectedCategorie = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 29%;
  border: 1px solid ${({ theme }) => theme.primaryPink};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${({ active, theme }) => active ? theme.primaryPink : theme.white};

  @media ${device.mobileL} {
    width: 90%;
    height: 37px;
    flex-direction: row;
  }
`

export const Label = styled.h3`
  font-size: 1.6rem;
  font-weight: 700;
  color:${({ theme }) => theme.grayMainText};
`

export const CategoryItemContainer = styled.div`
  width: 100%;
  height: 5vh;
  padding: 1%;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.white};
  }
  
  background-color: ${({ theme, selected }) => selected ? theme.white : 'transparent'};
  color: ${({ theme, selected }) => selected ? theme.primaryGreen : theme.grayMainText};
  `

export const CategoryItemContent = styled(CategoryItemContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5vh;
  padding: 0 3%;
  color: inherit;
  `

export const CategoryName = styled.span`
  width: 80%;
  font-size: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit;
  `

export const SelectedCategoryName = styled(CategoryName)`
  width: 90%;
  color: ${({ theme }) => theme.white};
`

export const GroupDisplayCategory = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 13px;
`;