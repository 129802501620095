import React from 'react'

import {
  HeaderContent,
  RegisterLink,
  RegisterText,
} from './Styles'

export default function LoginHeader() {
  return (
    <HeaderContent>
      <RegisterText>Não é um membro?</RegisterText>
      <RegisterLink to='/register'>Cadastre-se</RegisterLink>
    </HeaderContent>
  )
}
