import styled from 'styled-components'
import InputMask from 'react-input-mask'

export const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: ${({ theme }) => theme.white};
`

export const Content = styled.div`
  display: grid;
  grid-template-areas:
  'form . img'
  'socialMidia . img'
  ;
  grid-template-columns: 60% 0% 40%;
  grid-template-rows: 75% 25%;
  width: 100%;
  height: 100%;
  padding-left: 2%;
`

export const BackButtonArea = styled.div`
  margin-bottom: 8px;
  width: 100%;
`

export const ContactUsImg = styled.img`
  grid-area: img;
  width: 90%;
  align-self: flex-end;
  justify-self: flex-end;
  padding-bottom: 20%;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme }) => theme.grayMainText};
`

export const SocialMidiaContainer = styled.div`
  grid-area: socialMidia;
  display: flex;
  justify-content: space-between;
  align-items: center;  
`

export const SocialMidiaContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  width: 75%;
  height: 100%;
`

export const FormContainer = styled.div`
  grid-area: form;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const BorderContent = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection && flexDirection};
  justify-content: space-evenly;
  align-items: center;
  height: ${({ height }) => height ? height : '100%'};  
  width: 100%;
  max-width: 80%;
  padding: 2% 6%;
  background-color: ${({ activeBackground, theme }) => activeBackground ? theme.grayBackgrounds : 'transparent'};
  border-radius: 50px 10px 50px 10px;
`

export const BorderContentRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const BorderHeader = styled.div`
  width: 100%;
`

export const InputContent = styled.div`
  width: ${({ width }) => width || 'initial'};
  height: ${({ height }) => height || 'initial'};
  display: flex;
  flex-direction: column;
`

export const DefaultInput = styled(InputMask)`
  width: 100%;
  height: 6.66vh;
  padding-left: 16px;
  border-radius: 6px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.graySecondaryText};
  background-color: transparent;
  
  color: ${({ theme }) => theme.grayMainText};
  font-size: 1.6rem;
  
  ::placeholder {
    color: ${({ theme }) => theme.graySecondaryText};
  }
`

export const DefaultTextArea = styled.textarea`
  width: ${({ width }) => width};
  height: 100%;
  min-height: 30%;
  resize: none;
  padding: 16px;
  border-radius: 6px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.graySecondaryText};
  background-color: transparent;
  color: ${({ theme }) => theme.grayMainText};
  font-size: 1.6rem;

  ::placeholder {
    color: ${({ theme }) => theme.graySecondaryText};
  }
`

export const SocialMediaButton = styled.button`
  display: flex;
  width: 20%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5%; 
`

export const SocialMediaIcon = styled.img`
  width: 70%;
`

export const MessageError = styled.h2`
  color: ${({ theme }) => theme.primaryPink};
  align-self: flex-start;
`
