import React, { useState, useEffect, useRef } from 'react'
import { FiCalendar, FiX } from 'react-icons/fi'
import { ptBR } from 'date-fns/locale'
import { addDays, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import LeftArrowBlack from '../../assets/left_arrow_black.svg'
import { useTheme } from '../../context/ThemeContext'
import { formatOnlyDate } from '../../Utils'

import {
  Container,
  CustomData,
  FooterContainer,
  DateButton,
  HeaderContainer,
  HeaderContent,
  ClearButton,
  Title,
  LabelArea,
  SubTitle,
  TitleContainer,
  Content,
  CustomDataSide,
} from './DataPickerStyles'

const MONTH_BUTTON = 0
const MONTH = { start: startOfMonth(new Date()), end: endOfMonth(new Date()) }

const TODAY_BUTTON = 1
const TODAY = new Date()

const WEEK_BUTTON = 2
const WEEK = { start: startOfWeek(new Date(), { weekStartsOn: 1 }), end: endOfWeek(new Date(), { weekStartsOn: 1 }) }

export default function DataPicker({ onChange, defaultValue }) {
  const { currentTheme } = useTheme()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [dataString, setDataString] = useState(defaultValue ? [formatOnlyDate(defaultValue.startDate), formatOnlyDate(defaultValue.endDate)] : null)
  const [activeButton, setActiveButton] = useState(null)
  const [selectedDate, setSelectedDate] = useState(
    defaultValue ? [defaultValue] :
      [{
        startDate: undefined,
        endDate: undefined,
        key: 'selection',
      },]
  )
  const calendarRef = useRef(null)
  useEffect(() => {
    if (selectedDate[0].startDate && selectedDate[0].endDate) {
      onChange(selectedDate[0])
    } else {
      onChange(null)
    }
  }, [selectedDate])

  function selectRange(item) {
    const formatTimeDate = {
      selection: {
        startDate: new Date(item.selection.startDate.setHours(0)),
        endDate: new Date(item.selection.endDate.setHours(23, 59, 59)),
        key: 'selection',
      }
    }
    setSelectedDate([formatTimeDate.selection])
    setDataString([formatOnlyDate(formatTimeDate.selection.startDate), formatOnlyDate(formatTimeDate.selection.endDate)])
  }

  function handleClear() {
    setSelectedDate([{
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
    },])
    setDataString(null)
    setActiveButton(null)
  }

  function handleMonthClick() {
    const date = [
      {
        startDate: new Date(MONTH.start.setHours(0)),
        endDate: new Date(MONTH.end.setHours(23, 59, 59)),
        key: 'selection',
      },
    ]
    setSelectedDate(date)
    selectRange({ selection: { ...date[0] } })
    setActiveButton(MONTH_BUTTON)
  }
  function handleTodayClick() {
    const date = [
      {
        startDate: new Date(TODAY.setHours(0)),
        endDate: new Date(TODAY.setHours(23, 59, 59)),
        key: 'selection',
      },
    ]
    setSelectedDate(date)
    selectRange({ selection: { ...date[0] } })
    setActiveButton(TODAY_BUTTON)
  }
  function handleWeekClick() {
    const date = [
      {
        startDate: new Date(WEEK.start.setHours(23, 59, 59)),
        endDate: new Date(WEEK.end.setHours(23, 59, 59)),
        key: 'selection',
      },
    ]
    setSelectedDate(date)
    selectRange({ selection: { ...date[0] } })
    setActiveButton(WEEK_BUTTON)
  }

  function renderMonthYearAndArrows(focusedDate, changeShownDate, props) {
    const { locale } = props
    return (
      <div
        onMouseUp={(e) => e.stopPropagation()}
        className={'rdrMonthAndYearWrapper'}
      >
        <button
          type="button"
          onClick={() => changeShownDate(-1, 'monthOffset')}
        >
          <img src={LeftArrowBlack} />
        </button>

        <span className={'rdrMonthAndYearPickers'}>
          {locale.localize.month(focusedDate.getMonth())}{' '}
          {focusedDate.getFullYear()}
        </span>
        <button
          type="button"
          onClick={() => changeShownDate(+1, 'monthOffset')}
        >
          <img style={{ transform: 'rotate(180deg)' }} src={LeftArrowBlack} />
        </button>
      </div>
    )
  }

  return (
    <Container active={showDatePicker}>
      <TitleContainer>
        <Title>Período</Title>
      </TitleContainer>
      <LabelArea>
        <div className='left'>
          <SubTitle>De</SubTitle>
        </div>
        <div className='right'>
          <SubTitle>Até</SubTitle>
        </div>
      </LabelArea>
      <HeaderContainer>
        <ClearButton>
          <div className='withBackground'>
            <FiCalendar size='1.8rem' />
          </div>
        </ClearButton>
        <HeaderContent>
          <DateButton
            text={dataString ? dataString[0] : 'Data'}
            onClick={() => setShowDatePicker(true)}
            width='48%'
            height='60%'
            smallFont
          />
          <DateButton
            text={dataString ? dataString[1] : 'Data'}
            onClick={() => setShowDatePicker(true)}
            width='48%'
            height='60%'
            smallFont
          />
        </HeaderContent>
        <ClearButton onClick={handleClear} >Limpar</ClearButton>
      </HeaderContainer>

      <Content active={showDatePicker}>
        <CustomDataSide>
          <div className='withBackground' onClick={() => setShowDatePicker(false)}>
            <FiX color={currentTheme.grayMainText} size='1.8rem' />
          </div>
        </CustomDataSide>
        <CustomData ref={calendarRef}>
          <DateRangePicker
            locale={ptBR}
            weekdayDisplayFormat="EEEEE"
            showMonthAndYearPickers={false}
            showDateDisplay={false}
            rangeColors={[currentTheme.primaryGreen, currentTheme.primaryGreen]}
            ranges={selectedDate}
            onChange={selectRange}
            navigatorRenderer={renderMonthYearAndArrows}

          />
        </CustomData>
        <CustomDataSide />
      </Content>

      <FooterContainer>
        <DateButton
          text='Mês'
          width='30%'
          height='100%'
          smallFont
          onClick={handleMonthClick}
          active={activeButton === MONTH_BUTTON}
        />
        <DateButton
          text='Semana'
          width='30%'
          height='100%'
          smallFont
          onClick={handleWeekClick}
          active={activeButton === WEEK_BUTTON}
        />
        <DateButton
          text='Hoje'
          width='30%'
          height='100%'
          smallFont
          onClick={handleTodayClick}
          active={activeButton === TODAY_BUTTON}
        />
      </FooterContainer>
    </Container>
  )
}
