import React, { useState } from 'react'
import { FiCreditCard, FiLock, FiUser } from 'react-icons/fi'

import { useAuth } from '../../context/AuthContext'
import { useTheme } from '../../context/ThemeContext'

import { Container, MenuContainer, Content, MenuSelector, SelectorText, Title } from './styles'

import Account from './account'
import BankAccounts from './bankAccounts'
import Preferences from './preferences'
import Safety from './safety'

export default function Settings({ initialSection, location }) {
  const { currentTheme } = useTheme()
  const { user } = useAuth()
  const { isDark, setIsDark } = useTheme()

  const [pageSelected, setPageSelected] = useState(location.pathname.split('/')[2] || initialSection || 'account')

  // conta
  const [Logo, setLogo] = useState(user.storeData.image)

  // sistema 
  const [messagePush, setPushMessage] = useState(false)
  const [messageEmail, setMessageEmail] = useState(false)
  const [orderPush, setOrderPush] = useState(false)
  const [orderEmail, setOrderEmail] = useState(false)
  const [completedOrderPush, setCompletedOrderPush] = useState(false)
  const [completedOrderEmail, setCompletedOrderEmail] = useState(false)
  const [foundDeliveryPush, setFoundDeliveryPush] = useState(false)
  const [foundDeliveryEmail, setFoundDeliveryEmail] = useState(false)
  const [addedProductPush, setAddedProductPush] = useState(false)
  const [addedProductEmail, setAddedProductEmail] = useState(false)

  // segurança
  const [isSended, setIsSended] = useState(false)
  const [isRequestApproved, setIsRequestApproved] = useState(false)
  const [password, setPassword] = useState('')

  // contas bancarias
  const [auth, setAuth] = useState(false)
  const [pass, setPass] = useState(false)
  const [accounts, setAccounts] = useState([])


  function contentRendered(page) {
    switch (page) {
    case 'account':
      return { component: <Account Logo={Logo} setLogo={setLogo} />, title: 'Conta' }

    case 'preferences':
      return {
        component: <Preferences messagePush={messagePush} setPushMessage={setPushMessage}
          messageEmail={messageEmail} setMessageEmail={setMessageEmail}
          orderPush={orderPush} setOrderPush={setOrderPush}
          orderEmail={orderEmail} setOrderEmail={setOrderEmail}
          completedOrderPush={completedOrderPush} setCompletedOrderPush={setCompletedOrderPush}
          completedOrderEmail={completedOrderEmail} setCompletedOrderEmail={setCompletedOrderEmail}
          foundDeliveryPush={foundDeliveryPush} setFoundDeliveryPush={setFoundDeliveryPush}
          foundDeliveryEmail={foundDeliveryEmail} setFoundDeliveryEmail={setFoundDeliveryEmail}
          addedProductPush={addedProductPush} setAddedProductPush={setAddedProductPush}
          addedProductEmail={addedProductEmail} setAddedProductEmail={setAddedProductEmail}
          isDark={isDark} setIsDark={setIsDark}
        />,
        title: 'Sistema'
      }

    case 'safety':
      return {
        component: <Safety
          isSended={isSended} setIsSended={setIsSended} isRequestApproved={isRequestApproved}
          setIsRequestApproved={setIsRequestApproved} pass={password} setPass={setPassword}
        />,
        title: 'Segurança'
      }
    case 'revenues':
      return {
        component: <BankAccounts
          auth={auth} setAuth={setAuth}
          pass={pass} setPass={setPass}
          accounts={accounts} setAccounts={setAccounts}
        />,
        title: 'Contas Bancárias'
      }
    default:
      break
    }
  }

  return (
    <Container>
      <MenuContainer>
        <MenuSelector onClick={() => setPageSelected('account')} active={pageSelected === 'account'}>
          <FiUser size='2rem' color={currentTheme.grayMainText} />
          <SelectorText>
            Conta
          </SelectorText>
        </MenuSelector>
        {/* <MenuSelector onClick={() => setPageSelected('preferences')} active={pageSelected === 'preferences'}>
          <FiSettings size='2rem' color={currentTheme.grayMainText} />
          <SelectorText>
            Sistema
          </SelectorText>
        </MenuSelector> */}
        <MenuSelector onClick={() => setPageSelected('safety')} active={pageSelected === 'safety'}>
          <FiLock size='2rem' color={currentTheme.grayMainText} />
          <SelectorText>
            Segurança
          </SelectorText>
        </MenuSelector>
        <MenuSelector onClick={() => setPageSelected('revenues')} active={pageSelected === 'revenues'}>
          <FiCreditCard size='2rem' color={currentTheme.grayMainText} />
          <SelectorText>
            Contas bancárias
          </SelectorText>
        </MenuSelector>
      </MenuContainer>

      <Content>
        <Title>
          {contentRendered(pageSelected).title}
        </Title>
        {contentRendered(pageSelected).component}
      </Content>
    </Container>
  )
}
