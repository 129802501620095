import styled from 'styled-components'

export const Container = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BankContainer = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  `

export const AccountDataContainer = styled.div`
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
`

export const Title = styled.h4`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${props => props.theme.grayMainText};
  margin: 10% 0;
`

export const Text = styled.h4`
  font-size: 1.2rem;
  font-weight: ${props => props.bold ? 700 : 400};
  color: ${props => props.theme.grayMainText};
  display: flex;
  margin-left: ${props => props.bold ? 0 : '0.4vw'};
  margin-bottom: 2%;
`

export const Row = styled.div`
  display: flex;
`
