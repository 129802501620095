import styled from 'styled-components'
import { device } from '../../styles/sizes'

export const Container = styled.div`
  width: 100%;
  min-height: 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4vw;
  background-color: ${({ theme }) => theme.white};
`

export const TextContainer = styled.div`
  display: flex;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.graySecondaryText};
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  `

export const Subtitle = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayMainText};
`

export const Divisor = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayMainText};
  margin: 0 1vw;
`

export const NavigationButton = styled.div`
  position: relative;
  cursor: pointer;
`

export const NavigationContainer = styled.div`
  height: 65%;
  width: 15%;
  background: ${({ theme }) => theme.grayBackgrounds};
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.8rem;
  `

export const NotificationAdvice = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.white};
  bottom: -0.4vh;
  left: -0.3vw;
  height: 2vh;
  width: 2vh;
  border-radius: 50%;
  background: ${({ theme }) => theme.statusRed};
`
export const MobileContainer = styled.div`
    width: 100vw;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.mobileGreen};
`;

export const Logo = styled.img`
  width: 157px;
  height: auto;
  cursor: pointer;
`

export const MenuModal = styled.div`
  display: ${(props) => props.display};
  flex-direction: column;
  width: 100vw;
  height: 192px;
  top: 64px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  background-color: beige;
  position: absolute;
`;

export const ButtonGroup = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5vw;
`;

export const Button = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ButtonIcon = styled.img`
`;

export const ButtonText = styled.span`
    margin-left: 8px;
    font-size: 16px;
    font-weight: 700;
    color: ${({theme}) => theme.grayMainText};
`;

export const Icon = styled.img`
    margin-right: 10px;
`;