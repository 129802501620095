import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Content = styled.div`
  width: 100%;
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FilterContent = styled.div`
  width: 100%;
  height: 20%;
  margin-bottom: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 2% 0;
  
  @media ${device.mobileL} {
    margin: 1.8% 0;
  }
`

export const BorderButton = styled.button`
  width: 30%;
  height: 100%;
  border: ${({ active }) => active ? '2px' : '1px'} solid ${({ theme, active }) => active ? theme.secondaryPink : theme.graySecondaryText};
  border-radius: 10px;
  background-color: transparent;
  padding: 1% 2%;
  transition: 0.3s ease;

  div.number {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  h3.number {
    font-weight: 700;
    font-size: 2rem;
    color:  ${({ theme }) => theme.secondaryPink};
  }

  div.day {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  h3.day {
    font-weight: 600;
    font-size: 1.2rem;
    color:  ${({ theme }) => theme.secondaryPink};
  }

  @media ${device.mobileL} {
    height: 55px;
    width: 29%;
    margin: 1% 0;
    h3.number {
      font-size: 1.8rem;
    }
  }
`

export const ButtonText = styled.h3`
  font-weight: 700;
  font-size: 2rem;
  color:  ${({ theme }) => theme.secondaryPink};
`

export const LoadingArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
