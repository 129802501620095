import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;

  grid-template-areas: 
  'aHeader bHeader'
  'aList aDetails'
  ;

  grid-template-rows: 10%;
  grid-template-columns: 65% 35%;
`

export const HeaderContainer = styled.div`
  grid-area: aHeader;
  width: 100%;
  height: 100%;
`

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 2%;
  justify-content: space-between;
  align-items: center;
`

export const HeaderContainerRigth = styled(HeaderContainer)`
  grid-area: bHeader;
`

export const HeaderContentRigth = styled(HeaderContent)`
  justify-content: flex-end;
`

export const ListContainer = styled.div`
  grid-area: aList;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  `

export const OverflowMask = styled.div`
  width: 96%;
  height: 90vh;
  border-radius: 48px 16px 48px 16px;
  background-color: ${({ theme }) => theme.grayBackgrounds};
  overflow: hidden;
  `

export const ListContent = styled.div`
  width: 100%;
  height: 85%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  `
export const ListLoadingContent = styled(ListContent)`
  justify-content: center;
`

export const FocusContainer = styled.div`
  grid-area: aDetails;
  width: 100%;
  height: 100%;
`

export const FocusContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 2%;
`

export const ProductsAlert = styled.span`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme }) => theme.graySecondaryText};

  align-self: center;
`
