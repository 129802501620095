import React, { useState, useRef } from 'react'
import { useField } from 'formik'

import {
  InputContainer,
  LogoContainer,
  CircleImg,
  InfoContainer,
  InfoText,
  ChangeButton,
  InfoButtonsContainer,
  InputFile,
  ErrorMessage,
} from '../Styles'

import ProfileImg from '../../../assets/profile_img.svg'

export function LogoFileField({ onChange, value, ...props }) {
  // eslint-disable-next-line no-unused-vars
  const [field, meta] = useField(props)
  const [Logo, setLogo] = useState(readImage(value) || ProfileImg)
  const inputRef = useRef()

  function readImage(file) {
    if (!file) return false
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setLogo(reader.result)
      }
    }

    reader.readAsDataURL(file)
  }

  function handleImageChange(e) {
    readImage(e.target.files[0])
    onChange(e.target.files[0])
  }

  return (
    <InputContainer>
      <LogoContainer mtop='3vh'>
        <CircleImg src={Logo} />
        <InfoContainer>
          <InfoText>Adicionar logo da loja</InfoText>
          <InfoButtonsContainer>
            <ChangeButton htmlFor='logo'>
              {Logo === ProfileImg ? 'Adicionar' : 'Trocar'}
            </ChangeButton>
            <InputFile
              ref={inputRef}
              accept='image/jpeg, image/pjpeg, image/png'
              id='logo'
              type='file'
              onChange={handleImageChange}
            />
          </InfoButtonsContainer>
        </InfoContainer>
      </LogoContainer>
      {
        <ErrorMessage show={meta.touched && meta.error && true}>
          {meta.error}
        </ErrorMessage>
      }
    </InputContainer>
  )
}
