import { createMuiTheme } from '@material-ui/core'

import colors from './styles/colors'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.light_green,
    },
  },
  overrides: {
    MuiStepLabel: {
      label: {
        display: 'none',
      },
      active: {
        display: 'block',
        position: 'relative',
        textAlign: 'right',
        left: -160,
        minWidth: 100,
      },

    },
    MuiStepper: {
      vertical: {
        float: 'right',
      },
    },

  },
})