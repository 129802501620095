import * as Yup from 'yup'
import FormModel from './FormModel'

const {
  formField: {
    name,
    document,
    bank,
    accountType,
    agency,
    account,
    accountDigit,
    code
  },
  requiredErrorMsg,
  incompleteErrorMsg,
  maxCharAmount
} = FormModel

export default [
  Yup.object().shape({
    [name.name]: Yup.string().required(requiredErrorMsg),
    [document.name]: Yup.string().required(requiredErrorMsg).min(11, incompleteErrorMsg),
    [bank.name]: Yup.string().required(requiredErrorMsg),
    [accountType.name]: Yup.string().required(requiredErrorMsg),
    [agency.name]: Yup.string().required(requiredErrorMsg).min(3, incompleteErrorMsg).max(6, maxCharAmount),
    [account.name]: Yup.string().required(requiredErrorMsg).matches(/^[0-9]*$/g, 'Apenas números.').min(4, incompleteErrorMsg).max(11, maxCharAmount),
    [accountDigit.name]: Yup.string().optional().matches(/^[0-9]*$/g, 'Apenas número.').max(1, maxCharAmount),
  }),
  Yup.object().shape({}),
  Yup.object().shape({
    [code.name]: Yup.string().min(6, incompleteErrorMsg).required(requiredErrorMsg),
  }),
]