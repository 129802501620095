import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  Container,
  Content,
  ListLoadingContent,
  ProductsAlert
} from './styles'

import {
  Header,
  Title,
} from '../stockArea/styles'

import { useModal } from '../../../context/ModalContext'
import Focus from '../../../components/focus/Focus'
import Order from '../../../components/order'
import ButtonLink from '../../../components/buttons/buttonLink'
import LoadingIndicator from '../../../components/LoadingIndicator'
import { formatShortDate } from '../../../Utils'

export function PendingArea({ orders, loading }) {
  const { setModal } = useModal()
  const { push } = useHistory()

  function handleOpenOrderDetail(data) {
    setModal(<Focus width='30vw' height='65vh' focusItem={{ data }} showCloseBtn={true} />)
  }

  return (
    <Container>
      <Header>
        <Title>Pedidos pendentes</Title>
        <ButtonLink
          text='Ver mais'
          underline={false}
          onClick={() => push('/management')}
        />
      </Header>
      <Content>
        {loading ?
          <LoadingIndicator />
          :
          orders?.length > 0 ?
            orders
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) 
              .map((item, key) => (
                <Order
                  key={key}
                  marginVertical='0vh'
                  width='100%'
                  height='12vh'
                  orderNumber={item.code || '#453ABD'}
                  buyerName={item.client.name}
                  ammountItems={item.storeOrder[0]?.products?.length}
                  dateTime={formatShortDate(item.createdAt)}
                  orderStatus={item.status}
                  onClick={() => handleOpenOrderDetail(item)}
                  short={true}
                />
              ))
            :
            <ListLoadingContent>
              <ProductsAlert>Nenhum pedido pendente</ProductsAlert>
            </ListLoadingContent>
        }
      </Content>
    </Container>
  )
}
