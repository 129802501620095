import React from 'react'
import { FiX } from 'react-icons/fi'
import { useModal } from '../../context/ModalContext'
import { useTheme } from '../../context/ThemeContext'

import {
  Container,
  Header,
  CloseButotn,
  Title
} from './styles'

export function Modal({ height, width, title, children, noHeader }) {
  const { setModal } = useModal()
  const { currentTheme } = useTheme()

  return (
    <Container height={height} width={width}>
      {!noHeader && (
        <Header>
          <Title>{title}</Title>

          <CloseButotn onClick={() => setModal()}>
            <FiX size='2.2rem' color={currentTheme.grayMainText} />
          </CloseButotn>
        </Header>
      )}

      {children}

    </Container>
  )
}
