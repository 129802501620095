import styled from 'styled-components'
import { Link } from 'react-router-dom'
import colors from '../../styles/colors'
import { device } from '../../styles/sizes'

export const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  @media ${device.mobileL} {
    width: 80vw;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

export const LoginText = styled.p`
  margin: 2.5rem 3.5rem;
  font-size: 1rem;
  font-weight: 600;

  @media ${device.mobileL} {
    margin: 2.5rem 1rem;
  }
  `
  
export const LoginLink = styled(Link)`
  text-decoration: 0;
  color: ${colors.span_green};
  transition: 0.3s;

  &:hover {
    color: ${colors.secondary_green}
  }
`
