import React from 'react'

import { Container, Input, Label, CurrencyInput } from './styles'

export default function MainInput({ inputType, width, error, height, label, marginTop, mBottom, backgroundColor, ...rest }) {

  function renderInput(type) {
    switch (type) {

    case 'custom':
      return <CurrencyInput
        error={error}
        height={height}
        backgroundColor={backgroundColor}
        {...rest}
      />

    case 'currency':
      return <CurrencyInput
        error={error}
        height={height}
        backgroundColor={backgroundColor}
        thousandsGroupStyle="thousand"
        placeholder='R$ 0,00'
        prefix="R$ "
        decimalSeparator="."
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={false}
        decimalScale={2}
        isNumericString={true}
        allowLeadingZeros={true}
        fixedDecimalScale={true}
        allowEmptyFormatting={false}
        {...rest}
      />

    default:
      return <Input
        backgroundColor={backgroundColor}
        error={error}
        height={height}
        {...rest}
      />
    }
  }

  return (
    <Container width={width} mtop={marginTop} mbottom={mBottom}>
      <Label>
        {label}
      </Label>
      {renderInput(inputType)}
    </Container>
  )
}
