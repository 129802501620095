import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Modal } from '../modal'

import { useModal } from '../../context/ModalContext'
import { useTheme } from '../../context/ThemeContext'
import {
  TableDiv,
  Button,
  MiddleDiv,
  Container,
  TextH2,
  TextH3,
  VariationsTableHead,
  Switch1,
  TableHead,
  BottomDiv,
  TableRow,
  TableVariationInput,
  TableBody,
  TablePriceInput,
  ImageButton,
  TrashIcon,
  ClearButton,
  ImageInput,
  Trash,
  VariationImage,
  SecretPlus
} from './styles'
import ButtonPrimary from '../buttons/buttonPrimary'
import MainInput from '../inputs/mainInput'

const ROWS_LIMIT = 15

export default function ProductVariation({
  width = 'w-47',
  height = 'h-10',
  categories,
  placeholder,
  onValueChange,
  setFieldValue,
  currentValues,
  formikProps,
  ...props
}) {

  const { setModal } = useModal()

  const [samePrice, setSamePrice] = useState(false)

  const [variationName, setVariationName] = useState('')
  const [tableRows, setTableRows] = useState([['', '', '', '']])

  const handleClear = () => { setTableRows([['', '', '', '']]), formikProps.setFieldValue('productVariation', []) }
  const isAddRowButtonDisabled = () => tableRows.length >= ROWS_LIMIT

  const isConfirmButtonDisabled = () => {
    let hasEmptyRow = true
    tableRows.forEach(row => {
      hasEmptyRow = hasEmptyRow && row.every(item => !!item)

    })
    return !hasEmptyRow
  }

  useLayoutEffect(() => {
    if (formikProps.values.productVariation?.length > 0) {
      setTableRows(formikProps.values.productVariation)
      setVariationName(formikProps.values.productVariationName)
    }
  }, [])

  useEffect(() => {
    if (samePrice) {
      for (let index = 0; index < tableRows.length; index++) {
        handleRowChange(formikProps.values.productPrice, index, 1)
      }
    }
  }, [samePrice])

  function handleConfirmClick() {
    formikProps.setFieldValue('productVariation', tableRows)
    formikProps.setFieldValue('productVariationName', variationName)
    onValueChange(tableRows)
    setModal(null)
  }

  function addNewRow() {
    if (isAddRowButtonDisabled()) return
    const newState = [...tableRows, ['', '', '', '']]
    setTableRows(newState)
  }

  function deleteRow(rowIndex) {
    if (tableRows.length === 1) {
      handleClear()
      return
    }
    const newState = [...tableRows]
    newState.splice(rowIndex, 1)
    setTableRows(newState)
  }

  function handleRowChange(cellValue, rowIndex, cellIndex) {
    const newState = tableRows
    newState[rowIndex][cellIndex] = cellValue
    setTableRows([...newState])
  }

  const { currentTheme } = useTheme()

  function handleUpdateImg(value, rowIndex, columnIndex) {
    let file = {}
    if (value.target.files[0]) {
      file = value.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(value.target.files[0])
      reader.onload = () => {
        if (reader.readyState === 2) {
          handleRowChange([file, reader.result], rowIndex, columnIndex)
        }
      }
    }
  }

  return (
    <Modal
      title='Variação do Produto'
      height='80vh'
    >
      <Container>
        <MiddleDiv>
          <TextH2>Nome da variação</TextH2>
          <MainInput
            height='5vh'
            value={variationName}
            onChange={(event) => setVariationName(event.target.value)}
          />
          <Switch1
            checked={samePrice}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor={currentTheme.secondaryLightGreen}
            offColor={currentTheme.graySecondaryText}
            onChange={() => setSamePrice(!samePrice)}
          />
          <TextH3>Mesmo valor</TextH3 >
          <Button
            borderRadius='100px'
            text='Mais variação'
            smallFont='1rem'
            onClick={addNewRow}
          ></Button>
        </MiddleDiv>

        <TableDiv>
          <VariationsTableHead>
            <TableHead>Variação</TableHead>
            <TableHead>Preço</TableHead>
            <TableHead>Quantidade</TableHead>
            <TableHead>Imagem</TableHead>
          </VariationsTableHead>
          <TableBody>
            {tableRows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>

                <TableVariationInput value={tableRows[rowIndex][0]} onChange={(event) => handleRowChange(event.target.value, rowIndex, 0)} />
                <MainInput
                  inputType='currency'
                  value={tableRows[rowIndex][1]}
                  onValueChange={({ value }) => handleRowChange(parseFloat(value), rowIndex, 1)}
                  placeholder='R$ 0,00'
                  disabled={samePrice}
                  height='5vh'
                />

                <TableVariationInput value={tableRows[rowIndex][2]} onChange={(event) => handleRowChange(!event.target.value ? 0 : parseFloat(event.target.value), rowIndex, 2)} />
                <ImageButton for={rowIndex}>
                  {tableRows[rowIndex][3] ?
                    <VariationImage image={tableRows[rowIndex][3][1]}>
                      <SecretPlus />
                    </VariationImage>
                    :
                    <SecretPlus opacity={1} />
                  }
                </ImageButton>
                <ImageInput id={rowIndex} type='file' accept='image/*' onChange={(event) => handleUpdateImg(event, rowIndex, 3)} />
                <TrashIcon onClick={() => deleteRow(rowIndex)}> <Trash /> </TrashIcon>

              </TableRow>
            ))}
          </TableBody>
        </TableDiv>
        <BottomDiv>
          <ClearButton onClick={handleClear}>Limpar</ClearButton>
          <ButtonPrimary
            disabled={isConfirmButtonDisabled()}
            onClick={handleConfirmClick}
            text='Confirmar'
          >
          </ButtonPrimary>
        </BottomDiv>
      </Container>
    </Modal>
  )
}
