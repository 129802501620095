import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'

import { useModal } from '../../../context/ModalContext'

import { theme } from '../../../theme'
import {
  AccessArea,
  Content,
  Image,
  InputArea,
  Subtitle,
  Title,
  AccessInput,
} from './styles'

import ButtonStylizedPrimary from '../../../components/buttons/buttonStylizedPrimary'

import Helpersvg from '../../../assets/Helper.svg'

export default function Access() {
  const history = useHistory()
  const { setModal } = useModal()

  const [password, setPassword] = useState('')


  function handleAccess() {
    const authenticated = password === '123'
    setModal(<CircularProgress style={{ color: theme.palette.primary.main }} />)

    setTimeout(() => {
      if (authenticated) {
        history.push('/financial')
      }

      setModal()
    }, 1500)
  }

  return (
    <Content>
      <AccessArea>
        <Image src={Helpersvg} />
        <InputArea>
          <Title>
            Acesso restrito
          </Title>
          <Subtitle>
            Esse espaço é restrito ao administrador da loja.
          </Subtitle>
          <AccessInput
            placeholder='Senha'
            type='password'
            onChange={e => setPassword(e.target.value)}
            value={password}
          />
          <ButtonStylizedPrimary text='Avançar' onClick={handleAccess} />
        </InputArea>
      </AccessArea>

    </Content>
  )
}
