import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { FiSliders, FiPackage } from 'react-icons/fi'
import {
  Container,
  HeaderContainer,
  HeaderContainerRigth,
  HeaderContent,
  HeaderContentRigth,
  ListContainer,
  ListContent,
  OverflowMask,
  FocusContainer,
  FocusContent,
  ProductsAlert,
  ListLoadingContent
} from './styles'
import FilterModal from './FilterModal'

import { formatShortDate, translatePurchaseStatus } from '../../Utils'
import { useModal } from '../../context/ModalContext'
import { useAuth } from '../../context/AuthContext'
import { useStore } from '../../context/StoreDataContext'
import { getPurchases } from '../../api/purchases'

import Order from '../../components/order'
import Focus from '../../components/focus/Focus'
import Select from '../../components/selects/select'
import MainInput from '../../components/inputs/mainInput'
import LoadingIndicator from '../../components/LoadingIndicator'
import ButtonSecondaryIconLeft from '../../components/buttons/buttonSecondaryIconLeft'

export default function List() {
  const { setModal } = useModal()
  const { push } = useHistory()
  const { user } = useAuth()
  const { newOrderNotification } = useStore()

  const [orders, setOrders] = useState(null)

  const [loading, setLoading] = useState(false)
  const [inFocusItem, setInFocusItem] = useState({
    data: undefined,
    index: undefined,
  })
  const [search, setSearch] = useState('')
  const [select, setSelect] = useState('Mostrar todos')
  const [dateRange, setDateRange] = useState(null)
  const [priceRange, setPriceRange] = useState([0, 1000])

  async function handleFetchOrderHistory() {
    try {
      setLoading(true)
      const { data } = await getPurchases(user.storeData.id, {
        name: search,
        status: select === 'Mostrar todos' ? '' : translatePurchaseStatus(select),
        dateRange: dateRange ? [dateRange.startDate, dateRange.endDate] : [],
        priceRange: priceRange ? [priceRange[0], priceRange[1]] : []
      })
      setOrders(data.length > 0 ? data : null)
    } finally {
      setLoading(false)
    }
  }

  async function handleOpenModalFitler() {
    setModal(<FilterModal setDateRange={setDateRange} setPriceRange={setPriceRange} currentPriceRange={priceRange} currentDate={dateRange} />)
  }

  useEffect(() => {
    handleFetchOrderHistory()
  }, [select, dateRange, priceRange, search, newOrderNotification])

  const ordersList = useMemo(() => orders?.map((item, key) => (
    <Order key={key}
      width='98%'
      height='12vh'
      marginVertical='1vh'
      orderNumber={item.code || '#453ABD'}
      buyerName={item.client.name}
      ammountItems={item.storeOrder[0]?.products?.length}
      dateTime={formatShortDate(item.createdAt)}
      orderStatus={item.status}
      onClick={() => setInFocusItem({
        data: item,
        index: key,
      })}
    />
  )), [orders])

  return (
    <Container>
      <HeaderContainer>
        <HeaderContent>
          <MainInput
            width='53%'
            placeholder='Buscar...'
            onChange={(event) => setSearch(event.target.value)}
          />
          <Select
            title='Mostrar todos'
            width='23%'
            options={['Mostrar todos', translatePurchaseStatus('OPEN'), translatePurchaseStatus('ACCEPTED'), translatePurchaseStatus('SEPARATED'), translatePurchaseStatus('DELIVERING'), translatePurchaseStatus('DELIVERED'), translatePurchaseStatus('CANCELLED'), translatePurchaseStatus('CLOSED')]}
            onValueChange={(value) => setSelect(value)}
          />
          <ButtonSecondaryIconLeft
            icon={<FiSliders size='2rem' />}
            text='Filtros'
            width='20%'
            height='6vh'
            smallFont
            onClick={handleOpenModalFitler}
          />
        </HeaderContent>
      </HeaderContainer>

      <HeaderContainerRigth>
        <HeaderContentRigth>
          <ButtonSecondaryIconLeft
            icon={<FiPackage size='2rem' />}
            text='Gerenciamento'
            width='45%'
            height='6vh'
            smallFont
            onClick={() => push('/management')}
          />
        </HeaderContentRigth>
      </HeaderContainerRigth>

      <ListContainer>
        <OverflowMask>
          <ListContent>
            {loading ?
              <LoadingIndicator />
              :
              orders ?
                ordersList
                :
                <ListLoadingContent>
                  <ProductsAlert>Nenhum pedido encontrado</ProductsAlert>
                </ListLoadingContent>
            }
          </ListContent>
        </OverflowMask>
      </ListContainer>

      <FocusContainer>
        <FocusContent>
          <Focus
            focusItem={inFocusItem}
            width='100%'
            height='87%'
          />
        </FocusContent>
      </FocusContainer>
    </Container>
  )
}
