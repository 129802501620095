import React, { useEffect } from 'react'
import axios from 'axios'
import { Modal } from '../../components/modal'
import {
  FormContainer,
  MiniTitle,
  SmallInputContainer,
  RememberContainer,
  CheckInput,
  Text,
  TermsLink,
  CheckError,
  TermsContainer,
  TermsText,
  TermsHidden,
} from './Styles'

import { TextField } from './formFields/TextField'
import { MaskTextField } from './formFields/MaskTextField'
import { InputFileField } from './formFields/InputFileField'
import { LogoFileField } from './formFields/LogoFileFiled'
import { useModal } from '../../context/ModalContext'
import { ErrorMessage } from 'formik'
import api from '../../api/api'
import { clearMask, returnStateName, TermsOfUse } from '../../Utils'
import CancelModal from '../../components/modals/confirmModal'

export function Step3LegalPerson({ formField, setFieldValue, values }) {
  const {
    cnpj,
    fantasyName,
    socialReason,
    cep,
    neighborhood,
    address,
    city,
    state,
    number,
    logo,
    complement,
  } = formField

  async function handleGetCep() {
    const response = await axios.get(`https://viacep.com.br/ws/${clearMask(values.cep)}/json/`)
    if (response.data.logradouro && response.data.bairro) {
      setFieldValue(address.name, response.data.logradouro)
      setFieldValue(neighborhood.name, response.data.bairro)
      setFieldValue(city.name, response.data.localidade)
      setFieldValue(state.name, returnStateName(response.data.uf))
    }
  }

  async function handleGetCNPJ() {
    const response = await api.get(`validate/cnpj/${clearMask(values.CNPJ)}`)
    if (response.status === 200 && response.data.status === 'OK') {
      if (response.data.fantasia) setFieldValue(fantasyName.name, response.data.fantasia)
      if (response.data.nome) setFieldValue(socialReason.name, response.data.nome)
      if (response.data.cep) setFieldValue(cep.name, response.data.cep.replace('.', ''))
      if (response.data.numero) setFieldValue(number.name, response.data.numero)
      if (response.data.complemento) setFieldValue(complement.name, response.data.complemento)
    }
  }

  useEffect(() => {
    if (values.cep.length === 9 && !values.cep.includes('_')) {
      handleGetCep()
    }
  }, [values.cep])

  useEffect(() => {
    if (values.CNPJ.length === 18 && !values.CNPJ.includes('_')) {
      handleGetCNPJ()
    }
  }, [values.CNPJ])

  return (
    <FormContainer>
      <MiniTitle mtop='1' mbottom='2vh'>
        Informações da loja:
      </MiniTitle>
      <SmallInputContainer>
        <MaskTextField
          placeholder={cnpj.label}
          name={cnpj.name}
          mask='99.999.999/9999-99'
          onChange={(e) => setFieldValue(cnpj.name, e.target.value || '')}
          width='47%'
        />
        <TextField
          width='47%'
          placeholder={fantasyName.label}
          name={fantasyName.name}
          
        />
      </SmallInputContainer>
      <TextField
        width='100%'
        placeholder={socialReason.label}
        name={socialReason.name}
        mtop='3vh'
      />

      <MiniTitle mtop='2vh' mbottom='2vh'>
        Endereço:
      </MiniTitle>
      <SmallInputContainer>
        <MaskTextField
          placeholder={cep.label}
          name={cep.name}
          mask='99999-999'
          onChange={(e) => setFieldValue(cep.name, e.target.value || '')}
          width='32%'
        />
        <TextField
          width='62%'
          placeholder={address.label}
          name={address.name}
        />
      </SmallInputContainer>

      <SmallInputContainer>
        <TextField
          width='32%'
          placeholder={number.label}
          mtop='2vh'
          name={number.name}
        />
        <TextField
          width='62%'
          placeholder={complement.label}
          mtop='2vh'
          name={complement.name}
        />
      </SmallInputContainer>
      <SmallInputContainer>
        <TextField
          width='47%'
          placeholder={city.label}
          mtop='2vh'
          name={city.name}
        />
        <TextField
          width='47%'
          placeholder={state.label}
          mtop='2vh'
          name={state.name}
        />
      </SmallInputContainer>
      <LogoFileField
        onChange={(image) => setFieldValue(logo.name, image)}
        value={values[logo.name]}
        name={logo.name}
      />
    </FormContainer>
  )
}

export function Step4LegalPerson({ formField, setFieldValue, values }) {
  const { setModal } = useModal()
  const { cnpjPhoto, isTermsChecked } = formField

  function setIsTermsChecked() {
    if (!values.isTermsChecked) {
      setFieldValue(isTermsChecked.name, true)
    } else {
      setFieldValue(isTermsChecked.name, '')
    }
  }

  function openTermsModal() {
    setModal(
      <Modal title='Termo de compromisso'>
        <TermsHidden>
          <TermsContainer>
            <TermsText>
              {TermsOfUse}
            </TermsText>
          </TermsContainer>
        </TermsHidden>
      </Modal>
    )
  }
  return (
    <FormContainer>
      {/* <MiniTitle mbottom='2vh' mtop='5vh'>
        Faça upload do seu contrato social
      </MiniTitle>
      <InputFileField
        id='cnpj'
        active={!!values[cnpjPhoto.name]}
        width='47%'
        name={cnpjPhoto.name}
        onChange={(e) => {
          const sizeInMb = 10
          if (e.target.files[0].size > (sizeInMb * 1000 * 1000)) {
            setModal(
              <CancelModal
                text='Opss.. Esse arquivo tem mais de 10mb, por favor enviar um menor.'
                onlyButton
                confirmFunction={() => setModal()}
                cancelFunction='voltar'
              />
            )
            return
          }
          setFieldValue(cnpjPhoto.name, e.target.files[0])
        }}
      /> */}

      <MiniTitle mbottom='1vh' mtop='5vh'>
        Termo de compromisso
      </MiniTitle>
      <RememberContainer mbottom='1vh'>
        <CheckInput type='checkbox' id='termsJuridica' onChange={setIsTermsChecked} checked={values.isTermsChecked} />
        <Text htmlFor='termsJuridica'>
          li e aceito os termos{' '}
          <TermsLink type='button' onClick={openTermsModal}>termos e condições da Gowdock</TermsLink>
        </Text>
      </RememberContainer>
      <ErrorMessage name={isTermsChecked.name} render={(msg) => <CheckError>{msg}</CheckError>} />
    </FormContainer>
  )
}
