import React from 'react'

import { Container } from './styles'

export default function ButtonPrimary({ width, height, text, background, backgroundHover, color, smallFont, mTop, mBottom, ...rest }) {
  return (
    <Container
      width={width}
      height={height}
      background={background}
      backgroundHover={backgroundHover}
      color={color}
      smallFont={smallFont}
      mTop={mTop}
      mBottom={mBottom}
      {...rest}
    >
      {text}
    </Container>
  )
}
