import Detail from "../../pages/products/detail/Detail"
import { Modal } from "../modal"

const DetailsModal = ({item}) => {
  return (
    <Modal>
        <Detail item={item}/>
    </Modal>
  )
}

export default DetailsModal