import React from 'react'
import Header from '../../components/header'
import Navbar from '../../components/navbarStyled'

import { Container, Content } from './styles'

export default function DefaultLayout({ children, info }) {

  return (
    <Container>
      <Navbar />
      <Content>
        <Header subtitle={info.subtitle} title={info.title} />
        {children}
      </Content>
    </Container>
  )
}
