import * as Yup from 'yup'
import registerFormModel from './RegisterFormModel'

const {
  formField: {
    firstName,
    lastName,
    cpf,
    email,
    password,
    confirmPassword,
    numberPhone,
    code,
    cep,
    address,
    neighborhood,
    number,
    cnpj,
    cnpjPhoto,
    fantasyName,
    socialReason,
    logo,
    isTermsChecked
  },
  requiredErrorMsg,
  maskInputMsg,
} = registerFormModel

const validationSchema = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(requiredErrorMsg),
    [lastName.name]: Yup.string().required(requiredErrorMsg),
    [cpf.name]: Yup.string()
      .min(3)
      .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'Digite um CPF válido') // eslint-disable-line
      .required(requiredErrorMsg),
    [email.name]: Yup.string()
      .email(email.notValidError)
      .required(requiredErrorMsg),
    [password.name]: Yup.string()
      .required(requiredErrorMsg)
      .min(8, password.minError)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        'A senha deve conter pelo menos uma letra minúscula uma maiúscula e um número'
      ),
    [confirmPassword.name]: Yup.string()
      .required(requiredErrorMsg)
      .oneOf([Yup.ref(password.name), null], confirmPassword.notEqualError),
  }),
  Yup.object().shape({
    [numberPhone.name]: Yup.string()
      .required(requiredErrorMsg)
      .matches(/^[^_]+$/g, maskInputMsg),
    [code.name]: Yup.string().required(requiredErrorMsg).min(6, code.minError),
  }),
  Yup.object().shape({
    [cnpj.name]: Yup.string()
      .required(requiredErrorMsg)
      .matches(/^[^_]+$/g, maskInputMsg),
    [fantasyName.name]: Yup.string().required(requiredErrorMsg),
    [socialReason.name]: Yup.string().required(requiredErrorMsg),
    [cep.name]: Yup.string()
      .required(requiredErrorMsg)
      .matches(/^[^_]+$/g, maskInputMsg),
    [address.name]: Yup.string().required(requiredErrorMsg),
    [address.name]: Yup.string().required(requiredErrorMsg),
    [number.name]: Yup.string().required(requiredErrorMsg),
    [logo.name]: Yup.string().required(requiredErrorMsg),
  }),
  Yup.object().shape({
    // [cnpjPhoto.name]: Yup.string().required(requiredErrorMsg),
    [isTermsChecked.name]: Yup.string().required('Aceite os termos para finalizar'),
  }),
]

export default validationSchema
