import styled from 'styled-components'

export const Container = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BankContainer = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ValidationContainer = styled.div`
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%;
  padding-right: 15%;
`

export const Title = styled.h4`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${props => props.theme.grayMainText};
  margin-bottom: 5%;
  `

export const Advice = styled.p`
  font-size: 1.2rem;
  color: ${props => props.theme.grayMainText};
  margin-top: ${props => props.mTop || 0};
`

export const NumberPhone = styled.span`
  font-size: 1.6rem;
  color: ${props => props.theme.primaryPink};
  font-weight: 700;
  margin: 3% 0;
`

export const ForgotMessage = styled.span`

`
export const ForgotLink = styled.a`
  font-size: 1.2rem;
  color: ${props => props.theme.secondaryLightGreen};
`