import React from 'react'
import { Container } from './styles'

export default function ButtonStylizedPrimary({ width, height, text, background, backgroundHover, color, smallFont, ...rest }) {
  return (
    <Container 
      width={width}
      height={height} 
      background={background} 
      backgroundHover={backgroundHover}
      color={color}
      smallFont={smallFont}
      {...rest}
    >
      {text}
    </Container>
  )
}
