import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { device } from '../../styles/sizes'

export const Container = styled.div`
  height: 100%;
  width: 15%;
  border-bottom-right-radius: 50px;
  padding: 1vw 2vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.grayBackgrounds};
  justify-content: space-between;

  @media ${device.mobileL} {
    display: none;
  }
`

export const Logo = styled.img`
  height: auto;
  width: 100%;
`

export const LogoContainer = styled(Link)`
  width: 100%;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Nav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8vh;
`

export const PageTitle = styled.h2`
  font-size: 1.2rem;
  color: ${(props) => props.active ? props.theme.primaryGreen : props.theme.grayMainText };
  font-weight: 700;
  margin-left: 1vw;
  transition: 0.3s;
`

export const IconContainer = styled.div`
  height: 4.5vh;
  width: 4.5vh;
  border-radius: 50%;
  background: ${(props) => props.active ? props.theme.primaryGreen : props.theme.white};
  border: 1px solid ${({ theme }) => theme.primaryGreen};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PageSubtitle = styled(Link)`
  font-size: 1rem;
  color: ${(props) => props.active ? props.theme.secondaryLightGreen : props.theme.graySecondaryText};
  font-weight: 600;
  transition: 0.3s;

  :hover {
    color: ${({ theme }) => theme.grayMainText};
  }
`

export const InfoContainer = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.3s;
  margin-bottom: 2vh;
  transition: 0.3s;


  :hover ${PageTitle} {
    color: ${({ theme }) => theme.secondaryLightGreen}
  }
  
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.secondaryLightGreen};
  }
  `

export const HoverContent = styled.div`
  display: none;
  `

export const InfoHoverContainer = styled(Link)`
  width: 100%;
  display: flex;
  transition: 0.3s;
  height: 4.5vh;
  flex-direction: column;
  margin-bottom: 2vh;
  cursor: pointer;

  :hover {
    height: 12vh;
  }
  
  :hover ${HoverContent}{
    padding-top: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 3vw;
    flex: 1;
  }

  :hover ${PageTitle} {
    color: ${({ theme }) => theme.secondaryLightGreen}
  }
`

export const InfoHoverContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.3s;

  :hover ${PageTitle} {
    color: ${({ theme }) => theme.secondaryLightGreen}
  }
  
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.secondaryLightGreen};
  }
`

export const StoreContainer = styled.div`
  width: 100%;
  height:35%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`

export const StoreImage = styled.img`
  height: 12vh;
  width: 12vh;
  border-radius: 50%;
  border: 3px solid ${({ open, theme }) => open ? theme.secondaryLightGreen : theme.primaryPink};
`

export const StoreContent = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`

export const StoreName = styled.h4`
  font-size: 1.3rem;
  font-weight: 700;
  color: #000;
  text-align: center;
`

export const Logout = styled.a`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.primaryPink};
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s;
  
  :hover {
    color: pink;
  }
`

export const StoreStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 15%;
  align-items: center;
`

export const StoreStatus = styled.span`
  font-size: 1.1rem;
  font-weight: 700;
  color: ${({ open, theme }) => open ? theme.secondaryLightGreen : theme.primaryPink};
`

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalContent = styled.div`
  width: 90%;
  height: 80%;
  display: flex;
`

export const ModalFooter = styled.div`
  width: 30vw;
  height: 20%;
  display: flex;
  justify-content: space-between;
`

export const ModalImgContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalImg = styled.img`
  width: 12vw;
  height: auto;
`

export const ModaltextsContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ModalTitle = styled.h2`
  font-weight: bold;
  font-size: 2.5rem;
  color: ${props => props.color ? props.color : props.theme.grayMainText};
  line-height: 2.8rem;
  margin-bottom: 4vh;
  margin-right: 5vw;
`

export const ModalText = styled.span`
  font-weight: 400;
  font-size: 1.4rem;
  color: ${props => props.theme.grayMainText};
  margin-bottom: 4vh;
  `

export const ModalSpan = styled.span`
  color: ${props => props.color};
`

export const FooterContet = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const LogoutText = styled.p`
  font-weight: bold;
  font-size: 2rem;
  color: ${props => props.theme.grayMainText};
`