import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { getFAQ } from '../../../api/store'
import {
  Content,
  HeaderTitle,
  HeaderContent,
  HeaderImage,
  HeaderButtonArea,
  SearchInput,
  SearchContent,
  Footer,
  FooterContent,
  FooterHeader,
  BorderButton,

  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  AccordionTitle,
  AccordionPanel,
  AccordionIcon,
  AccordionDescription,
  AccordionSeeMore,
  ContainerSearch,
  SearchButton,
  MissingAlert,
} from './styles'
import { FiChevronRight } from 'react-icons/fi'
import HeaderImg from '../../../assets/Family_Values_Shopping.svg'

import ButtonSecondary from '../../../components/buttons/buttonSecondary'
import LoadingIndicator from '../../../components/LoadingIndicator'

import AccordionModal from './accordionModal'
import { useModal } from '../../../context/ModalContext'
import { useTheme } from '../../../context/ThemeContext'
import MainInput from '../../../components/inputs/mainInput'
import Chat from '../../../components/chat/Chat'
import ChatButton from '../../../components/chat/ChatButton'
import ChatWindow from '../../../components/chat/ChatWindow'


export default function Doubts() {
  const { setModal } = useModal()
  const { push } = useHistory()
  const { currentTheme } = useTheme()
  const [chatOpen, setChatOpen] = useState(false);

  const [filterSelected, setFilterSelected] = useState('general')
  const [searchKey, setSearchKey] = useState('')
  const [lastOpenedQuestion, setLestOpenedQuestion] = useState(0)

  const [questions, setQuestions] = useState(null)
  const [loading, setLoading] = useState(true)

  const toggleChat = () => {
    setChatOpen(!chatOpen);
  };

  const closeChat = () => {
    setChatOpen(false);
  };


  function handleOpenQuestion(id) {
    if (questions.length > 1 && lastOpenedQuestion !== id) {
      questions[lastOpenedQuestion].active = false
      questions[id].active = true
      setLestOpenedQuestion(id)
    } else {
      questions[id].active = !questions[id].active
    }

    setQuestions([...questions])
  }

  async function FetchQuestions() {
    setQuestions(null)
    setLoading(true)
    const { data } = await getFAQ({
      title: searchKey,
      type: filterSelected
    })
    if (data.length > 0) {
      setQuestions(data)
    }
    setLoading(false)
  }

  
  useEffect(() => {
    if (searchKey.length > 0) {
      setFilterSelected('general')
    }
    FetchQuestions()
  }, [filterSelected, searchKey])

  return (
    <Content>
      <HeaderContent>
        <HeaderImage src={HeaderImg} />
        <HeaderButtonArea>
          <ButtonSecondary
            text='Fale conosco'
            width='60%'
            height='95%'
            onClick={() => push('/contact-us')}
          />
           <>
      <ChatButton onClick={toggleChat} />
      <ChatWindow isOpen={chatOpen} onClose={closeChat}>
        {/* Janela do componente vai aqui ó */}
      </ChatWindow>
    </>
        </HeaderButtonArea>
        <HeaderTitle>Como podemos ajudar?</HeaderTitle>
        <ContainerSearch>
          <SearchContent>
            <SearchInput
              placeholder='Sua dúvida'
              onChange={(event) => setSearchKey(event.target.value)}
            />
            <SearchButton>
              Buscar
              <FiChevronRight size='2rem' />
            </SearchButton>
          </SearchContent>
        </ContainerSearch>
      </HeaderContent>
      <Footer>
        <FooterHeader>
          <BorderButton active={filterSelected === 'general'} onClick={() => setFilterSelected('general')}>Geral</BorderButton>
          <BorderButton active={filterSelected === 'order'} onClick={() => setFilterSelected('order')}>Pedidos</BorderButton>
          <BorderButton active={filterSelected === 'delivery'} onClick={() => setFilterSelected('delivery')}>Entregas</BorderButton>
          <BorderButton active={filterSelected === 'store'} onClick={() => setFilterSelected('store')}>Sua Loja</BorderButton>
          <BorderButton active={filterSelected === 'account'} onClick={() => setFilterSelected('account')}>Conta</BorderButton>
          <BorderButton active={filterSelected === 'financial'} onClick={() => setFilterSelected('financial')}>Balanço</BorderButton>
        </FooterHeader>

        <FooterContent>
          {loading ?
            <LoadingIndicator />
            :
            <>
              {questions ?
                questions.map((item, key) => (
                  <AccordionContainer key={item._id} first={key === 0} last={key === questions.length - 1} onClick={() => handleOpenQuestion(key)}>
                    <AccordionHeader>
                      <AccordionTitle>{item.title}</AccordionTitle>
                      <AccordionIcon size='2.5rem' active={item?.active} alt='' />
                    </AccordionHeader>
                    <AccordionContent>
                      <AccordionPanel active={item?.active}>
                        <AccordionDescription>{item.content}</AccordionDescription>
                      </AccordionPanel>
                    </AccordionContent>
                    <AccordionSeeMore active={item?.active} onClick={() => setModal(<AccordionModal item={item} closeModal={() => setModal(null)} />)}>ver mais</AccordionSeeMore>
                  </AccordionContainer>
                ))
                :
                <Content>
                  <MissingAlert>Não foi possível carregar este FAQ</MissingAlert>
                </Content>
              }
            </>
          }
        </FooterContent>
      </Footer>
    </Content>
  )
}