import styled from 'styled-components'

export const RedefineInfo = styled.p`
  font-size: 1rem;
  color: #666666;
  font-weight: 500;
`

export const RedefineList = styled.ul`
  margin-bottom: 1.5rem;
`

export const RedefineItem = styled.li`
  list-style: none;
  font-size: 1rem;
  margin: 0.5rem 2rem;
  color: #666666;
  font-weight: 500;
`

export const SuccessText = styled.p`
  font-size: 1.5rem;
  color: green;
  font-weight: 700;
  margin: 1rem 0;
`

export const Message = styled.p`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`
