import React from 'react'

import { FiStar } from 'react-icons/fi'
import {
  ImageContainer,
  Image,
  ItemContainer,
  ItemName,
  ItemInfoContainer,
  ItemsSold,
  ItemRating,
  ItemStock,
  ItemPrice,
  ItemLogo
} from './styles'
import MoneyTextField from '../../../components/moneyTextField'
import { useTheme } from '../../../context/ThemeContext'

export default function Item({
  image,
  name,
  description,
  variations,
  price,
  quantity,
  ammountReview,
  onClick,
  active,
  evaluation,
  isMobile
}) {
  const { currentTheme } = useTheme()

  return (
    <ItemContainer onClick={onClick}>
      <ImageContainer active={active} >
        <Image src={image} alt={description} />
      </ImageContainer>
      <ItemName>{name.charAt(0).toUpperCase() + name.slice(1)}</ItemName>
      <ItemInfoContainer>
        <ItemStock>
          {`Unidades: ${quantity}`}
        </ItemStock>
      </ItemInfoContainer>
      <ItemInfoContainer>
        <div className='divider'>
          <ItemPrice>
            {variations?.length > 0 ?
              (<><MoneyTextField value={price[0]} /> - <MoneyTextField value={price[1]} /></>)
              :
              <MoneyTextField value={price[0]} />
            }
          </ItemPrice>
        </div>
      </ItemInfoContainer>
    </ItemContainer>
  )
}
