import React from 'react'

import './styles.css'

import InfoIcon from '../../assets/info.svg'

const tooltip = ({ text }) => (
  <div className='tooltip-container'>
    <img src={InfoIcon} className='ml-2' alt='' />
    <div className='tooltip-hover group-hover:flex'>
      <span className='tooltip'>{text}</span>
      <div className='arrow'></div>
    </div>
  </div>
)

export default tooltip
