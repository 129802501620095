import React from 'react'
import Divisor from '../../../../../components/divisor'
import AccountInfo from '../accountInfo'

import {
  Container,
  AccountDataContainer,
  BankContainer,
  Text,
  Title,
  Row
} from './styles'

export default function Step2({ values }) {

  return (
    <Container>
      <BankContainer>
        <AccountInfo
          agency={values.agency}
          accountType={values.accountType}
          account={values.accountDigit ? `${values.account}-${values.accountDigit}` : values.account}
          bank={values.bank}
        />
      </BankContainer>
      <Divisor />
      <AccountDataContainer>
        <Title>Confirme os dados da conta bancária</Title>
        <Row>
          <Text bold>Nome:</Text>
          <Text>{values.name}</Text>
        </Row>
        <Row>
          <Text bold>CPF/CNPJ:</Text>
          <Text>{values.document}</Text>
        </Row>
        <Row>
          <Text bold>Banco:</Text>
          <Text>{values.bank}</Text>
        </Row>
        <Row>
          <Text bold>Agência:</Text>
          <Text>{values.agency}</Text>
        </Row>
        <Row>
          <Text bold>Conta:</Text>
          <Text>{values.accountDigit ? `${values.account}-${values.accountDigit}` : values.account}</Text>
        </Row>
      </AccountDataContainer>
    </Container>
  )
}
