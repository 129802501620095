import React, { useEffect, useState } from 'react'
import { useTheme } from '../../../../context/ThemeContext'
import {
  Container,
  ChartContainer,
  CircleLegend,
  LegendDiv,
  ChartLegend,
  ChartLegendContainer,
  FirstContent,
  IconContainer,
  TextContainer,
  BalanceText,
  BalanceValue,
  SecondContent,
  ValueContainer,
  ChartValue,
} from './styles'

import CircleChart from '../circleChart'
import { ReactComponent as BalanceIcon } from '../../../../assets/balanceIcon.svg'

import { useAuth } from '../../../../context/AuthContext'
import { convertCurrencyToBRL } from '../../../../Utils'
import { fetchWalletBalance } from '../../../../api/store'

export default function InfoCircleChart() {

  const { user, handleUpdateUserField } = useAuth()
  const { currentTheme } = useTheme()

  const [updatedWallet, setUpdatedWallet] = useState(null)

  async function handleUpdateWallet() {
    const response = await fetchWalletBalance(user.storeData?.id)
    if (response.status === 200 && response.data) {
      setUpdatedWallet(response.data)
      if (Object.is(response.data, user.storeData?.wallet)) {
        handleUpdateUserField({ wallet: response.data })
      }
    }
  }

  useEffect(() => {
    handleUpdateWallet()
  }, [])

  return (
    <Container>
      <FirstContent>
        <IconContainer>
          <BalanceIcon width='1.5rem' height='auto' />
        </IconContainer>
        <TextContainer>
          <BalanceText>Balanço</BalanceText>
          <BalanceValue>{convertCurrencyToBRL(updatedWallet?.value || 0)}</BalanceValue>
        </TextContainer>
      </FirstContent>

      <SecondContent>
        <ChartContainer>
          <CircleChart
            entry={user.storeData?.wallet.volumeThisMonth}
            tax={user.storeData?.wallet.taxesPaidThisMonth}
            available={user.storeData?.wallet?.value}
          />
        </ChartContainer>
        <LegendDiv>

          <ChartLegendContainer>
            <CircleLegend color={currentTheme.secondaryYellow} />
            <ValueContainer>
              <ChartLegend>
                Entrada
              </ChartLegend>
              <ChartValue>
                {convertCurrencyToBRL(updatedWallet?.volumeThisMonth || 0)}
              </ChartValue>
            </ValueContainer>
          </ChartLegendContainer>

          <ChartLegendContainer>
            <CircleLegend color={currentTheme.primaryPink} />
            <ValueContainer>
              <ChartLegend>
                Taxa
              </ChartLegend>
              <ChartValue>
                {convertCurrencyToBRL(updatedWallet?.taxesPaidThisMonth || 0)}
              </ChartValue>
            </ValueContainer>
          </ChartLegendContainer>

          <ChartLegendContainer>
            <CircleLegend color={currentTheme.secondaryLightGreen} />
            <ValueContainer>
              <ChartLegend>
                Saldo disponível
              </ChartLegend>
              <ChartValue>
                {convertCurrencyToBRL(updatedWallet?.valueAvailableForWithdraw || 0)}
              </ChartValue>
            </ValueContainer>
          </ChartLegendContainer>

        </LegendDiv>
      </SecondContent>
    </Container>
  )
}
