import React from 'react'

import {
  BalanceArea,
  Content,
  RevenueArea,
  SalesArea,
} from './styles'

import { ModalWithdrawBalance } from './modalWithdrawBalance'

export default function Balance() {

  return (
    <Content>
      <ModalWithdrawBalance />
    </Content>
  )
}
