import React, { useEffect, useState } from 'react'
import {
  SideContent,
  Divider,
  ChartContainer,
  InfoContainer,
  InfoContent,
  InputContainer,
  AddCashContainer,
  SelectWithdrawalContainer,
  BankIcon,
  ErrorMsg
} from './styles'

import {
  SubTitle,
  Description,
} from '../../modalWithdrawBalance/styles'

import { FiClock } from 'react-icons/fi'

import ButtonSecondaryIconLeft from '../../../../../components/buttons/buttonSecondaryIconLeft'
import ButtonSecondary from '../../../../../components/buttons/buttonSecondary'
import ButtonPrimary from '../../../../../components/buttons/buttonPrimary'
import InfoCircleChart from '../../infoCircleChart'
import MainInput from '../../../../../components/inputs/mainInput'

export default function FirstStep(props) {

  const [nextBtn, setNextBtn] = useState(false)

  useEffect(() => {
    if (props.formProps.values) {
      const currentValue = props.formProps.values.withdrawalQuantity
      const maxValue = props.formProps.values.maxWithdrawalQuantity

      if (currentValue > maxValue) {
        setNextBtn(true)
      } else if (currentValue <= maxValue && nextBtn) {
        setNextBtn(false)
      }
    }
  }, [props.formProps.values.withdrawalQuantity])

  return (
    <>
      <SideContent>
        <ChartContainer>
          <InfoCircleChart />
        </ChartContainer>
        <ButtonSecondaryIconLeft
          text='Histórico de retiradas'
          icon={<FiClock size='2rem' />}
          width='70%'
          smallFont
        />
      </SideContent>
      <Divider />
      <SideContent>
        <InfoContainer>
          <InfoContent>
            <SubTitle>Sacar valor</SubTitle>
            <InputContainer>
              <MainInput
                label=''
                inputType='currency'
                onValueChange={({ value }) => value != '' ? props.formProps.setFieldValue('withdrawalQuantity', value) : props.formProps.setFieldValue('withdrawalQuantity', '0')}
                value={props.formProps.values.withdrawalQuantity}
              />
            </InputContainer>
            <AddCashContainer>
              <ButtonSecondary
                type='button'
                onClick={() => props.formProps.setFieldValue('withdrawalQuantity', props.increaseValue(props.formProps.values.withdrawalQuantity, 50))}
                text='R$ 50'
                height='100%'
                width='30%'
                smallFont
              />
              <ButtonSecondary
                type='button'
                onClick={() => props.formProps.setFieldValue('withdrawalQuantity', props.increaseValue(props.formProps.values.withdrawalQuantity, 100))}
                text='R$ 100'
                height='100%'
                width='30%'
                smallFont
              />
              <ButtonSecondary
                type='button'
                onClick={() => props.formProps.setFieldValue('withdrawalQuantity', props.increaseValue(props.formProps.values.withdrawalQuantity, 200))}
                text='R$ 200'
                height='100%'
                width='30%'
                smallFont
              />
            </AddCashContainer>
            <ButtonPrimary
              type='button'
              text='Tudo'
              width='100%'
              height='20%'
              smallFont
              onClick={() => props.formProps.setFieldValue('withdrawalQuantity', props.formProps.values.maxWithdrawalQuantity)}
            />
          </InfoContent>

          <InfoContent>
            <SubTitle>Selecione o método</SubTitle>
            <button className='boxed' type='button' onClick={() => props.formProps.setFieldValue('checkoutMethod', 'transfer')}>
              <SelectWithdrawalContainer active={props.formProps.values.checkoutMethod}>
                <BankIcon />
                <SubTitle>Transferência bancária</SubTitle>
                <Description>Cairá em: 3 - 5 dias</Description>
              </SelectWithdrawalContainer>
            </button>
          </InfoContent>
          {props.formProps.errors.checkoutMethod && <ErrorMsg>{props.formProps.errors.checkoutMethod}</ErrorMsg>}
        </InfoContainer>
        <ButtonPrimary
          type='submit'
          disabled={nextBtn || parseFloat(props.formProps.values.withdrawalQuantity) <= 0 || props.formProps.values.withdrawalQuantity === ''}
          text='Avançar'
          width='90%'
          smallFont
          onClick={props.formProps.handleSubmit}
        />
      </SideContent>
    </>
  )
}
