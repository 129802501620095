import styled, { css } from 'styled-components'


export const ImageProfileContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10%;
`

export const ImageProfileButton = styled.div`
  display: flex;
  flex-direction: column;

`

export const Image = styled.img`
  width: 15vh;
  height: 15vh;
  border-radius: 50%;
`

export const ImageContainer = styled.div`
  width: 15vh;
  height: 15vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImageProfileText = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  padding: 3%;
`

export const HiddenInput = styled.input`
  display: none;
`

export const Button = styled.label`
  width: 12vw; 
  height: 5vh;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${(props) => props.theme.primaryGreen};
  transition: 0.3s;
  color: ${(props) => props.theme.primaryGreen};
  font-weight: 600;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  
  :hover {
    border: 1px solid ${(props) => props.theme.secondaryLightGreen};
    color: ${(props) => props.theme.secondaryLightGreen};
  }

  ${props => props.disabled && css`
  cursor: default;
  border: 1px solid ${props.theme.grayInactivy};
  color: ${props.theme.grayInactivy};
  
  :hover {
    border: 1px solid ${props.theme.grayInactivy};
    color: ${props.theme.grayInactivy};
  }
  `}
`