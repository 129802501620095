import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FiHeadphones } from 'react-icons/fi'

import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

import Logofield from './logofield'

import {
  Container,
  StaticDetails,
  UserDetails,
  ButtonsContainer,
  StaticText,
  UserInfo,
  SuportAdvice,
  DateText,
  ErrorMsg,
  InfoContainer,
} from './styles'

import { useModal } from '../../../context/ModalContext'
import { useAuth } from '../../../context/AuthContext'
import { updateStore } from '../../../api/store'
import CancelModal from '../../../components/modals/cancelModal'
import SaveModal from '../../../components/modals/confirmModal'
import ErrorModal from '../../../components/modals/errorModal'
import ButtonSecondary from '../../../components/buttons/buttonSecondary'
import ButtonPrimary from '../../../components/buttons/buttonPrimary'
import ButtonSecondaryIconLeft from '../../../components/buttons/buttonSecondaryIconLeft'
import { FormatMaskCep, FormatMaskCnpj, FormatMaskNumber } from '../../../Utils'

export default function Account({ Logo, setLogo }) {
  const { setModal } = useModal()
  const { user, setUser } = useAuth()
  const { push } = useHistory()

  const [isEditable, setIsEditable] = useState(false)
  const [loading, setLoading] = useState(false)

  const FORMATED_DATE = format(
    new Date(user.storeData.createdAt),
    '\'Essa conta foi crianda em\' dd \'de\' MMMM \'de\' yyyy \'às\' HH:mm\'h\'', { locale: ptBR, }
  )

  async function handleUploadImage(image) {
    try {
      setLoading(true)
      setModal(false)
      const response = await updateStore(image, user.storeData.userId, setUser)

      const newUser = user
      newUser.storeData.image = response.user.image

      setUser(newUser)
      localStorage.setItem('g-admin/userData', JSON.stringify(newUser))
      setIsEditable(!isEditable)
    } catch (error) {
      setModal(<ErrorModal closeModal={setModal} />)
    } finally {
      setLoading(false)
      window.location.reload(false)
    }
  }

  const handleSave = (image) => {
    setModal(
      <SaveModal
        cancelFunction={() => setModal(false)}
        confirmFunction={() => handleUploadImage(image)}
      />
    )
  }

  const handleCancel = (reset, initialImage) => {
    setModal(
      <CancelModal
        confirmFunction={() => {
          setIsEditable(!isEditable)
          reset()
          setModal(false)
          setLogo(initialImage)
        }}
        cancelFunction={() => setModal(false)}
      />
    )
  }

  function handleIsBodyEqual(initialValue, currentValue, reset) {
    if (initialValue === currentValue) {
      setIsEditable(!isEditable)
    } else {
      handleCancel(reset, initialValue['image'])
    }
  }

  function handleIsSaved(initialValue, currentValue) {
    return initialValue === currentValue
  }

  return (
    <Container>
      <UserDetails>
        <Formik
          initialValues={{
            image: user.storeData.image,
          }}
          validationSchema={
            Yup.object({
              image: Yup.string().required(),
            })
          }
          onSubmit={values => handleSave(values.image)}
        >
          {formik => (
            <Form>
              <fieldset disabled={!isEditable}>
                <Logofield
                  Logo={Logo}
                  setLogo={setLogo}
                  disabled={!isEditable}
                  setFieldValue={formik.setFieldValue}
                  value={formik.values['image']}
                  name='image'
                  loading={loading}
                />
                <ErrorMessage
                  name='image'
                  render={(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                />
              </fieldset>


              <InfoContainer>
                <StaticText>E-mail</StaticText>
                <UserInfo>{user.storeData.email}</UserInfo>
                <StaticText>Telefone</StaticText>
                <UserInfo>{ FormatMaskNumber(user.storeData.phoneNumber)}</UserInfo>
              </InfoContainer>

              <ButtonsContainer mTop='30%'>
                {isEditable ? (
                  <>
                    <ButtonSecondary text='Cancelar' width='47%' height='6vh' type='button' onClick={() => handleIsBodyEqual(formik.initialValues, formik.values, formik.resetForm)} />
                    <ButtonPrimary text='Salvar' width='47%' height='6vh' type='submit' disabled={handleIsSaved(formik.initialValues, formik.values)} />
                  </>
                ) : (
                  <ButtonSecondary type='button' text='Editar' width='47%' height='6vh' onClick={() => setIsEditable(!isEditable)} />
                )}
              </ButtonsContainer>

              <DateText>
                {FORMATED_DATE}
              </DateText>
            </Form>
          )}
        </Formik>
      </UserDetails>

      <StaticDetails>
        <StaticText>CNPJ/CPF</StaticText>
        <UserInfo>{  FormatMaskCnpj (user.storeData.document)}</UserInfo>
        <StaticText>Nome fantasia</StaticText>
        <UserInfo>{user.storeData.fantasyName}</UserInfo>
        <StaticText>Nome</StaticText>
        <UserInfo>{user.storeData.socialReason}</UserInfo>
        <StaticText>CEP</StaticText>
        <UserInfo>{FormatMaskCep(user.storeData.cep)}</UserInfo>
        <StaticText>Endereço</StaticText>
        <UserInfo>{user.storeData.street}</UserInfo>
        <StaticText>Número</StaticText>
        <UserInfo>{user.storeData.number}</UserInfo>
        <StaticText>Complemento</StaticText>
        <UserInfo>{user.storeData.complement || 'Nenhum complemento adicionado'}</UserInfo>

        <ButtonsContainer mTop='8%'>
          <ButtonSecondaryIconLeft text='Suporte' icon={<FiHeadphones />} width='47%' onClick={() => push('/doubt')} />
        </ButtonsContainer>

        <SuportAdvice>
          Para alterar os dados da loja, entre em contato com o suporte da Gowdock
        </SuportAdvice>
      </StaticDetails>
    </Container>
  )
}
