import FormModel from "./FormModel";

const {
    formField: {
        danfeCte,
        nrDoc,
        serie,
        valor,
        cfop,
        /* tpDocument */
    }
} = FormModel

export default { 
    [danfeCte.name]: '',
    [nrDoc.name]: '',
    [serie.name]: '',
    [valor.name]: '',
    [cfop.name]: '',
    /* [tpDocument.name]: '', */
}