import React from 'react'
import Switch from 'react-switch'

import { useTheme } from '../../../context/ThemeContext'
import {
  Row,
  Container,
  MiniSection,
  Section,
  SectionTitle,
  Subtitle,
  SwitchArea,
  Text,
  TextSection,
  NotificationTypeText,
  TypeContainer
} from './styles'

export default function Preferences({
  messagePush, setPushMessage,
  messageEmail, setMessageEmail,
  orderPush, setOrderPush,
  orderEmail, setOrderEmail,
  completedOrderPush, setCompletedOrderPush,
  completedOrderEmail, setCompletedOrderEmail,
  foundDeliveryPush, setFoundDeliveryPush,
  foundDeliveryEmail, setFoundDeliveryEmail,
  addedProductPush, setAddedProductPush,
  addedProductEmail, setAddedProductEmail,
  isDark, setIsDark,
}) {
  const { currentTheme } = useTheme()

  return (
    <Container>
      <SectionTitle>Notificações</SectionTitle>
      <Section>

        <TypeContainer>
          <SwitchArea>
            <NotificationTypeText>Sistema</NotificationTypeText>
            <NotificationTypeText>Email</NotificationTypeText>
          </SwitchArea>
        </TypeContainer>
        <Row mBottom={'4%'}>
          <Text>Notificações de Mensagens</Text>
          <SwitchArea>
            <Switch
              checked={messagePush}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor={currentTheme.secondaryLightGreen}
              offColor={currentTheme.primaryPink}
              onChange={() => setPushMessage(!messagePush)}
            />
            <Switch
              checked={messageEmail}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor={currentTheme.secondaryLightGreen}
              offColor={currentTheme.primaryPink}
              onChange={() => setMessageEmail(!messageEmail)}
            />
          </SwitchArea>
        </Row>

        <MiniSection>
          <Row mBottom='2%'>
            <Text>Notificações de gerenciamento</Text>
          </Row>

          <Row mBottom='2%'>
            <TextSection>
              <Subtitle>Entrada de pedidos</Subtitle>
            </TextSection>
            <SwitchArea>
              <Switch
                checked={orderPush}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={currentTheme.secondaryLightGreen}
                offColor={currentTheme.primaryPink}
                onChange={() => setOrderPush(!orderPush)}
              />
              <Switch
                checked={orderEmail}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={currentTheme.secondaryLightGreen}
                offColor={currentTheme.primaryPink}
                onChange={() => setOrderEmail(!orderEmail)}
              />
            </SwitchArea>
          </Row>

          <Row mBottom='2%'>
            <TextSection>
              <Subtitle>Pedidos concluídos</Subtitle>
            </TextSection>
            <SwitchArea>
              <Switch
                checked={completedOrderPush}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={currentTheme.secondaryLightGreen}
                offColor={currentTheme.primaryPink}
                onChange={() => setCompletedOrderPush(!completedOrderPush)}
              />
              <Switch
                checked={completedOrderEmail}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={currentTheme.secondaryLightGreen}
                offColor={currentTheme.primaryPink}
                onChange={() => setCompletedOrderEmail(!completedOrderEmail)}
              />
            </SwitchArea>
          </Row>

          <Row>
            <TextSection>
              <Subtitle>Entregador encontrado</Subtitle>
            </TextSection>
            <SwitchArea>
              <Switch
                checked={foundDeliveryPush}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={currentTheme.secondaryLightGreen}
                offColor={currentTheme.primaryPink}
                onChange={() => setFoundDeliveryPush(!foundDeliveryPush)}
              />
              <Switch
                checked={foundDeliveryEmail}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={currentTheme.secondaryLightGreen}
                offColor={currentTheme.primaryPink}
                onChange={() => setFoundDeliveryEmail(!foundDeliveryEmail)}
              />
            </SwitchArea>
          </Row>

        </MiniSection>

        <Row>
          <Text>Motificações de produto adicionado</Text>
          <SwitchArea>
            <Switch
              checked={addedProductPush}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor={currentTheme.secondaryLightGreen}
              offColor={currentTheme.primaryPink}
              onChange={() => setAddedProductPush(!addedProductPush)}
            />
            <Switch
              checked={addedProductEmail}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor={currentTheme.secondaryLightGreen}
              offColor={currentTheme.primaryPink}
              onChange={() => setAddedProductEmail(!addedProductEmail)}
            />
          </SwitchArea>
        </Row>

      </Section>

      <SectionTitle>Tema</SectionTitle>
      <Section>
        <Row>
          <Text>Modo escuro</Text>

          <SwitchArea>
            <Switch
              checked={isDark}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor={currentTheme.secondaryLightGreen}
              offColor={currentTheme.primaryPink}
              onChange={() => setIsDark(!isDark)}
            />
          </SwitchArea>
        </Row>
      </Section>
    </Container>
  )
}
