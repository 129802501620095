import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `

export const ProductImgArea = styled.img`
  width: 70%;
  height: 50%;
  border-radius: 6px;
  object-fit: contain;
`

export const DescriptionArea = styled.div`
  display: flex;
  width: 70%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const Title = styled.h1`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${({ theme }) => theme.grayMainText};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const AmmountTitle = styled.h2`
  font-weight: 700;
  font-size: 1rem;
  color: ${({ theme }) => theme.primaryPink};
`
