import React from 'react'
import {
  TD,
  ColumnContainer,
  ProductContainer,
  MiniTitle,
  ProductName,
  ImageContainer,
  ProductImage,
} from './ModalItemsStyles'
import imageTest from '../../../assets/imageTest.png'
import { formatMoneyBRL } from '../../../Utils'

export function Item({ item }) {
  
  return (
    <>
      <tr style={{ textAlign: 'center' }}>
        <TD width='10%'>
          <ImageContainer>
            <ProductImage src={item.image || imageTest} resizeMode='cover' />
          </ImageContainer>
        </TD>
        <TD width='15%'>
          <ProductContainer>
            <MiniTitle>{item.code}</MiniTitle>
            <ProductName>{item.name}</ProductName>
          </ProductContainer>
        </TD>
        <TD width='10%' left={true}>
          {item.quantity} itens
        </TD>
        <TD width='15%'>
          <ColumnContainer>
            <MiniTitle>{item.type}</MiniTitle>
            {item.variation}
          </ColumnContainer>
        </TD>
        <TD width='15%'>R$ {formatMoneyBRL(item.quantity * item.unitaryValue)}</TD>
      </tr>
    </>
  )
}
