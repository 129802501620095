import styled from 'styled-components'

export const Container = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`

export const DoubleInputArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const SideBySide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 47%;
  
  .separator {
    padding: 0 10px;
    font-size: 2rem;
  }
`
