import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2%;
  width: 73%;
  height: 100%;
  flex-direction: column; 

  @media ${device.mobileL} {
    width: 100%;
    padding-left: 0;
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  height: 20%;

`

export const Content = styled.div`
  display: flex;
  height: 70%;
  width: 100%;
  
  justify-content: center;

  @media ${device.mobileL} {
    width: 100%;
    margin-top: 20px;
  }
`

export const SearchDiv = styled.div`
  display: flex; 
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  height: 40%;
  width: 100%;
  margin-top: 5px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`

export const SearchContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  min-width: 50%;
  height: 80%;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.graySecondaryText};
  padding: 8px;

  @media ${device.mobileL} {
    min-width: 100%;
    height: 40px;
  }
`

export const SearchInput = styled.input`
  width: 100%;
  font-size: 1.5rem;
  background-color: transparent;
`

export const ItemList = styled.div`
  height: 70vh;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 2.5%;
  overflow-y: auto;
  padding: 0 5px;

  ::-webkit-scrollbar {
    display: none;
    width: 5px;
  
  }

  @media ${device.mobileL} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

export const ProductsAlert = styled.span`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme }) => theme.graySecondaryText};

  align-self: center;
`

export const DropdownGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0px;
`;

export const ButtonGroup = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;