import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { CircularProgress } from '@material-ui/core'

import { theme } from '../../theme'
import {
  Container,
  Content,
  Header,
  Sidebar,
  Step
} from '../../styles/layout/Layout'
import {
  ContentContainer,
  Button,
  FormContainer,
  Title,
  Subtitle,
} from '../login/Styles'
import {
  RedefineInfo,
  RedefineList,
  RedefineItem,
  Message,
  SuccessText
} from './Styles'

import LoginHeader from '../../components/loginHeader/LoginHeader'
import LoginRegisterSidebar from '../../components/loginRegisterSidebar/Index'
import { getErrorStyle } from '../../Utils'
import { Textfield } from '../login/Textfield'
import { useModal } from '../../context/ModalContext'
import api from '../../api/api'

export default function RedefinePassword() {
  const [isSubmited, setIsSubmited] = useState(false)

  const history = useHistory()
  const { setModal } = useModal()
  const { jwt } = useParams()

  async function handleSubmit(values) {
    const obj = { 'password': values.password }
    await api.post(`reset/${jwt}`, obj)

    if (isSubmited === false) {
      setModal(<CircularProgress style={{ color: theme.palette.primary.main }} />)
      setTimeout(() => {
        setIsSubmited(true)
        setModal()
      }, 1500)
    } else {
      history.push('/login')
    }
  }

  return (
    <Container>
      <Sidebar>
        <LoginRegisterSidebar />
      </Sidebar>

      <Header>
        <LoginHeader />
      </Header>

      <Content>
        <ContentContainer>
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validationSchema={
              Yup.object({
                password: Yup.string().required('Esta campo é obrigatório').min(6, 'Sua senha deve ter no mínimo 6 caracteres'),
                confirmPassword: Yup.string().required('Esta campo é obrigatório').oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
              })
            }
            onSubmit={handleSubmit}
          >
            {formikProps => (
              <Form>
                <FormContainer>
                  <Title>
                    Redefinição de senha
                  </Title>
                  <Subtitle></Subtitle>
                  {isSubmited ? (
                    <>
                      <SuccessText>
                        Você redefiniu sua senha!
                      </SuccessText>
                      <Message>
                        Faça o login com sua nova senha.
                      </Message>
                      <Button>
                        Fazer login
                      </Button>
                    </>
                  ) : (
                    <>
                      <Textfield
                        placeholder='Nova senha'
                        name='password'
                        type='password'
                        borderColor={!!getErrorStyle('password', formikProps.errors, formikProps.touched)}
                      // borderColor={!!getErrorStyle('login', formikProps.errors, formikProps.touched) || !!errMessage}
                      // mock da mensagem do backend
                      />
                      <Textfield
                        placeholder='Confirme a nova senha'
                        name='confirmPassword'
                        type='password'
                        borderColor={!!getErrorStyle('confirmPassword', formikProps.errors, formikProps.touched)}
                      // borderColor={!!getErrorStyle('login', formikProps.errors, formikProps.touched) || !!errMessage}
                      // mock da mensagem do backend
                      />
                      <RedefineInfo>
                        Sua senha deve possuir:
                      </RedefineInfo>
                      <RedefineList>
                        <RedefineItem>
                          - No mínimo 8 caracteres.
                        </RedefineItem>
                        <RedefineItem>
                          - Pelo menos uma letra maiúscula.
                        </RedefineItem>
                        <RedefineItem>
                          - Um caracter especial ou um número.
                        </RedefineItem>
                      </RedefineList>
                      <Button>
                        Confirmar
                      </Button>
                    </>
                  )}
                </FormContainer>
              </Form>
            )}
          </Formik>
        </ContentContainer>
      </Content>

      <Step></Step>
    </Container>
  )
}
