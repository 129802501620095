import styled from 'styled-components'
import { Link } from 'react-router-dom'
import colors from '../../styles/colors'
import { device } from '../../styles/sizes'

export const HeaderContent = styled.div`
  width:100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    width: 80vw;
    display: flex;
    justify-content: flex-start;
  }
`

export const RegisterText = styled.p`
  margin: 1rem;
  font-size: 1rem;
  font-weight: 600;
  `

export const RegisterLink = styled(Link)`
  color: ${colors.span_green};
  transition: 0.3s;
  font-size: 1rem;
  &:hover {
    color: ${colors.secondary_green}
  }
`
