import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%;
  overflow-y: auto;
  overflow-x: hidden;
`

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 8% 0;
`

export const FooterContainer = styled.div`
  width: 100%;
  height: 14vh;
  padding: 0 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
