import styled from 'styled-components'

export const SummaryContainer = styled.div`
  display:flex;
  width:100%;
  flex-direction:column;
  padding:5px 5%;
`
export const Container = styled.div`
  height:76vh;
  width:457px;
  position: relative;
  input:focus, button:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }
`
export const SummaryButton = styled.div`
  display:flex;
  width:100%;
  height:32px;
  margin: 10px;
  font-family:'Nunito';
  border-radius:4px;
  background-color:#96C444;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  justify-content:center;
  align-items:center;
  font-size:18px;
`
export const Exit = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width:50px;
  height:50px;
  position: absolute;
  border:2px solid #96C444;
  background-color:#fff;
  border-radius:50px;
  top:-20px;
  right:-20px;
`
export const Line = styled.div`
  display:flex;
  align-items:center;
  margin: 4px 0;
`
export const Text = styled.p`
  color:${props=>props.color};
  font-size:${props=>props.fontSize};
`
export const LineDecorator = styled.div`
  display:flex;
  flex:1;
  height:2px;
  background-color:#bbb;
  margin:0 10px;
`
export const List = styled.div`
  display:flex;
  justify-content:center;
  flex-direction:column;
  padding:0 10%;
  height:70%;
`
export const Time = styled.span`
  position:absolute;
  font-weight: bold;
  font-size: 10px;
  color: #CACACA;
  margin:0;
  top:12px;
  right:52px;
`
export const Inside = styled.div`
  width:100%;
  height:100%;
  border-radius:60px 10px;
  background-color:#F5F6F4;
  overflow:hidden;
`