import React, { useEffect, useState } from 'react'
import { ErrorMessage, getIn } from 'formik'


import {
  CategoryContainer,
  CategoryContent,
  VariationContainer,
  VariationContent,
  Container,
  Content,
  InputContainer,
  InputLabel,
  OpenButton,
  CustomInput,
  ContentSideBySide,
  ValueText
} from './styles'

import { FiPlus } from 'react-icons/fi'

import { useModal } from '../../../context/ModalContext'
import { useTheme } from '../../../context/ThemeContext'

import Tooltip from '../../../components/tooltip'
import MainInput from '../../../components/inputs/mainInput'
import ButtonLink from '../../../components/buttons/buttonLink'
import ProductVariation from '../../../components/productVariation'

import SelectCategory from '../selectCategory'
import { formatMoneyBRL } from '../../../Utils'

export default function FirstStep({ images, formFields, formikProps, deleteProduct, state, isMobile }) {
  const { productName, productCategory, productVariation, productPrice } = formFields
  const { errors, touched, values, getFieldProps, setFieldValue } = formikProps
  const { setModal } = useModal()
  const { currentTheme } = useTheme()

  useEffect(() => {
    if (images.length > 0) {
      setFieldValue('productImages', images)
    }
  }, [images])

  useEffect(() => {
    if (state && values.productName === '') {
      setFieldValue('productName', state.name)
      setFieldValue('productImages', [state.image])
      if (state.variations.length > 0) {
        setFieldValue('productVariationName', state.variations[0].type)
      }
      setFieldValue('productCategory', [
        { id: 0, name: state.category.first }, { id: 1, name: state.category.second }, { id: 2, name: state.category.third }
      ])
      setFieldValue('productPrice', state.priceRange[0])

      let formatVariaton = []
      let imgList = []

      state.variations.forEach((element, index) => {
        formatVariaton[index] = [
          element.name,
          element.originalPrice,
          element.quantity,
          [undefined, element.image],
        ]
        imgList.push(element.image)
      })

      setFieldValue('productVariation', formatVariaton)
    }
  }, [])

  function getErrorStyle(fieldName) {
    return getIn(errors, fieldName) && getIn(touched, fieldName)
  }

  function handleOpenModal() {
    setModal(
      <ProductVariation
        formikProps={formikProps}
        values={values[productVariation.name]}
        placeholder={productVariation.placeholder}
        errorStyle={getErrorStyle(productVariation.name)}
        width='w-full'
        onValueChange={variations => setFieldValue(productVariation.name, variations)}
      />)
  }

  function handleOpenCategoryModal() {
    setModal(
      <SelectCategory
        formikProps={formikProps}
        onValueChange={categories => setFieldValue(productCategory.name, categories)}
      />)
  }

  return (
    <Container>
      <Content>
        <ContentSideBySide>
          <InputLabel>{productName.label}</InputLabel>
          {state &&
            <ButtonLink
              text='Deletar produto'
              type='button'
              color={currentTheme.primaryPink}
              onClick={deleteProduct}
              fontSize='1.3rem'
            />
          }
        </ContentSideBySide>
        <CustomInput
          type='text'
          placeholder={productName.placeholder}
          errorStyle={getErrorStyle(productName.name)}
          {...getFieldProps(productName)}
        />
        <ErrorMessage name={productName.name} render={msg => <div className='error-message'>{msg}</div>} />{' '}
      </Content>
      <Content>

        <InputContainer>
          <ContentSideBySide>
            <InputLabel>
              {productCategory?.label}
              <Tooltip text={productCategory?.tooltip} />
            </InputLabel>
            {values.productCategory.length > 0 &&
              <ButtonLink
                text='Editar'
                type='button'
                icon={false}
                underline={false}
                fontSize='1.3rem'
                onClick={handleOpenCategoryModal}
              />}
          </ContentSideBySide>
          {values.productCategory.length > 0 ?
            <CategoryContainer>
              {values.productCategory.map((item, key) => {
                return (item.name) &&
                  <CategoryContent key={key}>
                    {item.name}
                  </CategoryContent>
              })}
            </CategoryContainer>
            :
            <OpenButton
              smallFont
              text='Selecionar categoria'
              type='button'
              width='100%'
              height='5.5vh'
              borderRadius={isMobile ? '6px' : '15px'}
              onClick={handleOpenCategoryModal}
              icon={<FiPlus />}
              errorStyle={getErrorStyle(productCategory.name)}
            />
          }
          <ErrorMessage name={productCategory.name} render={msg => <div className='error-message'>{msg}</div>} />
        </InputContainer>
      </Content>

      <Content>
        <InputContainer>
          <MainInput
            label={productPrice.label}
            inputType='currency'
            onValueChange={({ value }) => setFieldValue(productPrice.name, parseFloat(value))}
            value={values[productPrice.name]}
            placeholder={productPrice.placeholder}
            error={!!getErrorStyle(productPrice.name)}
          />
          <ErrorMessage name={productPrice.name} render={msg => <div className='error-message'>{msg}</div>} />
        </InputContainer>

        {Number(values[productPrice.name]) > 0 && (
          <ValueText>
            Você irá receber R$ {formatMoneyBRL((values[productPrice.name] * 0.9).toFixed(2))}{' '}
            por este produto, pois a Gowdock tem uma taxa de 10%.
          </ValueText>
        )}
      </Content>

      <Content>
        <InputContainer>
          <ContentSideBySide>
            <InputLabel>
              {productVariation?.label}
              <Tooltip text={productVariation?.tooltip} />
            </InputLabel>
            {values.productVariation.length > 0 &&
              <ButtonLink
                text='Editar'
                type='button'
                icon={false}
                underline={false}
                fontSize='1.3rem'
                onClick={handleOpenModal}
              />
            }
          </ContentSideBySide>
          {values.productVariation.length > 0 ?
            <>
              <InputLabel>
                {values.productVariationName}
              </InputLabel>
              <VariationContainer>
                {values.productVariation.map((item, key) => (
                  <VariationContent key={key}>
                    {item[0]}
                  </VariationContent>
                ))}
              </VariationContainer>
            </>
            :
            <OpenButton
              smallFont
              text='Selecionar variação'
              type='button'
              width='100%'
              height='5.5vh'
              borderRadius={isMobile ? '6px' : '15px'}
              onClick={handleOpenModal}
              icon={<FiPlus />}
              errorStyle={getErrorStyle(productVariation.name)}
            />
          }
          <ErrorMessage name={productVariation.name} render={msg => <div className='error-message'>{msg}</div>} />
        </InputContainer>
      </Content >
    </Container>

  )
}
