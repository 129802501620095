export default {
  formId: 'newBankAccountForm',
  requiredErrorMsg: 'Este campo é obrigatório.',
  maxCharAmount: 'Máximo de ${max} dígito.',
  requiredOptionErrorMsg: 'Por favor, selecione uma opção.',
  incompleteErrorMsg: 'Campo incompleto.',
  formField: {
    name: {
      name: 'name',
      label: 'Nome / Razão social'
    },
    document: {
      name: 'document',
      label: 'CPF / CNPJ'
    },
    bank: {
      name: 'bank',
      label: 'Banco'
    },
    accountType: {
      name: 'accountType',
      label: 'Conta'
    },
    agency: {
      name: 'agency',
      label: 'Agência'
    },
    account: {
      name: 'account',
      label: 'Conta'
    },
    accountDigit: {
      name: 'accountDigit',
      label: 'Digito da conta'
    },
    code: {
      name: 'code',
      label: 'Inserir código'
    },
  },
}
