import React, { useMemo, useState, useCallback, useEffect } from 'react'
import Carrousel, { arrowsPlugin, Dots } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

import EmptyIcon from '../../../assets/empty.svg'
import {
  FiArrowLeftCircle,
  FiArrowRightCircle
} from 'react-icons/fi'

import {
  Container,
  InactiveContainer,
  InactiveTitle,
  DetailContainer,
  ButtonContainer,
  ImageContainer,
  ProductImage,
  VariationIcon,
  ItemName,
  DetailsDiv,
  Units,
  Category,
  ItemPrice,
  ItemColorTitle,
  ItemColor,
  ItemDescription,
  VariationsBreak,
  ProductStockIncrement,
  ProductStockReduce,
  CloseQuantity,
  Row,
  Column
} from './styles'
import { ItemRating, ItemsSold } from '../item/styles'
import { useTheme } from '../../../context/ThemeContext'
import MoneyTextField from '../../../components/moneyTextField'
import ButtonSecondary from '../../../components/buttons/buttonSecondary'
import ButtonPrimary from '../../../components/buttons/buttonPrimary'
import MainInput from '../../../components/inputs/mainInput'
import { useAuth } from '../../../context/AuthContext'
import { useHistory } from 'react-router-dom'
import { updateProduct } from '../../../api/product'
import { useModal } from '../../../context/ModalContext'

export default function Detail({ item }) {
  const {
    id: productId,
    code,
    image,
    name,
    priceRange: price,
    evaluation,
    ammountReview,
    variations,
    category,
    quantity,
    description,
  } = item || {}
  const [sliderValue, setSliderValue] = useState(0)
  const [changeButton, setChangeButton] = useState(false)
  const [unitsValue, setUnitsValue] = useState(0)
  const { setModal } = useModal()

  const serializedVariationName = useMemo(() => variations?.map(({ name }) => name).join(', '), [variations])
  const { currentTheme } = useTheme()
  const { push } = useHistory()
  const { user: { storeData: { id } } } = useAuth()

  const setStockValue = useCallback((value) => {
    const rule = /^[0-9\b]+$/
    if (value === '' || rule.test(value)) {
      if (parseFloat(value)) {
        setUnitsValue(parseFloat(value))
      } else {
        setUnitsValue(0)
      }
    }
  }, [])

  function handleModal() {
    setChangeButton(!changeButton)
  }

  async function handleStockUpdate() {
    try {
      await updateProduct(productId, { quantity: unitsValue })
    } finally {
      window.location.reload()
    }
  }

  useEffect(() => {
    if (quantity) {
      setUnitsValue(quantity)
    }
  }, [quantity])

  function handleCarrousel(sliderValue, setSliderValue) {
    return (
      <>
        {variations?.length > 0 ?
          <div className='CarrouselContainer'>
            <Carrousel
              plugins={[
                {
                  resolve: arrowsPlugin,
                  options: {
                    arrowLeft: <button><FiArrowLeftCircle size={40} fill={currentTheme.primaryGreen} stroke={currentTheme.grayBackgrounds} /></button>,
                    arrowLeftDisabled: <button><FiArrowLeftCircle size={40} fill={currentTheme.grayInactivy} stroke={currentTheme.grayBackgrounds} /></button>,
                    arrowRight: <button><FiArrowRightCircle size={40} fill={currentTheme.primaryGreen} stroke={currentTheme.grayBackgrounds} /></button>,
                    arrowRightDisabled: <button><FiArrowRightCircle size={40} fill={currentTheme.grayInactivy} stroke={currentTheme.grayBackgrounds} /></button>,
                    addArrowClickHandler: true,
                  }
                }
              ]}
              animationSpeed={1000}
              value={sliderValue}
              onChange={setSliderValue}
            >
              {
                variations?.map((variation, key) => (
                  <VariationIcon key={key} alt={variation?.name} src={variation?.image} />
                ))
              }
            </Carrousel>
          </div>
          :
          <ProductImage src={image} />
        }
      </>
    )
  }

  return (
    <Container>
      {!item ?
        <InactiveContainer>
          <img alt="ProductImage" src={EmptyIcon} />
          <InactiveTitle>
            Nenhum pedido selecionado
          </InactiveTitle>
        </InactiveContainer>
        :
        <DetailContainer>
          <ImageContainer>
            {handleCarrousel(sliderValue, setSliderValue)}
            <Dots value={sliderValue} onChange={setSliderValue} number={variations.length} />
          </ImageContainer>
          <DetailsDiv>
            <Row height='25%'>
              <Column>
                <ItemName>
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </ItemName>
                <Units>
                  {quantity ? `${quantity} unidade(s)` : 'Sem estoque'}
                </Units>
              </Column>
              <Column>
                <Category>
                  {category?.first}
                </Category>
              </Column>
            </Row>
            <Row>
              <Column width='100%'>
                <ItemPrice>
                  {variations?.length > 0 ?
                    (<> <MoneyTextField value={price[0]} /> - <MoneyTextField value={price[1]} /></>)
                    :
                    <MoneyTextField value={price && price[0]} />
                  }
                </ItemPrice>
              </Column>
            </Row>
            <Row height='15%'>
              <Column width='75%'>
                <VariationsBreak>
                  <ItemColorTitle>Variações: </ItemColorTitle>
                  <ItemColor>{serializedVariationName}</ItemColor>
                </VariationsBreak>
              </Column>
            </Row>
            <Row height='50%'>
              <Column width='100%'>
                <ItemDescription>
                  {description}
                </ItemDescription>
              </Column>
            </Row>
            <Row height='15%'>
              <Column width='100%'>
                <ButtonContainer active={changeButton} >
                  <div className='first'>
                    <button onClick={handleModal}>
                      <CloseQuantity />
                    </button>
                    <ProductStockReduce onClick={() => setStockValue(unitsValue - 1 || 0)} />
                    <MainInput
                      width='25%'
                      autoFocus
                      value={unitsValue}
                      pattern="[0-9]*"
                      type="number"
                      placeholder={`${unitsValue} unidades`}
                      onChange={(event) => setStockValue(event.target.value)}
                    />
                    <ProductStockIncrement onClick={() => setStockValue(unitsValue + 1 || 1)} />
                    <ButtonPrimary
                      height='100%'
                      width='25%'
                      text='Atualizar'
                      smallFont
                      onClick={handleStockUpdate}
                      disabled={unitsValue === ''}
                    />
                  </div>

                  <div className='second'>
                    <ButtonSecondary
                      height='100%'
                      width='45%'
                      text='Atualizar estoque'
                      smallFont
                      backgroundColor={currentTheme.grayBackgrounds}
                      onClick={handleModal}
                    />
                    <ButtonSecondary
                      height='100%'
                      width='45%'
                      text='Editar produto'
                      smallFont
                      backgroundColor={currentTheme.grayBackgrounds}
                      onClick={() => {push('/addproduct', { ...item }); setModal()}}
                    />
                  </div>
                </ButtonContainer>
              </Column>
            </Row>
          </DetailsDiv>
        </DetailContainer>
      }
    </Container>
  )
}
