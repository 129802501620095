import RegisterFormModel from './RegisterFormModel'

const {
  formField: {
    firstName,
    lastName,
    cpf,
    email,
    password,
    confirmPassword,
    numberPhone,
    code,
    cep,
    address,
    city,
    state,
    neighborhood,
    number,
    complement,
    cnpj,
    cnpjPhoto,
    fantasyName,
    socialReason,
    logo,
    isTermsChecked
  },
} = RegisterFormModel

const initialValues = {
  [firstName.name]: '',
  [lastName.name]: '',
  [cpf.name]: '',
  [email.name]: '',
  [password.name]: '',
  [confirmPassword.name]: '',
  [numberPhone.name]: '',
  [code.name]: '',
  [cep.name]: '',
  [address.name]: '',
  [city.name]: '',
  [state.name]: '',
  [neighborhood.name]: '',
  [number.name]: '',
  [complement.name]: '',
  [logo.name]: '',
  [cnpj.name]: '',
  [cnpjPhoto.name]: '',
  [fantasyName.name]: '',
  [socialReason.name]: '',
  [isTermsChecked.name]: ''
}

export default initialValues
