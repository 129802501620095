import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    width: 100vw;
    padding: 0 20px;
    margin: 2.5% 0 0 0;
  }
`

export const CircularProgressContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  background-color: ${({ theme }) => theme.grayBackgrounds};
`

export const Header = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ItemContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 5%;
  width: 100%;
  height: 80%;
  padding: 3% 0;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.grayBackgrounds};

  @media ${device.mobileL} {
    border-radius: 0;
    margin-top: 1.2%;
  }
`

export const Title = styled.h1`
  font-weight: 700;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.black};

  @media ${device.mobileL} {
    font-size: 1.375;
  }
`

export const StockAlert = styled.span`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme }) => theme.graySecondaryText};
`
