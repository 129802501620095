import styled from 'styled-components'

export const Container  = styled.div`
  display:flex;
  justify-content:center;
  align-items:flex-end;
`
export const Images  = styled.div`
  display:flex;
  justify-content:space-evenly;
  margin:0 5px;
  width:40% ;
`
export const Text = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: #63BB45;
`