import styled from 'styled-components'
import ButtonSecondaryIconRight from '../../../components/buttons/buttonSecondaryIconRight'
import MainInput from '../../../components/inputs/mainInput'
import { device } from '../../../styles/sizes'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 65%;
  `

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3vh;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const InputLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayMainText};

  @media ${device.mobileL} {
    font-size: 16px;
  }
`

export const OpenButton = styled(ButtonSecondaryIconRight)`
  border-color: ${({ errorStyle, theme }) => errorStyle ? theme.statusRed : 'initial'};
  `

export const CustomInput = styled(MainInput)`
  border-color: ${({ errorStyle, theme }) => errorStyle ? theme.statusRed : 'inherit'};
  background-color: ${({ theme }) => theme.grayBackgrounds};

  @media ${device.mobileL} {
    height: auto;
  }
`

export const CategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  row-gap: 8px;
  column-gap: 16px;
  width: 100%;
  overflow-y: auto;
`

export const CategoryContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5vh;
  border-radius: 60px;
  background-color: rgba(149,17,89, 5%);
  color: ${({ theme }) => theme.primaryPink};
  font-size: 1.2rem;
  font-weight: 700;
`

export const VariationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  row-gap: 8px;
  column-gap: 16px;
  width: 100%;
  overflow-y: auto;
`

export const VariationContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5vh;
  border-radius: 60px;
  background-color: ${({ theme }) => theme.grayBackgrounds};
  color: ${({ theme }) => theme.grayMainText};
  font-size: 1.2rem;
  font-weight: 700;
`
export const ContentSideBySide = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ValueText = styled.span`
  color: ${({ theme }) => theme.grayMainText};
  font-size: 1rem;
  font-weight: 500;
  margin-top: .5rem;
`
