import React from 'react'
import Routes from './routes'
import { StoreProvider } from './context/StoreDataContext'
import { AuthProvider } from './context/AuthContext'
import { ThemeProvider } from './context/ThemeContext'
import { ApolloProvider } from '@apollo/client'
import apolloClient from './api/apolloServer'

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <ApolloProvider client={apolloClient}>
          <StoreProvider>
            <Routes />
          </StoreProvider>
        </ApolloProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
