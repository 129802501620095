import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { FiX } from 'react-icons/fi'

import { confirmValidationCode } from '../../../../api/api'
import { addBankAccount } from '../../../../api/store'
import { useModal } from '../../../../context/ModalContext'
import { useAuth } from '../../../../context/AuthContext'
import { useHistory } from 'react-router-dom'

import {
  Container,
  Header,
  Title,
  CloseButotn,
  Content,
  ButtonsArea,
  ButtonsContainer,
  ChildContainer,
} from './styles'

import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'

import InitialValues from './formModel/FormInitialValues'
import FormModel from './formModel/FormModel'
import ValidationSchema from './formModel/ValidationSchema'

import ButtonPrimary from '../../../../components/buttons/buttonPrimary'
import ButtonSecondary from '../../../../components/buttons/buttonSecondary'

export default function ModalNewAccount() {
  const { push } = useHistory()
  const { formField } = FormModel
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)

  const { setModal } = useModal()
  const { user, handleUpdateUserField } = useAuth()
  const currentValidationSchema = ValidationSchema[step - 1]

  function contentRendered(page, values, setFieldValue, formik) {
    // switch (page) {
    // case 1:
    //   return <Step1 formField={formField} setFieldValue={setFieldValue} values={values} formikProps={formik} />
    // case 2:
    //   return <Step2 values={values} />
    // case 3:
    //   return <Step3 values={values} formField={formField} formikProps={formik} loading={loading} />
    // case 4:
    //   return <Step4 />
    // default:
    //   break
    // }
    switch (page) {
      case 1:
        return <Step1 formField={formField} setFieldValue={setFieldValue} values={values} formikProps={formik} />
      case 2:
        return <Step2 values={values} />
      case 3:
        return <Step4 />
      case 4:
        return <Step3 values={values} formField={formField} formikProps={formik} loading={loading} />
      default:
        break
    }
  }

  function backStep() {
    setStep(step - 1)
  }

  function backToStart(reset) {
    setStep(1)
    reset()
  }

  async function submitForm(values) {
    setStep(previous => previous + 1)
    return values
  }

  // async function handleSubmit(values, actions) {
  //   if (step === 3) {
  //     const response = await confirmValidationCode(user?.storeData.email, values.code)
  //     if (response.data.validation) {
  //       const addAccount = await addBankAccount(user.storeData.id, values.bank, values.account)
  //       if (addAccount.status === 200) {
  //         handleUpdateUserField({ bankDetails: addAccount.data.bankDetails })
  //         push('/config/revenues')
  //         submitForm(values)
  //       }
  //     } else {
  //       alert('Código inválido!')
  //     }
  //   } else {
  //     actions.setTouched({})
  //     setStep(step + 1)
  //   }
  // }
  async function handleSubmit(values, actions) {
    if (step === 2) {

      let accountType = ""
      values.accountType === "Pessoa Jurídica" ? accountType = "Legal Entity" : accountType = "Individual"
      console.log(accountType)

      setLoading(true);
      try {
        const addAccount = await addBankAccount(
          user.storeData.id,
          values.bank,
          values.agency,
          values.accountDigit ? `${values.account}-${values.accountDigit}` : values.account,
          values.accountType
        );

        setLoading(false);

        // Verifica se o status da resposta não é 200, 201 ou qualquer status de sucesso
        if (![200, 201].includes(addAccount.status)) {
          // Lida com erro
          console.error(`Erro: Status da resposta - ${addAccount.status}`);
          // Você pode adicionar uma mensagem de erro aqui ou tratar o erro de outra maneira
          return; // Sai da função em caso de erro
        }

        // Sucesso: status 200 ou 201
        console.log(addAccount.response());

        handleUpdateUserField({ bankDetails: addAccount.data.bankDetails });
        push('/config/revenues');
        submitForm(values);
        window.location.reload();

      } catch (error) {
        setLoading(false);
        // Tratamento de erros na requisição
        alert('Erro na requisição');
      }

    } else {
      actions.setTouched({})
      setStep(step + 1)
    }
  }

  return (
    <Container>
      <Header>
        <Title>Adicionar nova conta</Title>

        <CloseButotn onClick={() => setModal()}>
          <FiX size='2rem' color='black' />
        </CloseButotn>
      </Header>

      <Formik
        initialValues={InitialValues}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
      >
        {formik => (
          <Form>
            <Content>
              <ChildContainer>
                {contentRendered(step, formik.values, formik.setFieldValue, formik)}
              </ChildContainer>
              <ButtonsArea>
                <ButtonsContainer>
                  {step === 1 && (
                    <>
                      <p></p>
                      <ButtonPrimary text='Avançar' width='47%' type='submit' />
                    </>
                  )
                  }
                  {/* {(step === 2 || step === 3) && ( */}
                  {(step === 2) && (
                    <>
                      <ButtonSecondary width='47%' text='Voltar' type='button' onClick={backStep} />
                      <ButtonPrimary width='47%' text={loading ? 'Carregando...' : 'Finalizar'} type='submit' disabled={loading} />
                    </>
                  )}
                  {/* {step === 4 && ( */}
                  {step === 3 && (
                    <>
                      <ButtonSecondary width='47%' text='Adicionar outra conta' type='button' onClick={() => backToStart(formik.resetForm)} />
                      <ButtonPrimary width='47%' text='Concluir' onClick={() => setModal(false)} />
                    </>
                  )}
                </ButtonsContainer>
              </ButtonsArea>
            </Content>
          </Form>
        )}
      </Formik>
    </Container>
  )
}