import React, { useContext, useState } from 'react'

import {
  Container,
  BackGround,
  Modal,
} from './ModalStyles'

const ModalContext = React.createContext()

export default function ModalProvider({ children }) {
  const [modal, setModal] = useState()

  return (
    <ModalContext.Provider value={{ setModal }}>
      {modal &&
        <Container>
          <BackGround onClick={() => setModal(false)} />
          <Modal>
            {modal}
          </Modal>
        </Container>
      }
      {children}
    </ModalContext.Provider>
  )
}

export function useModal() {
  const { setModal } = useContext(ModalContext)
  return { setModal }
}