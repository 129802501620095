// Código antigo

// import styled, { css } from 'styled-components'

// export const Container = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
// `

// export const Content = styled(Container)`
//   justify-content: center;
//   align-items: flex-start;
//   width: 55vw;
//   height: 100%;
//   padding-left: 1%;
// `

// export const DropContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   height: 25vh;
//   margin-top: 20px;
//   padding: 15px;
//   border-radius: 10px;
//   align-items: center;
//   justify-content: space-between;
//   transition: all 0.3s ease;
//   border: 6px ${({ theme }) => theme.grayBackgrounds} solid;
//   background-color: ${({ theme }) => theme.grayBackgrounds};
//   background-image: ${({ isDrag }) =>
//     isDrag
//       ? 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'16\' ry=\'16\' stroke=\'%23605E70FF\' stroke-width=\'4\' stroke-dasharray=\'20\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e");'
//       : 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'16\' ry=\'16\' stroke=\'transparent\' stroke-width=\'4\' stroke-dasharray=\'20\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e");'};
//   border-radius: 16px;
// `

// export const ColorButton = styled.button`
//   background-color: ${({ theme }) => theme.primaryGreen};
//   color: white;
//   border-radius: 6px;
//   padding: 8px 32px;
// `

// export const ImagesContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 30%);
//   grid-auto-rows: auto;
//   width: 100%;
//   max-height: 55vh;
//   gap: 4%;
//   overflow-y: auto;
// `

// export const ImageContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items:center;
//   flex-direction: column;
//   position: relative;
// `

// export const Filename = styled.h2`
//   width: 100%;
//   margin-top: 1px;
//   text-align: center;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
// `
// export const FavText = styled.span`
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display:none;
// `

// export const CornerButton = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: absolute;
//   top: 4%;
//   right: 10px;
//   width: 2vw;
//   height: 2vw;
//   opacity: 0;
//   border: 1.5px solid transparent;
//   border-radius: 9999px;
//   transition: 0.3s;
//   background-color: ${({ theme }) => theme.grayBackgrounds};
//   ${({ favorite }) =>
//     favorite &&
//     css`
//       height: 2vw;
//       opacity: 1;
//       border-radius: 100px;
      
//       :hover{
//         align-items:center;
//         justify-content: space-evenly;
//         width: 90%;
//       }

//       :hover ${FavText}{
//         display: initial;
//       }
//       `}

//   ${({ bottom }) =>
//     bottom &&
//     css`
//       bottom: 15%;
//       left: 8px;
//       top: initial;
//       right: initial;
//     `}

//   :hover {
//     border: 1.5px solid ${({ theme }) => theme.grayMainText};
//     border-radius: 9999px;
//   }

//   ${ImageContainer}:hover & {
//     opacity: 1;
//   }

// `

// export const ProductImage = styled.img`
//   height: 25vh;
//   width: 25vw;
//   border: 4px solid ${({ theme }) => theme.grayBackgrounds};
//   border-radius: 10px;
//   object-fit: cover;
//   object-position: center;
// `

// export const StepFormContent = styled.div`
//   width: 30vw;
//   height: 100%;
//   padding: 0 20px;
// `

// export const Footer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-top: 8%;
// `


// Add media-queries

import styled, { css } from 'styled-components'
import { device } from '../../styles/sizes'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @media ${device.mobileL} {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const Contet = styled(Container)`
  justify-content: center;
  align-items: flex-start;
  width: 55vw;
  height: 100%;
  padding-left: 1%;

  @media ${device.mobileL} {
    width: 80vw;
    padding-left: 0;
  }
`

export const Content = styled.div`
  display: flex;
  height: 70%;
  width: 100%;  

  justify-content: center;

  @media ${device.mobileL} {
    width: 100%;
    height: auto;
  }
`

export const DropContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 25vh;
  margin-top: 20px;
  padding: 15px;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
  border: 6px ${({ theme }) => theme.grayBackgrounds} solid;
  background-color: ${({ theme }) => theme.grayBackgrounds};
  background-image: ${({ isDrag }) =>
    isDrag
      ? 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'16\' ry=\'16\' stroke=\'%23605E70FF\' stroke-width=\'4\' stroke-dasharray=\'20\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e");'
      : 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'16\' ry=\'16\' stroke=\'transparent\' stroke-width=\'4\' stroke-dasharray=\'20\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e");'};
  border-radius: 16px;

  @media ${device.mobileL} {
    height: 20vh;
    margin-top: 0;
  }
`

export const ColorButton = styled.button`
  background-color: ${({ theme }) => theme.primaryGreen};
  color: white;
  border-radius: 6px;
  padding: 8px 32px;
`

export const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-auto-rows: auto;
  width: 100%;
  max-height: 55vh;
  gap: 4%;
  overflow-y: auto;

  @media ${device.mobileL} {
    grid-template-columns: repeat(2, 50%);
  }
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media ${device.mobileL} {
    height: 15vh;
  }
`

export const Filename = styled.h2`
  width: 100%;
  margin-top: 1px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const FavText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
`

export const CornerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4%;
  right: 10px;
  width: 2vw;
  height: 2vw;
  opacity: 0;
  border: 1.5px solid transparent;
  border-radius: 9999px;
  transition: 0.3s;
  background-color: ${({ theme }) => theme.grayBackgrounds};

  ${({ favorite }) =>
    favorite &&
    css`
      height: 2vw;
      opacity: 1;
      border-radius: 100px;
      
      :hover {
        align-items: center;
        justify-content: space-evenly;
        width: 90%;
      }

      :hover ${FavText} {
        display: initial;
      }
    `}

  ${({ bottom }) =>
    bottom &&
    css`
      bottom: 15%;
      left: 8px;
      top: initial;
      right: initial;
    `}

  :hover {
    border: 1.5px solid ${({ theme }) => theme.grayMainText};
    border-radius: 9999px;
  }

  ${ImageContainer}:hover & {
    opacity: 1;
  }

  @media ${device.mobileL} {
    width: 8vw;
    height: 8vw;
  }
`

export const ProductImage = styled.img`
  height: 25vh;
  width: 25vw;
  border: 4px solid ${({ theme }) => theme.grayBackgrounds};
  border-radius: 10px;
  object-fit: cover;
  object-position: center;

  @media ${device.mobileL} {
    height: 15vh;
    width: 15vw;
  }
`

export const StepFormContent = styled.div`
  width: 30vw;
  height: 100%;
  padding: 0 20px;

  @media ${device.mobileL} {
    width: 90vw;
    padding: 0;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8%;

  @media ${device.mobileL} {
    flex-direction: row;
    align-items: center;
    margin-top: 10%;
  }
`
