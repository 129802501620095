import styled from 'styled-components'

export const Container = styled.button`
  width: ${(props) => props.width ? props.width : '15vw'};
  height: ${(props) => props.height ? props.height : '7vh'};
  background: ${(props) => props.background ? props.background : props.theme.primaryGreen};
  transition: 0.3s;
  color: ${(props) => props.color ? props.color : props.theme.white};
  font-weight: 600;
  font-size: ${(props) => props.smallFont ? '1.4rem' : '1.6rem'};
  border-top-left-radius: 30px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 30px;
  
  :hover {
    background: ${(props) => props.backgroundHover ? props.backgroundHover : props.theme.secondaryLightGreen};
  }

  :disabled {
    background: ${({ theme }) => theme.grayInactivy};
    cursor: default;
  }
`
