import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  Container,
  Header,
  Sidebar,
  Content
} from '../../styles/layout/Layout'

import { Title, SubTitle, InsiderContent } from './styles'

import LoginHeader from '../../components/loginHeader/LoginHeader'
import LoginRegisterSidebar from '../../components/loginRegisterSidebar/Index'

import { ValidateAccount } from '../../api/store'

export default function CheckEmail() {
  const { id } = useParams()
  const { push } = useHistory()

  const counter = () => new Promise((resolve) => {
    setTimeout(resolve, 1000)
  })

  async function handleValidade() {
    const response = await ValidateAccount(id)
    if (response.status === 200) {
      await counter()
      push('/login')
      clearInterval(counter())
    }
  }

  useEffect(() => {
    handleValidade()
  }, [])

  return (
    <Container>
      <Sidebar>
        <LoginRegisterSidebar />
      </Sidebar>
      <Header>
        <LoginHeader />
      </Header>
      <Content>
        <InsiderContent>
          <Title>Pronto! Sua conta foi ativada com sucesso.</Title>
          <SubTitle>Você será redirecionado em segundos...</SubTitle>
        </InsiderContent>
      </Content>
    </Container>
  )
}
