import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.button`
  width: ${(props) => props.width ? props.width : '15vw'};
  height: ${(props) => props.height ? props.height : '7vh'};
  background: ${({ backgroundColor, theme }) => backgroundColor ? backgroundColor : theme.white};
  border: 1px solid ${(props) => props.color ? props.color : props.theme.primaryGreen};
  transition: 0.3s;
  color: ${(props) => props.color ? props.color : props.theme.primaryGreen};
  font-weight: 600;
  font-size: ${(props) => props.smallFont ? '1.4rem' : '1.6rem'};

  border-radius: 8px;
  
  :hover {
    border: 1px solid ${(props) => props.colorHover ? props.colorHover : props.theme.secondaryLightGreen};
    color: ${(props) => props.colorHover ? props.colorHover : props.theme.secondaryLightGreen};
  }

  :disabled {
    border: 1px solid ${({ theme }) => theme.grayInactivy};
    color: ${({ theme }) => theme.grayInactivy};
    cursor: default;
  } 

  @media ${device.mobileL} {
    border-radius: 6px;
    font-size: 16px;
  }
`
