import React, { useState, useEffect } from 'react'

import {
  Container,
  Content,
  Label
} from './styles'

import Tooltip from '../../../components/tooltip'
import { Modal } from '../../../components/modal'
import MainInput from '../../../components/inputs/mainInput'
import ButtonPrimary from '../../../components/buttons/buttonPrimary'
import { cancelOrderJadlog, updatePurchaseStatus, updateShipmentId } from '../../../api/purchases'
import { DELIVERING } from '../../../Utils'
import { useModal } from '../../../context/ModalContext'
import { includeOrderJadlog, findOrderByCode } from '../../../api/purchases'
import ErrorModal from '../../../components/modals/errorModal'

export function ModalShipOrder({ purchaseId, orderCode }) {

  const [orderId, setOrderId] = useState()
  const [code, setCode] = useState(undefined)
  const { setModal } = useModal()

  const handleFindOrderCode = async () => {
    const newOrderCode = orderCode.split('#')
    const {data} = await findOrderByCode(newOrderCode[1])
    setOrderId(data[0]._id)
  }

  async function handleConfirmCode() {
    try {
      const response = await updatePurchaseStatus(purchaseId, DELIVERING, code)
      if (response.status === 200) {
        window.location.reload()
      }
    } catch (error) {
      setModal(<ErrorModal />)
    }
  }

/*   const handleIncludeOrderJadlog = async () => {
    try {
      console.log(orderId)
      const responseOrder = await includeOrderJadlog(orderId);
      console.log(responseOrder)
      const body = { ...responseOrder.data, _id: orderId };
      const [responseUpdate, responsePurchase] = await Promise.all([
        updateShipmentId(body),
        updatePurchaseStatus(purchaseId, DELIVERING)
      ]);
  
      if (responseUpdate.status === 200 && responsePurchase.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      setModal(<ErrorModal errorMessage={'Waiting... Too many requests'}/>)
    }
  } */

  const handleIncludeOrderJadlog = async () => {
    try {
        console.log(orderId);
        
        // Chama a função includeOrderJadlog
        const responseOrder = await includeOrderJadlog(orderId);
        const orderData = responseOrder.data;

        // Verifica se a resposta da requisição includeOrderJadlog é válida
        if (!responseOrder || responseOrder.status !== 200) {
            throw new Error('Falha ao incluir ordem na Jadlog.');
        }

        // Prepara o corpo para as próximas requisições
        const body = { ...orderData, _id: orderId };

        // Realiza as requisições em paralelo usando Promise.all
        const [responseUpdate, responsePurchase] = await Promise.all([
            updateShipmentId(body),
            updatePurchaseStatus(purchaseId, DELIVERING)
        ]);

        // Verifica se ambas as respostas são válidas
        if (responseUpdate.status === 200 && responsePurchase.status === 200) {
            window.location.reload();
        } else {
            throw new Error('Erro ao atualizar as informações de envio ou status de compra.');
        }
    } catch (error) {
        // Verifica o tipo de erro
        console.error(error);
        if (error.message.includes('Falha ao incluir ordem na Jadlog')) {
            setModal(<ErrorModal errorMessage='Falha ao incluir ordem na Jadlog.' />);
        } else if (error.message.includes('Erro ao atualizar as informações de envio ou status de compra.')) {
            setModal(<ErrorModal errorMessage='Erro ao atualizar informações.' />);
        } else {
            setModal(<ErrorModal errorMessage='Erro desconhecido. Tente novamente mais tarde.' />);
        }
    }
}

  const test = () => {
    window.location.reload()
  }

  useEffect(() => {
    handleFindOrderCode()
  }, [])


  return (
    <Modal
      title='Despachar pedido'
      width='40vw'
      height='50vh'
    >
      <Container>
        <Content>
          <Label>Ao clicar em Confirmar, estaremos dando início ao processo de envio do seu pedido. Assim que essa etapa for concluída, a transportadora parceira será notificada para recolher o produto.</Label>
          {/* <Tooltip
            text='O código de despacho é fornecido pelo coletador vinculado ao produto.'
          /> */}
        </Content>
        
        {/* <Content>
          <MainInput
            value={code}
            onChange={(event) => setCode(event.target.value)}
            width='60%'
            maxLength='6'
            type='text'
            placeholder='Código de despacho'
          />
        </Content> */}
        <Content>
          <ButtonPrimary
            text='Confirmar'
            width='50%'
            onClick={handleIncludeOrderJadlog}
          />
        </Content>
      </Container>
    </Modal>
  )
}