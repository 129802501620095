import React from 'react'
import * as Yup from 'yup'
import { Formik, ErrorMessage } from 'formik'

import { useModal } from '../../../context/ModalContext'
import { useTheme } from '../../../context/ThemeContext'
import { useAuth } from '../../../context/AuthContext'

import {
  Container,
  ErrorMsg,
  SendContent,
  StyledForm,
  Text
} from './styles'

import ButtonPrimary from '../../../components/buttons/buttonPrimary'
import MainInput from '../../../components/inputs/mainInput'
import SaveModal from '../../../components/modals/confirmModal'
import ErrorModal from '../../../components/modals/errorModal'
import { changePassword } from '../../../api/api'

export default function Safety() {

  const { user: { storeData: { userId } } } = useAuth()
  const { setModal } = useModal()
  const { currentTheme } = useTheme()

  const requiredErrorMsg = 'Este campo é obrigatório'
  const minError = 'Este campo deve conter no mínimo ${min} caracteres'
  const notEqualError = 'As senhas devem ser iguais'

  async function handleSubmit({ oldPassword, password }, actions) {
    try {
      const response = await changePassword(userId, oldPassword, password)
      if (response.status === 200) {
        setModal(<SaveModal
          text='Sua senha foi alterada com sucesso!'
          confirmText='Ok'
          confirmFunction={() => null}
          onlyButton />
        )
      }
    } catch (error) {
      setModal(<ErrorModal />)
    } finally {
      actions.resetForm()
      actions.setSubmitting(false)
    }
  }

  return (
    <Container>
      <Text>Senha</Text>
      <SendContent>
        <Formik
          initialValues={{
            oldPassword: '',
            password: '',
            confirmPassword: ''
          }}
          validationSchema={
            Yup.object({
              oldPassword: Yup.string()
                .min(8, minError)
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'A senha deve conter pelo menos uma letra minúscula uma maiúscula e um número')
                .required(requiredErrorMsg),
              password: Yup.string()
                .min(8, minError)
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'A senha deve conter pelo menos uma letra minúscula uma maiúscula e um número')
                .required(requiredErrorMsg),
              confirmPassword: Yup.string().required(requiredErrorMsg).oneOf([Yup.ref('password'), null], notEqualError),
            })
          }
          onSubmit={handleSubmit}
        >
          {formik => (
            <StyledForm>
              <MainInput
                label='Senha atual'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.oldPassword}
                placeholder='Sua senha atual'
                name='oldPassword'
                type='password'
              />
              <ErrorMessage
                name='oldPassword'
                render={msg => <ErrorMsg>{msg}</ErrorMsg>}
              />

              <MainInput
                marginTop='0.75rem'
                label='Nova senha'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                placeholder='Nova senha'
                type='password'
                name='password'
              />
              <ErrorMessage
                name='password'
                render={msg => <ErrorMsg>{msg}</ErrorMsg>}
              />

              <MainInput
                marginTop='0.75rem'
                label='Confirmar nova senha'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                placeholder='Confirmar nova senha'
                type='password'
                name='confirmPassword'
              />
              <ErrorMessage
                name='confirmPassword'
                render={msg => <ErrorMsg>{msg}</ErrorMsg>}
              />
              <ButtonPrimary
                mTop='1.5rem'
                background={currentTheme.primaryPink}
                backgroundHover={currentTheme.secondaryLightPink}
                text='Confirmar'
                width='100%'
                type='submit'
              />
            </StyledForm>
          )}
        </Formik>
      </SendContent>
    </Container >
  )
}
