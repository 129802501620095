import { useModal } from '../../../../context/ModalContext'
import { Formik, Form } from 'formik'
import { findOrderByCode, updatedOrderJadlog } from '../../../../api/purchases'
import FormModel from './formModel/FormModel'
import InitialValues from './formModel/FormInitialValues'
import Textfield from '../../../settings/bankAccounts/modalNewAccount/Textfield'
import Selectfield from '../../../settings/bankAccounts/modalNewAccount/Selectfield'
import ValidationSchema from './formModel/ValidationSchema'
import { getErrorStyle } from '../../../../Utils'
import { FiX } from 'react-icons/fi'
import { updatePurchaseStatus } from '../../../../api/purchases'
import ErrorModal from '../../../../components/modals/errorModal'

import {
    Container,
    Header,
    Title,
    CloseButton,
    Content,
    ChildContainer,
    DoubleInputArea,
    ButtonsArea,
    ButtonsContainer
} from './styles'

import ButtonPrimary from '../../../../components/buttons/buttonPrimary'
import { useEffect, useState } from 'react'

const NfeModal = ({ orderCode, id, status }) => {

    const { setModal } = useModal()
    const [orderId, setOrderId] = useState()
    const { danfeCte, nrDoc, serie, valor, cfop } = FormModel.formField
    const [fieldValue, setFieldValue] = useState('')

    const handleFindOrderCode = async () => {
        const newOrderCode = orderCode.split('#')
        const { data } = await findOrderByCode(newOrderCode[1])
        setOrderId(data[0]._id)
    }

    const handleSubmit = async (values, actions) => {
        const newValues = {
            ...values,
            tpDocumento: 2
        }
        try {
            const {data} = await updatedOrderJadlog({ _id: orderId, dfe: [newValues], status: "Aguardando despachante" })
            await updatePurchaseStatus(id, status)
            setModal(false)
        } catch (error) {
            setModal(<ErrorModal closeModal={() => setModal()} />)
        }
        setModal(false)
        window.location.reload()
    }

    useEffect(() => {
        handleFindOrderCode()
    }, [])

    return (
        <Container>
            <Header>
                <Title>Adicionar dados da nota fiscal</Title>

                <CloseButton onClick={() => setModal()}>
                    <FiX size='2rem' color='black' />
                </CloseButton>
            </Header>
            <Formik
                initialValues={InitialValues}
                validationSchema={ValidationSchema[0]}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <Form>
                        <Content>
                            <ChildContainer>
                                <Textfield
                                    name={danfeCte.name}
                                    error={getErrorStyle(danfeCte.name, formik.errors, formik.touched)}
                                    placeholder={danfeCte.label}
                                    maxLength={44} 
                                />
                                <Textfield
                                    name={nrDoc.name}
                                    marginTop='2vh'
                                    error={getErrorStyle(nrDoc.name, formik.errors, formik.touched)}
                                    placeholder={nrDoc.label}
                                    maxLength={20} 
                                />
                                <DoubleInputArea>
                                    <Textfield
                                        width='45%'
                                        name={serie.name}
                                        marginTop='2vh'
                                        placeholder={serie.label}
                                        maxLength={3} 
                                    />
                                    <Textfield
                                        width='45%'
                                        name={valor.name}
                                        marginTop='2vh'
                                        placeholder={valor.label}
                                        type='number'
                                    />
                                </DoubleInputArea>
                                <Textfield
                                    name={cfop.name}
                                    marginTop='2vh'
                                    placeholder={cfop.label}
                                    maxLength={4} 
                                />
                                {/* <Selectfield
                                    width='100%'
                                    name={tpDocument.name}
                                    mTop='2vh'
                                    title={tpDocument.name}
                                    placeholder={tpDocument.label}
                                    options={['Declaração', 'Nota fiscal', 'Nota fiscal eletrônica', 'Conhecimento de transporte eletrônico']}
                                    setFieldValue={formik.setFieldValue}
                                    currentValue={'Nota fiscal eletrônica'}
                                /> */}
                            </ChildContainer>
                            <ButtonsArea>
                                <ButtonsContainer>
                                    <ButtonPrimary width='47%' text='Concluir' type='Submit' /* onClick={() => handleSubmit()} */ />
                                </ButtonsContainer>
                            </ButtonsArea>
                        </Content>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default NfeModal