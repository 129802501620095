import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CircularProgress } from '@material-ui/core';

import api from '../../api/api';
import LoginHeader from '../../components/loginHeader/LoginHeader';
import LoginRegisterSidebar from '../../components/loginRegisterSidebar/Index';

import {
  Container,
  Resize,
  Content,
  Header,
  Sidebar,
  Step
} from '../../styles/layout/Layout';

import {
  FormContainerBase,
  ContentContainer,
  Button,
  CheckInput,
  ForgotPassword,
  RememberContainer,
  Text,
  Title,
  ErrorMsg,
  Message
} from './Styles';

import { Textfield } from './Textfield';
import { useModal } from '../../context/ModalContext';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';
import { getErrorStyle } from '../../Utils';
import { Modal } from '../../components/modal';
import ButtonPrimary from '../../components/buttons/buttonPrimary';
import { Logo } from '../../components/loginRegisterSidebar/Styles';
import LogoHorizontal from '../../assets/logo/Logo_Horizontal.svg'

export default function Login(router) {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [checked, setChecked] = useState(true);

  const history = useHistory();
  const { setModal } = useModal();
  const { handleLogin } = useAuth();
  const { currentTheme } = useTheme();

  const [errMessage, setErrMessage] = useState();
  const [rememberMeData, setRememberMeData] = useState({});

  async function handleSubmit(values, actions) {
    setModal(<CircularProgress style={{ color: currentTheme.secondaryLightGreen }} />);
    await api.post('login', values)
      .then((response) => {
        handleLogin(response.data);
        history.push('/');
        setModal();
        handleSaveRememberMe(checked, values);
      }).catch((err) => {
        setErrMessage(err.response.data.message);
        setModal();
      });
  }

  function handleSaveRememberMe(checked, values) {
    if (checked) {
      localStorage.setItem('g-admin/rememberMe', JSON.stringify({ checked, email: values.login, password: values.password }));
    } else {
      localStorage.removeItem('g-admin/rememberMe');
    }
  }

  function LoadRememberMe() {
    const response = localStorage.getItem('g-admin/rememberMe');
    if (response) {
      const result = JSON.parse(response);
      setRememberMeData(result);
      setChecked(result.checked);
    } else {
      setRememberMeData({});
      setChecked(false);
    }
  }

  useEffect(() => {
    LoadRememberMe();
    if (router?.location?.state?.accountStatus === 'pending') {
      setModal(
        <Modal
          title='Atenção'
          width='40vw'
          height='40vh'
        >
          <Message>
            <Title>Sua conta está em processo de avaliação. Você terá uma resposta em até 48h</Title>
            <ButtonPrimary width='50%' height='20%' smallFont text='Ok!' onClick={() => setModal()} />
          </Message>
        </Modal>
      );
    }
  }, []);

  return (
    <Container>
      <Sidebar>
        <LoginRegisterSidebar />
      </Sidebar>

      <Resize>
        <Logo src={LogoHorizontal} />
      </Resize>
      
      <Header>
        <LoginHeader />
      </Header>
      <Content>
        <ContentContainer>
          <Formik
            initialValues={{
              login: '',
              password: '',
              type: 'store'
            }}
            validationSchema={
              Yup.object({
                login: Yup.string().required('Este Campo é obrigatório'),
                password: Yup.string().min(2, 'Este campo precisa ter no mínimo 2 dígitos').required('Este Campo é obrigatório')
              })
            }
            onSubmit={handleSubmit}
          >
            {formikProps => (
              <Form>
                <FormContainerBase>
                  <Title>Entre na sua conta.</Title>
                  <Textfield
                    borderColor={!!getErrorStyle('login', formikProps.errors, formikProps.touched) || !!errMessage}
                    onFocus={() => setErrMessage('')}
                    placeholder='E-mail ou número do celular'
                    name='login'
                    defaultValue={rememberMeData.email}
                  />
                  {!!errMessage && <ErrorMsg mtop='-1.5rem' mbottom='1.5rem'>{errMessage}</ErrorMsg>}
                  <Textfield
                    borderColor={!!getErrorStyle('password', formikProps.errors, formikProps.touched) || !!errMessage}
                    onFocus={() => setErrMessage('')}
                    placeholder='Senha'
                    type='password'
                    name='password'
                    defaultValue={rememberMeData.password}
                  />
                  <RememberContainer>
                    <CheckInput checked={checked} onClick={() => setChecked(!checked)} type='checkbox' id='remember' />
                    <Text for='remember'>Lembrar de mim</Text>
                  </RememberContainer>
                  <Button type='submit'>
                    Entrar
                  </Button>
                  <ForgotPassword to='/forgotpassword'>
                    Esqueci minha senha
                  </ForgotPassword>
                </FormContainerBase>
              </Form>
            )}
          </Formik>
        </ContentContainer>
      </Content>


      <Step></Step>
    </Container>
  );
}
