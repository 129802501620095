import React, { useEffect } from 'react'
import Divisor from '../../../../../components/divisor'
import { formatAndMaskPhoneNumber, getErrorStyle } from '../../../../../Utils'
import AccountInfo from '../accountInfo'
import Textfield from '../Textfield'

import {
  BankContainer,
  Container,
  ValidationContainer,
  Title,
  Advice,
  NumberPhone,
  ForgotLink
} from './styles'

import { sendValidationCode } from '../../../../../api/api'
import { useAuth } from '../../../../../context/AuthContext'

export default function Step3({ values, formField, formikProps }) {

  const { code } = formField
  const { errors, touched } = formikProps

  const { user } = useAuth()

  async function handleSendCodeConfirmationSms() {
    await sendValidationCode(user?.storeData.email, user?.storeData.phoneNumber, false)
  }

  useEffect(() => {
    handleSendCodeConfirmationSms()
  }, [])

  return (
    <Container>
      <BankContainer>
        <AccountInfo
          agency={values.agency}
          accountType={values.accountType}
          account={values.account}
          bank={values.bank}
        />
      </BankContainer>
      <Divisor />
      <ValidationContainer>
        <Title>Etapa de segurança:</Title>
        <Advice>Para sua segurança um código foi enviado para o número de telefone cadastrado na sua conta Gowdock</Advice>
        <NumberPhone>{formatAndMaskPhoneNumber(user?.storeData.phoneNumber)}</NumberPhone>
        <Textfield
          error={getErrorStyle(code.name, errors, touched)}
          name={code.name}
          placeholder={code.label}
        />
        <Advice mTop='3%'>Não tem acesso ao seu celular?</Advice>
        <ForgotLink>Enviar para email cadastrado.</ForgotLink>

      </ValidationContainer>
    </Container>
  )
}
