import React, { useState, useEffect } from 'react'

import {
  Container
} from './styles'

import { Grid } from '../../components/grid/GridStyle'

import Pending from './pending/Pending'
import Focus from '../../components/focus/Focus'
import Steps from './steps/Steps'


function Management() {
  const [focusItem, setFocusItem] = useState({
    data: undefined,
    index: undefined,
  })

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 650);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    window.addEventListener('resize', handleResize);

    // Limpe o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {
        isMobile
          ?
          <Container>
            <Pending area='pending' focusItem={focusItem} setFocusItem={setFocusItem} isMobile={isMobile}/>
            <Steps area='kanban' focusItem={focusItem} setFocusItem={setFocusItem} />
          </Container>
          :
          <Grid area={'\'focus kanban\' \'pending pending\''}>
            <Focus focusItem={focusItem} area='focus' />
            <Steps area='kanban' focusItem={focusItem} setFocusItem={setFocusItem} />
            <Pending area='pending' focusItem={focusItem} setFocusItem={setFocusItem} />
          </Grid>
      }
    </>
  )
}

export default Management
