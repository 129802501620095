import styled from 'styled-components'
import colors from '../../styles/colors'
import { device } from '../../styles/sizes'

export const  SuccessText = styled.p`
  color: green;
  font-size: 1rem;
  margin-bottom: 2rem;
`

export const BackButton = styled.button`
  height: 6.7vh;
  width: 45%;
  outline: 0;
  border: 1px solid red;
  border-top-left-radius: 25px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 6px;
  margin: 1rem 0;
  font-size: 1.4rem;
  background: #fff;
  color: red;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: red;
    color: white;
    border: 0;
  }

  @media ${device.mobileL} {
    height: 6vh;
  }
`

export const SendButton = styled.button`
  height: 6.7vh;
  width: 45%;
  outline: 0;
  border: 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 6px;
  margin: 1rem 0;
  font-size: 1.4rem;
  background: ${colors.span_green};
  color: white;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: ${colors.secondary_green};
  }

  @media ${device.mobileL} {
    height: 6vh;
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
`

export const DisabledInput = styled.input`
  width: 100%;
  height: 6.7vh;
  border-radius: 6px;
  border: 1px solid ${colors.span_green};
  color: ${colors.span_green};
  outline: 0;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  background: #F5F6F4;
  transition: 0.3s;
`