import React, { useEffect, useState } from 'react'
import {
  Container,
  ContainerComponentArea
} from './styles'
import { BalanceArea } from './balance'
import { ManagementArea } from './management'
import { PendingArea } from './pending'
import { Helper } from './helper'
import { StockArea } from './stockArea'

import { getPurchases } from '../../api/purchases'
import { useAuth } from '../../context/AuthContext'
import { useStore } from '../../context/StoreDataContext'

export default function Home() {

  const { user } = useAuth()
  const { newOrderNotification } = useStore()
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 650);

  async function handleFetchOrderHistory() {
    setLoading(true)
    const { data } = await getPurchases(user.storeData.id)
    if (data.length > 0) {
      setOrders(data)
    }
    setLoading(false)
  }

  useEffect(() => {
    handleFetchOrderHistory()

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    window.addEventListener('resize', handleResize);

    // Limpe o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [newOrderNotification])

  return (
    <>
      {
        isMobile
          ?
          <Container>
            <ContainerComponentArea
              gArea='balance'
            >
              <BalanceArea />
            </ContainerComponentArea>

            <ContainerComponentArea
              gArea='requests'
            >
              <PendingArea orders={orders?.filter((item) => item.status === 'OPEN')} loading={loading} />
            </ContainerComponentArea>
            
            <ContainerComponentArea
              gArea='stock'
            >
              <StockArea />
            </ContainerComponentArea>


            <ContainerComponentArea
              gArea='manageRequests'
            >
              <ManagementArea orders={orders} loading={loading} />
            </ContainerComponentArea>
          </Container>
          :
          <Container>
            <ContainerComponentArea
              gArea='balance'
            >
              <BalanceArea />
            </ContainerComponentArea>

            <ContainerComponentArea
              gArea='stock'
            >
              <StockArea />
            </ContainerComponentArea>

            <ContainerComponentArea
              gArea='requests'
            >
              <PendingArea orders={orders?.filter((item) => item.status === 'OPEN')} loading={loading} />
            </ContainerComponentArea>

            <ContainerComponentArea
              gArea='manageRequests'
            >
              <ManagementArea orders={orders} loading={loading} />
            </ContainerComponentArea>
          </Container>
      }
    </>
  )
}
