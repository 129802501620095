import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.button`
  width: ${(props) => props.width ? props.width : '15vw'};
  height: ${(props) => props.height ? props.height : '7vh'};
  background: ${(props) => props.background ? props.background : props.theme.primaryGreen};
  transition: 0.3s;
  color: ${(props) => props.color ? props.color : props.theme.white};
  font-weight: 600;
  font-size: 2.2rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.6vw;
  
  :hover {
    background: ${(props) => props.backgroundHover ? props.backgroundHover : props.theme.secondaryLightGreen};
  }

  :disabled {
    background: ${({ theme }) => theme.grayInactivy};
    cursor: default;
  } 

  @media ${device.mobileL} {
    font-size: 16px;
  }
`

export const Text = styled.span`
  font-size: ${(props) => props.smallFont ? '1.4rem' : '1.6rem'};

  margin-right: 0.3vw;

  @media ${device.mobileL} {
    font-size: 16px;
  }
`
