import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  `

export const Content = styled(Container)`
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
`

export const FieldContent = styled(Content)`
  margin-bottom: 3vh;
`

export const DefaultLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${props => props.theme.grayMainText};
`

export const InputLine = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const SpacedeInputLine = styled(InputLine)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`

export const DefaultButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5vw;
  height: 6vh;
  margin-top: 5%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.grayBackgrounds};
`
