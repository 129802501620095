import React from 'react'
import { useTheme } from '../../context/ThemeContext'
import { FooterContet, LogoutText, ModalContainer, ModalContent, ModalFooter, ModalImg, ModalImgContainer, ModaltextsContainer } from './styles'

import LockImg from '../../assets/safety.svg'
import ButtonPrimary from '../buttons/buttonPrimary'

export function ModalLogout({ handleClick }) {
  const { currentTheme } = useTheme()

  return (
    <ModalContainer>
      <ModalContent>
        <ModalImgContainer>
          <ModalImg src={LockImg} />
        </ModalImgContainer>

        <ModaltextsContainer>
          <LogoutText>
            Tem certeza que deseja sair?
          </LogoutText>

        </ModaltextsContainer>
      </ModalContent>
      <ModalFooter>
        <FooterContet>
          <ButtonPrimary
            onClick={handleClick}
            text='Sair'
            background={currentTheme.primaryPink}
            backgroundHover={currentTheme.secondaryLightPink}
          />
        </FooterContet>
      </ModalFooter>
    </ModalContainer>
  )
}
