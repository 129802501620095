import React from 'react'
import InputMask from 'react-input-mask'
import { TextField } from './TextField'
import { useField } from 'formik'

export function MaskTextField({ ...props }) {
  const [field, meta] = useField(props)
  return (
    <InputMask {...props} {...field}>
      {(inputProps) => <TextField {...inputProps} />}
    </InputMask>
  )
}
