import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const OverflowContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 48px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; 
  justify-content: flex-start;
  align-items: center;

  @media ${device.mobileL} {
    padding: 0 5%;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  height: 100%;
  width: 100%;  
  
  overflow-y: auto;
  overflow-x: hidden;

  `

export const ValuePickerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    height: auto;
  }
`

export const FooterContainer = styled.div`
  width: 100%;
  height: 25%;
  padding: 0 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 90%;
  padding: 2% 2%;
`

export const CategoryHeader= styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2% 2%;
`

export const CategoryContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 5vh;
  border-radius: 60px;
  background-color: rgba(149,17,89, 5%);
  color: ${({ theme }) => theme.primaryPink};
  font-size: 1.2rem;
  margin: 1% 0;
  font-weight: 700;
`

export const Clear = styled.button`
  color: ${({ theme }) => theme.grayMainText};
  font-size: 1.2rem;
  font-weight: 700;
`

export const UpLabel = styled.label`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.grayMainText};
`

export const Line = styled.div`
  width: 90%;
  height: 1px;
  background-color: ${({theme}) => theme.grayMainText};
`;