import React from 'react'

import {
  HeaderContent,
  LoginLink,
  LoginText,
} from './Styles'

export default function RegisterHeader() {
  return (
    <HeaderContent>
      <LoginText>
        Já é um membro? <LoginLink to='/login'>Entre na sua conta</LoginLink>
      </LoginText>
    </HeaderContent>
  )
}
