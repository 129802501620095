import React from 'react';
import styled from 'styled-components';
import { BsChatRightDotsFill } from "react-icons/bs";

const Button = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  background-color: #2C992B;
  color: #fff;
  border-radius: 100px;
  cursor: pointer;
`;

const ChatButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <BsChatRightDotsFill size={25} />
    </Button>
  );
};

export default ChatButton;
