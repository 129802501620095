import React, { useEffect, useState } from 'react'
import { FiChevronRight, FiChevronDown } from 'react-icons/fi'
import api from '../../../api/api'
import {
  Container,
  HeaderContainer,
  Content,
  ScrollableArea,
  FooterContainer,
  FooterContent,
  DisplaySelectedCategorie,
  Label,
  CategoryItemContainer,
  CategoryItemContent,
  CategoryName,
  SelectedCategoryName,
  ConfirmArea,
  LinkButtonContent,
  GroupDisplayCategory
} from './styles'

import { useTheme } from '../../../context/ThemeContext'
import { useModal } from '../../../context/ModalContext'

import { Modal } from '../../../components/modal'
import MainInput from '../../../components/inputs/mainInput'
import ButtonLink from '../../../components/buttons/buttonLink'
import LoadingIndicator from '../../../components/LoadingIndicator'
import ButtonPrimary from '../../../components/buttons/buttonPrimary'

export default function SelectCategory({ formikProps, onValueChange, confirmButton, categoryFilter, filterModal, isMobile }) {

  const { currentTheme } = useTheme()
  const { setModal } = useModal()

  const [current, setCurrent] = useState(0)
  const [categoryTree, setCategoryTree] = useState(null)
  const [searchKey, setSearchKey] = useState('')
  const [selectedCategorys, setSelectedCategorys] = useState(['', '', ''])
  const [loading, setLoading] = useState(true)

  function changeCurrent(isSubcategory) {
    console.log(current)
    if (current < 2 && isSubcategory) {
      setCurrent(current + 1)
    }
  }

  function handleConfirmCategories() {
    onValueChange(selectedCategorys)
    confirmButton ? confirmButton(selectedCategorys) : setModal(null)
  }

  function handleClearCategories() {
    onValueChange('')
    setSelectedCategorys(['', '', ''])
  }

  function confirmButtonBehaviour() {
    if (!filterModal) {
      if (selectedCategorys[0].name && selectedCategorys[1].name) {
        if (selectedCategorys[1].subcategory) {
          if (selectedCategorys[2].name) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      if (selectedCategorys[0].name) {
        return false
      } else {
        return true
      }
    }
  }

  async function handleFetchCategories() {
    setLoading(true)
    const { data } = await api.get('store/product/categories')
    if (data.CategoryTree.length > 0) {
      setCategoryTree(data.CategoryTree)
      setLoading(false)
    }
  }

  useEffect(() => {
    handleFetchCategories()
  }, [])

  useEffect(() => {
    if (formikProps && formikProps.values.productCategory.length > 0) {
      setSelectedCategorys(formikProps.values.productCategory)
    }

    if (categoryFilter) {
      setSelectedCategorys(categoryFilter)
    }
  }, [])

  return (
    <Modal
      title='Categoria do Produto'
      width={isMobile ? '95vw' : null}
      height='80vh'
    >
      <Container>
        <HeaderContainer>
          <MainInput
            width={isMobile ? '184px' : '31%'}
            placeholder='Pesquisar categoria'
            onChange={(event) => setSearchKey(event.target.value)}
          />
        </HeaderContainer>
        <Content>
          <>
            {
              isMobile
                ?
                <>
                  {
                    current == 0
                      ?
                      <ScrollableArea>
                        {loading ?
                          <LoadingIndicator />
                          :
                          categoryTree?.filter((item) => item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchKey) || item.name.toLowerCase().includes(searchKey)).map((item) => (
                            <CategoryItemContainer
                              key={item.id}
                              selected={selectedCategorys[0].id === item.id}
                              onClick={() => { setSelectedCategorys([item, '', '']); changeCurrent(true) }}
                            >
                              <CategoryItemContent>
                                <CategoryName>
                                  {item.name && item.name || item}
                                </CategoryName>
                                <FiChevronRight size='1.3rem' />
                              </CategoryItemContent>
                            </CategoryItemContainer>
                          ))}
                      </ScrollableArea>
                      :
                      null
                  }

                  {current == 1
                    ?
                    <ScrollableArea>
                      {loading ?
                        <LoadingIndicator />
                        :
                        selectedCategorys[0].subcategory?.length > 0 && selectedCategorys[0].subcategory.map((item) => (
                          <CategoryItemContainer
                            key={item.id}
                            selected={selectedCategorys[1].id === item.id}
                            onClick={() => {setSelectedCategorys(previous => [previous[0], item, item.subcategory && item.subcategory.length > 0 ? previous[2] : '']); changeCurrent(item.subcategory?.length > 0 ? true : false)}}
                          >
                            <CategoryItemContent>
                              <CategoryName>
                                {item.name && item.name || item}
                              </CategoryName>
                              <FiChevronRight color={item.subcategory && item.subcategory?.length > 0 ? 'inherit' : 'transparent'} size='1.3rem' />
                            </CategoryItemContent>
                          </CategoryItemContainer>
                        ))}
                    </ScrollableArea>
                    :
                    null
                  }
                  {current == 2
                    ?
                    <ScrollableArea>
                      {
                        loading ?
                          <LoadingIndicator />
                          :
                          selectedCategorys[1].subcategory?.length > 0 && selectedCategorys[1].subcategory.map((item) => (
                            <CategoryItemContainer
                              key={item.id}
                              selected={selectedCategorys[2].id === item.id}
                              onClick={() => {setSelectedCategorys(previous => [previous[0], previous[1], item]); changeCurrent(false)}}
                            >
                              <CategoryItemContent>
                                <CategoryName>
                                  {item.name && item.name || item}
                                </CategoryName>
                                <FiChevronRight color='transparent' size='1.3rem' />
                              </CategoryItemContent>
                            </CategoryItemContainer>
                          ))}
                    </ScrollableArea>
                    :
                    null
                  }
                </>
                :
                <>
                  <ScrollableArea>
                    {loading ?
                      <LoadingIndicator />
                      :
                      categoryTree?.filter((item) => item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchKey) || item.name.toLowerCase().includes(searchKey)).map((item) => (
                        <CategoryItemContainer
                          key={item.id}
                          selected={selectedCategorys[0].id === item.id}
                          onClick={() => setSelectedCategorys([item, '', ''])}
                        >
                          <CategoryItemContent>
                            <CategoryName>
                              {item.name && item.name || item}
                            </CategoryName>
                            <FiChevronRight size='1.3rem' />
                          </CategoryItemContent>
                        </CategoryItemContainer>
                      ))}
                  </ScrollableArea>

                  <ScrollableArea>
                    {loading ?
                      <LoadingIndicator />
                      :
                      selectedCategorys[0].subcategory?.length > 0 && selectedCategorys[0].subcategory.map((item) => (
                        <CategoryItemContainer
                          key={item.id}
                          selected={selectedCategorys[1].id === item.id}
                          onClick={() => setSelectedCategorys(previous => [previous[0], item, item.subcategory && item.subcategory.length > 0 ? previous[2] : ''])}
                        >
                          <CategoryItemContent>
                            <CategoryName>
                              {item.name && item.name || item}
                            </CategoryName>
                            <FiChevronRight color={item.subcategory && item.subcategory?.length > 0 ? 'inherit' : 'transparent'} size='1.3rem' />
                          </CategoryItemContent>
                        </CategoryItemContainer>
                      ))}
                  </ScrollableArea>

                  <ScrollableArea>
                    {
                      loading ?
                        <LoadingIndicator />
                        :
                        selectedCategorys[1].subcategory?.length > 0 && selectedCategorys[1].subcategory.map((item) => (
                          <CategoryItemContainer
                            key={item.id}
                            selected={selectedCategorys[2].id === item.id}
                            onClick={() => setSelectedCategorys(previous => [previous[0], previous[1], item])}
                          >
                            <CategoryItemContent>
                              <CategoryName>
                                {item.name && item.name || item}
                              </CategoryName>
                              <FiChevronRight color='transparent' size='1.3rem' />
                            </CategoryItemContent>
                          </CategoryItemContainer>
                        ))}
                  </ScrollableArea>
                </>
            }
          </>

        </Content>
        <FooterContainer>
          <Label>Selecionados</Label>
          <FooterContent>
            {
              isMobile
                ?
                <GroupDisplayCategory>
                  <DisplaySelectedCategorie active={selectedCategorys[0]?.name}>
                    <SelectedCategoryName>{selectedCategorys[0]?.name}</SelectedCategoryName>
                  </DisplaySelectedCategorie>
                  <FiChevronDown size='2rem' />
                </GroupDisplayCategory>
                :
                <>
                  <DisplaySelectedCategorie active={selectedCategorys[0]?.name}>
                    <SelectedCategoryName>{selectedCategorys[0]?.name}</SelectedCategoryName>
                  </DisplaySelectedCategorie>
                  <FiChevronRight size='2rem' />
                </>
            }
            {
              isMobile
                ?
                current >= 1
                  ?
                  <GroupDisplayCategory>
                    <DisplaySelectedCategorie active={selectedCategorys[1]?.name}>
                      <SelectedCategoryName>{selectedCategorys[1]?.name}</SelectedCategoryName>
                    </DisplaySelectedCategorie>
                    <FiChevronDown size='2rem' />
                  </GroupDisplayCategory>

                  : null
                :
                <>
                  <DisplaySelectedCategorie active={selectedCategorys[1]?.name}>
                    <SelectedCategoryName>{selectedCategorys[1]?.name}</SelectedCategoryName>
                  </DisplaySelectedCategorie>
                  <FiChevronRight size='2rem' />
                </>
            }
            {
              isMobile
                ?
                current >= 2
                  ?
                  <GroupDisplayCategory>
                    <DisplaySelectedCategorie active={selectedCategorys[2]?.name}>
                      <SelectedCategoryName>{selectedCategorys[2]?.name}</SelectedCategoryName>
                    </DisplaySelectedCategorie>
                    <FiChevronDown size='2rem' />
                  </GroupDisplayCategory>
                  :
                  null
                :
                <>
                  <DisplaySelectedCategorie active={selectedCategorys[2]?.name}>
                    <SelectedCategoryName>{selectedCategorys[2]?.name}</SelectedCategoryName>
                  </DisplaySelectedCategorie>
                  <FiChevronRight size='2rem' />
                </>
            }
          </FooterContent>
        </FooterContainer>
        <ConfirmArea>
          <LinkButtonContent>
            <ButtonLink
              text='Limpar'
              sideIcon='right'
              color={currentTheme.primaryPink}
              onClick={() => { handleClearCategories(); setCurrent(0) }}
            />
          </LinkButtonContent>
          <ButtonPrimary
            text='Confirmar'
            onClick={handleConfirmCategories}
            disabled={confirmButtonBehaviour()}
          />
        </ConfirmArea>
      </Container>
    </Modal >
  )
}

