const LightTheme = {
  // primary
  primaryGreen: '#2C992B',
  mobileGreen: '#E1EBD0',
  primaryPink: '#951159',

  // secondary
  secondaryLightGreen: '#96c444',
  secondaryNeutralGreen: '#63bb45',
  secondaryLightPink: '#F20D8A',
  secondaryPink: '#5a0a27',
  secondaryDarkPurple: '#6b4b55',
  secondaryPurple: '#7b5da3',
  secondaryYellow: '#efb957',
  secondaryBlue: '#7dc4d3',

  // neutral
  black: '#000',
  white: '#fff',
  grayMainText: '#605e70',
  graySecondaryText: '#9D9AAC',
  grayInactivy: '#c1ccc1',
  grayBackgrounds: '#f6f9f9',
  grayButtonBackground: '#EFEEF1',
  
  // status
  statusBlue: '#2f80ed',
  statusGreen: '#01c782',
  statusYellow: '#ffaa15',
  statusRed: '#ff4041',
}

const DarkTheme = {
  // primary
  primaryGreen: '#b87fdc',
  primaryPink: '#885038',
  
  // secondary
  secondaryLightGreen: '#419dda',
  secondaryNeutralGreen: '#9090a6',
  secondaryPink: '#19222e',
  secondaryDarkPurple: '#0d8d0a',
  secondaryPurple: '#61041d',
  secondaryYellow: '#17e49e',
  secondaryBlue: '#683264',
  
  // neutral
  black: '#1f43d7',
  white: '#33383B',
  grayMainText: '#f1df40',
  graySecondaryText: '#ed81ed',
  grayInactivy: '#5920d2',
  grayBackgrounds: '#343F45',
  grayButtonBackground: '#EFE170',

  // status
  statusBlue: '#3ac1bc',
  statusGreen: '#cae6b8',
  statusYellow: '#e860c0',
  statusRed: '#f7dc5b',
}

export { LightTheme, DarkTheme }
