import React from 'react'
import Switch from 'react-switch'
import { useLocation } from 'react-router-dom'
import { FiHome, FiShoppingBag, FiDollarSign, FiLogOut } from 'react-icons/fi'
import { ReactComponent as StoreIcon } from '../../assets/store_icon.svg'

import {
  Container,
  Content,
  HoverContent,
  IconContainer,
  InfoContainer,
  InfoHoverContainer,
  InfoHoverContent,
  Logo,
  LogoContainer,
  Logout,
  Nav,
  PageSubtitle,
  PageTitle,
  StoreContainer,
  StoreContent,
  StoreImage,
  StoreName,
  StoreStatus,
  StoreStatusContainer,
} from './styles'

import data from './data'
import logomarca from '../../assets/logo/Logo_Horizontal.svg'
import { useModal } from '../../context/ModalContext'
import { useAuth } from '../../context/AuthContext'
import { useTheme } from '../../context/ThemeContext'
import ModalOpenMarket from './modalOpen'
import ModalCloseMarket from './modalClose'
import { changeStoreStatus } from '../../api/store'
import ErrorModal from '../modals/errorModal'
import { Modal } from '../modal'
import { ModalLogout } from './ModalLogout'

export default function Navbar() {
  const location = useLocation()
  const { setModal } = useModal()
  const { currentTheme } = useTheme()
  const { user, handleLogout, setUser } = useAuth()

  const ChangestoreOpen = () => {
    if (user.storeData.open) {
      setModal(<ModalCloseMarket closeModal={() => setModal()} closeStore={() => handleChangeStoreStatus(user.storeData.id)} />)
    } else {
      setModal(<ModalOpenMarket closeModal={() => setModal()} openStore={() => handleChangeStoreStatus(user.storeData.id)} />)
    }
  }

  function handleOpenLogout() {
    setModal(
      <Modal width='35vw' height='55vh'>
        <ModalLogout handleClick={() => {
          handleLogout()
          setModal()
        }} />
      </Modal>
    )
  }

  async function handleChangeStoreStatus(id) {
    try {
      const response = await changeStoreStatus(id)
      const newUser = user
      newUser.storeData.open = response.data.open
      setUser(newUser)
      localStorage.setItem('g-admin/userData', JSON.stringify(newUser))

      setModal()
    } catch {
      setModal(<ErrorModal closeModal={() => setModal()} />)
    }
  }

  const homeActived = location.pathname === data.home.link
  const managementActived = location.pathname === data.orders.link
  const historyActived = location.pathname === data.orders.history.link
  const storeActived = location.pathname === data.store.link
  const addProductsActived = location.pathname === data.store.addProduct.link
  const financialActived = location.pathname === data.financial.balance.link || location.pathname === data.financial.access.link

  return (
    <Container>
      <Content>
        <LogoContainer to='/'>
          <Logo src={logomarca} />
        </LogoContainer>

        <Nav>
          <InfoContainer to={data.home.link}>
            <IconContainer active={homeActived}><FiHome size='2vh' color={homeActived ? currentTheme.white : currentTheme.primaryGreen} /></IconContainer>
            <PageTitle active={homeActived}>{data.home.name}</PageTitle>
          </InfoContainer>


          <InfoHoverContainer to={data.orders.management.link}>
            <InfoHoverContent>

              <IconContainer active={historyActived || managementActived}><FiShoppingBag size='2vh' color={historyActived || managementActived ? currentTheme.white : currentTheme.primaryGreen} /></IconContainer>
              <PageTitle active={historyActived || managementActived}>{data.orders.name}</PageTitle>
            </InfoHoverContent>
            <HoverContent>
              <PageSubtitle active={managementActived} to={data.orders.management.link}>{data.orders.management.name}</PageSubtitle>
              <PageSubtitle active={historyActived} to={data.orders.history.link}>{data.orders.history.name}</PageSubtitle>
            </HoverContent>
          </InfoHoverContainer>

          <InfoHoverContainer to={data.store.products.link}>
            <InfoHoverContent>
              <IconContainer active={storeActived || addProductsActived}><StoreIcon width='2vh' height='2vh' stroke={storeActived || addProductsActived ? currentTheme.white : currentTheme.primaryGreen} /></IconContainer>
              <PageTitle active={storeActived || addProductsActived}>{data.store.name}</PageTitle>
            </InfoHoverContent>

            <HoverContent>
              <PageSubtitle active={storeActived} to={data.store.products.link}>{data.store.products.name}</PageSubtitle>
              <PageSubtitle active={addProductsActived} to={data.store.addProduct.link}>{data.store.addProduct.name}</PageSubtitle>
            </HoverContent>
          </InfoHoverContainer>

          <InfoContainer to={data.financial.balance.link}>
            <IconContainer active={financialActived}><FiDollarSign size='2vh' color={financialActived ? currentTheme.white : currentTheme.primaryGreen} /></IconContainer>
            <PageTitle active={financialActived}>{data.financial.name}</PageTitle>
          </InfoContainer>
        </Nav>
      </Content>

      <StoreContainer>
        <StoreContent>
          <StoreImage open={user?.storeData.open} src={user?.storeData.image} />
          <StoreName>{user?.storeData?.fantasyName}</StoreName>
          <Switch
            checked={user?.storeData.open}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor={currentTheme.secondaryLightGreen}
            offColor={currentTheme.primaryPink}
            onChange={ChangestoreOpen}
          />
          <StoreStatusContainer>
            <StoreStatus open={user?.storeData.open}>
              {user?.storeData.open ? 'Loja aberta' : 'Loja fechada'}
            </StoreStatus>
          </StoreStatusContainer>
        </StoreContent>

        <Logout onClick={handleOpenLogout}>
          Sair
          <FiLogOut color={currentTheme.primaryPink} size={20} className='ml-4' />
        </Logout>
      </StoreContainer>

    </Container>
  )
}
