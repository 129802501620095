import React from 'react'
import { FiTrash2 } from 'react-icons/fi'

import {
  Container,
  Icon,
  ImageContainer,
  TextContainer,
  AccountInfo,
  AccountType,
  Bank,
  InfoContainer,
  BankContainer
} from './styles'

import { ReactComponent as BankSvg } from '../../../../assets/bankIcon.svg'
import { useTheme } from '../../../../context/ThemeContext'

export default function BankAccountItem({ bank, type, account, agency, click }) {
  const { currentTheme } = useTheme()

  return (
    <BankContainer>
      <Container>
        <ImageContainer>
          <BankSvg fill={currentTheme.white} width='3vh' height='auto'/>
        </ImageContainer>

        <InfoContainer>
          <TextContainer>
            <Bank>{bank} • </Bank>
            <AccountType>{type}</AccountType>
          </TextContainer>

          <TextContainer>
            <AccountInfo>Ag. {agency}</AccountInfo>
            <AccountInfo mLeft='1vw'>Conta {account}</AccountInfo>
          </TextContainer>
        </InfoContainer>

      </Container>
      {/* <Icon onClick={click}><FiTrash2 /></Icon> */}
    </BankContainer>
  )
}
