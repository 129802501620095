const formModel = {
  requiredErrorMsg: 'Este campo é obrigatório.',
  formField: {
    userName: {
      name: 'userName',
    },
    number: {
      name: 'number',
      minError: 'O telefone deve ter pelo menos ${min} digitos'
    },
    email: {
      name: 'email',
      notValidError: 'Por favor, digite um email válido',
    },
    description: {
      name: 'description',
      minError: 'A descrição deve ter pelo menos ${min} caracteres'
    },
    selectedDocs: {
      name: 'selectedDocs',
    }
  }
}

export default formModel
