import { Form } from 'formik'
import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  margin-top: 3%;
  padding-left: 1.5%;
  display: flex;
  flex-direction: column;
`

export const Text = styled.h3`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${(props) => props.theme.grayMainText};
  margin-bottom: 2%;
`

export const SendContent = styled.div`
  width: 35%;
`

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const SendedText = styled.span`
  font-weight: 700;
  font-size: 1.2rem;
  color: ${(props) => props.theme.primaryGreen};
  margin: 1% 0;
  `

export const InfoText = styled.span`
  font-weight: 400;
  color: ${(props) => props.theme.grayMainText};
  font-size: 1rem;
  margin-top: 2%;
`

export const FormArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ErrorMsg = styled.span`
  font-size: 1rem;
  color: ${props => props.theme.statusRed};
  align-self: flex-start;
  margin-bottom: ${props => props.mBottom || 0};
`
