import React from 'react'
import {
  Container,
  ProductImgArea,
  DescriptionArea,
  Title,
  AmmountTitle,
} from './styles'

export function StockItem({ productImg, productName, productQuantity }) {
  return (
    <Container>
      <ProductImgArea src={productImg} />
      <DescriptionArea>
        <Title>{productName}</Title>
        <AmmountTitle>{`${productQuantity} unidades`}</AmmountTitle>
      </DescriptionArea>
    </Container>
  )
}
