import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'

import {
  Content,
} from './styles'

import FirstStep from '../modalSteps/firstStep'
import SecondStep from '../modalSteps/secondStep'
import ThirdStep from '../modalSteps/thirdStep'
import FourStep from '../modalSteps/fourStep'
import { useModal } from '../../../../context/ModalContext'
import { useAuth } from '../../../../context/AuthContext'
import ModalNewAccount from '../../../settings/bankAccounts/modalNewAccount'

export function ModalWithdrawBalance() {
  const { user } = useAuth()
  const { setModal } = useModal()

  const [currentStep, setCurrentStep] = useState(0)
  const [selectedWithdrawalAmount, setSelectedWithdrawalAmount] = useState(0)

  function openNewAccountModal() {
    setModal(<ModalNewAccount />)
  }

  function Steps(formikProps) {
    switch (currentStep) {
    case 0:
      return <FirstStep
        withdrawalAmount={selectedWithdrawalAmount}
        increaseValue={handleValueIncrease}
        setValue={setSelectedWithdrawalAmount}
        formProps={formikProps}
      />
    case 1:
      return <SecondStep
        backStep={handleBackStep}
        formProps={formikProps}
        openNewAccountModal={openNewAccountModal}
      />
    case 2:
      return <ThirdStep
        backStep={handleBackStep}
        formProps={formikProps}
      />

    case 3:
      return <FourStep
        withdrawalAmount={selectedWithdrawalAmount}
        handleCloseModal={() => setModal(null)}
        formProps={formikProps}
        setCurrentStep={setCurrentStep}
      />
    }
  }

  function handleNextStep() {
    setCurrentStep(currentStep + 1)
  }

  function handleBackStep() {
    setCurrentStep(currentStep - 1)
  }

  function handleValueIncrease(withdrawalQuantity, value) {
    return Number(withdrawalQuantity) + Number(value)
  }

  function handleConfirmWithdrawal() {
    if (currentStep === 4) {
      return
    }
    handleNextStep()
  }

  const validationSchema = (index) => {

    const validation = [
      Yup.object().shape({
        withdrawalQuantity: Yup.number().min(user.storeData?.wallet?.valueAvailableForWithdraw).required(),
        checkoutMethod: Yup.string().required('Agora, selecione um método de pagamento!')
      }),
      Yup.object().shape({
        accountForWithdrawal: Yup.object().required()
      }),
      Yup.object().shape({
        code: Yup.string().min(6).max(6).required()
      }),
    ]

    return validation[index]
  }

  return (
    <Content>
      <Formik
        initialValues={{
          withdrawalQuantity: 0,
          maxWithdrawalQuantity: user.storeData?.wallet?.valueAvailableForWithdraw,
          accountForWithdrawal: '',
          checkoutMethod: '',
          code: ''
        }}
        validationSchema={validationSchema(currentStep)}
        onSubmit={() => handleConfirmWithdrawal()}
      >
        {(formikProps) =>
          Steps(formikProps)
        }
      </Formik>
    </Content >
  )
}
