import styled from 'styled-components'

export const Input = styled.input`
  margin-top: ${({ mTop }) => mTop || 0};
  width: 100%;
  height: ${props => props.height || '6vh'};
  border: 1px solid ${props => props.error ? props.theme.statusRed : props.theme.graySecondaryText};
  border-radius: 6px;
  font-size: 1.4rem;
  padding: 0 0.5vw;
  background-color: ${({ backgroundColor, theme }) => backgroundColor ? backgroundColor : theme.white};
  :focus {
    border: 1px solid ${props => props.theme.primaryGreen};
    box-shadow: 0px 8px 24px rgba(94, 135, 138, 0.1);
  }

  ::placeholder {
    color: ${(props) => props.theme.graySecondaryText};
  } 
  
  :disabled {
    opacity: 0.5;
  }
`

export const ErrorMsg = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.statusRed};
`
