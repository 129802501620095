import styled from 'styled-components'

export const Grid = styled.section`
  display:grid;
  background-color:#fff;
  margin: 0;
  padding: 0;
  user-select: none;
  z-index: 1;
  grid-template-areas: ${props=>props.area};
  font-family: "Nunito", sans-serif;
  background-color: ${({ theme }) => theme.white};
`