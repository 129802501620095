import * as Yup from 'yup'
import { formModel } from './FormModel'

const {
  formFields: {
    productName,
    productDescription,
    productCategory,
    productVariation,
    productPrice,
    productUnits,
    productVariationName,
    productDimensions,
    productImages
  },
  requiredErrorMsg,
  requiredOptionErrorMsg,
  maskInputMsg,
} = formModel

const validationSchema = [
  Yup.object().shape({
    [productName.name]: Yup.string().required(requiredErrorMsg),
    [productImages.name]: Yup.array().required().min(1, 'Você precisa adicionar até 1 imagem').max(1, 'Limite máximo de 1 imagem').required(requiredErrorMsg),
    [productCategory.name]: Yup.array().required(requiredErrorMsg),
    [productVariation.name]: Yup.array().optional(),
    [productPrice.name]: Yup.string().required(requiredErrorMsg),
    [productVariationName.name]: Yup.string().optional()
  }),
  Yup.object().shape({
    [productDimensions.name]: Yup.object().shape({
      width: Yup.number().positive().min(1, requiredErrorMsg),
      height: Yup.number().positive().min(1, requiredErrorMsg),
      length: Yup.number().positive().min(1, requiredErrorMsg),
      weight: Yup.number().min(0.01, requiredErrorMsg)
    }),
    [productDescription.name]: Yup.string().required(requiredErrorMsg),
    [productUnits.name]: Yup.number().positive().min(1, 'Você precisa adicionar no mínimo 1 unidade').required(requiredErrorMsg)
  }),
]

export default validationSchema
