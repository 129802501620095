import React, { useState, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'

import { Container, Label, ContainerList, List, ListAmount, LoadingArea } from './PendingStyles'
import { OPEN } from '../../../Utils'

import { useAuth } from '../../../context/AuthContext'
import { useTheme } from '../../../context/ThemeContext'

import { getPurchases } from '../../../api/purchases'

import { Item } from '../steps/Steps'
import { CenterDiv, NoProductsText } from '../steps/StepsStyles'
import EmptyIcon from '../../../assets/empty.svg'

export default function Pending({ area, setFocusItem, focusItem, isMobile }) {
  const {
    user: { storeData },
  } = useAuth()
  const { currentTheme } = useTheme()
  const { secondaryLightGreen, primaryPink, grayBackgrounds } = currentTheme

  const [pendingPurchases, setPendingPurchases] = useState('')

  async function fetchPurchases(status, setPurchase) {
    const response = await getPurchases(storeData.id, { status })
    setPurchase(response.data)
  }

  useEffect(() => {
    fetchPurchases(OPEN, setPendingPurchases)
  }, [])

  return (
    <Container area={area}>
      <Label>
        Pedidos pendentes
        <ListAmount>{pendingPurchases.length} {pendingPurchases.length === 1 ? 'pedido' : 'pedidos'}</ListAmount>
      </Label>
      <ContainerList>
        <List>
          {pendingPurchases ? pendingPurchases.length === 0 ? (
            <CenterDiv>
              <img src={EmptyIcon} alt='Empty Icon' />
              <NoProductsText>Nenhum produto nesta etapa</NoProductsText>
            </CenterDiv>
          ) : (
            pendingPurchases.map((item, index) => {
              return (
                <Item
                  key={index}
                  item={item}
                  color={primaryPink}
                  hideName
                  backgroundColor={grayBackgrounds}
                  active={focusItem.index === index && focusItem.data.status === item.status}
                  click={() => setFocusItem({ data: item, index })}
                  isMobile={isMobile}
                />
              )
            })
          ) : (
            <LoadingArea>
              <CircularProgress style={{ color: secondaryLightGreen }} />
            </LoadingArea>
          )}
        </List>
      </ContainerList>
    </Container>
  )
}
