import React from 'react'
import { useField } from 'formik'

import { InputContainer, Input, ErrorMessage } from '../Styles'

export function TextField({...props}) {
  const [field, meta] = useField(props)

  return (
    <InputContainer {...props}>
      <Input  
        type='text' 
        error={meta.touched && meta.error && true}
        {...field} 
        {...props}
      />
      { <ErrorMessage show={meta.touched && meta.error && true}>
        {meta.error}
      </ErrorMessage> }
    </InputContainer>
  )
}
