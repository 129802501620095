import React from 'react'
import { Modal } from '../modal'

import {
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalImg,
  ModalImgContainer,
  ModalText,
  ModaltextsContainer,
  ModalTitle,
  ModalSpan
} from './styles'

import Helpersvg from '../../assets/Helper.svg'
import ButtonPrimary from '../buttons/buttonPrimary'
import ButtonSecondary from '../buttons/buttonSecondary'

import { useTheme } from '../../context/ThemeContext'

export default function ModalOpenMarket({ closeModal, openStore }) {
  const { currentTheme } = useTheme()

  function handleConfirm() {
    openStore()
  }
  return (
    <Modal title='Abrir loja'>
      <ModalContainer>
        <ModalContent>

          <ModalImgContainer>
            <ModalImg src={Helpersvg} />
          </ModalImgContainer>

          <ModaltextsContainer>
            <ModalTitle>
              Gostaria de <ModalSpan color={currentTheme.primaryGreen}>abrir</ModalSpan> {'\n'}
              sua loja agora?
            </ModalTitle>
            <ModalText>
              Ao abrir sua loja seus produtos ficarão
              disponiveis para os clientes da Gowdock.
            </ModalText>

          </ModaltextsContainer>

        </ModalContent>

        <ModalFooter>
          <ButtonSecondary
            width='13vw'
            height='7vh'
            smallFont
            text='Cancelar'
            onClick={closeModal}
          />

          <ButtonPrimary
            width='13vw'
            height='7vh'
            smallFont
            text='Abrir loja'
            onClick={handleConfirm}
          />
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}
