import styled from "styled-components";

export const Container = styled.div`
    margin: 0 10px;
    cursor: pointer;
`;

export const Icon = styled.img`
    width: 18px;
    height: auto;
`;