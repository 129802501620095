import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    text-align: center;
    padding: 50px;
    max-width: 600px;
    margin: auto;
    font-family: Arial, sans-serif;
`;

const Title = styled.h1`
    font-size: 2em;
    color: #333;
`;

const Description = styled.p`
    font-size: 1.2em;
    color: #666;
`;

const InstallButton = styled.button`
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #45a049;
    }
`;

const Tray = () => {
    const handleInstallClick = () => {
        alert("Integração com o aplicativo iniciada!");
    };

    return (
        <Container>
            <Title>Bem-vindo ao Nosso Aplicativo</Title>
            <Description>
                Descubra todas as funcionalidades que oferecemos para otimizar a sua experiência.
                Integre agora e aproveite todos os benefícios!
            </Description>
            <InstallButton onClick={handleInstallClick}>
                Instale agora
            </InstallButton>
        </Container>
    );
};

export default Tray;
