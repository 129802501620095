import React from 'react'
import ButtonPrimary from '../buttons/buttonPrimary'
import ButtonSecondary from '../buttons/buttonSecondary'
import { Modal } from '../modal'
import { useTheme } from '../../context/ThemeContext'

import {
  ButtonsContainer,
  Container,
  Text
} from './styles'

export default function CancelModal({ text, cancelText, confirmText, confirmFunction, cancelFunction }) {
  const { primaryPink, secondaryLightPink } = useTheme().currentTheme

  return (
    <Modal width='28vw' height='28vh' noHeader>
      <Container>

        <Text>
          {text || 'Deseja cancelar todas as alterações?'}
        </Text>
        <ButtonsContainer>
          <ButtonSecondary
            onClick={cancelFunction}
            text={cancelText || 'Não'}
            width='8vw'
            height='6vh'
            smallFont
          />
          <ButtonPrimary
            onClick={confirmFunction}
            text={confirmText || 'Sim'}
            width='8vw'
            height='6vh'
            smallFont
            background={primaryPink}
            backgroundHover={secondaryLightPink}
          />
        </ButtonsContainer>

      </Container>
    </Modal>
  )
}
