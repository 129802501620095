import React, { useRef, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { FiMail, FiHeadphones, FiSettings } from 'react-icons/fi'
import { useModal } from '../../context/ModalContext'
import { useTheme } from '../../context/ThemeContext'
import { useStore } from '../../context/StoreDataContext'
import {
  Container,
  Subtitle,
  Divisor,
  TextContainer,
  Title,
  NotificationAdvice,
  NavigationContainer,
  NavigationButton,
  MobileContainer,
  Logo,
  MenuModal,
  ButtonGroup,
  Button,
  ButtonIcon,
  ButtonText,
  Icon
} from './styles'

import ModalMessage from '../modalMessage/ModalMessage'
import Hamburguer from '../hamburguerMenu'
import LogoHorizontal from '../../assets/logo/Logo_Horizontal.svg'
import mobileHome from '../../assets/mobileHome.svg'
import mobileOrder from '../../assets/mobileOrder.svg'
import mobileStore from '../../assets/mobileStore.svg'
import mobileFinances from '../../assets/mobileFinances.svg'
import settings from '../../assets/settings.svg'
import bell from '../../assets/bell.svg'

export default function Header({ title, subtitle }) {
  const { setModal } = useModal()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const { currentTheme } = useTheme()
  const { notifications } = useStore()

  function messageClick() {
    setModal(<ModalMessage />)
  }

  const [isActive, setIsActive] = useState(false)

  const toggleMenu = () => {
    setIsActive(!isActive)
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 650);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    window.addEventListener('resize', handleResize);

    // Limpe o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const buttonClick = (ref) => {
    push(`${ref}`)
    setIsActive(false)
  }

  return (
    <>
      {
        isMobile
          ?
          <MobileContainer>
            <MenuModal display={isActive ? 'flex' : 'none'}>
              <ButtonGroup>
                <Button onClick={() => buttonClick('/')}>
                  <ButtonIcon src={mobileHome} />
                  <ButtonText>Início</ButtonText>
                </Button>
                <Button onClick={() => buttonClick('/management')}>
                  <ButtonIcon src={mobileOrder} />
                  <ButtonText>Seus Pedidos</ButtonText>
                </Button>
                <Button onClick={() => buttonClick('/products')}>
                  <ButtonIcon src={mobileStore} />
                  <ButtonText>Sua Loja</ButtonText>
                </Button>
                <Button onClick={() => buttonClick('/financial')}>
                  <ButtonIcon src={mobileFinances} />
                  <ButtonText>Financeiro</ButtonText>
                </Button>
              </ButtonGroup>
              <Button onClick={() => buttonClick('/config')}>
                <ButtonIcon src={settings} />
                <ButtonText>Configurações</ButtonText>
              </Button>
            </MenuModal>
            <Hamburguer toggleMenu={toggleMenu} />
            <Logo src={LogoHorizontal} />
            <Icon src={bell}/>
          </MobileContainer>
          :
          <Container>
            <TextContainer>
              <Title>{title}</Title>
              {subtitle && (
                <>
                  <Divisor> • </Divisor>
                  <Subtitle>{subtitle}</Subtitle>
                </>
              )}
            </TextContainer>
            <NavigationContainer>
              <NavigationButton onClick={messageClick}>
                <FiMail color={currentTheme.grayMainText} />
                {
                  notifications.amountUnread > 0 &&
                  <NotificationAdvice>
                    {notifications.amountUnread}
                  </NotificationAdvice>
                }
              </NavigationButton>
              <NavigationButton onClick={() => push('/doubt')}>
                <FiHeadphones color={pathname === '/doubt' || pathname === '/contact-us' ? currentTheme.primaryGreen : currentTheme.grayMainText} />
              </NavigationButton>
              <NavigationButton onClick={() => push('/config')}>
                <FiSettings color={pathname === '/config' ? currentTheme.primaryGreen : currentTheme.grayMainText} />
              </NavigationButton>
            </NavigationContainer>

          </Container>
      }
    </>
  )
}
