import React from 'react'
import { FiImage, FiCircle } from 'react-icons/fi'
import {
  Container,
  Content,
  HeaderInfo,
  MessageContainer,
  MessageContent,
  MessageImgBg,
  MessageTextContent,
  Overflow,
  Title,
  Description,
  ButtonContainer,
  ReadContainer
} from './ModalMessageStyle'
import { ReactComponent as GowdockIcon } from '../../assets/white_logo.svg'

import { Modal } from '../modal'
import ButtonLink from '../buttons/buttonLink'
import { useTheme } from '../../context/ThemeContext'
import { useStore } from '../../context/StoreDataContext'

export default function ModalMessage() {
  const { currentTheme } = useTheme()
  const { notifications, handleReadInboxMessage } = useStore()

  return (
    <Modal
      width='40vw'
      height='70vh'
      title='Notificações'
    >
      <Container>
        <HeaderInfo>
          {`${notifications.amountUnread} de ${notifications.data.length} Não lidas`}
        </HeaderInfo>
        <Overflow>
          <Content>
            {notifications && notifications.data.map((item) => (
              <MessageContainer key={item.id}>
                <MessageContent>
                  <ReadContainer>
                    <FiCircle size='70%' stroke={null} fill={!item.read ? currentTheme.primaryPink : 'transparent'} />
                  </ReadContainer>
                  <MessageImgBg bgColor={currentTheme.primaryGreen}>
                    {!item.img ?
                      <GowdockIcon width='50%' height='50%' />
                      :
                      <FiImage size='50% ' color={currentTheme.grayInactivy} />
                    }
                  </MessageImgBg>
                  <MessageTextContent>
                    <Title>
                      {item.title}
                    </Title>
                    <Description>
                      {item.content}
                    </Description>
                    <ButtonContainer>
                      <ButtonLink
                        text='Marcar como lida'
                        onClick={() => handleReadInboxMessage(item.id)}
                      />
                    </ButtonContainer>
                  </MessageTextContent>
                </MessageContent>
              </MessageContainer>
            ))}
          </Content>
        </Overflow>
      </Container>
    </Modal>
  )
}
