import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${({theme }) => theme.white};
`

export const AccessArea = styled.div`
  flex: 1;
  padding: 0 12vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Image = styled.img`
  height: auto;
  width: 30vw;
  `

export const InputArea = styled.div`
  height: auto;
  width: 30vw;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Title = styled.h3`
  color: ${({ theme }) => theme.grayMainText};
  font-size: 40px;
  font-weight: 600;
  `

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.grayMainText};
  font-size: 16px;
  font-weight: 500;
`

export const AccessInput = styled.input`
  width: 90%;
  height: 7vh;
  border-radius: 10px;
  padding: 0 15px;
  border: 1px solid ${({theme}) => theme.primaryGreen};
  font-size: 16px;
  color: ${({ theme }) => theme.grayMainText};
  margin: 5vh 0;
  background-color: transparent;
`
