import styled from 'styled-components'

export const SeeMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  `

export const SeeMoreContent = styled.div`
  flex: 1;
  padding: 5% 10%;
`

export const AccordionDescription = styled.p`
  font-weight: 600;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.grayMainText}
  `
