import styled from 'styled-components'

export const InsiderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.primaryGreen};
`

export const SubTitle = styled(Title)`
  font-size: 2rem;
  color: ${({ theme }) => theme.grayMainText};
`
