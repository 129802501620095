import React from 'react'
import { FiPlus } from 'react-icons/fi'

import { Container, Text } from './styles'

export default function ButtonSecondaryIconLeft({ width, height, text, color, colorHover, icon, smallFont,borderRadius, ...rest }) {
  return (
    <Container
      width={width}
      height={height}
      colorHover={colorHover}
      color={color}
      borderRadius={borderRadius}
      {...rest}
    >
      {icon || <FiPlus />}
      <Text smallFont={smallFont}>
        {text}
      </Text>
    </Container>
  )
}
