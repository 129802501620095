import React, { createContext, useContext, useState, useEffect } from 'react'
import { useSubscription, gql } from '@apollo/client'
import { useAuth } from './AuthContext'
import OnPurchaseCreatedNotificationSound from '../assets/sounds/OnPurchaseCreatedNotification.mp3'
import { getInbox, readInboxMessage } from '../api/store'
import { useTabNotification } from '../hooks/useTabNotification'

const StoreContext = createContext({})

export function StoreProvider({ children }) {
  const { user, handleUpdateUserField, authenticated } = useAuth()
  const [storeOpen, setStoreOpen] = useState(false)

  const [notifications, setNotifications] = useState({ data: [], amountUnread: 0 })

  const soundEffect = new Audio(OnPurchaseCreatedNotificationSound)

  function changeStoreState() {
    setStoreOpen(!storeOpen)
  }

  const Subiscription = gql`
  subscription OnPurchaseCreated($channelId: ID) {
    onPurchaseCreated(channelId: $channelId) {
      id,
      storeOrder {
        id,
        products {
          image
        }
      },
      client {
        name
      },
      createdAt
    }
   } 
`

  const { data: newOrderNotification, error, loading } = useSubscription(Subiscription, {
    variables: {
      channelId: user?.storeData?.id
    }
  })

  const { setNotification, clearNotification } = useTabNotification(3000, authenticated)

  async function handleReadInboxMessage(messageId) {
    const response = await readInboxMessage(user?.storeData?.id, messageId)
    if (response.status === 200) {
      handleUpdateUserField({ inbox: response.data })
      updateInbox(response.data)
    }
  }

  useEffect(() => {
    async function handleFetchInbox() {
      const { data } = await getInbox(user?.storeData?.id)
      updateInbox(data)
    }

    if (authenticated) {
      handleFetchInbox()
    }
  }, [newOrderNotification])

  function updateInbox(data) {
    const totalUnread = data.filter((item) => !item.read).length
    setNotifications({ data, amountUnread: totalUnread })
    if (totalUnread > 0) {
      setNotification(`📨 (${totalUnread}) Novas mensagens`)
      soundEffect.play()
    } else {
      clearNotification()
    }
  }

  return (
    <StoreContext.Provider value={{
      changeStoreState,
      storeOpen,
      newOrderNotification,
      notifications,
      handleReadInboxMessage,
    }}>
      {children}
    </StoreContext.Provider>
  )
}

export function useStore() {
  const context = useContext(StoreContext)

  return context
}
