import React from 'react'
import { CircularProgress } from '@material-ui/core'
import {
  Container,
} from './styles'
import { useTheme } from '../../context/ThemeContext'

export default function LoadingIndicator() {

  const { currentTheme: { primaryGreen } } = useTheme()

  return (
    <Container>
      <CircularProgress
        style={{
          color: primaryGreen,
          justifySelf: 'center',
          alignSelf: 'center'
        }}
      />
    </Container>
  )
}
