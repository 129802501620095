import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.button`
  width: ${props => (props.width ? props.width : '15vw')};
  height: ${props => (props.height ? props.height : '7vh')};
  background: ${props => (props.background ? props.background : props.theme.primaryGreen)};
  transition: 0.3s;
  color: ${props => (props.color ? props.color : props.theme.white)};
  font-weight: 600;
  font-size: ${props => (props.smallFont ? '1.4rem' : '1.6rem')};
  margin-bottom: ${props => props.mBottom || 0};
  margin-top: ${props => props.mTop || 0};
  border-radius: 8px;

  :hover {
    background: ${props => (props.backgroundHover ? props.backgroundHover : props.theme.secondaryLightGreen)};
  }

  :disabled {
    background: ${({ theme }) => theme.grayInactivy};
    cursor: default;
  }

  @media ${device.mobileL} {
    width: 120px;
    height: 37.58px;
    font-size: 16px;
  }
`
