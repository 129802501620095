import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    width: 100vw;
    padding: 0 20px;
    margin: 2.5% 0 0 0;
  }
`

export const FilterContent = styled.div`
  width: 100%;
  height: 15%;
  margin-bottom: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media ${device.mobileL} {
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 1.2%;
  }
`

export const Content = styled.div`
  height: 33vh;
  width: 100%;
  overflow-y: auto;

  border-radius: 16px 16px 48px 16px;
  background-color: ${({ theme }) => theme.grayBackgrounds};

  @media ${device.mobileL} {
    height: 278px;
    margin-top: 1.2%;
    border-radius: 0px
  }
`

export const ListLoadingContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProductsAlert = styled.span`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme }) => theme.graySecondaryText};

  align-self: center;
`

export const ManageBoxContainer = styled.div`
  border: 2px solid ${({ bgColor }) => bgColor};
  border-radius: 10px;
  padding: 0 2%;
  width: 23%;
  height: 100%;
  cursor: pointer;
  background-color: ${({ active, bgColor }) => active && bgColor || 'transparent'};

  color: ${({ active, theme, bgColor }) => active && theme.white || bgColor}; 
  :hover {
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ theme }) => theme.white};
  }
  transition: 0.3s;

  @media ${device.mobileL} {
    width: 48%;
    height: 10vw;
  }
`

export const ManageBoxHeader = styled.div`
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  color: inherit;
  font-weight: 600;
  font-size: 2rem;

  @media ${device.mobileL} {
    font-size: 1.5rem;
    margin: 1.5px 0.5px 0 0;
  }
`

export const ManageBoxFooter = styled.div`
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  color: inherit;
  font-weight: 600;
  font-size: 1.4rem;
`
