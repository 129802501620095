import FormModel from './FormModel'
const {
  formField: {
    name, 
    document,
    bank,
    accountType,
    agency,
    account,
    accountDigit,
    code
  }
}  = FormModel

export default {
  [name.name]: '', 
  [document.name]: '',
  [bank.name]: '',
  [accountType.name]: '',
  [agency.name]: '',
  [account.name]: '',
  [accountDigit.name]: '',
  [code.name]: '',
}