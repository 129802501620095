import StepConnector from '@material-ui/core/StepConnector'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import colors from '../../styles/colors'
import { device } from '../../styles/sizes'

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.mobileL} {
    padding: 0;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 3vh;

  @media ${device.mobileL} {
    width: 80vw;
  }
`

export const Title = styled.h2`
  font-size: 2.3rem;
  font-weight: 400;
  color: ${colors.title_purple};
  margin-bottom: 2.5rem;
`

export const Subtitle = styled.p`
  font-size: 1.875rem;
  margin-top: 20vh;
  margin-bottom: 2.5rem;
  font-weight: bold;
`
export const MiniTitle = styled.p`
  font-size: 1rem;
  margin-top: ${({ mtop }) => mtop};
  margin-bottom: ${({ mbottom }) => mbottom};
  font-weight: bold;
`
export const Helper = styled.p`
  font-size: 1rem;
  color: #666666;
`
export const Description = styled.p`
  font-size: 1.875rem;

  margin-bottom: 2.5rem;
`

export const MediaSocialLogin = styled.div`
  display: flex;
  margin-top: 2rem;
`

export const CircleButton = styled.button`
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  outline: 0;
  border: 2px solid ${colors.title_purple};
  background: #fff;
  transition: 0.3s;
  margin-right: 1rem;
  color: ${colors.title_purple};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${colors.title_purple};
    color: white;
  }
`

export const InputBox = styled.input`
  width: ${({ width }) => (width ? width : '10vh')};
  height: 6.2vh;
  border-radius: 6px;
  border: 1px solid #cacaca;
  outline: 0;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  background: #f5f6f4;
`

export const RememberContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.mbottom};
`

export const CheckError = styled.p`
  font-size: 1rem;
  color: ${colors.statusRed};
  margin-bottom: ${(props) => props.margin ? '0' : '3vh'};
`

export const CheckInput = styled.input``

export const Text = styled.label`
  margin-left: 0.5rem;
  font-size: 0.85rem;
  cursor: pointer;
  font-weight: 600;
`

export const Button = styled.button`
  visibility: ${props => props.visibility};
  width: 15vw;
  height: 6.7vh;
  outline: 0 !important;
  border: 0;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.3s;
  background: ${colors.span_green};
  color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 6px;
  margin-top: ${({ mtop }) => mtop};
  &:hover {
    background: ${colors.secondary_green};
  }

  @media ${device.mobileL} {
    width: 100%;
    height: 6vh;
  }
`

export const BackButton = styled.button`
  height: 6.7vh;
  width: 15vw;
  outline: 0 !important;
  border: 1px solid ${colors.pink};
  border-top-left-radius: 25px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 6px;
  font-size: 1.5rem;
  background: #fff;
  color: ${colors.pink};
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: ${colors.pink};
    color: white;
    border: 0;
  }
`

export const ForgotPassword = styled(Link)`
  align-self: center;
  font-size: 1.2rem;
  color: ${colors.light_green};
  text-decoration: 0;
  transition: 0.3s;

  &:hover {
    color: ${colors.secondary_green};
  }
`

export const FormAndStep = styled.div`
  flex-direction: column;
`

export const LeftBanner = styled.section`
  display: flex;
  flex-direction: column;
  width: 25vw;
  height: 100vh;
  padding: 0vw;
  background-color: #f4f4f4;

  .logo {
    position: fixed;
    margin-top: 0vh;
    margin-left: 0vw;
  }

  .illustration {
    position: fixed;
    display: block;
    top: 30vh;

    max-width: 25vw;
    min-width: 10vw;
    max-height: 30vh;
    min-height: 15vh;
    margin-top: 20vh;
    margin-left: -3vw;
  }
`

export const SteperContainer = styled.div``

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`

export const Input = styled.input`
  height: 6.5vh;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${({ error }) => (error ? 'red' : '#cacaca')};
  outline: 0;
  padding-left: 2vh;
  margin-top: ${({ mtop }) => mtop};
  /* margin-bottom: 3vh; */
  font-size: 1.2rem;
  font-weight: 500;
  background: #f5f6f4;
  transition: 0.3s;

  :focus {
    border: 1px solid ${colors.light_green};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    background: #fff;
  }

  &::placeholder {
    color: #cacaca;
  }
`

export const ErrorMessage = styled.span`
  font-size: 1rem;
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin: 0;
  color: red;
`

export const SmallInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const LogoContainer = styled.div`
  display: flex;
  margin-top: ${({ mtop }) => mtop};
  margin-bottom: ${({ mbottom }) => mbottom};
`

export const CircleImg = styled.img`
  height: 13vh;
  width: 13vh;
  border-radius: 50%;
  margin-right: 3vw;
  border: 1px solid #cacaca;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const InfoText = styled.span`
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
  margin-bottom: 2vh;
`
export const InfoButtonsContainer = styled.div`
  display: flex;
`

export const ChangeButton = styled.label`
  height: 5vh;
  width: 7.25vw;
  outline: 0 !important;
  background: ${colors.light_green};
  border-top-left-radius: 20px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 1vw;
  transition: 0.3s;
  cursor: pointer;

  :hover {
    background: ${colors.span_green};
  }
`
export const RemoveButton = styled.button`
  height: 5vh;
  width: 7.25vw;
  outline: 0 !important;
  border: 1px solid ${colors.pink};
  border-top-left-radius: 20px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.pink};
  margin-left: 1vw;
  transition: 0.3s;

  :hover {
    border: 0;
    color: white;
    background: ${colors.pink};
  }
`

export const AdviceInput = styled.span`
  font-size: 1rem;
  color: #666;
  margin: 0;
  padding: 0;
  margin-top: 1vh;
  margin-bottom: 10vh;
`

export const RadioLabel = styled.label`
  height: 6.7vh;
  width: 13vw;
  cursor: pointer;
  border: 1px solid ${colors.light_green};
  border-radius: 6px;
  font-size: 1.2rem;
  color: #666;
  display: flex;
  align-items: center;
  margin-right: 5vh;

  ::before {
    content: '';
    height: 16px;
    width: 16px;
    border: 1px solid ${colors.light_green};
    border-radius: 50%;
    margin: 0 2vh;
  }
`

export const RadioInput = styled.input`
  display: none;
  transition: 0.3s;

  :checked + ${RadioLabel} {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  }

  :checked + ${RadioLabel}::before {
    background: ${colors.light_green};
  }
`

export const WaitingCodeContainer = styled.div`
  height: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 10vh;
`

export const AdviceText = styled.span`
  font-size: ${props => props.size};
  color: ${props => props.color};
  font-weight: ${props => props.fweight};
  margin: 0;
  padding: 0;
  margin-top: ${props => props.mtop};
  margin-bottom: ${props => props.mbottom};
`

export const RadioButtonContainer = styled.div`
  display: flex;
  margin-top: ${props => props.mtop};
`

export const FinishedText = styled.span`
  font-size: 1.5rem;
  font-weight: ${({ fweight }) => fweight};
  color: #000;
  margin: 0;
  padding: 0;
  margin-bottom: 1vh;
  margin-top: ${props => props.mtop};
`

export const TermsLink = styled.button`
  color: ${colors.light_green};
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: 0;
`

export const InputFile = styled.input`
  display: none;
`

export const FileLabel = styled.label`
  height: 6.5vh;
  width: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  background: #f5f6f4;
  border: 1px solid
    ${({ active }) => (!active ? colors.secondary_green : colors.light_green)};
  color: ${({ active }) => (!active ? colors.light_green : colors.light_green)};
`

export const ChildContainer = styled.div`
  max-width: 37vw;

  @media ${device.mobileL} {
    align-items: center;
    justify-content: cenrter;
    max-width: 100vw;
  }
`

export const StepperContainer = styled.div`
  margin-right: -100px;
  margin-top: 100px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StepperConnector = withStyles({
  alternativeLabel: {
    top: 15,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    color: colors.light_green,
    '& $line': {
      borderColor: colors.light_green,
    },
  },
  completed: {
    '& $line': {
      borderColor: colors.light_green,
    },
  },
  line: {
    height: '10vh',
    borderColor: colors.gray_background,
    borderLeftWidth: 2,
  },
})(StepConnector)

export const stepperStyles = makeStyles({
  root: {
    color: colors.gray_background,
    display: 'flex',
    height: 20,
    width: 20,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    alignItems: 'center',
  },
  active: {
    color: colors.light_green,
    height: 25,
    width: 25,
  },
  completed: {
    color: colors.light_green,
    zIndex: 1,
    fontSize: 18,
  },
  label: {
    color: colors.light_green,
  },
})

export const Select = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  > p {
    padding: 0 12%;
  }
  > img {
    margin-right: 7%;
  }
`

export const SelectContainer = styled.div`
  width: 100%;
  height: 4.5rem;
  background-color: #fff;
  border: 1px solid ${props => (props.active ? '#96c444' : '#cacaca')};
  position: relative;
  border-radius: 6px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
`

export const Option = styled.div`
  position: absolute;
  background-color: #fff;
  width: 100%;
  max-height: 300%;
  border: 1px solid #96c444;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  top: 130%;
  overflow: hidden;
  z-index: 10;
`
export const OptionList = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 9px;
    height: 5px;
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #e1e3de;
    height: 5px;
    border-radius: 20px;
  }
  > p {
    display: flex;
    align-items: center;
    width: 100%;
    height: 5vh;
    padding: 0 12%;
    border-bottom: 1px solid rgba(202, 202, 202, 0.3);
    :hover {
      background-color: #96c444;
    }
  }
`

export const TermsHidden = styled.div`
  overflow: hidden;
  flex: 1;
  display: flex;
  border-bottom-right-radius: 48px;
`

export const TermsContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 3vh 2vw;
`

export const TermsText = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${colors.grayMainText};
`
export const StepContainer = styled.div`
  display: block;

  @media ${device.mobileL} {
    display: none;
  }
`;