import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 2vw;
`

export const Text = styled.span`
  font-size: 1.8rem;
  margin-bottom: 5vh;
  text-align: center;
  font-weight: 500;
`
export const TextAtention = styled.span`
  font-size: 1.5rem;
  margin-bottom: 1.5vh;
  text-align: center;
  font-weight: 200;
  color: tomato ;
`


export const ButtonsContainer = styled.div`
  display: flex;
  width: 20vw;
  justify-content: center;
  gap: 10%;

`

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalContent = styled.div`
  width: 90%;
  height: 80%;
  display: flex;
`

export const ModalFooter = styled.div`
  width: 30vw;
  height: 20%;
  display: flex;
  justify-content: space-between;
`

export const ModalImgContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalImg = styled.img`
  width: 12vw;
  height: auto;
`

export const ModaltextsContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ModalTitle = styled.h2`
  font-weight: bold;
  font-size: 2.5rem;
  color: ${props => props.color ? props.color : props.theme.grayMainText};
  line-height: 2.8rem;
  margin-bottom: 2vh;
  margin-right: 5vw;
  `

export const ModalText = styled.span`
  font-weight: 400;
  font-size: 1.4rem;
  color: ${props => props.theme.grayMainText};
  margin-bottom: 4vh;
  `

export const ModalSpan = styled.span`
  color: ${props => props.color};
  `

export const ItemsContainer = styled.div`
  width: 65%;
  height: 4vh;
  border-radius: 26px;
  margin-bottom: 2vh;
  background: ${props => props.theme.secondaryLightGreen};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ItemsText = styled.span`
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
`