import styled, { css } from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 50vw;
  height: 58vh;

  @media ${device.mobileL} {
    width: 100vw;
    padding: 0 20px;
    margin-top: 2vh;
    height: auto;
  }
`
export const Headers = styled.div`
  display: flex;
  height: 18%;
  width: 100%;
  justify-content: space-between;
`

export const HeaderContainer = styled.div`
  width: 22%;
  border-radius: 10px;
  background-color: ${props => props.color};
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 7px 1%;

  @media ${device.mobileL} {
    width: 45%;
    height: 36px;
    padding: 1px 6px;
    margin: 0;
    align-items: center;

  }
`
export const HeaderName = styled.h2`
  font-size: 1.5em;
  align-self: flex-end;
  color: ${({ theme }) => theme.white};
  font-weight: 600;

  @media ${device.mobileL} {
    font-size: 16px;
  }
`
export const HeaderAmount = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 1.8em;
  color: ${({ theme }) => theme.white};

  @media ${device.mobileL} {
    font-size: 22px;
  }
`
export const Lists = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 82%;
`
export const ListContainer = styled.div`
  width: 22%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.grayBackgrounds};
  border-radius: 50px 10px 10px 10px;
  display: flex;
  margin: 7px 1% 0 1%;

  @media ${device.mobileL} {
    width: 100%;
    border-radius: 0;
    margin: 0;
  }
`
export const ScrollContainer = styled.div`
  width: 100%;
  padding: 10px 10px 0 10px;
  ::-webkit-scrollbar {
    width: 0;
    background: ${({ theme }) => theme.white};
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  overflow: hidden;
  overflow-y: scroll;

  @media ${device.mobileL} {
    display: flex;
    flex-direction: row;
    margin-left: -5px;
    gap: 8px;

    overflow-x: scroll;
    overflow-y: unset;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  overflow: visible;
  flex-direction: column;
  width: 10vw;
  border-radius: 40px 10px 10px 10px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ active, backgroundColor, theme }) => (active ? backgroundColor : theme.white)};
  border: 2px solid ${({ active, color, theme }) => (active ? color : theme.grayInactivy)};
  position: relative;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  @media ${device.mobileL} {
    width: 204px;
    height: 122px;
    border-radius: 12px;
    margin-bottom: 0px;

    position: static;
  }
`

export const Description = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5%;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    justify-content: space-between;
    padding: 0 6px 0 8px;
  }
`
export const OrderIdDetail = styled.span`
  display: flex;
  width: 100%;
  height: 4vh;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: -4px;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.secondaryDarkPurple};
  justify-content: center;
  align-items: center;
  transition: 0.3 ease;
  color: white;
  border-radius: 30px;
  z-index: 1000;
`

export const OrderId = styled.h2`
  font-size: 1.25em;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ active, theme }) => (active ? theme.grayMainText : theme.graySecondaryText)};
  font-weight: ${({ active }) => (active ? 800 : 500)};
  
  :hover ${OrderIdDetail} { 
    visibility: visible;
    opacity: 1;
  }

  @media ${device.mobileL} {
    :hover ${OrderIdDetail} {
      visibility: hidden;
    }
  }
`

export const Date = styled.span`
  font-weight: bold;
  font-size: 10px;
  align-self: flex-end;
  color: ${({ theme }) => theme.grayMainText};
  margin: 5px 5px;
`
export const Amount = styled.h2`
  font-size: 1.1em;
  margin: 0;
  color: ${({ theme }) => theme.primaryPink};
  font-weight: ${({ active }) => (active ? 900 : 400)};
`
export const Texts = styled.div`
  width: 38%;

  @media ${device.mobileL} {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`

export const Tooltip = styled.div`
  visibility: hidden;
  font-weight: bold;
  width: 10rem;
  font-size: 0.8em;
  background-color: ${({ theme }) => theme.secondaryDarkPurple};
  color: ${({ theme }) => theme.white};
  text-align: center;
  margin: 0;
  border-radius: 6px;
  padding: 2% 4%;
  overflow: visible;
  position: absolute;
  z-index: 999;
  bottom: 120%;
  left: 50%;
  transform: translate(-50%, 0);
  ::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.secondaryDarkPurple} transparent transparent transparent;
  }
`

export const Timer = styled.div`
  background-color: ${({ theme }) => theme.secondaryPurple};
  overflow: visible;
  padding: 0.4rem 1rem;
  border-radius: 3rem;
  text-align: center;
  z-index: 100;
  font-weight: bold;
  font-size: 1.2em;
  color: ${({ theme }) => theme.white};
  background-color: ${props => props.color};
  position: relative;
  :hover ${Tooltip} {
    visibility: visible;
  }
`

export const ClientContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  padding: 0 8%;
`

export const IconContainer = styled.div`
  display: flex;
  flex: 0.1;
`

export const ClientName = styled.span`
  font-size: 1.2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 2%;
  color: ${({ theme }) => theme.grayMainText};
`

export const PlaceholdImage = styled.div`
  width: 4em;
  height: 4rem;
  display: flex;
  background-color: ${({ color, theme }) => (color === theme.primaryPink ? theme.white : color)};
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 8%;
  border: 1px solid ${({ color, theme }) => (color === theme.primaryPink ? theme.grayMainText : color)};

  @media ${device.mobileL} {
    width: 70px;
    height: 70px;
  }
`
export const Outside = styled.div`
  position: relative;

  @media ${device.mobileL} {
    position: static;
  }
`

export const LoadingArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

export const NoProductsText = styled.p`
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.graySecondaryText};
  margin: 1rem;
`
export const SectionGroup = styled.div`
  width: 100%;
`;