import {
    Container,
    ModalContainer,
    Icon
} from './styles'

import menu from '../../assets/menu.svg'

export default function Hamburguer({ toggleMenu }) {
    return (
        <Container onClick={toggleMenu}>
            <Icon src={menu} />
        </Container>
    )
}