import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import colors from '../../styles/colors'
import {
  FormContainer,
  SmallInputContainer,
  MiniTitle,
  AdviceInput,
  AdviceText,
  WaitingCodeContainer,
  FinishedText,
  TermsLink,
  Button,
  ErrorMessage,
  CheckError,
} from './Styles'

import { TextField } from './formFields/TextField'
import { MaskTextField } from './formFields/MaskTextField'

export function Step1({ formField, setTouched, emailError, setEmailError }) {
  const { firstName, lastName, email, cpf, password, confirmPassword } = formField

  useEffect(() => {
    setTouched({})
  }, [])
  return (
    <FormContainer>
      <MiniTitle mtop='1' mbottom='2vh'>
        Dados do responsável pela conta:
      </MiniTitle>
      <SmallInputContainer>
        <TextField
          mtop='3vh'
          width='47%'
          placeholder={firstName.label}
          name={firstName.name}
        />
        <TextField
          mtop='3vh'
          width='47%'
          placeholder={lastName.label}
          name={lastName.name}
        />
      </SmallInputContainer>
      {/* RETIREI O CPF */}
      <MaskTextField
        mtop='3vh'
        width='100%'
        placeholder={cpf.label}
        name={cpf.name}
        mask='999.999.999-99'
      />
      <TextField
        onFocus={() => setEmailError('')}
        mtop='3vh'
        width='100%'
        placeholder={email.label}
        name={email.name}
      />
      {emailError && <CheckError margin>{emailError}</CheckError>}
      <TextField
        mtop='3vh'
        width='100%'
        placeholder={password.label}
        type='password'
        name={password.name}
      />
      <TextField
        mtop='3vh'
        width='100%'
        placeholder={confirmPassword.label}
        type='password'
        name={confirmPassword.name}
      />
    </FormContainer>
  )
}

export function Step2({
  isCodeSent,
  setFieldValue,
  formField,
  setTouched,
  setShowCodeErrorMsg,
  showCodeErrorMsg,
  showNumberPhoneError,
  setShowNumberPhoneError,
  handlePhoneCodeValidation,
  timer,
  setTimer,
  formikProps,
  ...props
}) {
  const { numberPhone, code } = formField

  useEffect(() => {
    if (!isCodeSent) return
    if (timer > 0) {
      setTimeout(() => {
        setTimer((previous) => previous - 1)
      }, 1000)
    } else {
      setTimer(0)
    }
  }, [timer])

  useEffect(() => {
    setTouched({})
  }, [])

  return (
    <FormContainer>
      {isCodeSent ? (
        <>
          <MiniTitle mtop='5vh' mbottom='2vh'>
            Adicione o código que foi enviado para o seu celular:
          </MiniTitle>
          <TextField
            width='100%'
            onFocus={() => setShowCodeErrorMsg(false)}
            placeholder={code.label}
            name={code.name}
          />
          <ErrorMessage show={showCodeErrorMsg}>Codígo inválido</ErrorMessage>
          <WaitingCodeContainer>
            <AdviceText color='#666' size='1.2rem'>
              Seu código chegará em até {' '}
              <AdviceText color={colors.pink} fweight='600'>
                {timer} segundos
              </AdviceText>
            </AdviceText>
            {timer <= 0 &&
              <AdviceText size='1.4rem' fweight='600'>
                Não recebi! <TermsLink type='button' onClick={() => handlePhoneCodeValidation(formikProps.values.email, formikProps.values.numberPhone)} >Envie novamente</TermsLink>
              </AdviceText>
            }
          </WaitingCodeContainer>
        </>
      ) : (
        <>
          <MiniTitle mtop='5vh' mbottom='2vh'>
            Valide sua conta com seu número de celular:
          </MiniTitle>

          <MaskTextField
            onFocus={() => setShowNumberPhoneError(false)}
            placeholder={numberPhone.label}
            name={numberPhone.name}
            mask='(99) 99999-9999'
            onChange={(e) =>
              setFieldValue(numberPhone.name, e.target.value || '')
            }
          />
          <ErrorMessage show={showNumberPhoneError}>Este número ja está sendo usado</ErrorMessage>

          <AdviceInput mtop='-2vh'>
            Coloque seu número com DDD e 9 dígitos
          </AdviceInput>
        </>
      )}
    </FormContainer>
  )
}

export function RegisterFinished() {
  const { push } = useHistory()
  function handleClick() {
    push('/login')
  }

  return (
    <FormContainer>
      <FinishedText fweight='700' mtop='7vh'>
        Você concluiu seu cadastro!
      </FinishedText>
      <FinishedText fweight='400'>
        Confirme seu e-mail e faça o login para acessar sua conta gowdock.
      </FinishedText>
      <Button mtop='12vh' onClick={handleClick}>
        Fazer login
      </Button>
    </FormContainer>
  )
}
