import React, { useEffect, useState } from 'react'

import { Container, Header, Title } from '../stockArea/styles'
import { BorderButton, Content, FilterContent } from './styles'

import Chart from '../../../components/chart/Chart'
import { fetchPurchaseBalance } from '../../../api/purchases'
import { passingDataToArray } from '../../../Utils'
import { useAuth } from '../../../context/AuthContext'
import { CircularProgress } from '@material-ui/core'
import { useTheme } from '../../../context/ThemeContext'

export function BalanceArea() {
  const { user } = useAuth()
  const { currentTheme } = useTheme()

  const [chartData, setChartData] = useState()

  const [dataToday, setDataToday] = useState()
  const [todayLength, setTodayLength] = useState()

  const [dataWeek, setDataWeek] = useState()
  const [weekLength, setWeekLength] = useState()

  const [dataMonth, setDataMonth] = useState()
  const [monthLength, setMonthLength] = useState()

  async function fetchDataChart() {
    const data = await fetchPurchaseBalance(user.storeData.id)

    passingDataToArray(data[0], setDataToday, setTodayLength)
    passingDataToArray(data[1], setDataWeek, setWeekLength)
    passingDataToArray(data[2], setDataMonth, setMonthLength)
  }

  useEffect(() => {
    fetchDataChart()
  }, [])

  useEffect(() => {
    if (!chartData) {
      setChartData(dataToday)
    }

  }, [dataToday])

  return (
    <Container>
      <Header>
        <Title>Balanço de pedidos</Title>
      </Header>
      <FilterContent>
        <BorderButton
          onClick={() => setChartData(dataToday)}
          active={chartData === dataToday}
        >
          <div className='number'>
            <h3 className='number'>
              {todayLength || '0'}
            </h3>
          </div>
          <div className='day'>
            <h3 className='day'>
              hoje
            </h3>
          </div>
        </BorderButton>

        <BorderButton
          onClick={() => setChartData(dataWeek)}
          active={chartData === dataWeek}
        >
          <div className='number'>
            <h3 className='number'>
              {weekLength || '0'}
            </h3>
          </div>
          <div className='day'>
            <h3 className='day'>
              semana
            </h3>
          </div>
        </BorderButton>
        <BorderButton
          onClick={() => setChartData(dataMonth)}
          active={chartData === dataMonth}
        >
          <div className='number'>
            <h3 className='number'>
              {monthLength || '0'}
            </h3>
          </div>
          <div className='day'>
            <h3 className='day'>
              mês
            </h3>
          </div>
        </BorderButton>
      </FilterContent>
      <Content>
        {chartData ? (
          <Chart data={chartData} />
        ) : (
          <CircularProgress style={{ color: currentTheme.primaryGreen }} />
        )}
      </Content>
    </Container>
  )
}
