import styled, { css } from 'styled-components'
import { FiMinusCircle, FiXCircle } from 'react-icons/fi'
import { BsPlusCircleFill } from 'react-icons/bs'

export const Container = styled.div`
	width: 100%;
	height: 90%;
`

export const InactiveContainer = styled.div` 
	border: 2px solid ${({ theme }) => theme.grayInactivy};
	border-radius: 8px 8px;
	width: 100%; 
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content:center;
	`

export const InactiveTitle = styled.h1`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.grayInactivy};
	margin-top: 5%;
`

export const DetailContainer = styled.div`
	display: flex;
	padding: 0% 3%;
	width: 100%; 
	height: 100%;
	border-radius: 8px 8px;
	flex-direction: column;
	align-items: center;
	justify-content:center;
	background-color: ${({ theme }) => theme.grayBackgrounds};
`

export const ImageContainer = styled.div`
	display:flex;
	flex-direction: column;
	width: 100%;
	height: 40%;
	justify-content: center;
	align-items: center;
	
	div.CarrouselContainer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 25vw;
	}
`
export const ProductImage = styled.img`
	display: flex;
	justify-content: center;
	align-items: center;
	width:100%;
	height:100%;
	object-fit: contain;
`

export const VariationIcon = styled.img`
	width: 30vw;
	height: 30vh;
	object-fit: cover;
	background-color: transparent;
`

export const ButtonContainer = styled.div`
	display:flex;
	align-items :center;
	justify-content:space-evenly;
	height: 100%;
	width: 100%;

	div.first{
		${({ active }) => active ? `
		width: 100%;
		height: 100%;
		opacity: 1;
		overflow: visible;
		` : `
		width: 0%;
		height: 0%;
		opacity: 0;
		overflow: hidden;
		`}
		display:inherit;
		align-items :inherit;
		justify-content:inherit;
		transition: 0.3s ease;
	}
	div.second{
		${({ active }) => active ? `
		width: 0%;
		height: 0%;
		opacity: 0;
		overflow: hidden;
		` : `
		width: 100%;
		height: 100%;
		opacity: 1;
		overflow: visible;
		`}
		display:inherit;
		align-items :inherit;
		justify-content:inherit;
		transition: 0.3s ease;
	}
`

export const DetailsDiv = styled.div`
	height: 50%;
	width:100%;
	display:flex;
	flex-direction:column;
`

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: ${({ height }) => height || '10%'};
	justify-content: space-between;
	align-items: center;
	`

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: ${({ width }) => width || '50%'};
	height: 100%;
	justify-content: center;
	align-items: flex-start;
`

export const Break = styled.div`
	display:flex;
	width: 100%;
	height: 100%;
	flex-direction:column;
	`

export const VariationsBreak = styled(Break)`
	align-items: flex-start;
	width: 70%;
	height: 100%;
`

export const ItemName = styled.h1`
	font-size: 1.5rem;
	font-weight: 700;
	`

export const Units = styled.h2`
	font-size: 1.2rem;
	font-weight: 400;
	color: ${({ theme }) => theme.primaryPink};
`

export const Category = styled.div`
	display:flex;
	justify-content:center;
	align-items:center;
	height: 55%;
	width: 85%;
	border-radius: 80px;
	background-color: ${({ theme }) => theme.grayButtonBackground};

	color: ${({ theme }) => theme.primaryPink};
	font-size: 1.3rem;
	font-weight: 400;
	`

export const ItemPrice = styled.h1`
	font-size: 1.6rem;
	font-weight: 700;
	color: ${({ theme }) => theme.primaryGreen};
	align-self: flex-end;
	`

export const ItemColorTitle = styled.h1`
	font-size: 1.2rem;
	font-weight: 700;
	`

export const ItemColor = styled.h2`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.grayMainText};
	`

export const ItemDescription = styled.h2`
	height: 60%;
	color: ${({ theme }) => theme.grayMainText};
	font-size: 1.2rem;
	overflow-y: auto;
`
export const StartContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 30%;
`

export const ProductStockReduce = styled(FiMinusCircle).attrs({ size: '3.5rem' })`
  color: ${({ theme }) => theme.primaryGreen};
  cursor: pointer;
`

export const ProductStockIncrement = styled(BsPlusCircleFill).attrs({ size: '3.5rem' })`
  fill: ${({ theme }) => theme.primaryGreen};
  border-color: ${({ theme }) => theme.primaryGreen};
  color: ${({ theme }) => theme.white};
  cursor: pointer;
	`

export const CloseQuantity = styled(FiXCircle).attrs({ size: '3.5rem' })`
	fill: ${({ theme }) => theme.grayButtonBackground};
	stroke: ${({ theme }) => theme.graySecondaryText};
	cursor: pointer;
`
