import styled from 'styled-components'
import { device } from '../../../styles/sizes'

export const Container = styled.section`
  grid-area: ${props => props.area};
  width: auto;
  display: flex;
  flex-direction: column;
  height: 27vh;
  overflow: hidden;
  justify-content: space-between;
  padding-left: 2%;
  padding-top: 1vh;
  padding-bottom: 2vh;

  @media ${device.mobileL} {
    width: 100vw;
    padding: 0 20px;
    margin-top: 2vh;
    height: auto;
  }
`

export const Label = styled.p`
  font-weight: 700;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.grayMainText};
`

export const ContainerList = styled.div`
  background-color: ${({ theme }) => theme.grayBackgrounds};
  border-radius: 50px 0 0 6px;
  width: 100%;
  height: 80%;
  display: flex;
  overflow: hidden;

  @media ${device.mobileL} {
    border-radius: 0;
    height: auto;
  }
`

export const List = styled.div`
  display: inline-flex;
  gap: 1%;
  width: 100%;
  ::-webkit-scrollbar {
    height: 7px;
    background: ${({ theme }) => theme.grayBackgrounds};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.grayMainText};
    height: 7px;
    border-radius: 20px;
  }
  padding: 1vh;
  padding-top: 1vh;
  overflow-x: auto;
  overflow-y: hidden;

  @media ${device.mobileL} {
    overflow-x: scroll;
  }
`

export const ListAmount = styled.p`
  font-weight: 700;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.primaryPink};
  ::before {
    content: '•';
    text-align: center;
    margin: 0 10px;
    color: ${({ theme }) => theme.grayMainText};
  }
`

export const LoadingArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
