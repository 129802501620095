import React, { useRef } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import formInitialValues from './formModel/formInitalValues'
import validationSchema from './formModel/validationSchema'
import {
  Container,
  Content,
  ContactUsImg,
  SocialMidiaContainer,
  Title,
  FormContainer,
  BorderContent,
  BorderHeader,
  DefaultInput,
  DefaultTextArea,
  BorderContentRow,
  SocialMediaIcon,
  SocialMediaButton,
  BackButtonArea,
  MessageError,
  InputContent,
  SocialMidiaContent,
} from './styles'

import ButtonPrimaryIconRight from '../../../components/buttons/buttonPrimaryIconRight'
import ButtonSecondaryIconLeft from '../../../components/buttons/buttonSecondaryIconLeft'
import ButtonLink from '../../../components/buttons/buttonLink'

import ContactUsSvg from '../../../assets/contact-us.svg'
import FacebookIcon from '../../../assets/facebook.svg'
import InstagramIcon from '../../../assets/instagram.svg'
import { FiSend, FiUploadCloud, FiChevronLeft } from 'react-icons/fi'
import { useAuth } from '../../../context/AuthContext'
import { sendMessageToSupport, uploadImageMessage } from '../../../api/store'
import { useModal } from '../../../context/ModalContext'
import LoadingIndicator from '../../../components/LoadingIndicator'
import SaveModal from '../../../components/modals/confirmModal'
import ErrorModal from '../../../components/modals/errorModal'

export default function ContactUs() {
  const { setModal } = useModal()
  const fileInput = useRef(null)
  const { goBack } = useHistory()
  const { user } = useAuth()

  function handleUploadImages(images) {
    const uploadImagesArray = Object.values(images).map(item => uploadImageMessage(item))
    const response = Promise.all(uploadImagesArray).then(res => res.map(item => item.data.image))
    return response
  }

  async function handleSubmitMessage(values, actions) {
    setModal(<LoadingIndicator />)
    let imageUrl
    if (values.selectedDocs.length > 0) {
      imageUrl = await handleUploadImages(values.selectedDocs)
    }

    const body = {
      name: values.userName,
      email: values.email,
      cellphone: values.number,
      message: values.description,
      image: imageUrl ? imageUrl.join(', ') : null
    }


    try {
      await sendMessageToSupport(user.storeData.id, body)
      setModal(
        <SaveModal
          onlyButton
          text='Mensagem enviada com sucesso! Agradecemos a sua colaboração.'
          confirmText='Continuar'
          confirmFunction={() => setModal(false)}
        />
      )

      actions.resetForm()
    } catch {
      setModal(<ErrorModal closeModal={() => setModal()} />)
    }
  }

  return (
    <Container>
      <Content>
        <ContactUsImg src={ContactUsSvg} />
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitMessage}
        >
          {(props) => (
            <FormContainer>
              <BackButtonArea>
                <ButtonLink
                  onClick={() => goBack()}
                  withIcon={true}
                  sideIcon='left'
                  icon={<FiChevronLeft size='2.2rem' />}
                  text='Voltar'
                  underline={false}
                />
              </BackButtonArea>
              <BorderContent flexDirection='column' activeBackground={true}>
                <BorderHeader>
                  <Title>Deixe sua mensagem</Title>
                </BorderHeader>
                <BorderContentRow>
                  <InputContent width='60%'>
                    <DefaultInput
                      placeholder='Nome'
                      onChange={(value) => props.setFieldValue('userName', value.target.value)}
                      value={props.values.userName}
                      type='text'
                      onBlur={props.handleBlur('userName')}
                    />
                    <MessageError>{props.touched.userName && props.errors.userName}</MessageError>
                  </InputContent>

                  <InputContent width='36%'>
                    <DefaultInput
                      placeholder='Número'
                      onChange={(value) => props.setFieldValue('number', value.target.value.replace('/ /g _', ''))}
                      value={props.values.number}
                      mask="(99)9 9999-9999"
                      onBlur={props.handleBlur('number')}
                    />
                    <MessageError>{props.touched.number && props.errors.number}</MessageError>
                  </InputContent>
                </BorderContentRow>

                <InputContent width='100%'>
                  <DefaultInput
                    placeholder='Email'
                    onChange={(value) => props.setFieldValue('email', value.target.value)}
                    value={props.values.email}
                    type='email'
                    onBlur={props.handleBlur('email')}
                  />
                  <MessageError>{props.touched.email && props.errors.email}</MessageError>
                </InputContent>

                <InputContent width='100%' height='40%'>
                  <DefaultTextArea
                    width='100%'
                    placeholder='Inserir mensagem'
                    onChange={(value) => props.setFieldValue('description', value.target.value)}
                    value={props.values.description}
                    type='text'
                    onBlur={props.handleBlur('description')}
                  />
                  <MessageError>{props.touched.description && props.errors.description}</MessageError>
                </InputContent>

                <BorderContentRow>
                  <ButtonSecondaryIconLeft
                    width='60%'
                    text={props.values.selectedDocs.length > 0 ? `${props.values.selectedDocs.length} Arquivo(s)` : 'Carregar arquivo(s)'}
                    icon={<FiUploadCloud />}
                    onClick={() => fileInput.current.click()}
                  />
                  <input
                    type='file'
                    accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'
                    multiple
                    ref={fileInput}
                    onChange={(event) => props.setFieldValue('selectedDocs', event.target.files)}
                    style={{ display: 'none' }}
                  />

                  <ButtonPrimaryIconRight
                    text='Enviar'
                    type='submit'
                    width='35%'
                    icon={<FiSend size='2rem' />}
                    onClick={() => props.handleSubmit(props.values)}
                  />
                </BorderContentRow>
              </BorderContent>
            </FormContainer>
          )}
        </Formik>

        <SocialMidiaContainer>
          <Title>Nossas redes</Title>
          <SocialMidiaContent>
            <SocialMediaButton onClick={() => window.open('https://instagram.com/gowdock', '_blank')}>
              <SocialMediaIcon src={InstagramIcon} alt='' />
              <Title>Instagram</Title>
            </SocialMediaButton>
            <SocialMediaButton onClick={() => window.open('https://facebook.com/gowdock', '_blank')}>
              <SocialMediaIcon src={FacebookIcon} alt='' />
              <Title>Facebook</Title>
            </SocialMediaButton>
          </SocialMidiaContent>
        </SocialMidiaContainer>
      </Content>
    </Container>
  )
}
