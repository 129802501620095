import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { device } from '../../../styles/sizes'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.mtop ? props.mtop : '0'};
  margin-bottom: ${props => props.mbottom ? props.mbottom : '0'};
  width: ${props => props.width || '100%'};
  
  @media ${device.mobileL} {
    height: 40px;
  }
  `

export const Input = styled.input`
  width: 100%;
  height: ${props => props.height || '6vh'};
  border: 1px solid ${props => props.error ? props.theme.statusRed : props.theme.graySecondaryText};
  border-radius: 6px;
  font-size: 1.4rem;
  padding: 0 0.5vw;
  background-color: ${({ backgroundColor, theme }) => backgroundColor ? backgroundColor : theme.white};
  :focus {
    border: 1px solid ${props => props.theme.primaryGreen};
    box-shadow: 0px 8px 24px rgba(94, 135, 138, 0.1);
  }

  ::placeholder {
    color: ${(props) => props.theme.graySecondaryText};
  } 
  
  :disabled {
    opacity: 0.5;
  }
`

export const CurrencyInput = styled(NumberFormat)`
  width: 100%;
  height: ${props => props.height || '6vh'};
  border: 1px solid ${props => props.error ? props.theme.statusRed : props.theme.graySecondaryText};
  border-radius: 6px;
  font-size: 1.4rem;
  padding: 0 0.5vw;
  background-color: ${({ backgroundColor, theme }) => backgroundColor ? backgroundColor : theme.white};
  :focus {
    border: 1px solid ${props => props.theme.primaryGreen};
    box-shadow: 0px 8px 24px rgba(94, 135, 138, 0.1);
  }

  ::placeholder {
    color: ${(props) => props.theme.graySecondaryText};
  }
`

export const Label = styled.label`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${props => props.theme.grayMainText};

  @media ${device.mobileL} {
   font-size: 16px;
  }
`
