import React from 'react'
import {
  Label,
  TextArea
} from './styles'

export default function MainTextArea({ width, height, error, label, ...rest }) {
  return (
    <>
      <Label>
        {label}
      </Label>
      <TextArea
        width={width}
        height={height}
        error={error}
        placeholder='Inserir descrição'
        {...rest}
      />
    </>
  )
}
