import styled from 'styled-components'
import { device } from '../../styles/sizes'

export const Container = styled.div`
  flex: 1;
  display: flex;

  @media ${device.mobileL} {
    padding: 0 10px;
    
  }
`

export const MenuContainer = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.mobileL} {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`

export const MenuSelector = styled.div`
  width: 100%;
  height: 15%;
  background: ${(props) => props.active ? props.theme.grayBackgrounds : props.theme.white};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 4vw;

  @media ${device.mobileL} {
    height: 104px;
    padding-left: 34px;
  }
`

export const SelectorText = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${props => props.theme.grayMainText};
  margin-left: 5%;
`

export const Content = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.grayBackgrounds};
  padding-left: 2%;
  padding-top: 1%;

  @media ${device.mobileL} {
    display: none;
  }
`

export const Title = styled.h2`
  font-weight: 700;
  font-size: 2rem;
  color: ${(props) => props.theme.grayMainText};
`