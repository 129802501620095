import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import {
  FilterContent,
  Content,
  Container,
  ListLoadingContent,
  ProductsAlert,
  ManageBoxContainer,
  ManageBoxHeader,
  ManageBoxFooter
} from './styles'

import {
  Header,
  Title
} from '../stockArea/styles'

import { useTheme } from '../../../context/ThemeContext'
import { useModal } from '../../../context/ModalContext'

import Focus from '../../../components/focus/Focus'
import Order from '../../../components/order'
import ButtonLink from '../../../components/buttons/buttonLink'
import LoadingIndicator from '../../../components/LoadingIndicator'

import {
  formatShortDate,
  translatePurchaseStatus,
  ACCEPTED,
  SEPARATED,
  DELIVERING,
  DELIVERED,
  CLOSED,
} from '../../../Utils'

export function ManagementArea({ loading, orders }) {

  const { currentTheme } = useTheme()
  const { setModal } = useModal()
  const { push } = useHistory()

  const [boxActived, setBoxActived] = useState(ACCEPTED)

  const filterOrders = useCallback((currentStatus) => { return orders?.filter((item) => item.status === (currentStatus && currentStatus || boxActived)) }, [boxActived, orders])

  function handleOpenOrderDetail(data) {
    setModal(<Focus focusItem={{ data }} showCloseBtn={true} />)
  }



  return (
    <Container>
      <Header>
        <Title>Gerenciamento de pedidos</Title>
        <ButtonLink
          text='Ver mais'
          underline={false}
          onClick={() => push('/management')}
        />
      </Header>
      <FilterContent>

        <ManageBoxContainer
          active={boxActived === ACCEPTED}
          bgColor={currentTheme.secondaryNeutralGreen}
          onClick={() => setBoxActived(ACCEPTED)}
        >
          <ManageBoxHeader>{filterOrders(ACCEPTED)?.length || 0}</ManageBoxHeader>
          <ManageBoxFooter>ativos</ManageBoxFooter>
        </ManageBoxContainer>

        <ManageBoxContainer
          active={boxActived === SEPARATED}
          bgColor={currentTheme.secondaryPurple}
          onClick={() => setBoxActived(SEPARATED)}
        >
          <ManageBoxHeader>{filterOrders(SEPARATED)?.length || 0}</ManageBoxHeader>
          <ManageBoxFooter>separados</ManageBoxFooter>
        </ManageBoxContainer>

        <ManageBoxContainer
          active={boxActived === DELIVERING}
          bgColor={currentTheme.secondaryYellow}
          onClick={() => setBoxActived(DELIVERING)}
        >
          <ManageBoxHeader>{filterOrders(DELIVERING)?.length || 0}</ManageBoxHeader>
          <ManageBoxFooter>enviado</ManageBoxFooter>
        </ManageBoxContainer>

        <ManageBoxContainer
          active={boxActived === DELIVERED}
          bgColor={currentTheme.secondaryBlue}
          onClick={() => setBoxActived(DELIVERED)}
        >
          <ManageBoxHeader>{filterOrders(DELIVERED)?.length || 0}</ManageBoxHeader>
          <ManageBoxFooter>concluídos</ManageBoxFooter>
        </ManageBoxContainer>

      </FilterContent>
      <Content>
        {loading ?
          <LoadingIndicator />
          :
          filterOrders()?.length > 0 ?
            filterOrders()
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((item, key) => (
                <Order
                  key={key}
                  marginVertical='0vh'
                  width='100%'
                  height='12vh'
                  orderNumber={item.code || '#453ABD'}
                  buyerName={item.client.name}
                  ammountItems={item.storeOrder[0]?.products?.length}
                  dateTime={formatShortDate(item.createdAt)}
                  orderStatus={item.status}
                  onClick={() => handleOpenOrderDetail(item)}
                />
              ))
            :
            <ListLoadingContent>
              <ProductsAlert>Nenhum pedido {translatePurchaseStatus(boxActived)}</ProductsAlert>
            </ListLoadingContent>
        }
      </Content>
    </Container>
  )
}
