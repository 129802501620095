import styled from 'styled-components'

export const Icon = styled.button`
  color: ${props => props.theme.primaryPink};
  font-size: 2rem;
  display: none;
  transition: 0.3s;
`

export const BankContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover ${Icon} {
    display: block;
  }
`

export const Container = styled.div`
  width: 90%;
  min-height: 10vh;
  border-radius: 16px;
  background: ${props => props.theme.white};
  margin-bottom: 3%;
  position: relative;
  padding-left: 5%;
  display: flex;
  align-items: center;
  transition: 0.3s;
`

export const ImageContainer = styled.div`
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  background: ${props => props.theme.grayInactivy};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 75%;
  justify-content: space-between;
  margin-left: 5%;
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`

export const AccountType = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${props => props.theme.graySecondaryText};
  margin: 0;
  padding: 0;
  margin-left: 0.5vw;
`

export const Bank = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${props => props.theme.grayMainText};
  margin: 0;
  padding: 0;
`

export const AccountInfo = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${props => props.theme.secondaryLightGreen};
  margin-left: ${props => props.mLeft}
`