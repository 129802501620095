import React, { useEffect, useState } from 'react'
import { FiUser, FiX } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { useModal } from '../../context/ModalContext'
import { useTheme } from '../../context/ThemeContext'

import {
  Container,
  Header,
  List,
  Footer,
  FlexContainer,
  Description,
  Order,
  Name,
  Amount,
  Time,
  Label,
  ListContainer,
  ItemContainer,
  ItemImageContainer,
  ItemImage,
  ItemName,
  ItemAmount,
  Empty,
  EmptyImage,
  StatusAndValueContiner,
  Status,
  Value,
  DescriptionText,
  LabelContainer,
  Count,
  More,
  Check,
  FooterColumn,
  Timer,
  ClientName,
  IconContainer,
  OrderLogo,
  CloseButton,
  OrderMarquee,
  CollectorInfo,
} from './FocusStyles'

import { OPEN, ACCEPTED, SEPARATED, DELIVERING, DELIVERED, CANCELLED, CLOSED } from '../../Utils'
import { formatShortDate } from '../../Utils'

import ButtonPrimary from '../buttons/buttonPrimary'
import ButtonSecondary from '../buttons/buttonSecondary'

import Logo from '../../assets/logo/Marca.svg'
import WhiteLogo from '../../assets/white_logo.svg'
import ImageTest from '../../assets/imageTest.png'
import EmptyIcon from '../../assets/empty.svg'
import { ReactComponent as TruckIcon } from '../../assets/truck.svg'
import ModalItems from '../../pages/management/modalItems/ModalItems'
import ModalSummary from '../../pages/management/modalSummary/ModalSummary'

import { formatMoneyBRL } from '../../Utils'
import SaveModal from '../modals/confirmModal'
import CancelModal from '../modals/cancelModal'
import PassStepModal from '../modals/passStep'

import { cancelPurchaseAndRefound, updatePurchaseStatus } from '../../api/purchases'
import ErrorModal from '../modals/errorModal'
import { ModalShipOrder } from '../../pages/management/modalShipOrder'
import { useAuth } from '../../context/AuthContext'
import NfeModal from '../../pages/home/management/nfeModal'
import { FaCopy } from "react-icons/fa";

import { includeOrderJadlog } from '../../api/purchases'

function Item({ item }) {
  return (
    <ItemContainer>
      <ItemImageContainer>
        <ItemImage src={item.image || ImageTest} />
      </ItemImageContainer>
      <ItemName>{item.name}</ItemName>
      <Value style={{ fontSize: '0.9em' }}>R$ {formatMoneyBRL(item.unitaryValue)}</Value>
      <ItemAmount>{item.quantity} unidades</ItemAmount>
    </ItemContainer>
  )
}

export default function Focus({ focusItem, area, width, height, showCloseBtn }) {

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Texto copiado para a área de transferência!');
    }).catch(err => {
      alert('Erro ao copiar texto: ', err);
    });
  };


  const { setModal } = useModal()
  const { push } = useHistory()
  const { user } = useAuth()
  const [color, setColor] = useState()
  const { data } = focusItem
  const checkedItems = data?.storeOrder[0]?.products?.filter(item => item.status === ACCEPTED).length
  const items = data?.storeOrder[0]?.products.length

  const productsTotalValue = (products) => products.reduce((acc, curr) => {
    return acc + curr.unitaryValue
  }, 0)

  console.log(data)

  const { secondaryLightPink, grayMainText, primaryPink, primaryGreen, secondaryPurple, secondaryBlue, secondaryYellow, statusRed, secondaryPink } =
    useTheme().currentTheme

  function handleSupport() {
    push('/contact-us')
    setModal(null)
  }

  async function handleCancelOrder() {
    const totalAmountInCents = data.paymentDetail.items.reduce((previousValeu, value) => previousValeu + value.amount, 0)
    try {
      const response = await cancelPurchaseAndRefound(data.invoice_id, data._id, totalAmountInCents)
      if (response.status === 200) {
        await handleChangePurchaseStatus(data._id, CANCELLED)
      }
    } catch (error) {
      setModal(<ErrorModal closeModal={() => setModal()} />)
    }
  }

  async function handleChangePurchaseStatus(id, status) {
    if (status == SEPARATED) {
      setModal(<NfeModal orderCode={data.code} id={id} status={status} />)
    } else {

      try {
        await updatePurchaseStatus(id, status)
        setModal(false)
        window.location.reload(false)
      } catch (error) {
        setModal(<ErrorModal closeModal={() => setModal()} />)
      }
    }
  }

  function handleModalItems(data) {
    return setModal(
      <ModalItems
        items={items}
        checkedItems={checkedItems}
        exit={() => setModal(false)}
        data={data}
        acceptFunction={() => handleChangePurchaseStatus(data._id, SEPARATED)}
        backPass={() => handleBackStep(data)}
      />
    )
  }

  function handleShipOrder() {
    setModal(<ModalShipOrder purchaseId={data._id} orderCode={data.code} />)
  }

  function handleModalSummary() {
    return setModal(<ModalSummary exit={() => setModal(false)} focusItem={focusItem} />)
  }

  function handleDenyPurchase(data) {
    setModal(
      <CancelModal
        text='Tem certeza que deseja negar o pedido?'
        confirmText='Negar'
        cancelText='Cancelar'
        cancelFunction={() => setModal()}
        confirmFunction={handleCancelOrder}
      />
    )
  }

  function handleAcceptPurchase(data) {
    setModal(
      <SaveModal
        text='Tem certeza que deseja aceitar o pedido?'
        confirmText='Aceitar'
        cancelText='Cancelar'
        cancelFunction={() => setModal()}
        confirmFunction={() => handleChangePurchaseStatus(data._id, ACCEPTED)}
      />
    )
  }

  function handlePassStep(data) {
    setModal(
      <PassStepModal
        closeModal={() => setModal()}
        confirm={() => handleChangePurchaseStatus(data._id, SEPARATED)}
        items={items}
        separetedItems={data?.storeOrder[0]?.products?.filter(item => item.status === ACCEPTED).length}
      />
    )
  }

  function handleBackStep(data) {
    setModal(
      <CancelModal
        text='Tem certeza que deseja voltar esse pedido para Aceito?'
        cancelFunction={() => setModal()}
        confirmFunction={() => handleChangePurchaseStatus(data._id, ACCEPTED)}
      />
    )
  }

  function FooterContainer({ data }) {
    switch (data.status) {
      case OPEN:
        setColor(primaryPink)
        return (
          <Footer>
            <ButtonPrimary onClick={() => handleDenyPurchase(data)} text='Negar' width='44%' height='3em' smallFont background={primaryPink} backgroundHover={secondaryLightPink} />
            <ButtonPrimary onClick={() => handleAcceptPurchase(data)} text='Aceitar' width='44%' height='3em' smallFont />
          </Footer>
        )
      case ACCEPTED:
        setColor(primaryGreen)
        return (
          <Footer>
            <ButtonSecondary
              onClick={handleSupport}
              text='Relatar problema'
              width='44%'
              height='3em'
              smallFont
              color={primaryPink}
              colorHover={secondaryLightPink}
            />
            <ButtonPrimary
              onClick={() => { handleModalItems(data) }}
              text='Finalizar preparo'
              width='44%'
              height='3em'
              smallFont
              background={secondaryPurple}
              backgroundHover={secondaryPink}
            />
          </Footer>
        )
      case SEPARATED:
        setColor(secondaryPurple)
        return (
          <Footer
            direction={'column'}
          >
            <ButtonPrimary
              onClick={handleShipOrder}
              text='Acionar transportadora'
              width='100%'
              height='7vh'
              smallFont
              background={secondaryPurple}
              backgroundHover={secondaryPink}
            />
            {/* <CollectorInfo>
            <Order>Dados do coletador</Order>
            <Name>Nome: {data.storeOrder[0]?.collector?.name}</Name>
            <Name>Telefone: {data.storeOrder[0]?.collector?.cellphone}</Name>
          </CollectorInfo> */}
          </Footer>
        )
      case DELIVERING:
        setColor(secondaryYellow)
        return (
          <Footer direction={'column'} width={'100%'} height={'60px'} bottom={'0'}>
            <FooterColumn>
              {/* <Timer color={secondaryYellow}>O pedido será entregue em até 24h</Timer> */}
              <ClientName>
                <IconContainer>
                  <TruckIcon height={16} />
                </IconContainer>
                {data.storeOrder[0].collector?.name}
              </ClientName>
            </FooterColumn>
          </Footer>
        )
      case DELIVERED:
        setColor(secondaryBlue)
        return (
          <Footer>
            <FooterColumn>
              <Timer color={secondaryBlue}>Pedido entregue às 02:24</Timer>
              <ClientName>
                <IconContainer>
                  <FiUser size={13} color={grayMainText} />
                </IconContainer>
                {data.client.name}
              </ClientName>
            </FooterColumn>
          </Footer>
        )

      case CLOSED:
        setColor(secondaryBlue)
        return (
          <Footer>
            <FooterColumn>
              <Timer color={secondaryBlue}>Pedido concluído às 14:33</Timer>
              <ClientName>
                <IconContainer>
                  <FiUser size={13} color={grayMainText} />
                </IconContainer>
                {data.client.name}
              </ClientName>
            </FooterColumn>
          </Footer>
        )

      case CANCELLED:
        setColor(statusRed)
        return (
          <Footer>
            <FooterColumn>
              <Timer color={statusRed}>Pedido CANCELADO</Timer>
              <ClientName>
                <IconContainer>
                  <FiUser size={13} color={statusRed} />
                </IconContainer>
                {data.client.name}
              </ClientName>
            </FooterColumn>
          </Footer>
        )
      default:
        break
    }
  }

  return (
    <Container area={area} background={data && data} width={width} height={height}>
      {data ? (
        <>
          <Header>
            {showCloseBtn && <CloseButton onClick={() => setModal(null)}><FiX size='2.2rem' color={grayMainText} /></CloseButton>}
            <FlexContainer>
              <OrderLogo color={color}>
                {color === primaryPink ? (
                  <img src={Logo} width='50%' height='50%' />
                ) : (
                  <img src={WhiteLogo} height='50%' width='50%' />
                )}
              </OrderLogo>
              <Description>
                <Order>Pedido Nº</Order>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Order scrollamount={1}>{data.code}</Order>
                  <div style={{cursor: 'pointer'}}>
                    <FaCopy size={18} color='#605e70' onClick={() => copyToClipboard(`${data.code} ${data.client.name} ${data.client.cpf} ${data.client.deliveryAddress.cep} ${data.client.deliveryAddress.neighbourhood} ${data.client.deliveryAddress.street} ${data.client.deliveryAddress.number} ${data.client.cellPhone} ${data.client.email}`)} />
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Name>Cliente: {data.client.name}</Name>
                  {/* <FaCopy color='#605e70' onClick={() => copyToClipboard(data.client.name)} /> */}
                </div>
                <div style={{ display: "flex", gap: 35 }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Name>CPF: {data.client.cpf}</Name>
                    {/* <FaCopy color='#605e70' onClick={() => copyToClipboard(data.client.cpf)} /> */}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Name>CEP: {data.client.deliveryAddress.cep}</Name>
                    {/* <FaCopy color='#605e70' onClick={() => copyToClipboard(data.client.deliveryAddress.cep)} /> */}
                  </div>
                </div>
                <div style={{ display: "flex", gap: 35 }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Name>Bairro: {data.client.deliveryAddress.neighbourhood}</Name>
                    {/* <FaCopy color='#605e70' onClick={() => copyToClipboard(data.client.deliveryAddress.neighbourhood)} /> */}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Name>{data.client.deliveryAddress.street}</Name>
                    {/* <FaCopy color='#605e70' onClick={() => copyToClipboard(data.client.deliveryAddress.street)} /> */}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Name>Nº: {data.client.deliveryAddress.number}</Name>
                    {/* <FaCopy color='#605e70' onClick={() => copyToClipboard(data.client.deliveryAddress.number)} /> */}
                  </div>
                </div>
              </Description>

            </FlexContainer>
            <StatusAndValueContiner>
              <Status position={data.storeOrder[0]?.products.length}>
                <h2>Pago pelo app</h2>
              </Status>
              <Amount>{data.storeOrder[0]?.products.length} itens</Amount>
              <Value>R$ {formatMoneyBRL(productsTotalValue(data.storeOrder[0].products))}</Value>
            </StatusAndValueContiner>
            <Time>{formatShortDate(data.createdAt)}</Time>
          </Header>
          <List>
            <Label>Observações do pedido</Label>
            <DescriptionText>{data.description}</DescriptionText>
            <LabelContainer>
              <Label>Itens</Label>
              {data.status == ACCEPTED || data.status == SEPARATED && (
                <>
                  <Count colorGB={data.status == ACCEPTED}>
                    {checkedItems}/{items} itens separados
                  </Count>
                  {data.status === SEPARATED && <More onClick={() => handleModalItems(data)}>Ver lista</More>}
                </>
              )}
            </LabelContainer>
            <ListContainer>
              {data.storeOrder[0]?.products.map((item, index) => (
                <Item key={index} item={item} orderStatus={data.status} />
              ))}
            </ListContainer>
          </List>
          <FooterContainer data={data} />
        </>
      ) : (
        <Empty>
          <EmptyImage src={EmptyIcon} />
          <p>Nenhum pedido selecionado</p>
        </Empty>
      )}
    </Container>
  )
}