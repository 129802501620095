import styled from 'styled-components'
import ButtonSecondaryIconLeft from '../buttons/buttonSecondaryIconLeft'
import MaskedInput from 'react-text-mask'
import Switch from 'react-switch'
import { FiTrash, FiPlus } from 'react-icons/fi'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const MiddleDiv = styled.div`
  display:grid;

  grid-template-areas:
   'name name name name name name'
   'input input switch text button button';
   grid-template-columns: 1fr 1fr minmax(56,auto) 1fr 1fr 1fr;
  width: 100%;
  height: 16%;
  padding: 2% 4%;
  align-items:center;
`

export const TextH2 = styled.h2`
  grid-area: name;
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 3px;
  color: ${({ theme }) => theme.grayMainText};
`
export const TextH3 = styled.h3`
  display: flex;
  grid-area: text;
  font-size: 1.3rem;
  font-weight: 400;
  align-items: center;
  justify-content: flex-start;
  
  color: ${({ theme }) => theme.grayMainText};
`

export const Switch1 = styled(Switch)`
  grid-area:switch;
  margin-left: 25%;
  max-width:50px;
`

export const Button = styled(ButtonSecondaryIconLeft)`
  grid-area: button;
  width:90%;
  height: 110%;
`

export const TableDiv = styled.div`
  display: flex;
  flex-direction:column;
  align-items: center;
  width: 100%;
  height: 45vh;
  padding:2% 4%;
`

export const VariationsTableHead = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 15% 10%;
  grid-template-rows: auto;
  width: 100%;
  min-height: 15%;
  background-color: ${({ theme }) => theme.graySecondaryText};
  border-radius: 8px 8px 0px 0px;
`

export const TableHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.white};
  font-size: 1.3rem;
`

export const TableBody = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width:100%;
  height: 100%;
  overflow: auto;
  background-color: ${({ theme }) => theme.grayBackgrounds};
`

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 15% 10%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  background-color: ${({ theme }) => theme.grayBackgrounds};
 `

export const TableVariationInput = styled.input`
  justify-self: center;
  width: 90%;
  height: 5vh;
  border: 1px solid ${({ theme }) => theme.graySecondaryText};
  border-radius: 6px;
  padding: 1rem;
  color: ${({ theme }) => theme.grayMainText};
  font-size: 1.5rem;
  font-weight:400;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  &[type=number] {
    -moz-appearance: textfield;
  }
`

export const TablePriceInput = styled(MaskedInput)`
  justify-self: center;
  width: 90%;
  height: 5vh;
  border: 1px solid ${({ theme }) => theme.graySecondaryText};
  border-radius: 6px;
  padding: 1rem;
  color: ${({ theme }) => theme.grayMainText};
  font-size: 1.5rem;
  font-weight:400;
`
export const SecretPlus = styled(FiPlus)`
  width: 80%;
  height: 80%;
  opacity: ${({ opacity }) => opacity || 0};
`

export const ImageButton = styled.label`
  display: flex;
  align-items:center;
  justify-content: center;
  justify-self: center;
  height: 8vh;
  width: 4vw;
  border-radius: 8px ;
  transition: 0.3s;
  cursor: pointer; 
  font-size: 3rem;
  background-color: ${({ theme }) => theme.white};
  color:${({ theme }) => theme.secondaryLightGreen};
  
  :hover{
    opacity: 0.5;
  }
  
  :hover ${SecretPlus} {
    opacity: 1;
    color:  ${({ theme }) => theme.secondaryLightGreen};
  }
`

export const VariationImage = styled.div`
  display:flex;
  height:100%;
  width:100%;
  justify-content: center;
  align-items:center;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat:no-repeat;
  background-position:center;
  border-radius: 8px; 
`

export const ImageInput = styled.input`
  display: none; 
`

export const TrashIcon = styled.button`
  display:flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height:50%;
  opacity: 0%;
  transition: 0.3s;
  
  color: ${({ theme }) => theme.primaryPink};

:hover{
  opacity:100%;
}
`

export const Trash = styled(FiTrash)`
  width: 1.5vw;
  height: 3.5vh;
`
export const BottomDiv = styled.div`
  width: 100%;
  height: 15%;
  padding: 0 4%;   
  display: grid;
  grid-template-columns: repeat(3 , 1fr);
  align-items: center;
`
export const ClearButton = styled.button`
  color: ${({ theme }) => theme.primaryPink};
  text-decoration:none;
  font-weight: 700;
  font-size: 1.3rem ;
  cursor : pointer ;
 
`