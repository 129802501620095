import axios from 'axios'
import registerFormModel from '../pages/register/formModel/RegisterFormModel'

import { clearMask } from '../Utils'

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

const {
  formField: {
    firstName,
    lastName,
    cpf,
    email,
    password,
    numberPhone,
    cep,
    address,
    state,
    city,
    neighborhood,
    number,
    complement,
    cnpj,
    fantasyName,
    socialReason,
  }
} = registerFormModel

const formatRegisterJson = obj => {
  const formatted = {
    login: obj[email.name],
    cpf: clearMask(obj[cpf.name]),
    password: obj[password.name],
    type: 'store',
    image: '',
    firstName: obj[firstName.name],
    lastName: obj[lastName.name],
    email: obj[email.name],
    cellPhone: '+55' + clearMask(obj[numberPhone.name]),
    address: {
      cep: clearMask(obj[cep.name]),
      complement: obj[complement.name],
      neighborhood: obj[neighborhood.name],
      number: obj[number.name],
      street: obj[address.name],
      state: obj[state.name],
      city: obj[city.name],
    },
    fantasyName: obj[fantasyName.name],
    companyName: obj[socialReason.name],
    cnpj: clearMask(obj[cnpj.name]),
    documentAttach: []
  }
  return formatted
}

export function getProfileImageFormData(file) {
  const imageData = new FormData()
  imageData.append('file', file)
  return imageData
}

function getDocFormData(file) {
  const imageData = new FormData()
  imageData.append('files', file)
  return imageData
}

export async function uploadImages(image, token, userId) {
  const imageProfileData = getProfileImageFormData(image)
  const response = await api.post(`user/upload-image/${userId}`, imageProfileData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      token,
    },
  })
  return response.data
}

export async function uploadDoc(file, token, userId) {
  const docFormData = getDocFormData(file)
  const response = await api.post(`store/upload/${userId}`, docFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      token,
    },
  })
  return response.data
}

export const register = async userData => {
  const requestBody = formatRegisterJson(userData)

  const response = await api.post('user', requestBody)
  return response.data
}

export async function sendValidationCode(email, phoneNumber, checkUnitKey) {
  const response = await api.post('validation',
    {
      email: email,
      phoneNumber: phoneNumber,
      checkUnitKey: checkUnitKey
    })
  return response
}

export async function confirmValidationCode(email, code) {
  const response = await api.post('validation/code',
    {
      email: email,
      code: code,
    })
  return response
}

export async function locationByCep(cep) {
  const response = await api.get(`store/location/${cep}`)
  return response
}

export async function changePassword(userId, oldPassword, newPassword) {
  const response = await api.put(`change-password/${userId}`, {
    oldPassword,
    newPassword,
    type: 'store'
  })

  return response
}

export default api
