import React, { useState, useMemo, useEffect } from 'react'
import { Form, Formik } from 'formik'
import RUG, { DropArea } from 'react-upload-gallery'
import { postProduct, uploadProductImages, deleteProduct, updateProductBody } from '../../api/product'

import { FiStar } from 'react-icons/fi'
import UploadIcon from '../../assets/upload.svg'
import TrashIcon from '../../assets/trash.svg'

import ErrorModal from '../../components/modals/errorModal'
import ConfirmModal from '../../components/modals/confirmModal'
import LoadingIndicator from '../../components/LoadingIndicator'
import ButtonPrimary from '../../components/buttons/buttonPrimary'
import ButtonSecondary from '../../components/buttons/buttonSecondary'
import { useModal } from '../../context/ModalContext'
import { useAuth } from '../../context/AuthContext'
import { useTheme } from '../../context/ThemeContext'
import { useHistory } from 'react-router-dom'

import validationSchema from './formModel/ValidationSchema'
import { formModel, initialValues } from './formModel/FormModel'

import FirstStep from './firstStep/FirstStep'
import SecondStep from './secondStep/SecondStep'

import {
  Container,
  Content,
  DropContainer,
  ColorButton,
  ImagesContainer,
  ImageContainer,
  ProductImage,
  Filename,
  CornerButton,
  FavText,
  StepFormContent,
  Footer,
} from './styles'
import 'react-upload-gallery/dist/style.css'
import ButtonPrimaryIconRight from '../../components/buttons/buttonPrimaryIconRight'

const { formId, formFields } = formModel

export default function AddProducts({ location }) {
  const state = location.state || null
  const [files, setFiles] = useState([])
  const [step, setStep] = useState(1)
  const { setModal } = useModal()
  const { user } = useAuth()
  const { push } = useHistory()
  const { currentTheme: { secondaryYellow, grayMainText } } = useTheme()

  const [imgError, setImgError] = useState(null)

  const currentValidationSchema = useMemo(() => (step === 1 ? validationSchema[0] : validationSchema[1]), [step])

  async function handleDeleteProduct() {
    setModal(<LoadingIndicator />)
    const response = await deleteProduct(state.id)
    if (response.status === 200) {
      setModal(
        <ConfirmModal
          text='Produto deletado com sucesso.'
          confirmText='Okay!'
          confirmFunction={() => push('/products')}
          onlyButton={true}
        />)
    }
  }

  function renderStepContent(step, formikProps) {
    setImgError(formikProps.errors.productImages)
    switch (step) {
    case 1:
      return <FirstStep images={files} formFields={formFields} formikProps={formikProps} deleteProduct={handleDeleteProduct} state={state} isMobile={isMobile}/>
    case 2:
      return <SecondStep setStep={setStep} formFields={formFields} formikProps={formikProps} state={state} />
    case 3:
      return <SecondStep setStep={setStep} formFields={formFields} formikProps={formikProps} />
    default:
      break
    }
  }

  function handleBackClick() {
    setStep(step - 1)
  }

  const renderBottomButtons = () => {
    return (
      <Footer>
        {step === 2 ? (
          <ButtonSecondary
            onClick={handleBackClick}
            type='button'
            text='Voltar'
            width={isMobile ? '47%' : '45%'}
            height={isMobile ? '5.5vh' : '6vh'}
          />
        ) :
          <ButtonSecondary type='button' onClick={() => push('/products')} width={isMobile ? '47%' : '45%'} height={isMobile ? '5.5vh' : '6vh'} text='Cancelar' />
        }
        {
          step === 2
            ?
            <ButtonPrimary type='submit' width={isMobile ? '47%' : '45%'} height={isMobile ? '5.5vh' : '6vh'} text={step === 2 ? 'Adicionar' : 'Próximo'} />
            :
            <ButtonPrimaryIconRight type='submit' width={isMobile ? '47%' : '45%'} height={isMobile ? '5.5vh' : '6vh'} text={step === 2 ? 'Adicionar' : 'Próximo'} />
        }
      </Footer>
    )
  }

  async function submitForm(values, actions) {
    let tempProductId = undefined
    values = { ...values, storeId: user.storeData.id }
    try {
      const { data } = state ? await updateProductBody(state.id, { ...values, id: state.id }) : await postProduct(user.storeData.id, values)
      if (Object.keys(data).length > 0) {
        let imgList = []
        let imgPosition = []
        if (values.productImages[0].file) {
          imgList.push(values.productImages[0].file)
          imgPosition.push({ coverImage: 0 })
        }
        if (values.productVariation.length > 0) {
          values.productVariation.forEach((element, index) => {
            if (element[3][0]) {
              imgList.push(element[3][0])
              imgPosition.push(index)
            }
          })
          tempProductId = data.id
        }
        const response = imgList.length > 0 ? await uploadProductImages(data.id, imgList, imgPosition) : { status: 200 }
        if (response.status === 200) {
          actions.setSubmitting(false)
          setModal(false)
          setModal(
            <ConfirmModal
              text='Pronto! Seu produto foi criado com sucesso.'
              confirmText='Okay!'
              confirmFunction={() => push('/products')}
              onlyButton={true}
            />)
          setStep(step + 1)
        }
      }
    } catch (error) {
      await deleteProduct(tempProductId)
      actions.setSubmitting(false)
      setModal(<ErrorModal closeModal={() => push('/products')} />)
    }
  }

  function handleSubmit(values, actions) {
    if (step === 2) {
      setModal(<LoadingIndicator />)
      submitForm(values, actions)
    } else {
      setStep(step + 1)
      actions.setTouched({})
      actions.setSubmitting(false)
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 650);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    window.addEventListener('resize', handleResize);

    // Limpe o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Container>
      <Content>
        <RUG
          onChange={(img) => setFiles(img)}
          style={{ width: '100%' }}
          header={() => <></>}
          footer={({ openDialogue }) => (
            <DropArea>
              {isDrag => (
                <>
                  <DropContainer isDrag={isDrag}>
                    <img src={UploadIcon} alt='' style={{ height: 40, width: 40 }} />
                    <h2>Arraste as imagens aqui</h2>
                    <h2>ou</h2>
                    <ColorButton onClick={openDialogue}>Selecione as imagens</ColorButton>
                    <h3>{imgError}</h3>
                  </DropContainer>
                </>
              )}
            </DropArea>
          )}
        >
          {images => (
            <ImagesContainer>
              {images.length < 1 &&
                state && state.image && (
                <ImageContainer>
                  <ProductImage src={state.image} />
                  <Filename>Imagem de capa</Filename>
                </ImageContainer>
              )
              }
              {images.map((image, index) => (
                <div key={index}>
                  <ImageContainer>
                    <ProductImage src={image.source} alt={image.name} />
                    <CornerButton onClick={image.remove}>
                      <img src={TrashIcon} alt='' />
                    </CornerButton>
                    <CornerButton onClick={image.select} bottom favorite={image.selected}>
                      <FiStar
                        fill={image.selected ? secondaryYellow : 'transparent'}
                        stroke={image.selected ? secondaryYellow : grayMainText}
                      />
                      {image.selected && <FavText>Imagem de capa</FavText>}
                    </CornerButton>
                    <Filename>{image.file.name}</Filename>
                  </ImageContainer>
                </div>
              ))}
            </ImagesContainer>
          )}
        </RUG>
      </Content>
      <Formik initialValues={initialValues} validationSchema={currentValidationSchema} onSubmit={handleSubmit}>
        {formikProps => (
          <StepFormContent>
            <Form id={formId}>
              {renderStepContent(step, formikProps)}
              {renderBottomButtons()}
            </Form>
          </StepFormContent>
        )}
      </Formik>
    </Container >
  )
}
