import styled from 'styled-components'
import { device } from '../../styles/sizes'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: ${({ theme }) => theme.white};

  @media ${device.mobileL} {
    height: auto;
    align-items: center;
  }
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
