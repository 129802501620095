import * as Yup from 'yup'
import FormModel from './FormModel'

const {
    formField: {
        danfeCte,
        nrDoc,
        serie,
        valor,
        cfop,
        /* tpDocument */
    },
    requiredErrorMsg,
    incompleteErrorMsg,
    maxCharAmount
} = FormModel

export default [
    Yup.object().shape({
        [danfeCte.name]: Yup.string()
            .transform(value => (value ? value.trim().replace(/\s+/g, '') : value))
            .required(requiredErrorMsg)
            .min(44, `${danfeCte.label} deve ter no mínimo 44 caracteres`)
            .max(44, `${danfeCte.label} deve ter no máximo 44 caracteres`),
    
        [nrDoc.name]: Yup.string()
            .transform(value => (value ? value.trim().replace(/\s+/g, '') : value))
            .required(requiredErrorMsg)
            .max(20, `${nrDoc.label} deve ter no máximo 20 caracteres`),
    
        [serie.name]: Yup.string()
            .transform(value => (value ? value.trim().replace(/\s+/g, '') : value))
            .required(requiredErrorMsg)
            .max(3, `${serie.label} deve ter no máximo 3 caracteres`),
    
        [valor.name]: Yup.number().required(requiredErrorMsg),
    
        [cfop.name]: Yup.string()
            .transform(value => (value ? value.trim().replace(/\s+/g, '') : value))
            .required(requiredErrorMsg)
            .min(4, `${cfop.label} deve ter no mínimo 4 caracteres`)
            .max(4, `${cfop.label} deve ter no máximo 4 caracteres`),
        /* [tpDocument.name]: Yup.string().required(requiredErrorMsg) */
    })
    ]