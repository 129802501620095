import api from './api'

const formatRequestBody = (data) => {
  const variations = []
  const variationsPrice = []
  let MinAndMaxVariationPrice = []
  if (data.productVariation.length > 0) {
    data.productVariation.forEach((row, index) => {
      variations[index] = {
        type: data.productVariationName,
        name: row[0],
        originalPrice: row[1],
        quantity: row[2],
        image: row[3][0] ? '' : row[3][1],
      }

      variationsPrice.push(row[1])
    })
    MinAndMaxVariationPrice[0] = Math.min(...variationsPrice)
    MinAndMaxVariationPrice[1] = Math.max(...variationsPrice)
  }

  const formatted = {
    id: data.id || '',
    name: data.productName,
    code: Math.floor(Math.random() * (99999 - 0)) + 0,
    description: data.productDescription,
    measurement: {
      width: data.productDimensions.width,
      height: data.productDimensions.height,
      length: data.productDimensions.length,
      weight: data.productDimensions.weight
    },
    quantity: data.productUnits,
    priceRange: data.productVariation.length > 0 ? [MinAndMaxVariationPrice[0], MinAndMaxVariationPrice[1]] : [data.productPrice],
    variations,
    discountPrice: undefined,
    image: '',
    category: {
      first: data.productCategory[0].name,
      second: data.productCategory[1].name,
      third: data.productCategory[2].name,
    },
    store: data.storeId,
  }
  return formatted
}

export const postProduct = async (storeId, userData) => {
  const requestBody = formatRequestBody(userData)
  const response = await api.post(`store/${storeId}/product`, requestBody)

  return response
}

export const uploadProductImages = async (productId, imagesUrl, imgPosition) => {
  const imgs = new FormData()
  imagesUrl.forEach((element) => {
    imgs.append('file', element)
  })
  imgs.append('body', JSON.stringify(imgPosition))
  const response = await api.post(`store/product/upload/${productId}`, imgs, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return response
}

export const deleteProduct = async (productId) => {
  const response = await api.delete(`store/product/${productId}`)
  return response
}

export const getProducts = async (storeId, params) => {
  const response = await api.get(`store/${storeId}/product`, {
    params
  })
  return response
}

export const getSingleProduct = async (storeId, productId) => {
  const response = await api.get(`store/${storeId}/products/${productId}`)
  return response
}

export const updateProduct = async (productId, params) => {
  const response = await api.patch(`store/product/${productId}/storage`, null, { params: { ...params } })
  return response
}

export const updateProductBody = async (productId, body) => {
  const requestBody = formatRequestBody(body)
  const response = await api.put(`store/${productId}/product`, requestBody)

  return response
}
