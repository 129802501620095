import React, { useState } from 'react'

import { FiPlus } from 'react-icons/fi'

import {
  Container,
  Content,
  ValuePickerContainer,
  OverflowContainer,
  FooterContainer,
  CategoryContainer,
  CategoryContent,
  UpLabel,
  CategoryHeader,
  Clear,
  Line
} from './styles'

import { useModal } from '../../../context/ModalContext'

import { Modal } from '../../../components/modal'
import ValuePicker from '../../../components/valuePicker/ValuePicker'
import ButtonPrimary from '../../../components/buttons/buttonPrimary'
import ButtonLink from '../../../components/buttons/buttonLink'
import SelectCategory from '../../addProduct/selectCategory'

export default function FilterModal({ setValueRange, currentPriceRange, currentCategories, setCategory, isMobile }) {

  const { setModal } = useModal()

  const [price, setPrice] = useState([0, 10000])
  const [categories, setCategories] = useState(currentCategories || null)

  function handleApplyFilters() {
    setValueRange(price)
    setCategory(categories)
    setModal(null)
  }

  function handleClearFilters() {
    setValueRange([0, 10000])
    setCategory(null)
    setModal(null)
  }

  function handleOpenCategoryModal() {
    setModal(
      <SelectCategory
        onValueChange={setCategories}
        categoryFilter={categories}
        filterModal={true}
        confirmButton={(categories) => setModal(
          <FilterModal
            setValueRange={setValueRange}
            currentPriceRange={price}
            currentCategories={categories}
            setCategory={setCategory}
          />)}
        isMobile={isMobile}
      />)
  }

  return (
    <Modal
      title='Filtros'
      width={isMobile ? '95vw' : '40vw'}
      height='70vh'
    >
      <OverflowContainer>
        <Container>
          <Content>
            <ValuePickerContainer>
              <ValuePicker
                onChange={(value) => setPrice(value)}
                defaultValue={currentPriceRange}
                isMobile={isMobile}
              />
            </ValuePickerContainer>
            <Line/>
            <CategoryContainer>
              <CategoryHeader>
                <UpLabel>
                  Categorias
                </UpLabel>
                <ButtonLink
                  icon={<FiPlus size={28} />}
                  text='Adicionar Categoria'
                  sideIcon='right'
                  fontSize='1.4rem'
                  onClick={handleOpenCategoryModal}
                />
              </CategoryHeader>
              {categories && categories.map((item, key) => {
                return (item.name) &&
                  <CategoryContent key={key}>
                    {item.name}
                  </CategoryContent>
              })}
            </CategoryContainer>
          </Content>
          <FooterContainer>
            <ButtonLink
              text='Limpar'
              onClick={handleClearFilters}
            />
            <ButtonPrimary
              text='Aplicar filtros'
              height='60%'
              width='45%'
              smallFont
              onClick={handleApplyFilters}
            />
          </FooterContainer>
        </Container>
      </OverflowContainer>
    </Modal>
  )
}
