import React from 'react'

import {
  Container,
  Content,
  BackgroundIcon,
  StatusTitle,
} from './styles'

import {
  Footer,
} from '../../modalWithdrawBalance/styles'

import { FiSend, FiDownload } from 'react-icons/fi'
import { ReactComponent as BankIcon } from '../../../../../assets/bankIcon.svg'
import { useTheme } from '../../../../../context/ThemeContext'

import ButtonPrimary from '../../../../../components/buttons/buttonPrimary'
import ButtonSecondary from '../../../../../components/buttons/buttonSecondary'
import ButtonSecondaryIconLeft from '../../../../../components/buttons/buttonSecondaryIconLeft'


export default function FourStep({ handleCloseModal, formProps, setCurrentStep }) {

  const { currentTheme } = useTheme()

  function backToStart(reset, setStep) {
    reset()
    setStep(0)
  }

  return (
    <Container>
      <Content>
        <BackgroundIcon>
          <BankIcon height='2rem' width='2rem' fill={currentTheme.white} />
        </BackgroundIcon>
        <StatusTitle>Transferência realizada com sucesso! 🎉 </StatusTitle>
        <div className='buttons'>
          <ButtonSecondaryIconLeft
            text='Baixar comprovante'
            width='30%'
            icon={<FiDownload size='2rem' />}
            onClick={null}
            height='40%'
          />
          <ButtonSecondaryIconLeft
            text='Enviar para celular'
            width='30%'
            icon={<FiSend size='2rem' />}
            onClick={null}
            height='40%'
          />
        </div>
      </Content>
      <Footer>
        <ButtonSecondary
          text='Nova transferência'
          width='30%'
          onClick={() => backToStart(formProps.resetForm, setCurrentStep)}
        />
        <div />
        <ButtonPrimary
          text='Fechar'
          width='30%'
          onClick={() => backToStart(formProps.resetForm, setCurrentStep)}
        />
      </Footer>
    </Container>
  )
}
