import React, { createContext, useState, useContext, useEffect } from 'react'
import api from '../api/api'
import { getStoreData } from '../api/store'
import LoadingPage from '../pages/Loading'

const AuthContext = createContext({})

export function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false)
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  function handleLogin(response) {
    const userData = {
      storeData: {
        userId: response.user._id,
        userCPF: response.store.cpf,
        id: response.store.id,
        image: response.user.image,
        email: response.store.email,
        fantasyName: response.store.fantasyName,
        document: response.store.cnpj,
        socialReason: response.store.companyName,
        phoneNumber: response.user.cellPhone,
        cep: response.store.address.cep,
        street: response.store.address.street,
        number: response.store.address.number,
        complement: response.store.address.complement,
        createdAt: response.store.createdAt,
        open: response.store.open,
        active: response.store.active,
        wallet: response.store.wallet,
        bankDetails: response.store.bankDetails,
        userToken: response.store.user_token,
      },
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
    }

    localStorage.setItem('g-admin/userData', JSON.stringify(userData))
    localStorage.setItem('g-admin/token', response.accessToken)
    localStorage.setItem('g-admin/refreshToken', response.refreshToken)

    api.defaults.headers.token = response.accessToken

    setUser(userData)
    setAuthenticated(true)
  }

  function handleLogout() {
    setAuthenticated(false)
    api.defaults.headers.token = undefined
    localStorage.removeItem('g-admin/token')
    localStorage.removeItem('g-admin/refreshToken')
    localStorage.removeItem('g-admin/userData')
    setUser(null)
  }

  function handleUpdateUserField(storeDataField) {
    const updatedUserData = {
      ...user,
      storeData: {
        ...user.storeData,
        ...storeDataField
      }
    }
    localStorage.setItem('g-admin/userData', JSON.stringify(updatedUserData))
    setUser(updatedUserData)
  }

  useEffect(() => {
    async function loadUser() {
      const user = JSON.parse(localStorage.getItem('g-admin/userData'))
      const userId = user?.storeData?.userId
      const token = localStorage.getItem('g-admin/token')
      if (token) {
        try {
          const { data } = await getStoreData(userId, token)
          const userData = {
            storeData: {
              userId: data.user._id,
              userCPF: data.store.cpf,
              id: data.store.id,
              image: data.user.image,
              email: data.store.email,
              fantasyName: data.store.fantasyName,
              document: data.store.cnpj,
              socialReason: data.store.companyName,
              phoneNumber: data.user.cellPhone,
              cep: data.store.address.cep,
              street: data.store.address.street,
              number: data.store.address.number,
              complement: data.store.address.complement,
              createdAt: data.store.createdAt,
              open: data.store.open,
              active: data.store.active,
              wallet: data.store.wallet,
              bankDetails: data.store.bankDetails,
              userToken: data.store.user_token,
            }
          }

          setUser(userData)

          api.defaults.headers.token = token
          setAuthenticated(true)
        } catch {
          handleLogout()
        }
      }
      setLoading(false)
    }

    loadUser()
  }, [])

  if (loading) {
    return <LoadingPage />
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        loading,
        setLoading,
        user,
        setUser,
        handleLogin,
        handleLogout,
        handleUpdateUserField
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)

  return context
}
