import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  `

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const ContentSide = styled.div`
  width: ${({ width }) => width || 'auto'};
  height: 90%;
  padding-top:  2%;
  padding-left: 3%;
`

export const ContentRightHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const ContainerAccounts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 35vh;
  overflow-y: auto;
  padding-top: 2.5%;
  padding-right: 2%;
  ::-webkit-scrollbar-track {
    background-color: white; 
  }

  ::-webkit-scrollbar-thumb {
  background-color: ${({ theme }) => theme.graySecondaryText}; 
}
`

export const ContainerUserAccount = styled.button`
  display: flex;
  width: 100%;
  min-height: 10vh;
  
  border-radius: 16px;
  margin-bottom: 3%;
  padding: 0 3%;
  background-color: ${({ theme }) => theme.grayBackgrounds};
  transition: 0.3s ease;

  ${({ active, theme }) => active && ` border: 2px solid ${theme.primaryGreen}`}
`

export const ContainerUserAccountIconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 100%;
  `

export const ContainerUserAccountInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 100%;
  
  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 0 3%;
    width: 100%;
  }
  
  div .dotDivider{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8%;
  }
`

export const BackgroundIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  width: 6vh;
  border-radius: 3vh;
  background-color: ${({ theme }) => theme.grayInactivy};
`

export const ContentInfo = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
