import { createGlobalStyle } from 'styled-components'
import { device } from './sizes'

export const Global = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    font-size: 12px;
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    width: 100vw;
    overflow-x: hidden;
    height: 100vh;
    text-decoration: 0;

    -webkit-font-smoothing: antialiased !important;
}

  * {
    font-family: 'Nunito';
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    :focus{
      outline: none !important;
    }

    ::-webkit-scrollbar{
      width: 8px;
    }
    ::-webkit-scrollbar-thumb{
      background: #9D9AAC;
      border-radius: 30px;
    }
  }

  #root {
    overflow: hidden;
    margin: 0;
  }

  img {
      pointer-events: none;
  }

  button {
    cursor: pointer;
  }
`
