import styled from 'styled-components'
import { Link } from 'react-router-dom'
import colors from '../../styles/colors'
import { device } from '../../styles/sizes'

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    width: 80vw;
  }
`

export const Message = styled(ContentContainer)`
  padding: 0 5%;
  flex-direction: column;

`

export const FormContainerBase = styled.div`
  width: 30vw;
  display: flex;
  flex-direction: column;

  @media ${device.mobileL} {
    width: 80vw;
  }
`;

export const FormContainer = styled(FormContainerBase)`
  /* @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  } */


`;

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  color: ${colors.title_purple};
  margin-bottom: 0.5em;
`

export const Subtitle = styled.p`
  font-size: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
`

export const FormInput = styled.input`
  width: 100%;
  height: 6.7vh;
  border-radius: 6px;
  border: 1px solid ${({ borderColor }) => borderColor ? 'red' : '#cacaca'};
  outline: 0;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  background: #F5F6F4;
  transition: 0.3s;

  &:focus {
    border: 1px solid ${colors.light_green};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

export const ErrorMsg = styled.span`
  margin: 0;
  margin-top: ${({ mtop }) => mtop}; 
  margin-bottom:${({ mbottom }) => mbottom};
  font-size: 0.9rem;
  color: red;
  font-weight: 600;
`;

export const RememberContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckInput = styled.input``;

export const Text = styled.label`
  margin-left: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
`;

export const Button = styled.button`
  width:  100%;
  height: 6.7vh;
  outline: 0;
  border: 0;
  font-size: 1.4rem;
  margin: 2.5rem 0;
  cursor: pointer;
  transition: 0.3s;
  background: ${colors.span_green};
  color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 6px;

  &:hover {
    background: ${colors.secondary_green};
  }
`;

export const ForgotPassword = styled(Link)`
  align-self: center;
  font-size: 1.2rem;
  color: ${colors.light_green};
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    color: ${colors.secondary_green}
  }
`;
