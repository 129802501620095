import React from 'react'

import {
  ImageContainer,
  SmallText,
  Text
} from './styles'

import { ReactComponent as BankSvg } from '../../../../../assets/bankIcon.svg'
import { useTheme } from '../../../../../context/ThemeContext'

export default function AccountInfo({ accountType, agency, account, bank }) {
  const { currentTheme } = useTheme()

  return (
    <>
      <ImageContainer>
        <BankSvg fill={currentTheme.white} width='6vh' height='auto' />
      </ImageContainer>
      <Text grayText>{bank}</Text>
      <SmallText>{accountType}</SmallText>
      <Text>Ag. {agency}</Text>
      <Text>Conta {account}</Text>
    </>
  )
}
