import React from 'react'
import { Modal } from '../modal'
import { useTheme } from '../../context/ThemeContext'
import { useModal } from '../../context/ModalContext'
import ButtonPrimary from '../buttons/buttonPrimary'

import {
  Container,
  Text,
  TextAtention
} from './styles'

export default function ErrorModal({ closeModal, errorMessage }) {
  const { primaryPink, secondaryLightPink } = useTheme().currentTheme
  const { setModal } = useModal()

  function handleClose() {
    closeModal()
    setModal()
  }

  switch (true) {
  case errorMessage.includes('Email is not validated'):
    errorMessage = 'O email já está registrado'
    break
  case errorMessage.includes('Store user already exists for document'):
    errorMessage = 'O CNPJ já está em uso'
    break
  case errorMessage.includes('already exists'):
    errorMessage = 'Usuário já cadastrado'
    break
  case errorMessage.includes('it was not possible to validate the CNPJ'):
    errorMessage = 'Não foi possível validar o CNPJ'
    break
  case errorMessage.includes('Waiting... Too many requests'):
    errorMessage = 'Aguarde um pouco, muitas requisições seguidas.'
    break
  case errorMessage.includes('Serve timeout'):
    errorMessage = 'Problema com a validação do CNPJ'
    break
  default:
    errorMessage = ''
    break
  }

  return (
    <Modal width='28vw' height='28vh' noHeader>
      <Container>

        <Text>
          Algo de errado aconteceu, tente novamente em alguns instantes
        </Text>
        <TextAtention>
          {errorMessage}
        </TextAtention>
        <ButtonPrimary
          text='Ok'
          width='8vw'
          height='6vh'
          smallFont
          background={primaryPink}
          backgroundHover={secondaryLightPink}
          onClick={() => closeModal ? handleClose() : setModal()}
        />

      </Container>
    </Modal>
  )
}
