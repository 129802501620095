export const formModel = {
  formId: 'addProductForm',
  requiredErrorMsg: 'Este campo é obrigatório.',
  requiredOptionErrorMsg: 'Por favor, selecione uma opção.',
  maskInputMsg: 'Campo incompleto',
  formFields: {
    productName: {
      name: 'productName',
      label: 'Nome do produto',
      placeholder: 'Inserir nome',
    },
    productDescription: {
      name: 'productDescription',
      label: 'Descrição',
      placeholder: 'Inserir descrição',
    },
    productCategory: {
      name: 'productCategory',
      label: 'Categoria',
      placeholder: 'Selecionar categoria',
      tooltip: 'Categorias que seu produto se encaixa'
    },
    productVariationName: {
      name: 'productVariationName',
      label: 'Nome da variação',
      placeholder: 'Inserir variação',
    },
    productVariation: {
      name: 'productVariation',
      label: 'Variação',
      placeholder: 'Inserir modelos',
      tooltip: 'Ex: Tamanho, Cor, etc...',
    },
    productPrice: {
      name: 'productPrice',
      placeholder: 'R$ 1,00 - R$ 5,00',
      label: 'Valor',
    },
    productWeight: {
      name: 'productWeight',
      label: 'Peso da unidade em kg',
      placeholder: '1kg',
    },
    productUnits: {
      name: 'productUnits',
      label: 'Unidades do produto',
      placeholder: 'Unidades',
    },
    productDimensions: {
      name: 'productDimensions',
      label: 'Tamanho do produto (cm)',
      tooltip: 'Unidade: centímetros',
    },
    productHeight: {
      name: 'productHeight',
      placeholder: 'Alt.',
    },
    productWidth: {
      name: 'productWidth',
      placeholder: 'Larg',
    },
    productLength: {
      name: 'productLength',
      placeholder: 'Compr.',
    },
    productImages: {
      name: 'productImages',
      placeholder: 'Selecione as imagens'
    },
    InputFileLabel: 'Carregar arquivo',
    InputFileLabelLoaded: 'Arquivo carregado',
  },
}

export const initialValues = {
  [formModel.formFields.productName.name]: '',
  [formModel.formFields.productDescription.name]: '',
  [formModel.formFields.productCategory.name]: '',
  [formModel.formFields.productVariationName.name]: '',
  [formModel.formFields.productVariation.name]: [],
  [formModel.formFields.productPrice.name]: '',
  [formModel.formFields.productUnits.name]: 0,
  [formModel.formFields.productDimensions.name]: {
    width: 0,
    height: 0,
    length: 0,
    weight: 0
  },
  [formModel.formFields.productImages.name]: []
}
