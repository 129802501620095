import styled from 'styled-components'
import { device } from '../sizes';

export const Container = styled.div`
  height: 100svh;
  display: grid;

  grid-template-areas:
    "sidebar form register"
    "sidebar form step";

  grid-template-columns: 30% 50% 20%;
  grid-template-rows: 10% 90%;

  @media ${device.mobileL} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${device.mobileL} {
    display: flex;
    justify-content: center;
  }
`;
export const Sidebar = styled.aside`
  grid-area: sidebar;
  background: ${({ theme }) => theme.grayBackgrounds};
`

export const Step = styled.div`
  grid-area: step;
  background: ${({ theme }) => theme.white};
`

export const Resize = styled.div`
  display: none;

  @media ${device.mobileL} {
    width: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -80vh;
  }
`;

export const Header = styled.div`
  grid-area: register;
`

export const Content = styled.div`
  grid-area: form;
  background: ${({ theme }) => theme.white};
`

