export default {
    formId: 'nfeForm',
    requiredErrorMsg: 'Este campo é obrigatório.',
    maxCharAmount: 'Máximo de ${max} dígito.',
    incompleteErrorMsg: 'Campo incompleto.',
    formField: {
      danfeCte: {
        name: 'danfeCte',
        label: 'Número da DANFE OU CTE'
      },
      nrDoc: {
        name: 'nrDoc',
        label: 'Número do documento'
      },
      serie: {
        name: 'serie',
        label: 'Serie do documento'
      },
      valor: {
        name: 'valor',
        label: 'Valor'
      },
      cfop: {
        name: 'cfop',
        label: 'CFOP da NFE'
      },
      /* tpDocument: {
        name: 'Documento',
        label: 'Tipo do documento'
      } */
    },
  }
  